$mp: !important;

.VirtualScroll.viewport {
  padding-bottom:2rem;
  min-height: 300px;// min height failsafe just in case
  width: 100%;
  overscroll-behavior-y: contain;
  .visible_area {
    width: 100%;
    padding: 0 .25rem;
    .inner_content{
      width:100%;
    }/*inner_content*/
  }/*visible_area*/
}/*viewport*/
