.tip_pages_snap_ctrls {
  padding: 0.5rem 0.25rem 0;
}
.tip_pages_snap_ctrls .tip_pages_snap_btn {
  border-radius: 1em !important;
  padding: 0.25rem 0.5rem 0.4rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1.2rem;
}

.tip_pages_snap_cont {
  border: unset !important;
  padding: 0.5rem 0.75rem !important;
}