$mp: !important;

%go-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  height: 26px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  padding: 4px 7px;
  border-radius: 5px;
  overflow: hidden;
  margin: unset;
  width: 100% !important;
  justify-self: center;
  align-self: center;
  text-align: center;
  z-index: 1;
}
%input-icon{
  position: relative;
  color: #333;
  font-size: 1em;
  line-height: 1.3;
  font-family: sans-serif;
}
%input-icon-style{
  margin: .5em 0;
  background-color: #fff;
  border-color: #ddd;
  border-width: 1px;
  border-style: solid;
  text-shadow: 0 1px 0 #f3f3f3;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 20%);
  border-radius: .3125em;
};
%tasks-label{
  display: flex;
  text-shadow: 2px 2px 2px #ccc;
  // justify-content: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  gap: .5rem;

  .custom_input_wrapper{width: 100%;}

  .task_tags_edit_cont {
    display: flex;
    flex-flow: row-reverse;
    gap: 1rem;

    .task_tags_btn,
    .tasks_sort_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.loader {
        height: 2rem;
        width: 2rem;
        border-width: 0.4rem;
      }

      // .loader
      &.save_tasks {
        color: green;
      }
    }

    // task_tags_btn
  }
}

.tasksForm, .tagsForm{
  .tasks_sort_icon{
    font-size: 1.4rem;
    cursor: pointer;
  }

  .tasksLabel{@extend %tasks-label;}
  .tags_ICont, .tasks_ICont{
    display: grid;
    grid-template-areas: "input go";
    grid-template-columns: 8fr 1fr;
    padding: unset $mp;
    gap: 0.5rem;
    padding: 0.25rem $mp;
    @extend %input-icon;
    .tagsInput, .tasksInput {
      grid-area: input;
    }
    .tasksInput{
      padding: .5rem .75rem;
    }
    .tasks_inBtn_wrapper, .tags_inBtn_wrapper{
      display: flex;
      flex-flow: column nowrap;
      grid-area: go;
      gap: .5rem;
      
      .tags_inBtn, .tasks_inBtn {
        @extend %go-btn;
        &.go-btn{
          background-color: #87cf96 !important; 
          color: white;
        }
        &.close-btn{
          background-color: #ccc !important;
          // color: white;
          grid-area: close;
          border: unset;
        }
      }// tasks_inBtn
    }// tasks_inBtn_wrapper

    .tasks_inBtn_wrapper{
      justify-content: flex-end;
      padding-bottom: 1rem;
    }
    .tags_inBtn_wrapper{justify-content: center;}
    
    // .tasks_inBtn{align-self: flex-start;}
  }// .tags_ICont

  .tags_ICont{
    @extend %input-icon-style;
    .tagsInput {
      border: transparent $mp;
    }
  }

  .tags_side_ctrls_cont {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    padding-bottom: 1rem;
    gap: 1rem;
  }

  .tagsHolder {
      flex-flow: row wrap;
      max-height: 115px;
  }

  .tagsHolder, .tasksHolder{
    width: 90%;
    margin: 5px auto;
    padding: 3px;
    background-color: #ddd;
  }
  .sort_wrapper{width: 100%; flex-flow: row wrap;}

  .tagsHolder, .tasksHolder, .sort_wrapper{
    display: flex;
    min-height: 40px;
    text-align: left;
    overflow-y: auto;

    .tags_box, .tasks_box {
      margin: 2px 3px;
      // display: inline-block;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      .tags_text, .tasks_text {
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
      }// .tags_text, .tasks_text
      .tags_text{
        max-height: 30px;
      }// tags_text
      .tasks_text{
        max-height: unset $mp;
      }// tasks_text

      .tags_inner, .tasks_inner {
        margin: 3px;
        display: inline-block;
        vertical-align: top;
      }// .tags_inner, .tasks_inner
      .tasks_check_cont, .tasks_close_cont{
        height: 100%;
      }// tasks_check_cont
      .tags_inner, .tasks_check {
        height: 20px;
      }// tags_inner
      .tasks_text {
        height: unset $mp;
      }// tasks_inner
      .tasks_check, .tasks_close{ margin-top: .4em !important; }
    }// tags_box, .tasks_box
    .tags_box {
      height: 30px;
      max-width: 95%;
      .tags_text{
        display: flex;
        align-items: center;
      }// .tags_text
    }// tags_box

    .tags_tasks_no_data{padding: .5rem 1rem;}
  }// *Holder
}// tasksForm

.faux_error{
  color: red; margin: unset;
  color: red;
  font-size: .8em $mp;
  width: 90%;
  margin: 0 auto $mp;
  text-align: left;
}

.part_temp_cont{
  .tasksHolder{
    max-height: 24rem $mp;
  }// tasksHolder
}

.snap_section {
  .tasksHolder{
    border: 1px solid #ccc;
    max-height: 24rem $mp;
    background-color: unset;
    .sort_wrapper{background-color: unset;}
  }// tasksHolder
  // .tasksLabel{
  //   @extend %tasks-label;
  // }// tasksLabel
}// snap_section