.paper_cont div.paper_icon.right .paper_icon_menu_wrapper {
  justify-content: flex-start;
  right: 0;
  left: unset;
  padding: 0 4rem 0 1rem;
}

.paper_cont div.paper_icon.left .paper_icon_menu_wrapper {
  justify-content: flex-end;
  left: 0;
  right: unset;
  padding: 0 1rem 0 4rem;
}

.paper_cont div.paper_icon.right .paper_icon_btn {
  right: -1.5rem;
  left: unset;
}

.paper_cont div.paper_icon.left .paper_icon_btn {
  left: -1.5rem;
  right: unset;
}

.paper_cont div.paper_icon {
  display: none;
  position: absolute;
  font-size: 3rem;
  bottom: 3rem;
  left: 50%;
  width: 4rem !important;
  height: 4rem;
  touch-action: none;
  user-select: none;
}
.paper_cont div.paper_icon.block .paper_icon_handle {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 2.3rem;
  box-shadow: 4px 4px 4px #ccc;
  transition: 1s border-color;
}
.paper_cont div.paper_icon.active .paper_icon_handle {
  border-color: #ffeb3b;
}
.paper_cont div.paper_icon.inactive .paper_icon_handle {
  border-color: #4caf50;
}
.paper_cont div.paper_icon.menu .paper_icon_handle {
  border-color: #2196f3;
}
.paper_cont div.paper_icon.left .paper_icon_menu_cont {
  flex-flow: row-reverse;
}
.paper_cont div.paper_icon .paper_icon_dialog_cont .paperPopupDialog {
  outline: unset;
  border: unset;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  font-size: 1.2rem;
  width: 90vw;
  max-width: 450px;
  height: 80vh;
}
.paper_cont div.paper_icon .paper_icon_dialog_cont .paperPopupDialog .paperIcon_dialog_close_btn {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 1rem;
  color: var(--off-black, #333);
  cursor: pointer;
}
.paper_cont div.paper_icon .paper_icon_handle {
  width: 100%;
  height: 100%;
  text-shadow: 2px 2px 2px #ccc;
  box-shadow: 2px 2px 2px #ccc;
  touch-action: none;
  user-select: none;
  outline: white solid 2px;
  border: 3px solid white;
}
.paper_cont div.paper_icon .paper_icon_menu_wrapper {
  display: flex;
  right: 0;
  height: 80%;
  position: absolute;
  width: 16rem;
  background: #f6f6f6;
  top: 0.5rem;
  border-radius: 1em;
  box-shadow: 2px 2px 2px #ccc;
  z-index: -1;
  transition: 0.5s width;
}
.paper_cont div.paper_icon .paper_icon_menu_wrapper.inactive {
  padding: unset !important;
  width: 4rem;
}
.paper_cont div.paper_icon .paper_icon_menu_wrapper .paper_icon_menu_cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  width: 3rem;
  height: 100%;
  flex: 0 0 100%;
  transition: 2s opacity;
}
.paper_cont div.paper_icon .paper_icon_menu_wrapper .paper_icon_menu_cont .paper_option_btn {
  cursor: pointer;
  font-size: 2rem;
  color: var(--off-black, #333);
}
.paper_cont div.paper_icon .paper_icon_menu_wrapper .paper_icon_menu_cont .paper_option_btn.disabled {
  color: #ccc;
}
.paper_cont div.paper_icon .paper_icon_btn {
  position: absolute;
  text-shadow: 2px 2px 2px #ccc;
  top: -1rem;
  right: -1.5rem;
  font-size: 1rem;
  color: #f6f6f6;
}