.charcoal.filter_wrapper .filter_cont {
  background-color: #676363;
}
.charcoal.filter_wrapper .filter_cont input {
  background-color: #676363;
  color: white;
}
.charcoal.filter_wrapper .filter_cont input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1;
  /* Firefox */
}
.charcoal.snap_core_snap_ctrls {
  justify-content: center;
  background-color: #676363;
  border-radius: 7px 7px 25px 25px;
}
.charcoal.snap_core_snap_ctrls .snap_btn {
  color: #ccc;
}
.charcoal.snap_core_snap_ctrls .snap_btn.active {
  color: #b5b73d !important;
}