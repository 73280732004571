$mp: !important;

.notify-icon{
  grid-area: note;
  align-self: center;
  &.active{color: var(--active-blue);}
}

.notifyDialog{
  border: unset;
  border-radius: 7px;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.3);
  &::backdrop{
    backdrop-filter: blur(2px);
  }
  header{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .notify-image-icon{
      font-size: 2.5rem;
      color: var(--site-main);
      text-shadow: 2px 2px 2px #ccc;
      &.remove{color: red;}
    }
    .notify-title{text-shadow: 2px 2px 2px #ccc;}
    p{
      max-width: 15rem;
      font-weight: 100;
    }
  }
  footer{
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    .notify-btn{
      border-radius: 7px;
      &:focus{outline-color: var(--active-blue);}
    }
    .opt-in{
      background-color: var(--site-main);
    }
    .opt-out{
      background-color: red;
      color: white;
    }
    .test{
      flex: 0 100%;
      width: 100%;
      margin-top: 0.5rem;
      border: 2px solid;
    }
  }
}