$min5: #ff9980;
$min4: #ffad99;
$min3: #ffc2b3;
$min2: #ffd6cc;
$min1: #ffebe6;
$min0: #fff0ff; //same
$plus0: #fff0ff; //same
$plus1: #eaeafb;
$plus2: #e6e6fa;
$plus3: #d4d4f7;
$plus4: #bfbff2;
$plus5: #aaaaee;

div.priority_badge{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2em;
  height: 2.2em;
  // padding: 0.5em;
  font-size: 80%;
  &:before {content: attr(value);} /* value attribute in the element - the example uses title */
  /* [css content](https:css-tricks.com/css-content/) */
  &.min5 { background-color: $min5; }
  &.min4 { background-color: $min4; }
  &.min3 { background-color: $min3; }
  &.min2 { background-color: $min2; }
  &.min1 { background-color: $min1; }
  &.min0, &.plus0{ background-color: $plus0;}
  &.plus1{ background-color: $plus1; }
  &.plus2{ background-color: $plus2; }
  &.plus3{ background-color: $plus3; }
  &.plus4{ background-color: $plus4; }
  &.plus5{ background-color: $plus5; }
}