$mp:!important;
@import '../../../css/media';

:root{
  --tallest: 700px;
}
// example: background-color: var(--active-blue)/*#2d2dad*/ /*blue*/ $mp;

.details_modal_cont{
  &.block{display: block $mp;}
  // height: auto $mp;/*gets rid of side scrollbar*/
  %topper{
    top: 35px /*-22px*/ $mp;
    right: -.75rem $mp;
  }
  height: 103vh $mp;
  .closeBtn.bin{
    // right: 30px $mp; @extend %topper;
    // top: 13px !important;
    top: -10px/*-23px*/ !important;
    // right: 42px!important;
    right: -.75rem $mp;
  }
  .hideBtn{@extend %topper;}
  .binBtn{ right: -.75rem $mp; top: 85px; z-index: 1;}
  .feat_header, .narr_header, .arc_view_header{padding-right: 1rem;}
}

.details_modal, .segue_view_cont{
  padding:unset $mp;
  margin: 2vh auto 0 $mp;
  // max-height: var(--tallest) $mp;
  // height: 100% /*90%*/ $mp;
  height: 96% $mp;// 85%;
  .details_content, .segue_view_content{
    // max-height: var(--tallest);
    max-height: unset;
    height: 100%;
    .details_display{
          // padding: 10px 10px .25rem;/*20px*/
          padding: 0 0 0.25rem;
      height: 100%;
      .details_cont{
        border: 1px solid #ddd;
        overflow-y: auto;
        overflow-x: hidden;
        // height: 36rem;
        height: 100%;
        .details_caption_display{
          .details_caption{
            // background: black;
            // color: white;
            margin-bottom: 0.25rem;
            .dv_Caption_wrap{
              &.sections{width: 85%;}
              width: 95%;
              // padding: 1rem;
              padding: 1rem 1rem 0;
            }
          }// details_caption
        }// details_caption_display
        .rich_cont{
          // rich preview container
          background: #000;
          color: #ccc;
        }
        .dv_Cat{ text-shadow: 2px 2px 2px #ccc; font-size: 1rem /*5vw*/;
          grid-area:category;
          cursor: pointer;
        }
        .dv_Cat.mobile{font-size: .9rem;}
        .dv_Head{
          display: grid;
          grid-template-areas:
          "title"
          "date";
          grid-template-rows: auto 1fr;
          grid-area: title;
        }
        .dv_Title {font-size: 1.3rem /*3.7vw*/; /*margin-top:.5rem;*/ display:block; grid-area:title; user-select: text;}
        .dv_Title.mobile, .dv_Desc.mobile {font-size: 1rem; line-height: 1.2rem;}
        .dv_Date{
          grid-area: date; 
          font-size: 1rem;
          color: #9A9A9A;
          padding-left: 1rem;
          &.mobile{
            font-size: .9rem;
          }
        }
        .dv_event_wrapper{
          padding: 0 1rem;
          width: 100%;
          margin: 0 auto .5rem;
          grid-area: event;
          .dv_event_inner{
            display: grid;
            // grid-template-areas:
            //     "type type"
            //     "date time";
            grid-template-areas:
                "type type"
                "hr hr"
                "date date"
                "time time";
            grid-template-columns: 1fr 1fr;
            border: 1px solid #ccc;
            border-radius: 7px;
            padding: .5rem;
            
            .dv_event_type_label{
              grid-area: type; text-align: center; font-size: .9em; margin: unset;
              &::first-letter {
                text-transform: capitalize;// WORKS
              }
            }
            .dv_event_type_hr{grid-area: hr; margin: 0 0 .25rem;}
            .dv_event_cont{
              justify-self: flex-start; 
              align-self: center; 
              display: flex;
              flex-flow: row;
              *{ margin: 0 .5rem 0 0; font-size: .9em; }
            }// dv_event_cont
            .dv_event_date_cont{grid-area: date; justify-self: center;}
            .dv_event_time_cont{grid-area: time; justify-self: center;}
          }// dv_event_inner
        }// dv_event_wrapper
        .dv_Icon_Cont{
          display:flex; flex-flow: column nowrap; justify-content: flex-start; align-items: center; grid-area: icon;
        }
        .dv_Icon.mobile {width:3rem; height:3rem; font-size:3.5rem;}
        .dv_Icon
        {
            display: inline-block !important;
            width: 4rem /*10vw */;
            height: 4rem /*10vw */;
            margin: .5rem !important;
            position: relative;
            vertical-align: top;
            // grid-area:icon;
            font-size:3.5rem;
            color:rgba(0, 0, 0, 0.3);
            &.pending{/*color: red;*/}// no color change
            &.active{color: var(--status-active-lt);}
            &.stop{color: var(--status-stop-lt);}
            &.done{color: var(--status-done-lt);}
        }
        .dv_Hr{margin: 10px 0;}
        .dv_Share{}
        .dv_share_btn{ text-decoration: none; &:hover{text-decoration: none; }}
        .dv_Url, .dv_Core{ /*max-height: 50px;*/ overflow: hidden; grid-area:core; }
        .dv_Desc{font-size: 1rem; color: #7a7a7a; grid-area: desc_head;}
        .dv_desc_wrapper{
          display: grid;
          grid-template-areas:"desc_head" "desc_foot";
          grid-area: desc;
          gap: .5rem;
          .dv_desc_see_more{
            grid-area: desc_foot; 
            justify-self: flex-end; 
            color: blue; 
            padding: 0 1.5rem;
            cursor: pointer;
          }// dv_desc_see_more
        }// dv_desc_wrapper
        .dv_Other{grid-area:other;}
        // .dv_Note{
        //   width:100%; border-radius:7px; background-color: #fff !important;
        //   max-height:5rem;
        //   overflow:hidden;
        //   border-color: #ccc; margin:10px auto;
        //   background-color: rgba(204, 204, 204, 0.27);
        //   display: block; /*min-height:20vw;*/
        //   grid-area:note;
        // }
        .dv_Note{height: unset $mp; padding: 1rem 1.5rem $mp; font-weight: 400;}
        .dv_Embed{margin-bottom: 1rem;}/*padding spaces inside the w3-card*/
        .dv_Tags{width:100%; margin:.7rem auto; grid-area:tags;}
        .dv_Prev, .dv_PrevImg, .dv_PrevUrl{
          width:90%;
          text-align:center;/*background-color:#ccc;*/
          background-color:#e9e9e9;
          display: block;
          margin:10px auto;
          padding:5px;
          color:#333 !important;
          &:hover{color:#333 !important;}
        }

        .dv_Txt{display:inline-block;width:75%;}


        .dv_Cat, .dv_Title, .dv_Desc{display:inline-block;width:100%;/*float:left;*/}
        .dv_Url, .dv_Core, .dv_Other{margin:.7rem auto; display:block; border:none;width:100%;}
        .dv_Url, .dv_Core p{display:block; /*max-height: 40px;*/ text-overflow: ellipsis;;}
        .dv_Cat, .dv_Title, .dv_Desc, .dv_Url, .dv_Core, .dv_Note, .dv_Other, .dv_Tags{padding-left: 1rem;}
        .dv_Url, .dv_Core, .dv_Desc, .dv_Note, .dv_Other, .dv_Tags{font-size: .9rem; padding-right: 1rem;}
        .dv_Url, .dv_Core.mobile, .dv_Note.mobile, .dv_Other.mobile, .dv_Tags.mobile{font-size: .8rem;}

        .dv_Caption_wrap{
          grid-area: caption;
          display: grid;
          grid-template-areas:  "tHr tHr" "profile caption" "bHr bHr";
          grid-template-columns: .6fr 4fr;
          gap: .25rem;
          padding: 0 1rem;
          .dv_Profile{
            grid-area: profile;
            height: 2rem;
            width: 2rem;
            // margin-left: 1rem;
            cursor: pointer;
            .image_area {
              height: 100%;
              width: 100%;
            }// image_area
            .panel_profile_icon{font-size: 1.4rem $mp;}
            .d3-profile-rounded {border-width: 2px $mp;}
            img, canvas{
              height: 100%;
              width: 100%;
              object-fit: cover;
            }// img, canvas
          }// snap_profile
          .dv_Caption{grid-area: caption; font-size: .9rem;}
          hr{ margin: .5rem; border-color: #ddd;}
          .tHr{grid-area: tHr;}
          .bHr{grid-area: bHr;}
        }

        .ellipsis{}

        .d3-med::after{width:3rem; height:3rem;}

        .display_area, .disp_area{
          height:200px $mp;// this is competing with .prev_cont
          width: 200px;// this doesn't need to be important or it forces banners into a 1:1 box
        }

        }/*details_cont*/
    }/*details_display*/
  }/*details_content */
  .bio_links{padding-top: 0 $mp;}// 1rem
}/*details_modal*/

  .detail_viewer_top{
    display:grid;
    width:100%; 
    padding: 1.5rem 0.5rem 0; 
    text-align: left;
    grid-template-columns: 5fr 1fr;
    grid-template-areas:
    "category review"
    "title icon"
    "core icon"
    "options options"
    "event event"
    "desc desc"
    //"tags tags"
    "caption caption";
    grid-template-rows: min-content min-content min-content 1fr;
    gap: .5rem;
    .review_rate_cont{grid-area: review;
      .rate_btn{
        &:before{color: #ccc;}
      }
    }//review_rate_cont
  }

.details_modal.detail{
  // margin-top:2rem $mp;
  // margin-top: 4.8rem $mp;
  max-height: 88vh;

  // transform: scale(1.02);//.95
  // transform-origin: top;
  .details_content{
    // max-height: 80vh;
    overflow-y: auto;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    min-height: 200px;
    .details_display{
      // padding:10px;
      .details_cont{
        // height: 32.5rem;/* works with details_snap.snap_cont (this has a lesser effect on the total height) */
      }/*details_cont*/
    }/*details_display*/
  }/*details_content*/
}
.preview_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content /*100% during snapper phase*/;
  width: unset;
  &.image{
    width:100%;
    padding: 5%;
    height:50% /*100% during snapper phase*/;
    .prev_cont {
      margin: 0 auto;
      // width: 100px;
      // height: 100% $mp;// why did i set this in .image? at 100% and make it !important?
      overflow: hidden;
      img{
        object-fit: contain;
        // width should be whatever it happens to be
        height: 100%;
      }// img
      //position: relative;
    }
  }
  &.banner{
    .prev_cont{
      width: 100% $mp;
      height: 100% $mp;
      img{
        object-fit: cover;
        height: 100%;
        width: 100%;
      }// img
    }
  }
  .prev_cont{
    display: flex;
    justify-content: center;
    align-items: center;
    height: unset $mp;
    width: unset $mp;
  }
}

.snapBox.details_snap{
  // padding: 10px;
  padding: 0 0 10px;// bottom padding
  height: 100%;
  display: flex;
  flex-flow: column;
    .details_snap.snap_cont{
      height: 33rem;/* this is the main element that affects the details_snap container height - along with details_cont */
      border: unset $mp;
      flex: 1 auto;
      // max-height: 34rem;
      // height: 77vh;
      padding-bottom: .25rem;
      position: relative;
      .details_snap_snap_section{
        padding-bottom: unset;
        height: 100%;
        .details_display{padding-bottom: unset;}
        .activity_mplx_container{
          
        }
        .details_rawhide{
          padding: 0 1rem;
          .rawDrop_display_cont{
            display: flex;
            justify-content: center;
            flex-flow: row nowrap;
            gap: 1rem;
          }// rawDrop_display_cont
        }// details_rawhide
      }// details_snap_snap_section
    }// .details_snap.snap_cont
}// .snapBox.details_snap


.details_snap_snap_section {
  // LATER: relocate this to the appropriate parent - idk if li 223 isn't too narrow a scope
  .public-DraftStyleDefault-pre{
    width: 100%;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    -ms-overflow-style: none;
    
  }// outer pre
  pre::-webkit-scrollbar{
      display: none;
  }

  pre {
    color: white;
    background: #3f51b5;
    padding: 0 .25rem;
  }// inner and outer pre

  .feat_header, .arc_view_header{
    margin-top: 0.5rem;
  }
  .narr_header{
    margin: .5rem .5rem 0;
    width: auto $mp;
  }

}

.copy_modal_cont{
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 30px;
  *{
    margin: 10px;
  }
  .copy_textarea{ padding: 10px; height: 200px; resize: none; }
  .copy_btn{}
}

.details_options{
  font-size: 1.3rem;
  color: #7e7e7e;
}
.details_ctrls{
  display: flex;
  justify-content: space-evenly;//flex-start
  flex-flow: row;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
  align-items: center;
  grid-area: options;
  .details_more_ctrls{
    width: 100%;
    hr{ margin: 0.5rem 0; }
    .rawDrop_display_cont{
      flex-flow: column nowrap $mp;
      .details_main_ctrls{padding-top: .5rem;}
      .details_more_opt_btns{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 8%;
        @include smartphone{ gap: 6%; }
        @include tiny{ gap: .5rem 4%;}
      }// details_more_opt_btns
    }// rawDrop_display_cont

  }
  // margin: 0 2rem;
  &.float{
    flex-flow: column;
    background: white;
    border-radius: 0 7px 7px 0;
  
    position: absolute;
    right: -1rem;
    // top: 7rem;
    bottom: 5rem;
  }

  .my_info_btn {background: white;}

  .icon {
    height: 1.9rem $mp;
  }

  &.stretch{
    max-height: 10rem;
  }
  &.squish{
    max-height:0rem;
  }
}

.details_spacer{
  margin-bottom: 8rem;
}

dialog.SendShare{
  &.active{
    width: 90%;
    display: flex;
    @include mini {
      max-width: 30rem !important;
    }
  }// active
  .dialog-btn{
    position: absolute;
    border: 1px solid #ccc;
    border-radius: 2rem;
    padding: .25rem;
    top: .5rem;
    right: .5rem;
  }// dialog-btn
  .dialog-cont{
    display: flex;
    .share_options_snapBox{
      height: 100%;
      .share_options_snap_cont{height: 95%;}
    }
    .share_options_snap_ctrls{align-items: center;}
    .share_options_snap_btn{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .25rem !important;
    }
  }// dialog-cont
}