@import '../../../../css/media';
$mp: !important;

%clamp-1,
%clamp-2,
%clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: block !important;
  display: -webkit-box !important;
  line-height: 1.5;
}

%clamp-0 {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

%clamp-1 {
  -webkit-line-clamp: 1;
  white-space: initial;
}

%clamp-2 {
  -webkit-line-clamp: 2;
  white-space: initial;
}

%clamp-3 {
  -webkit-line-clamp: 3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
%hide-scroll::-webkit-scrollbar,
%hide_scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
%hide-scroll,
%hide_scroll {
  -ms-overflow-style: none;
}


.calendar_cont{
  padding: 1rem;
  height: 100%;
  overflow: auto;

  // has footer ctrls view
  &.foot_view{
    .fc{
      height: 100%;
      .fc-header-toolbar {margin-bottom: 1rem; gap: unset;}
      .fc-toolbar-chunk:last-child {
          grid-area: nav;
          .fc-button-group {
            width: unset;// DEPRECATED - i think this isn't being used anymore - no nav at the bottom
          }
      }
      .fc-footer-toolbar{
        flex-flow: column nowrap;
        margin-top: 1rem;
        .fc-toolbar-chunk{
          width: 100%;
          .fc-button-group{width: 100%;}
        }
      }
    }
  }

  .fc{
    // full calendar outer wrapper
    // height: 100%;
    &:has(.fc-dayGridMonth-view) {}
    
    &:has(.fc-timeGridWeek-view) {
      // .fc-toolbar-title{font-size: 1.1rem; font-weight: 500;}
    }
  
    &:has(.fc-timeGridDay-view) {
      // .fc-toolbar-title{font-size: 1.4rem;}
    }
  }

  .fc-view-harness{
    .fc-listMonth-view{
      .fc-list-event-time{
        width: 20%;
        white-space: unset !important;
      }// fc-list-event-time
      .fc-list-event-graphic{
        padding: unset;
      }
      .fc-list-event-title{
        a{@extend %clamp-3;}
      }

      .fc-scroller{
        @extend %hide-scroll
      }
    }// fc-listMonth-view
  }
    

  // use for mobile view to change the week header sun, mon, etc.
  .fc-col-header-cell{
    font-size: 0.8em;
    @include smartphone {
      // effects everything smaller than..
      font-size: 0.6em;
    }

  }
  .fc-header-toolbar{
    display: grid;
    // grid-template-areas: "title nav" "time time";
    grid-template-areas: "title title" "nav nav";
    gap: .5rem 0;
    .fc-toolbar-title{
      font-size: 1.4rem; 
      text-align: center; 
      margin-bottom: .5rem;
      @include mobile {
        font-size: 1rem;
      }
    }// fc-toolbar-title
    .fc-toolbar-chunk:first-child{ grid-area: title;}
    .fc-toolbar-chunk{grid-area: nav;}
    .fc-toolbar-chunk:last-child{
      grid-area: time;
      display: flex;
      justify-content: center;
      button{flex:1;}
      .fc-button-group{ width: 100%; flex: 1;}
      @include mobile {
        // effects everything smaller than..
        // display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        .fc-button-group{ margin-left: unset;}
      }
    }
  }

  .fc-more-popover{
    // popover absolute positioning confined to the calendar itself
    top: 25% $mp;
    left: 0 $mp;
    width: 100%;
  }
}