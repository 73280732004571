.pTree_form .info_booth {
  padding-top: 2rem;
}
.pTree_form .info_booth .previewBox {
  padding: 1rem;
  position: relative;
}
.pTree_form .info_booth .previewBox .rich_refresh_btn {
  position: absolute;
  top: -1.5rem;
  right: 0;
}