
$mp:!important;

  .edit_resize_box{
    display: flex $mp;
    justify-content: center;
    align-items: center;
  }
  .edit_preview{
    position: relative;
    .reset_btn{
      position: absolute;
      top: .25rem;
      right: .25rem;
      color: red;
      font-weight: bold;
    }
  }
