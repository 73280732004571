/*
// IMPORTANT: the cascade for tiny and mobile work in the opposite the smaller must be last
// to override the larger

  @include mobile{ gap: 6%; }
  @include tiny{ gap: 4%;}
*/
/*DOCS: mobile first setup "min" 
* IMPORTANT - mobile first items have to cascade in a specific order from small to large otherwise
* ISSUE the smaller queries cancel the larger ones out
* keep the same order as below
* GOTCHA: NOTE MOBILE && TINY ARE NOT IN THE SAME FLOW AS EVERYTHING ELSE - WEARABLE IS THE BOTTOM OF THE MULTI DEVICE FLOW
*/
/**
  * NOTE: this is a mobile first setup.
  */
/**
    usage:

    @use "../../scss/media";
    @include media.mobile{
      padding: 0 .25rem;
      font-size: 1.5rem;
    }

  */
:root {
  --disabled-grey: #9e9e9e;
}

.qrc_view_content .qr_code_wrapper .qr_content .qr_code_ctrls .qr_btn.active {
  border: 2px solid var(--active-blue) !important;
}

.qrc_view_modal_cont, .paperPopupDialog {
  padding-top: 1em !important;
  font-size: 1rem;
}
.qrc_view_modal_cont .qrc_view_modal, .paperPopupDialog .qrc_view_modal {
  max-height: unset;
  min-height: 38rem;
  padding-bottom: 0.5em;
  margin: 0 auto;
}
@media only screen and (max-width: 400px) {
  .qrc_view_modal_cont .qrc_view_modal, .paperPopupDialog .qrc_view_modal {
    width: 80%;
  }
}
.qrc_view_modal_cont .closeBtn, .paperPopupDialog .closeBtn {
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 1.3rem;
  height: 1.7em !important;
  width: 1.7em !important;
  top: -1em !important;
  right: -0.5em !important;
  padding: unset;
}
.qrc_view_modal_cont .closeBtn:before, .paperPopupDialog .closeBtn:before {
  height: 1.4em !important;
  width: 1.4em !important;
  line-height: 1.4em;
}

.qrc_view_content {
  border: unset;
  margin-bottom: unset;
}
.qrc_view_content .qr_sponsor, .qrc_view_content .qr_counselor, .qrc_view_content .qr_copy, .qrc_view_content .qr_share {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  width: unset;
  border-radius: 7px;
  border: 1px solid #ccc;
}
.qrc_view_content .qr_sponsor {
  grid-area: sponsor;
  height: 3em;
  flex: 1 auto;
}
.qrc_view_content .qr_counselor {
  grid-area: counselor;
  height: 3em;
}
.qrc_view_content .qr_code_wrapper {
  max-width: 90vw;
  height: 100%;
  display: grid;
  grid-template-areas: "title" "content";
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
}
.qrc_view_content .qr_code_wrapper .qr_content {
  display: grid;
  grid-template-areas: "canvas" "hr" "ctrls" "url";
  grid-template-rows: auto 0.25fr auto auto;
  grid-template-columns: 100%;
  padding: 1rem 0;
}
.qrc_view_content .qr_code_wrapper .qr_content canvas, .qrc_view_content .qr_code_wrapper .qr_content .qr_load {
  justify-self: center;
  align-self: center;
  grid-area: canvas;
}
.qrc_view_content .qr_code_wrapper .qr_content hr {
  grid-area: hr;
  margin: 1rem 0 0;
}
.qrc_view_content .qr_code_wrapper .qr_content .qr_code_ctrls {
  grid-area: ctrls;
  display: flex;
  justify-content: space-around;
  margin: 1em 0 0;
  gap: 0.5rem;
  padding: 0 1rem;
  flex-flow: row wrap;
}
.qrc_view_content .qr_code_wrapper .qr_embed {
  margin: 1rem auto;
  min-height: unset;
}
.qrc_view_content .qr_code_wrapper .snapBox {
  grid-area: content;
}
.qrc_view_content .qr_code_wrapper .snapBox .snap_cont {
  border: unset !important;
}
.qrc_view_content .qr_text_url_cont {
  grid-area: url;
  padding: 1em;
  display: grid;
  grid-template-areas: "text text" "copy share" "visit visit";
  grid-template-columns: 5fr auto;
  gap: 0.5em;
}
.qrc_view_content .qr_text_url_cont .qr_text_url {
  grid-area: text;
  height: 2.5em;
  border: 1px solid #ccc;
  padding: 5px 0.5em;
  background: var(--disabled-grey) !important;
  color: white;
}
.qrc_view_content .qr_text_url_cont .qr_copy {
  grid-area: copy;
  gap: 1rem;
}
.qrc_view_content .qr_text_url_cont .qr_copy .pp_panelBtn {
  font-size: 2rem;
}
.qrc_view_content .qr_text_url_cont .qr_share {
  font-size: 1.4rem;
  cursor: pointer;
}
.qrc_view_content .qr_text_url_cont .qr_visit {
  grid-area: visit;
  padding: 0.5rem 0;
  color: blue;
}
.qrc_view_content .qr_title_cont {
  height: fit-content;
}
.qrc_view_content .qr_title_cont p {
  margin: unset;
  padding: 0 1em;
  color: #9e9e9e;
  line-height: 1.3;
  margin-bottom: 1em;
}
.qrc_view_content .qr_title_cont label {
  text-shadow: 2px 2px 2px #ccc;
  font-size: 1.3em;
  padding: 0.5em 1em 0;
}
.qrc_view_content .snap_ctrls_main {
  padding: 0.25em;
}
.qrc_view_content .snap_btn.snap_core {
  width: 1.75rem !important;
  margin: 5px;
  flex: 0 0 1.75rem;
  height: 1.75rem !important;
  background-color: unset !important;
  border-color: #ddd;
}

.qrc_modal_cont .snap_section {
  max-height: unset;
}