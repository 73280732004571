  $mp: !important;
  @import '../../../../css/media';

  .paper_cont{
    display:flex;
    flex-flow: column;
    .paper_modal_main{
      height: 100%;
      padding-top: unset $mp;
      overflow: unset;
      .paper_wrapper{
        // display: flex;
        display: grid;
        grid-template-areas: "ctrls""content";
        grid-template-rows: auto 8fr;
        flex-flow: column;
        height: 99vh;//98%;
        padding-top: .5rem;// 2rem;
        &.details{
          padding-top: 4rem;
        }
        .paper_contents{
          flex: 1 auto;
          height: 100%;//95%;
          grid-area: content;
          // position: fixed;
          width: 100%;
          .details_modal_cont {
            height: 95% $mp;
            // padding-top: 2rem $mp;
            // padding: 1rem 0 1rem $mp;
            padding: 3VH 0 0 $mp;
            width: 100%;
            max-width: 100vw;
            @include mobile{
                height: 95vh $mp;
            }// mobile
            // position: fixed;// ISSUE: conflict with paperIcon options display l/r swiching
            .details_modal{
              max-height: unset $mp;
              margin: 0 auto $mp;
              height: 100% $mp;
              width: 90% $mp;
              @include mobile{
                height: 95% $mp;
              }// mobile
            }// details_modal
          }// details_modal_cont
          // &.preview{z-index: 2000;}/*RESEARCH: why did i set this to 2000?*/
          .Slider{
            // border: 1px solid red;
            // width: 400px;
            z-index: 2000;
            transform: scale(.7);
            height: 100%;
            // max-width: 400px;
            // .details_modal_cont{}
            // disable details scrolling WORKS
            .sli_swiper{height: 100%;}
            .swiper-slide{display: flex; justify-content: center;}
            .swiper-slide-shadow{background: unset;}
            .details_content, .details_snap_snap_cont, .details_snap_snap_section{overflow: hidden;}
            .paper_overlay{
              // border: 2px solid green; 
              // background: #ff000036; 
              // width: 100%;
              // aspect-ratio: 10/19;
              // witout this somehow aspect-ratio is doing nothing with width 
              // (height is ok w/a-r)
              // position: absolute;// not helping
              .details_modal_cont{
                z-index: -1 $mp; 
                position: relative; 
                height: unset $mp;
                padding: unset $mp;
                .details_modal{aspect-ratio: 10/19;}
              }
            }
          }
        }//paper_contents
        .paper_header_cont{
          flex: 1 auto;
          height: 1rem;//5%; NOTE: requires a height since its grid-area is set to auto
          // margin-top: 1rem;
          grid-area: ctrls;
        }// paper_header_cont
      }//paper_wrapper
    }//paper_modal_main
  }

  .paper_cont.detail{
    .paper_modal_main{
      // display:flex $mp;
      justify-content: center;
      align-items: flex-end;
      // .paper_wrapper{
      //   height:98%;
      //   padding-top: 1rem;
      //   .paper_contents{
      //     height:88%;
      //   }/*paper_contents*/
      //   .paper_header_cont{
      //     flex: 1 8%;
      //   }/*paper_header_cont*/
      // }/*paper_wrapper*/
    }/*paper_modal_main*/
  }


    #content{
      /*temp fix for layer*/
      z-index: 3100;
      // position: fixed;
      width: 100%;
    }

  .item_loader_cont{
    display: flex;
    justify-content: center;
  }

  div.share_modal_cont.details{
    padding-top: 3rem $mp;
  }
