.admin_container {
  height: 100%;
  display: grid;
  grid-template-areas: "label" "main";
  grid-template-rows: auto 9fr;
  height: 100%;
  padding: 0 0.15rem;
  gap: 0.5rem;
}
.admin_container .admin_header {
  display: grid;
  grid-template-areas: "icon title options";
  grid-template-columns: 1fr 8fr 1fr;
  overflow: unset;
  align-items: center;
  width: 100%;
  text-shadow: 2px 2px 2px #ccc;
  font-size: 1.2rem;
  gap: 0.5rem;
  grid-area: label;
}
.admin_container .admin_header .TopOp_wrapper.hide {
  display: none;
}
.admin_container .admin_header.details_admin {
  height: 2.5rem;
}
.admin_container .admin_header .admin_title {
  grid-area: title;
}
.admin_container .admin_header .admin_icon {
  grid-area: icon;
  justify-self: left;
  text-align: left;
  margin-left: 0.75rem;
  padding: 0.25rem 0 0;
  font-size: 2.2rem;
}
.admin_container .admin_error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 0.9em;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
  height: 90%;
  flex: 0 1 auto;
}
.admin_container .admin_featured {
  width: 100%;
  display: grid;
  grid-template-areas: "nav" "content";
  grid-template-rows: auto 12fr;
  /*.8fr*/
  height: 100%;
  flex: 1;
  grid-area: main;
}
.admin_container .admin_featured.search {
  grid-template-areas: "input" "nav" "content";
  grid-template-rows: auto auto 12fr;
  /*.8fr*/
}
.admin_container .admin_featured.details_admin {
  grid-template-rows: auto 8fr;
}
.admin_container .admin_featured.details_admin.search {
  grid-template-rows: auto 0.8fr 8fr;
}
.admin_container .admin_featured .admin_mock_input {
  background-color: #e9ecef;
  width: 100%;
  margin: 0 auto;
  border-radius: 7px;
  height: 80%;
  align-self: center;
  grid-area: input;
  color: #b4bbc1;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: text;
}
.admin_container .admin_featured .admin_nav_section_wrap {
  display: flex;
  width: 100%;
  overflow: auto;
}
.admin_container .admin_featured .admin_nav_section_wrap .admin_nav_section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25rem;
  gap: 0.5rem;
  grid-area: nav;
}
.admin_container .admin_featured .admin_nav_section_wrap .admin_nav_section .admin_nav_ctrls {
  padding: 0 0.5rem;
}
.admin_container .admin_featured .admin_nav_section_wrap .admin_nav_section .admin_nav_ctrls.active {
  background-color: var(--active-blue) !important;
  color: white;
}
.admin_container .admin_featured .admin_nav_section_wrap .admin_nav_section .admin_nav_ctrls:focus {
  outline: none;
}
.admin_container .admin_featured .admin_featured_content {
  grid-area: content;
  width: 100%;
  border-radius: 7px;
  margin-top: 0.25rem;
  overflow: hidden;
}
.admin_container .admin_featured .admin_featured_content .snap_admin_snapBox {
  display: grid;
  /* flex-flow: column nowrap; */
  grid-template-areas: "ctrls" "cont";
  grid-template-rows: 2rem 90%;
  height: 100%;
}
.admin_container .admin_featured .admin_featured_content .snap_admin_snapBox .snap_admin_snap_ctrls {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
  padding-bottom: 0.3rem;
  grid-area: ctrls;
}
.admin_container .admin_featured .admin_featured_content .snap_admin_snapBox .snap_admin_snap_ctrls .snap_admin_snap_btn {
  padding: 0 0.5rem;
  font-size: 1.3rem;
}
.admin_container .admin_featured .admin_featured_content .snap_admin_snapBox .snap_admin_snap_ctrls .snap_admin_snap_btn.active {
  background-color: var(--active-blue);
  color: white;
}
.admin_container .admin_featured .admin_featured_content .snap_admin_snap_cont {
  border: 1px solid #ddd;
  grid-area: cont;
}
.admin_container .admin_featured .admin_featured_content .segue_item_wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  height: 255px;
  margin: 5px;
}
.admin_container .admin_featured .admin_featured_content .segue_view_content {
  height: 100%;
  padding: 0.25rem 0;
  overflow-y: auto;
}
.admin_container .admin_featured .admin_featured_content .arc_view_content.plain {
  padding-top: unset;
}
.admin_container .admin_featured .admin_featured_content .arc_view_content.plain.admin_requests .arc_item_wrapper {
  height: -moz-fit-content;
  height: fit-content;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests {
  height: 70px;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests .lookout_wrapper {
  display: grid;
  grid-template-areas: "image title icon" "image text icon" "ctrls ctrls ctrls";
  width: 100%;
  padding: 0.5rem 0.25rem 1rem;
  position: relative;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests .lookout_wrapper.open {
  padding: 0.5rem 0.25rem;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests .lookout_wrapper .lookout_title {
  font-size: 1.2rem;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests .lookout_wrapper .lookout_type {
  bottom: -0.5rem;
  left: 28px;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests .lookout_wrapper .lookout_icon_cont {
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests .lookout_wrapper .lookout_icon_cont .acs-tree__option-btn {
  font-size: 1.3rem;
}
.admin_container .admin_featured .admin_featured_content .arc_item_wrapper.arc_admin.admin_requests .lookout_wrapper .acs-tree__user-input-btns_btnGrpCont {
  grid-area: ctrls;
  padding: 1rem 0.25rem 0;
  gap: 0.5rem;
}
.admin_container .admin_search {
  width: 100%;
  display: grid;
  grid-template-areas: "input" "content";
  grid-template-rows: 5.7rem 12fr;
  height: 100%;
  flex: 1;
  grid-area: main;
  gap: 0.25rem;
}
.admin_container .admin_search .admin_search_header {
  display: flex;
  justify-content: space-between;
  grid-area: input;
}
.admin_container .admin_search .admin_search_header .admin_search_back {
  height: fit-content;
  margin-top: 0.75rem;
}
.admin_container .admin_search .admin_search_header input {
  width: 85%;
}
.admin_container .admin_search .admin_search_header .filter_wrapper {
  width: 100%;
  border: unset;
}
.admin_container .admin_search .admin_search_content {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
  height: 95%;
  /* margin-top: 0.25rem; */
  overflow: hidden;
}
.admin_container .admin_search .admin_search_content .search_display {
  height: 100%;
}