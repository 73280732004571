$mp: !important;
html{
  // scroll-snap-type: y mandatory;// having this snap set also fails my x-scrolling
}

body{
  touch-action: unset $mp;
}

#content{  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.root{
  display: flex;
  flex-flow: column;
  height: 100vh $mp;
  &.details{height: unset $mp;}
  overflow-y: auto;
  scroll-snap-align: end;
  scroll-snap-stop: always;
  .arc_intro_btn{
    flex:1;
  }
}
.modal_dialog.core{
  margin: 0 auto $mp;
}

.arc_popup_content{
  .snap_section.snap_core{
    padding: 0 .5rem;
  }
  .snap_ctrls.snap_core{
    // padding-top:unset;
    .snap_ctrls_right{
      display: flex;
      justify-content: center;
      align-items: center;
    }/*snap_ctrls_right*/
  }/*snap_ctrls*/
}/*arc_popup_content*/
