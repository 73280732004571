$mp: !important;

  .topchoice-select_label{}
  .topchoice_cont{
    display:grid;
    margin-bottom: .5rem;
    grid-template-areas: "tcsel tcclr";
    grid-template-columns: 7fr auto;
    // gap: .5rem;// not needed - fails with only-child
    &.show-in{
      grid-template-areas: "tcsel tcclr";// what does this btn do?
    }// show-in
  }
  // .topchoice-select_label {text-shadow: unset; grid-area: tclbl;}

  .topchoice-select-wrapper {
    position: relative;
    user-select: none;
    width: 100%;
    padding-right: .5rem;
    grid-area: tcsel;
    &:only-child{
      padding-right: unset;
    }
  }
  .topchoice-select {
     position: relative;
     display: flex;
     flex-direction: column;
     border-width: 0 2px 0 2px;
     border-style: solid;
     border-color: #ddd;
     border-radius: 7px;
  }
  .topchoice-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    font-size: 1.1rem;
    font-weight: 300;
    color: #3b3b3b;
    height: 2.5rem;
    line-height: 60px;
    background: #ffffff;
    cursor: pointer;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: #ddd;
    border-radius: 7px;
  }
  .topchoice-options {
     position: absolute;
     display: block;
     top: 100%;
     left: 0;
     right: 0;
     border: 2px solid #394a6d;
     border-top: 0;
     background: #fff;
     transition: all 0.5s;
     opacity: 0;
     visibility: hidden;
     pointer-events: none;
     height: 10rem;
      overflow-y: auto;
     z-index: 2;
  }
  .topchoice-select.open .topchoice-options {
     opacity: 1;
     visibility: visible;
     pointer-events: all;
  }
  .topchoice-option {
    position: relative;
    display: block;
    padding: 0 22px 0 22px;
    font-size: 1em;
    font-weight: 300;
    color: #3b3b3b;
    line-height: 2.5rem;
    cursor: pointer;
    transition: all 0.5s;
    &.place_me{ color: #ddd;}
  }
  .topchoice-option:hover {
     cursor: pointer;
     background-color: #b2b2b2;
  }
  .topchoice-option.selected {
     color: #ffffff;
     background-color: #305c91 $mp;
  }
  .topchoice-input{
    margin: 5px auto;
    display: block;
    position: relative;
    margin: .5em 0;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-color: #ddd;
    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 20%);
    border-radius: .3125em;
    font-size: 1em;
    line-height: 1.3;
    font-family: sans-serif;
    padding: .5rem;
    grid-area: tcsel;
  }// topchoice-input

  .topchoice-btn {
    display: flex;
    color: #6c757d;
    justify-content: center;
    align-items: center;
    position: relative !important;
    height: 35px;
    width: 35px !important;
    cursor: pointer;
    padding: 4px 7px;
    border-radius: 5px;
    border: 1px solid #ccc;
    overflow: hidden;
    margin: unset;
    justify-self: center;
    align-self: center;
    text-align: center;
    z-index: 1;
    grid-area: tcclr;
  }// topchoice-btn

  .arrow {
     position: relative;
     height: 11px;
     width: 11px;
  }
  .arrow::before, .arrow::after {
     content: "";
     position: absolute;
     bottom: 0px;
     width: 0.15rem;
     height: 100%;
     transition: all 0.5s;
  }
  .arrow::before {
     left: -3px;
     transform: rotate(45deg);
     background-color: #394a6d;
  }
  .arrow::after {
     left: 4px;
     transform: rotate(-45deg);
     background-color: #394a6d;
  }
  .open .arrow::before {
     left: -3px;
     transform: rotate(-45deg);
  }
  .open .arrow::after {
     left: 4px;
     transform: rotate(45deg);
  }
