.access_select_icon_cont {
  display: flex;
  justify-content: center;
  margin: 0.3em auto;
  width: 100%;
}
.access_select_icon_cont .access_slider_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.access_select_icon_cont .access_slider_cont label {
  margin: unset;
}
.access_select_icon_cont .access_slider_cont, .access_select_icon_cont .access_rear_title_cont, .access_select_icon_cont .access_mid_title_cont {
  float: left;
  width: 100%;
  min-height: 40px;
}

.access_hr {
  margin: 0.75rem 1rem;
  border-width: 0.15rem;
  border-top: 1px solid #eeeeee45;
}

/* The switch - the box around the slider */
.switch_label.switch {
  position: relative;
  display: inline-block;
  width: 6.7em;
  height: 2.3em;
  flex: 0 0 auto;
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
.switch_label.switch input {
  display: none;
}
.switch_label.switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch_label.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.4em;
  text-align: center;
  line-height: 2.3em;
  text-indent: 1.5em;
}
.switch_label.switch .slider.allow, .switch_label.switch .slider.on {
  text-indent: -1.5em;
  color: white;
  text-shadow: 0 1px 0 #059;
}
.switch_label.switch .slider:before {
  position: absolute;
  content: "";
  height: 1.8em;
  width: 1.8em;
  left: 0.3em;
  bottom: 0.25em;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.4em;
}
.switch_label.switch input:checked + .slider {
  background-color: #38c;
}
.switch_label.switch input:focus + .slider {
  box-shadow: 0 0 1px #059;
}
.switch_label.switch input:checked + .slider:before {
  -webkit-transform: translateX(4.3em);
  -ms-transform: translateX(4.3em);
  transform: translateX(4.3em);
}
.switch_label.switch .slider.round {
  border-radius: 2.4em;
}
.switch_label.switch .slider.round:before {
  border-radius: 50%;
}