@layer base {
  :root {
    --site-main: #ffc107;
    --status-active-lt: #ffc107c9;
    --status-active: #ffc107;
    --status-stop-lt: #ff00008a;
    --status-stop: red;
    --status-done-lt: #0080007d;
    --status-done: green;
    --off-black: #333;
  }

  * {
    box-sizing: border-box;
  }

  body {
    overscroll-behavior-y: contain;
  }

  /* tab design
  border: 1px solid rebeccapurple;
  text-align: center;
  border-bottom: none;
  border-radius: 7px 7px 0px 0px;
  */
  .topper {
    display: none;
  }
  .topper.extend {
    display: block !important;
  }

  /** SiteSide **/
  .arc_hidden_cont {
    height: 0px;
    width: 0px;
  }

  /*liteBox animation*/
  .arc_box_space {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .liteBox {
    width: 100%;
    height: 690px;
    position: absolute;
    /*z-index: 1150;*/
    top: 0;
    left: 0;
    opacity: 0;
  }

  .liteBox.help {
    top: -30px;
  }

  .arc_popup {
    position: relative !important;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-color: #ddd;
    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    /*card shadow*/
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.6);
    /*does nothing*/
    -webkit-background-clip: padding;
    background-clip: padding-box;
    /*rounded*/
    -webkit-border-radius: 0.3125em;
    border-radius: 0.3125em;
  }

  /*formerly unset - i don't know why its unset.*/
  .w3-modal.loading {
    display: block;
    /*z-index:2000;*/
  }

  .liteBox.popup {
    opacity: 1;
    background-color: none;
    /* background: url("../images/trans_block.png") 0 0;*/
    background-image: linear-gradient(rgba(74, 74, 74, 0.31) 400px, rgba(255, 255, 255, 0.5) 100%);
  }

  .fishBowl {
    width: 95%;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    margin: 0 auto;
    top: 55px;
  }

  /*width: 100%;*/
  .fish_head {
    display: flex;
  }

  .fish_eye {
    top: 0px !important;
    right: 5px !important;
    z-index: 100;
  }

  .fish_content {
    /*max-height:525px;*/
    max-height: 62vh;
    overflow-y: hidden;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    min-height: 200px;
  }

  .fish_content.edit_img {
    overflow-y: scroll !important;
  }

  .fish_eye.fishy {
    position: absolute;
    top: -15px !important;
    right: -7px !important;
  }

  /* top: 0px;right: 10px;*/
  @keyframes lB_fade_in {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      background-color: none;
      background: url("../images/trans_block.png") 0 0;
    }
  }
  @-o-keyframes lB_fade_in {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      background-color: none;
      background-image: url("../images/trans_block.png");
    }
  }
  @-moz-keyframes lB_fade_in {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      background-color: none;
      background-image: url("../images/trans_block.png");
    }
  }
  @-webkit-keyframes lB_fade_in {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      background-color: none;
      background-image: url("../images/trans_block.png");
    }
  }
  .ui-nosvg .ui-icon-google_plus:after {
    /* Make your icon fit */
    background-size: 18px 18px;
  }

  .bright {
    -o-animation: lB_fade_in 2s 500ms 1 linear normal forwards;
    -mos-animation: lB_fade_in 2s 500ms 1 linear normal forwards;
    -webkit-animation: lB_fade_in 2s 500ms 1 linear normal forwards;
    animation: lB_fade_in 2s 500ms infinite 1 normal forwards;
  }

  /* end liteBox animation */
  .arc_stage {
    /*width: 100%;*/
    display: flex;
    flex-flow: column;
    justify-content: center;
    border-radius: 7px 7px 0 0;
    /*margin: 2% 0 0 0;*/
  }
  .arc_stage .arc_intro_btn {
    background-color: #f6f6f6;
    border-color: #ddd;
    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
  }

  .glass_ctrls {
    height: 35px;
  }

  .w3-modal {
    padding-top: 25px !important;
  }

  .ui-btn {
    font-size: 16px;
    margin: 0.5em 0;
    padding: 0.7em 1em;
    display: block;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none !important;
    font-weight: 700;
    border-width: 1px;
    border-style: solid;
    font-size: 1em;
    line-height: 1.3;
    font-family: sans-serif;
  }

  /*.ui-btn*/
  .arc_ctr_stage {
    background-color: #fff;
    border-radius: 7px;
    padding: 10px;
    text-align: left;
    margin-top: 5px;
  }

  #pUS_closeBtn {
    z-index: 50 !important;
    right: -7px !important;
    position: absolute;
    top: -11px;
    margin: 0;
  }

  /*right foremerly 1%*/
  .arc_ctr_stage h4 {
    margin: 0 0 10px 10px;
  }

  .arc_intro_btn {
    height: 190px;
    width: 100%;
    box-shadow: 5px 5px 5px #5d5a5a;
    border-radius: 0px !important;
    margin: 0.5em 0 0;
  }

  .arc_popup {
    width: 95%;
    margin: 0 auto;
  }

  /*width:90%;*/
  #arc_popup-popup {
    width: 100% !important;
    top: 30px !important;
    left: 0 !important;
    max-width: none !important;
  }

  /*top:60p;left:7% ;width:98%*/
  #arc_popup-screen {
    background-image: linear-gradient(#4a4a4a 400px, white 100%);
  }

  .feedback {
    background-image: none;
    /*removes btn gradients*/
    /*touch-action: none;*/
    touch-action: auto !important;
  }

  .feedback:hover {
    background-color: #296CDB !important;
    color: #fff;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }

  .feedback:focus {
    background-color: #0F52C1 !important;
    color: #fff;
    /* The outline parameter suppresses the border
    color / outline when focused */
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }

  .feedback:active {
    background-color: #0039A8 !important;
    color: #fff;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }

  .feedback::-moz-focus-inner {
    border: 0;
    /*-moz-focus-inner - firefox tap hightlight*/
  }

  button.console_btns, button.in_ctrls, .in_cont {
    margin: 1px 2px !important;
    display: inline-block;
    flex: 1 !important;
    padding: 0px !important;
  }

  button.console_btns {
    max-width: 22%;
  }

  button.in_ctrls {
    border-radius: 5px !important;
    height: 100%;
  }

  button.view_bookmark.in_ctrls {
    width: 100% !important;
    margin: 0px !important;
    font-size: 22px;
  }

  button.console_btns:after, button.in_ctrls:after {
    background-size: 70% !important;
    height: 25px !important;
    width: 24px !important;
    top: 45% !important;
    left: 45% !important;
    border-radius: 5px !important;
  }

  .data_type_select_groupCont {
    display: flex !important;
  }

  .media_btn {
    flex-grow: 1 !important;
    flex-basis: auto !important;
    background-color: #f6f6f6 !important;
    border-color: #ddd !important;
    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .arc_option_space, .option_space, .travel_space, .sort_space {
    flex: 1;
    padding: 2px 10px;
    background: #dedede;
    margin-bottom: 5px;
  }

  .ctrl_case.c_more, .arc_view_options.c_more, .arc_option_space.c_more, .arc_travel_space.c_more, .arc_sort_space.c_more {
    display: flex !important;
  }

  .ctrl_case.c_none, .arc_view_options.c_none, .arc_option_space.c_none, .arc_travel_space.c_none, .arc_sort_space.c_none {
    display: none !important;
  }

  .view_options:after {
    background-color: unset !important;
  }

  .flexbox {
    display: flex;
  }

  .arc_title, .chk_title, .glass_title {
    text-align: left;
    margin: 0px;
    font-size: 1.2rem;
    padding: 3px;
    max-height: 30px;
    overflow: hidden;
  }

  .arc_title, .title, .glass_title {
    text-shadow: 2px 2px 2px #ccc;
  }

  .glass_title h5 {
    margin: 0px !important;
  }

  div.arc_nav_cont.flexbox, div.chk_nav_cont.flexbox, .navBtn_groupCase {
    height: auto !important;
    padding: 0px;
    justify-content: flex-start;
  }

  .arc_navBtn_groupCont, .chk_navBtn_groupCont {
    display: flex !important;
    /*display:flex;height:auto $mp; width:100%;*/
  }

  .arc_nav_cont .TCasing, .chk_nav_cont .TCasing {
    padding-left: 0px !important;
  }

  .arc_navHome {
    /*width: 1.75em $mp;*/
  }

  .flex1 {
    flex: 1;
  }

  .flex2 {
    flex: 2;
  }

  .flex3 {
    flex: 3;
  }

  .flex4 {
    flex: 4;
  }

  .flex5 {
    flex: 5;
  }

  .flex6 {
    flex: 6;
  }

  .nav_disp {
    margin: 0 1%;
  }

  .navBtn {
    height: 25px !important;
    width: 30px !important;
    padding: 0px !important;
    margin: 0 2% !important;
    flex: 1 !important;
    text-indent: -9999px;
    white-space: nowrap !important;
  }

  .navBtn:after {
    background-color: #666;
    background-color: rgba(0, 0, 0, 0.3);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    content: "";
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    left: 50%;
    margin-left: -11px;
    top: 50%;
    margin-top: -11px;
  }

  .navBtn.d3-ico:after {
    background: none;
  }

  .navHome {
    /*padding:0 $mp;*/
    margin: 0 !important;
  }

  .movHome {
    height: 30px;
    width: 30px;
  }

  .arc_display_icon_cont, .chk_display_icon_cont {
    display: flex;
    justify-content: flex-end;
    gap: 0.25rem;
  }
  .arc_display_icon_cont .custom_chk_TCont, .chk_display_icon_cont .custom_chk_TCont {
    width: 22px;
  }

  .move_chk_TInput {
    height: 20px;
    width: 20px;
    margin: 2px auto;
    display: block;
  }

  .tabH2.ui-collapsible-heading {
    position: -webkit-sticky;
    position: sticky;
    top: -5px;
    background: #fff;
    z-index: 50;
  }

  .list_btns {
    float: left;
  }

  .arc_add_info {
    float: right;
  }

  .clear, .clr {
    clear: both;
  }

  .arc_display, .chk_display {
    width: 100%;
    min-height: 200px;
    /*overflow-y: auto;
    max-height: 490px;*/
    padding: 5px;
    overscroll-behavior-y: contain;
  }

  /*max-height: 450px;*/
  /*the folder and unpublished color classes are attached to the li that gives me room here to add additional colors
  with specificity*/
  .move_data {
    background-color: #c9d8ff !important;
    /*box-shadow: 5px 5px 5px #5d5a5a;*/
  }

  .ul_display_list {
    margin: 0px !important;
  }

  .contact_form_title {
    margin: 0;
  }

  .contact_form_cont, .contact_form_mid, .contact_form_rear {
    width: 100%;
    min-height: 250px;
    margin-top: 15px;
    border: 1px solid #ccc;
    max-height: 500px;
    overflow-y: auto;
    padding-bottom: 10px;
    overflow-x: hidden;
  }

  .contact_form_cont .display_area {
    width: 150px !important;
    height: 150px !important;
  }

  .hr_90 {
    width: 90%;
    margin: 8px auto !important;
  }

  .info_booth {
    padding: 5px 0;
    margin-top: 10px;
  }

  .arc_info_type, .arc_info_core, .arc_info_other {
    margin: 10px auto;
    width: 90%;
  }

  .arc_info_other.info_booth {
    margin-top: 0px;
  }

  .arc_info_other .TLabel {
    margin: 0px auto !important;
    width: 90% !important;
  }

  .arc_info_other .TCont, .arc_info_type .TCont {
    margin: 0px auto !important;
    width: 90% !important;
  }

  .arc_info_other .TInput, .arc_info_type .TInput {
    width: 100% !important;
  }

  .contact_form_select.form_input_cont {
    margin-bottom: 5px;
  }

  .contact_form_select {
    margin: 10px auto 5px;
    width: 90% !important;
  }

  .form_input_cont {
    width: 100%;
    min-height: 30px;
    padding-bottom: 10px;
  }

  div.data_type_select_cont {
    width: 100%;
    margin-bottom: 5px;
    padding: 0px !important;
  }

  div.data_type_select_TCasing {
    padding: 0px;
    margin: 0px auto;
    width: 90%;
    padding-left: 0px !important;
  }

  .data_type_select_cont .groupCase {
    width: 100%;
    text-align: left;
    position: relative;
    display: block;
  }

  .arc_input {
    width: 90%;
    border-radius: 7px;
    font-size: 1rem;
    line-height: 20px !important;
    margin: 0px auto !important;
    min-height: 20px;
    display: block;
  }

  .arc_input.TInput {
    padding: 2px 5px !important;
  }

  .dataInp_TInput.arc_input {
    width: 100% !important;
  }

  .dataInp_groupCont {
    display: flex !important;
  }

  .act_btn {
    flex-grow: 1 !important;
    flex-basis: auto !important;
  }

  .act_stop {
    background-color: rgba(207, 47, 47, 0.11) !important;
  }

  .act_work {
    background-color: rgba(242, 229, 12, 0.28) !important;
  }

  .act_fin {
    background-color: rgba(76, 200, 40, 0.341) !important;
  }

  .arc_select {
    width: 90%;
    margin: auto !important;
    display: block;
    border-radius: 7px;
    background-color: #fff;
  }

  /*padding: 2px 5px $mp;*/
  .catSel_CInput {
    padding: 2px 5px !important;
  }

  .catSel_CInput.invalid, .catSel_ICont.invalid {
    background: rgba(232, 16, 28, 0.12);
  }

  .catSel {
    margin-top: 5px !important;
  }

  .nb {
    height: 30px;
  }

  .TInput.arc_select, .TCont.arc_select {
    width: 100%;
  }

  .web_preview.TLabel, .web_preview.TCont {
    margin: 0 auto;
    width: 90%;
  }

  .previewBox {
    position: relative;
    background-color: #ddd;
    width: 90%;
    margin: 0 auto;
    /*min-height: 75px;*/
    padding: 5px;
    text-align: center;
  }

  .prev_cont {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    overflow: hidden;
    /*position:relative;*/
  }

  .prev_img {
    margin: 0px auto;
  }
  .prev_img img {
    object-fit: contain;
  }

  .slide_ph {
    /*TODO were going to rotate the sliders 90deg*/
    -ms-transform: rotate(20deg);
    /* IE 9 */
    -webkit-transform: rotate(20deg);
    /* Safari */
    transform: rotate(20deg);
    /* Standard syntax */
  }

  a.prev_edit_btn {
    position: absolute !important;
    right: 0.25rem;
    top: 0.25rem;
    font-size: 1rem;
    font-size: 1.3rem;
    padding: 0.5rem;
  }

  .TCasing {
    padding-left: 10px !important;
  }

  div.noteInp_TCasing {
    margin: 0 !important;
    padding: 0px !important;
    width: 100% !important;
  }

  /*formatting for nicedit display*/
  .nice_size {
    width: 100% !important;
  }

  .nicEdit-main {
    width: 95% !important;
    margin: 4px auto !important;
    height: 300px;
  }

  .nicEdit-button {
    height: 20px !important;
    width: 20px !important;
  }

  .note_spacer {
    margin: 15px auto 0px !important;
  }

  input.noteInp_TInput {
    width: 100% !important;
  }

  textarea.noteInp_TArea {
    height: 100px !important;
    margin-top: 5px !important;
  }

  .arc_select:active {
    border-color: none;
  }

  .SInput.arc_select {
    margin-bottom: 5px;
  }

  /*div.STly{display:none $mp;}*/
  .SLabel {
    display: block !important;
  }

  .SLabel, .STly {
    width: 90%;
    margin: 3px auto !important;
  }

  .accSlide_SLabel.SLabel {
    width: auto;
    float: left;
    margin: 5% 0 !important;
  }

  .accSlide_SLabel {
    width: 50% !important;
  }

  .accSlide_SCont {
    float: left;
    width: 40%;
    margin-left: 3%;
  }

  .accSlide_SCont .ui-slider {
    width: 100%;
  }

  .contact_form_select_icon_cont, .contact_form_rear_head_cont, .contact_form_mid_top_cont {
    margin: 5px auto;
    width: 90%;
  }

  .contact_form_select_icon_cont {
    margin: 5px auto;
  }

  .contact_form_slider_cont, .contact_form_rear_title_cont, .contact_form_mid_title_cont {
    float: left;
    width: 80%;
    min-height: 40px;
  }

  .contact_form_slider_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .contact_form_slider_cont label {
    margin: unset;
  }

  .contact_form_rear_title, .contact_form_mid_title {
    text-shadow: 2px 2px 2px #ccc;
    padding-top: 12px;
  }

  .contact_form_rear_top_cont {
    margin-bottom: 25px;
  }

  .contact_form_rear_low_cont, .contact_form_mid_low_cont {
    width: 90%;
    margin: 0px auto;
  }

  .contact_form_rear_low_cont div.TCasing, .contact_form_mid_low_cont div.TCasing {
    margin-top: 15px !important;
  }

  .contact_form_rear_low_cont div.TAreaCont,
.contact_form_rear_low_cont textarea.TArea,
.contact_form_rear_low_cont label.TAreaLabel {
    width: 100% !important;
  }

  .contact_form_mid_low_cont div.TAreaCont,
.contact_form_mid_low_cont textarea.TArea,
.contact_form_mid_low_cont label.TAreaLabel {
    width: 100% !important;
  }

  .contact_form_rear_low_cont label.TAreaLabel, .contact_form_mid_low_cont label.TAreaLabel {
    margin-bottom: 10px !important;
  }

  .SIcon {
    float: right;
    margin-right: 5px !important;
    cursor: pointer !important;
  }

  /*.arc_panel_go{right: 2.7em $mp;}*/
  .arc_go_btn, .arc_can_btn, .fish_confirm, .fish_cancel {
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 3px;
    width: 100% !important;
    height: 25px;
    margin: 3px auto 6px !important;
    padding: 0 !important;
  }

  .arc_go_btn, .fish_confirm, .arc_panel_go {
    background-image: linear-gradient(to bottom, #58c547, #0F8A09) !important;
    color: #fff !important;
  }

  .arc_can_btn, .fish_cancel {
    background-image: linear-gradient(to bottom, #f9fff8, #AAADAA) !important;
  }
  .arc_can_btn h4, .fish_cancel h4 {
    margin: unset;
  }

  .arc_can_btn h4 {
    margin: 0px;
    font-weight: 600;
  }

  .tNoteBtn_btn_group {
    font-size: 16px !important;
    font-weight: 300 !important;
    margin: 0px !important;
    width: 50% !important;
    height: 30px;
    padding: 3px !important;
    text-align: right !important;
  }

  .tNoteBtn_btn_group0 {
    padding-right: 10% !important;
  }

  .tNoteBtn_btn_group1 {
    padding-right: 5% !important;
  }

  .tNoteBtn_groupCont {
    width: 90%;
    margin: 0 auto;
  }

  /************ Panel Stuff ******************/
  .arc_panel.ui-panel {
    width: 100% !important;
    /*z-index:1102;*/
  }

  .arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-overlay,
.arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-push {
    right: 0;
    -webkit-transform: translate3d(22em, 0, 0);
    -moz-transform: translate3d(22em, 0, 0);
    transform: translate3d(22em, 0, 0);
  }

  li.arc_unpublished, li.arc_unpublished.arc_collection {
    background-color: #ffe3db !important;
  }

  .arc_collection {
    background-color: rgba(239, 224, 47, 0.42);
  }

  .arc_collection.arc_folder {
    background-color: #f6f6f6;
  }

  .colap_cont_ListArea {
    margin: 0 !important;
    border-radius: 0px !important;
  }

  .arc_mstr_li.hide_mstr, .chk_mstr_li.hide_mstr {
    display: none !important;
  }

  .typeSel_TCasing, .dataInp_TCasing {
    width: 70%;
    display: inline-block;
  }

  .groupCase.form_fldr_type {
    display: flex;
    justify-content: center;
  }

  .typeSel_btn_group.form_fldr_type {
    min-width: 100px;
  }

  div.dataInp_TCasing {
    width: 70%;
    display: inline-block !important;
    min-height: 65px;
  }

  .side_car {
    width: 20%;
    float: right;
    margin: 0 3% 5px;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
  }

  .siteIcon_LBTag {
    margin: 0 auto 5px auto !important;
  }

  .siteIcon_TDTag, .assoc_icon_TDTag {
    width: 30px;
    height: 30px;
    margin: 0 auto 5px !important;
  }

  .siteIcon_chk_TCont {
    height: 25px !important;
  }

  .siteIcon_chk_TInput {
    left: 0 !important;
    top: 0 !important;
    margin: 0 auto !important;
    position: relative !important;
  }

  .siteIcon_chk_TCont .ui-checkbox {
    margin: 0;
    text-align: center;
  }

  .chng_img_TDTag {
    width: 90% !important;
    margin: 0 auto !important;
    border-radius: 7px;
  }

  .chng_img {
    width: 50% !important;
    display: inline-block !important;
    margin-bottom: 0px !important;
  }

  .tNoteBtn_TCasing {
    margin-top: 25px;
  }

  .data_list {
    cursor: pointer;
    padding: 0px !important;
    border: none;
  }

  .data_list.ghost, .my_a_wrap.ghost {
    opacity: 0.4;
    width: 90%;
    margin: 5px auto !important;
    background-color: #c9d8ff !important;
    color: white !important;
  }

  div.li_liteBox_lightbox.arc_list_preview, div.arc_rF_Box_lightbox.arc_bkmk_list {
    /*z-index:1200;*/
    height: 100% !important;
    position: absolute;
  }

  .chk_rF_Box_lightbox.chk_bkmk_list {
    /*z-index:1210;*/
    height: 100% !important;
    position: absolute;
  }

  div.arc_rF_Box_lightbox.arc_bkmk_list,
div.chk_rF_Box_lightbox.chk_bkmk_list {
    position: fixed !important;
  }

  div.li_liteBox_glassHouse {
    margin: 30px auto 0;
    width: 95% !important;
  }

  .bkmk_targ, li.arc_unpublished.ListArea.bkmk_targ {
    border: 2px solid rgba(18, 214, 18, 0.4) !important;
    border-radius: 7px;
    background-color: rgba(18, 214, 18, 0.4) !important;
  }

  .bkmk_list {
    overscroll-behavior-y: contain;
  }

  .arc_rF_Box_glassHouse.glassHouse, .chk_rF_Box_glassHouse.glassHouse {
    width: 100% !important;
    height: 90vh;
  }

  .arc_rF_Box_glass_content, .chk_rF_Box_glass_content {
    height: 85%;
  }

  .arc_rF_Box_glass_page, .chk_rF_Box_glass_page {
    height: 100%;
    flex-flow: column;
  }

  .arc_rF_Box_glass_page.lbx_page_show, .chk_rF_Box_glass_page.lbx_page_show {
    display: flex !important;
  }

  .arc_rF_Box_glass_page.lbx_page_hide, .chk_rF_Box_glass_page.lbx_page_hide {
    display: none !important;
  }

  .my_info_icon {
    /*float:left;*/
  }

  .info_dot {
    width: 20px;
    /*height: 20px $mp;*/
    margin: 5px !important;
    border-radius: 50% !important;
    background: #ccc;
    /*border:1px solid red;*/
    overflow: visible !important;
  }

  .info_dot:after {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-left: -10px !important;
    margin-top: -10px !important;
    left: 45% !important;
  }

  .info_dot.expanded:after {
    left: 55% !important;
  }

  .my_info_icon.info_dot {
    /*width: 25px $mp;*/
    /*: 25px $mp;*/
    margin: 5px !important;
    border-radius: 50% !important;
    background: #ccc !important;
    /*border:1px solid red;*/
  }

  .my_info_icon.info_dot:after {
    width: 23px !important;
    height: 23px !important;
    border-radius: 50% !important;
    margin-left: -11px !important;
    margin-top: -9px !important;
    top: 40%;
    overflow: hidden;
  }

  button.rich_refresh_btn {
    /*width:27px $mp;
    height:27px $mp;*/
    position: absolute;
    top: 0px !important;
    right: 0px !important;
  }

  img.fit-cover {
    object-fit: cover;
  }

  img.fit-contain {
    object-fit: contain;
  }

  div.arc_icon_img_canvas_area.expanded, div.chk_icon_img_canvas_area.expanded {
    width: 100% !important;
    display: inline-flex;
    margin: 5px;
    height: 3.5rem;
  }

  div.arc_icon_img_image_area.expanded, div.chk_icon_img_image_area.expanded {
    width: 100% !important;
    display: inline-flex;
    margin: 5px;
    height: 3.12rem;
  }

  .arc_icon_img_ImgCanvas, .chk_icon_img_ImgCanvas, .arc_icon_img_ImgImage, .chk_icon_img_ImgImage {
    width: 100% !important;
    height: 100%;
    margin: 0px auto !important;
  }

  .arc_icon_img_ImgImage, .chk_icon_img_ImgImage {
    object-fit: cover;
  }

  .arc_list_preview {
    text-align: left;
  }

  /* The switch - the box around the slider */
  .arc_switch_label.switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 34px;
    flex: 0 0 auto;
    /* Hide default HTML checkbox */
    /* The slider */
    /* Rounded sliders */
  }
  .arc_switch_label.switch input {
    display: none;
  }
  .arc_switch_label.switch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .arc_switch_label.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 7px;
    text-align: center;
    line-height: 32px;
    text-indent: 1.5rem;
  }
  .arc_switch_label.switch .slider.allow {
    text-indent: -1.5rem;
    color: white;
    text-shadow: 0 1px 0 #059;
  }
  .arc_switch_label.switch .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 7px;
  }
  .arc_switch_label.switch input:checked + .slider {
    background-color: #38c;
  }
  .arc_switch_label.switch input:focus + .slider {
    box-shadow: 0 0 1px #059;
  }
  .arc_switch_label.switch input:checked + .slider:before {
    -webkit-transform: translateX(65px);
    -ms-transform: translateX(65px);
    transform: translateX(65px);
  }
  .arc_switch_label.switch .slider.round {
    border-radius: 34px;
  }
  .arc_switch_label.switch .slider.round:before {
    border-radius: 50%;
  }

  .bookmark_li.search {
    border-bottom: 1px solid #ccc;
  }

  .search_cont {
    display: flex;
    flex-direction: column;
    padding: 2%;
    border-bottom: 1px solid #ccc;
  }

  .search_display {
    min-height: 200px;
    border-top: 1px solid #ccc;
    border-top: 1px solid #ccc;
    flex: 1;
    overflow-y: auto;
  }

  .search_display_ul {
    display: flex;
    flex-direction: column;
  }

  .search_log {
    width: 95%;
    margin: 5px auto;
    border: 1px solid #ccc;
    display: none;
  }

  .search_log p {
    margin: 1% 4%;
  }

  .ICont.inCombo:after {
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
  }

  .srchInp_ICont {
    display: inline-block;
  }

  .srchInp_TTly {
    font-size: 0.8rem;
  }

  .bm_info_text.search {
    height: 1.2rem;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button.tasks_inBtn, button.tags_inBtn, button.inBtn.inbox {
    background-color: #87cf96;
  }

  .ui-icon-activity.stopped, .d3-icon-activity.stopped {
    background: rgba(162, 11, 20, 0.6) !important;
  }

  .ui-icon-activity.working, .d3-icon-activity.working {
    background: rgba(199, 199, 17, 0.73) !important;
  }

  .ui-icon-activity.finished, .d3-icon-activity.finished {
    background: rgba(15, 162, 11, 0.6) !important;
  }

  .d3-ui-icon:after {
    content: "";
    background-size: contain !important;
    background-position: center center;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
  }

  .d3-med::after {
    margin-top: 0;
    margin-left: 0;
    left: 0;
    top: 0;
    width: 4rem;
    height: 4rem;
  }

  .d3-sm::after {
    left: 50%;
    top: 50%;
    width: 22px;
    height: 22px;
  }

  /*vw experiment
  div.li_liteBox_lightbox {width:100vw;}
  div.li_liteBox_glassHouse{width:90vw;}
  div.li_liteBox_glass_content{width:85vw}
  */
  @media only screen and (min-width: 300px) and (max-width: 324px) {
    #form_ctrls button.form_btns {
      /*width:5%;*/
    }

    button.console_btns {
      width: 25% !important;
      border-radius: 5px !important;
    }

    button.console_btns:after {
      background-size: 16px 16px;
      width: 20px;
      height: 20px;
      top: 54%;
      left: 52%;
    }

    .tabH2.ui-collapsible-heading {
      top: 0px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 479px) {
    #form_ctrls button.form_btns {
      /*width:8%;*/
    }

    button.console_btns {
      width: 25% !important;
      border-radius: 7px !important;
    }

    button.console_btns:after {
      background-size: 16px 16px;
      width: 20px;
      height: 20px;
      top: 54%;
      left: 52%;
    }

    .tabH2.ui-collapsible-heading {
      top: 0px;
    }
  }
  /* AT LEAST THIS TALL*/
  @media only screen and (min-width: 480px) {
    .arc_popup, .arc_panel, div.glassHouse {
      max-width: 30rem !important;
    }
  }
  @media only screen and (min-width: 768px) {
    .arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-overlay,
.arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-push {
      /*controls panel slide animation*/
      right: 0;
      -webkit-transform: translate3d(17, 0, 0);
      -moz-transform: translate3d(17, 0, 0);
      transform: translate3d(17em, 0, 0);
    }
  }
  .TInput.borderline, .CInput.borderline, .detail_view.borderline {
    border-top: transparent !important;
    border-right: transparent !important;
    border-left: transparent !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #ccc !important;
  }

  .borderline input[disabled], .CInput.borderline:disabled {
    background-color: transparent !important;
    border-bottom: 2px solid rgba(178, 13, 13, 0.31) !important;
    cursor: not-allowed;
  }

  .arc_btn.d3-btn {
    width: 1.75em;
    height: 1.75em;
    font-size: 16px;
    margin: 0.5em 0;
    padding: 0.7em 1em;
    display: block;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
    text-indent: -9999px;
    white-space: nowrap !important;
  }

  .grid-item, .grid_item {
    min-height: 0;
    min-width: 0;
  }

  .d3-modal {
    display: none;
    padding-top: 100px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
  }

  /***************** test rules **********************/
  .test_yellow {
    border: 1px solid yellow !important;
  }

  .test_red {
    border: 1px solid red !important;
  }

  .test_blue {
    border: 1px solid blue !important;
  }

  .test_green {
    border: 1px solid green !important;
  }

  .test_purple {
    border: 1px solid purple !important;
  }

  .test_orange {
    border: 1px solid orange !important;
  }

  .test_brown {
    border: 1px solid brown !important;
  }

  .test_pink {
    border: 1px solid pink !important;
  }

  /***************** test rules **********************/
  .clamp-3, .clamp-2, .clamp-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: block !important;
    display: -webkit-box !important;
    line-height: 1.5;
    white-space: initial;
    height: -moz-fit-content;
    height: fit-content;
  }

  .clamp-0 {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .clamp-1 {
    -webkit-line-clamp: 1;
  }

  .clamp-2 {
    -webkit-line-clamp: 2;
  }

  .clamp-3 {
    -webkit-line-clamp: 3;
  }

  .word_wrap {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  /* styles for '...' */
  .block-with-text {
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 3.6em;
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;
    /* place for '...' */
    margin-right: -1em;
    padding-right: 1.2em;
  }

  /* create the ... */
  .block-with-text:before {
    /* points in the end */
    content: "...";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }

  /* hide ... if we have text, which is less than or equal to max lines */
  .block-with-text:after {
    /* points in the end */
    content: "";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
  }

  .bwt1 {
    max-height: 1.2em;
  }

  .bwt2 {
    max-height: 2.4em;
  }

  .bwt3 {
    max-height: 3.6em;
  }

  .chk_hidden_cont .fishBowl {
    max-width: 30rem !important;
  }

  /* Hide scrollbar for IE and Edge */
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scroll::-webkit-scrollbar, .hide_scroll::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  .hide-scroll, .hide_scroll {
    -ms-overflow-style: none;
  }

  .d3-fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .d3-fade-in-quick {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  .d3-fade-out-quick {
    opacity: 0;
    animation-name: fadeOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  .d3-fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  .d3-fade-delay-1 {
    /*opacity: 1;*/
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    animation-delay: 1s;
    /*both makes opacity obsolete - uses both start and end keyframe*/
    animation-fill-mode: both;
  }

  .d3-fade-delay-2 {
    /*opacity: 1;*/
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    animation-delay: 2s;
    /*both makes opacity obsolete - uses both start and end keyframe*/
    animation-fill-mode: both;
  }

  .d3-fade-delay-2-5 {
    /*opacity: 1;*/
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2.5s;
    animation-delay: 2.5s;
    /*both makes opacity obsolete - uses both start and end keyframe*/
    animation-fill-mode: both;
  }

  .d3-fade-delay-3 {
    /*opacity: 1;*/
    /*this does matter - its represents the starting opacity*/
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    animation-delay: 3s;
    animation-fill-mode: both;
    /*represents the ending opacity*/
  }

  .d3-fade-delay-4 {
    /*opacity: 1;*/
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    animation-delay: 4s;
    animation-fill-mode: both;
  }

  .d3-fade-delay-5 {
    /*opacity: 1;*/
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    animation-delay: 5s;
    animation-fill-mode: both;
  }

  .heartbeat {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
  }

  /* ----------------------------------------------
   * Generated by Animista on 2020-6-23 18:5:49
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info.
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  /**
   * ----------------------------------------
   * animation heartbeat
   * ----------------------------------------
   */
  @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
  .shonuff {
    -webkit-animation: shonuff 1.5s ease-in-out infinite both !important;
    animation: shonuff 1.5s ease-in-out infinite both !important;
    transform: scale(0.9) !important;
    /*slightly smaller, glow sometimes appears cut off*/
  }

  @keyframes shonuff {
    from {
      box-shadow: 0px 0px 0px 0px #0ff;
    }
    to {
      box-shadow: 0px 0px 14px 7px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
  }
  @-webkit-keyframes shonuff {
    from {
      box-shadow: 0px 0px 0px 0px #0ff;
    }
    to {
      box-shadow: 0px 0px 14px 7px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
  }
  .showoff {
    -webkit-animation: showoff 1.5s ease-in-out infinite both !important;
    animation: showoff 1.5s ease-in-out infinite both !important;
    transform: scale(0.9) !important;
    /*slightly smaller, glow sometimes appears cut off*/
  }

  @keyframes showoff {
    from {
      text-shadow: 0px 0px 0px #0ff;
    }
    to {
      text-shadow: 0px 0px 14px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
  }
  @-webkit-keyframes showoff {
    from {
      text-shadow: 0px 0px 0px 0px #0ff;
    }
    to {
      text-shadow: 0px 0px 14px 7px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
  }
  :root {
    --active-blue: #2d2dad;
    --bold-grey: #9e9e9e;
    --light-grey: #ccc;
    --button-blue: #38c;
  }

  .d3-slide-in-bottom {
    -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /* ----------------------------------------------
   * Generated by Animista on 2020-12-24 2:11:31
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info.
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  /**
   * ----------------------------------------
   * animation slide-in-bottom
   * ----------------------------------------
   */
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* AT LEAST THIS TALL*/
  /*
  // IMPORTANT: the cascade for tiny and mobile work in the opposite the smaller must be last
  // to override the larger

    @include mobile{ gap: 6%; }
    @include tiny{ gap: 4%;}
  */
  /*DOCS: mobile first setup "min" 
  * IMPORTANT - mobile first items have to cascade in a specific order from small to large otherwise
  * ISSUE the smaller queries cancel the larger ones out
  * keep the same order as below
  * GOTCHA: NOTE MOBILE && TINY ARE NOT IN THE SAME FLOW AS EVERYTHING ELSE - WEARABLE IS THE BOTTOM OF THE MULTI DEVICE FLOW
  */
  /**
    * NOTE: this is a mobile first setup.
    */
  /**
      usage:

      @use "../../scss/media";
      @include media.mobile{
        padding: 0 .25rem;
        font-size: 1.5rem;
      }

    */
  :root {
    --shimmer-color: #fff;
  }

  .d3-skeleton-video {
    position: relative;
    box-sizing: content-box;
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    background-image: linear-gradient(#E0E0E0 20px, transparent 0), radial-gradient(circle 17.5px at 17.5px 17.5px, #E0E0E0 16.5px, transparent 17.5px), linear-gradient(#E0E0E0 200px, transparent 0);
    background-repeat: no-repeat;
    background-size: 300px 290px, 35px 290px, 360px 290px;
    background-position: 70px 244px, 20px 236px, 20px 20px;
  }

  .d3-skeleton-video::before {
    content: " ";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 290px;
    -webkit-mask-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 290px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(var(--shimmer-color) 20px, transparent 0), radial-gradient(circle 17.5px at 17.5px 17.5px, var(--shimmer-color) 16.5px, transparent 17.5px), linear-gradient(var(--shimmer-color) 200px, transparent 0);
    background-repeat: repeat-y;
    background-size: 300px 290px, 35px 290px, 360px 290px;
    background-position: 70px 244px, 20px 236px, 20px 20px;
    animation: shineForD3-skeleton-video 2s infinite;
  }

  .d3-skeleton-video {
    width: 96%;
    aspect-ratio: 4/3;
    margin: 1rem auto 0;
  }
  @media only screen and (max-width: 400px) {
    .d3-skeleton-video {
      background-size: 72% 10%, 30% 30%, 88% 60%;
      background-position: 70px 210px, 20px 200px, 20px 20px;
    }
  }
  @media only screen and (max-width: 400px) {
    .d3-skeleton-video::before {
      background-size: 72% 10%, 30% 30%, 88% 60%;
      background-position: 70px 210px, 20px 200px, 20px 20px;
    }
  }
  .d3-skeleton-video div {
    z-index: 2;
  }

  @keyframes shineForD3-skeleton-video {
    to {
      -webkit-mask-position: 120% 0;
    }
  }
  .d3-skeleton-ig {
    position: relative;
    height: 452px;
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    background-image: linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), radial-gradient(circle 14px at 14px 14px, #cccccc 13px, transparent 14px), linear-gradient(#E0E0E0 20px, transparent 0), linear-gradient(#E0E0E0 300px, transparent 0);
    background-repeat: repeat-y;
    background-size: 350px 451px, 56px 451px, 180px 451px, 30px 451px, 300px 451px, 28px 451px, 30px 451px, 360px 451px;
    background-position: 23px 415px, 317px 381px, 121px 381px, 25px 381px, 70px 17px, 29px 13px, 70px 381px, 22px 53px;
  }

  .d3-skeleton-ig::before {
    content: " ";
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 452px;
    -webkit-mask-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
    -webkit-mask-repeat: repeat-y;
    -webkit-mask-size: 50px 452px;
    -webkit-mask-position: -20% 0;
    background-image: linear-gradient(#666666 20px, transparent 0), linear-gradient(#666666 20px, transparent 0), linear-gradient(#666666 20px, transparent 0), linear-gradient(#666666 20px, transparent 0), linear-gradient(#666666 20px, transparent 0), radial-gradient(circle 14px at 14px 14px, #666666 13px, transparent 14px), linear-gradient(#666666 20px, transparent 0), linear-gradient(#666666 300px, transparent 0);
    background-repeat: repeat-y;
    background-size: 350px 451px, 56px 451px, 180px 451px, 30px 451px, 300px 451px, 28px 451px, 30px 451px, 360px 451px;
    background-position: 23px 415px, 317px 381px, 121px 381px, 25px 381px, 70px 17px, 29px 13px, 70px 381px, 22px 53px;
    animation: shineForSkeleton-ig 2s infinite;
  }

  @keyframes shineForSkeleton-ig {
    to {
      -webkit-mask-position: 120% 0;
    }
  }
  .d3-skeleton-ig {
    aspect-ratio: 2/4;
  }
  .d3-skeleton-ig div {
    z-index: 2;
  }
}