/*flip style*/
.arc_view_content.plain {
  display: flex;
  flex-flow: row wrap;
  padding-top: 0.5rem;
  /*.arc_item*/
}
.arc_view_content.plain .arc_item {
  margin: 0rem 0.3rem 0.6rem 0.3rem;
  padding-bottom: 1rem;
  border-radius: unset;
  cursor: pointer;
  flex: 0 1 100%;
  max-height: 17rem;
  /*item_imgbx*/
}
.arc_view_content.plain .arc_item.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.arc_view_content.plain .arc_item img, .arc_view_content.plain .arc_item canvas {
  width: 400px;
  height: 200px;
  min-height: 100px;
  max-height: 250px !important;
}
.arc_view_content.plain .arc_item .arc_item_txtbx, .arc_view_content.plain .arc_item .arc_item_category {
  padding: 0.25rem 0.75rem;
}
.arc_view_content.plain .arc_item .arc_item_info_wrapper {
  display: grid;
  grid-template-areas: "category rating";
  grid-template-columns: 4fr 1fr;
  grid-gap: 1rem;
  margin: 0 0.7rem;
}
.arc_view_content.plain .arc_item .arc_item_info_wrapper .arc_item_category {
  grid-area: category;
  font-size: 12px;
  padding: 0 0 0.25rem;
  color: #9a9a9a;
}
.arc_view_content.plain .arc_item .arc_imgbx {
  display: flex;
  justify-content: center;
  overflow: hidden;
  /*item_img_image_area*/
  /*item_img_image_area, item_canvas_area*/
}
.arc_view_content.plain .arc_item .arc_imgbx .arc_image_area {
  width: 100%;
  max-height: 200px;
  /*item_img_ImgImage*/
}
.arc_view_content.plain .arc_item .arc_imgbx .arc_image_area .arc_ImgImage {
  width: 100%;
  height: 100%;
}
.arc_view_content.plain .arc_item .arc_imgbx .arc_image_area, .arc_view_content.plain .arc_item .arc_imgbx .arc_canvas_area {
  display: flex;
  justify-content: center;
  flex: 1;
}
.arc_view_content.plain .arc_item .arc_imgbx .arc_image_area img, .arc_view_content.plain .arc_item .arc_imgbx .arc_image_area canvas, .arc_view_content.plain .arc_item .arc_imgbx .arc_canvas_area img, .arc_view_content.plain .arc_item .arc_imgbx .arc_canvas_area canvas {
  object-fit: cover;
  /* i need a flex height and width the accomodate css order: property*/
  /*min-height: 100px;
  max-height: 100px;*/
}

/*arc_view_content .plain*/
.arc_view_content.flip {
  display: flex;
  flex-flow: row wrap;
  padding-top: 0.5rem;
  /*item_item*/
}
.arc_view_content.flip .arc_item {
  margin: 0rem 0.3rem 0.6rem 0.3rem;
  padding-bottom: 1rem;
  border-radius: unset;
  cursor: pointer;
  flex: 1 1 45%;
  /*item_imgbx*/
}
.arc_view_content.flip .arc_item.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.arc_view_content.flip .arc_item:nth-child(3n+1) {
  flex: 0 1 100%;
  max-height: 17rem;
}
.arc_view_content.flip .arc_item:nth-child(3n+1) img, .arc_view_content.flip .arc_item:nth-child(3n+1) canvas {
  width: 400px;
  height: 200px;
  min-height: 100px;
  max-height: 250px !important;
}
.arc_view_content.flip .arc_item .arc_txtbx {
  padding: 0.25rem 0.75rem;
}
.arc_view_content.flip .arc_item .arc_imgbx {
  display: flex;
  justify-content: center;
  overflow: hidden;
  /*item_img_image_area*/
  /*item_img_image_area, item_canvas_area*/
}
.arc_view_content.flip .arc_item .arc_imgbx .arc_image_area {
  width: 100%;
  max-height: 200px;
  /*item_img_ImgImage*/
}
.arc_view_content.flip .arc_item .arc_imgbx .arc_image_area .arc_ImgImage {
  width: 100%;
  height: 100%;
}
.arc_view_content.flip .arc_item .arc_imgbx .arc_image_area, .arc_view_content.flip .arc_item .arc_imgbx .arc_canvas_area {
  display: flex;
  justify-content: center;
  flex: 1;
}
.arc_view_content.flip .arc_item .arc_imgbx .arc_image_area img, .arc_view_content.flip .arc_item .arc_imgbx .arc_image_area canvas, .arc_view_content.flip .arc_item .arc_imgbx .arc_canvas_area img, .arc_view_content.flip .arc_item .arc_imgbx .arc_canvas_area canvas {
  object-fit: cover;
  /* i need a flex height and width the accomodate css order: property*/
  /*min-height: 100px;
  max-height: 100px;*/
}

/*arc_view_content .flip*/