$mp: !important;

 /*flip style*/
.arc_view_content.plain {
  display: flex;
  flex-flow: row wrap;
  padding-top: .5rem;

  .arc_item{
    margin: 0rem 0.3rem 0.6rem 0.3rem;
    padding-bottom: 1rem;
    border-radius: unset;
    cursor: pointer;
    &.card{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    }

      flex: 0 1 100%;
      max-height: 17rem;
      img, canvas{
        width:400px; height: 200px;
        min-height: 100px;
        max-height: 250px $mp;
      }

    .arc_item_txtbx, .arc_item_category{
      padding: .25rem .75rem;
    }
    .arc_item_info_wrapper{
      display: grid;
      grid-template-areas: "category rating";
      grid-template-columns: 4fr 1fr;
      grid-gap: 1rem;
      margin: 0 .7rem;
      .arc_item_category{
        grid-area: category;
        font-size: 12px;
        // line-height: 12px;/*clamp sets a line-height and if it did't use measurements like 1.4 no rem or px*/
        padding: 0 0 .25rem;
        color: #9a9a9a;
      }
    }//arc_item_info_wrapper
    .arc_imgbx{
      // .arc_item_img_cont{
        display: flex;
        justify-content: center;
        overflow: hidden;
        .arc_image_area{
          width:100%;
          max-height:200px;
          .arc_ImgImage{
            width:100%;
            height:100%;
          }/*item_img_ImgImage*/
        }/*item_img_image_area*/
        .arc_image_area, .arc_canvas_area{
          display: flex;
          justify-content: center;
          flex: 1;
          img, canvas{
            object-fit: cover;
            /* i need a flex height and width the accomodate css order: property*/
            /*min-height: 100px;
            max-height: 100px;*/
          }
        }/*item_img_image_area, item_canvas_area*/
      // }/*item_image_cont*/
    }/*item_imgbx*/
  }/*.arc_item*/


}/*arc_view_content .plain*/

.arc_view_content.flip {
  display: flex;
  flex-flow: row wrap;
  padding-top: .5rem;

  .arc_item{
    margin: 0rem 0.3rem 0.6rem 0.3rem;
    padding-bottom: 1rem;
    border-radius: unset;
    cursor: pointer;
    &.card{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    }

    &:nth-child(3n+1){
      // LATER: i think i want to make this every nth child that doesnt have portrait class for horizontal views or x-tool
      //the 1st and every 4th item
      flex: 0 1 100%;
      max-height: 17rem;
      img, canvas{
        width:400px; height: 200px;
        min-height: 100px;
        max-height: 250px $mp;
      }
    }
    //otherwise make every 2nd and 3rd
    flex: 1 1 45%;
    .arc_txtbx{
      padding: .25rem .75rem;
    }
    .arc_imgbx{
      // .arc_item_img_cont{
        display: flex;
        justify-content: center;
        overflow: hidden;
        .arc_image_area{
          width:100%;
          max-height:200px;
          .arc_ImgImage{
            width:100%;
            height:100%;
          }/*item_img_ImgImage*/
        }/*item_img_image_area*/
        .arc_image_area, .arc_canvas_area{
          display: flex;
          justify-content: center;
          flex: 1;
          img, canvas{
            object-fit: cover;
            /* i need a flex height and width the accomodate css order: property*/
            /*min-height: 100px;
            max-height: 100px;*/
          }
        }/*item_img_image_area, item_canvas_area*/
      // }/*item_image_cont*/
    }/*item_imgbx*/
  }/*item_item*/


}/*arc_view_content .flip*/
