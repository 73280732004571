.list_item_wrapper.skeleton.expanded .my_info_icon_cont {
  position: relative;
  padding: 0.5rem 0.25rem;
  height: 4.5rem;
}
.list_item_wrapper.skeleton.minified .icon_img {
  display: none;
}
.list_item_wrapper.skeleton.minified .my_info_options {
  padding-top: 0.5rem;
}
.list_item_wrapper.skeleton .icon_img, .list_item_wrapper.skeleton .my_info_icon, .list_item_wrapper.skeleton .my_info_btn {
  background-color: #ccc;
}