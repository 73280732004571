$mp: !important;

.charcoal{
  &.filter_wrapper{
    .filter_cont{
      background-color: #676363;
      input{
        background-color: #676363;
        color: white;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #ccc;
          opacity: 1; /* Firefox */
        }// placeholder
      }// input
    }// filter_cont
  }// filter_wrapper
  &.snap_core_snap_ctrls {
    justify-content: center;
    background-color: #676363;
    border-radius: 7px 7px 25px 25px;
    .snap_btn{
      color: #ccc;
      &.active {
          color: #b5b73d $mp;
      }//.active
    }// snap_btn
  }// snap_core_snap_ctrls
}// charcoal
