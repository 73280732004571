$mp: !important;
.join_in_wrapper{
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
  gap: 2rem;
  .join_ctrls_wrap{
    display: flex;
    justify-content: space-between;
    // gap: 1rem;
    margin-top: 2rem;
    width: 80%;
    .basicProfile_share{margin: unset $mp;}
    .joinBtn{
      font-size: 1.5rem;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }//:hover
      &:before{
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        height: 22px;
        width: 22px;
        line-height: 22px;
      }//:brefore
      &.active{
        transform: scale(1.2);
        &:before{color: var(--active-blue, #1EA7FD);}
      }//.active
    }
  }// join_ctrls_wrap
  .join_option{
    min-width: 12rem;
    &.join{color: #fff; background-color: var(--active-blue, #1EA7FD)}
    &.subscribe{color: #fff; background-color: red;}
    &.watch{color: #fff; background-color: #ae15a5;}
    &.pin{color: #fff; background-color: #da8e03;}
    &.support{color: #fff; background-color: green;}
    &.unregistered{color: #fff; background-color: grey;}
  }// join_option
}
