.join_in_wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
  gap: 2rem;
}
.join_in_wrapper .join_ctrls_wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 80%;
}
.join_in_wrapper .join_ctrls_wrap .basicProfile_share {
  margin: unset !important;
}
.join_in_wrapper .join_ctrls_wrap .joinBtn {
  font-size: 1.5rem;
  cursor: pointer;
}
.join_in_wrapper .join_ctrls_wrap .joinBtn:hover {
  transform: scale(1.2);
}
.join_in_wrapper .join_ctrls_wrap .joinBtn:before {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 22px;
  width: 22px;
  line-height: 22px;
}
.join_in_wrapper .join_ctrls_wrap .joinBtn.active {
  transform: scale(1.2);
}
.join_in_wrapper .join_ctrls_wrap .joinBtn.active:before {
  color: var(--active-blue, #1EA7FD);
}
.join_in_wrapper .join_option {
  min-width: 12rem;
}
.join_in_wrapper .join_option.join {
  color: #fff;
  background-color: var(--active-blue, #1EA7FD);
}
.join_in_wrapper .join_option.subscribe {
  color: #fff;
  background-color: red;
}
.join_in_wrapper .join_option.watch {
  color: #fff;
  background-color: #ae15a5;
}
.join_in_wrapper .join_option.pin {
  color: #fff;
  background-color: #da8e03;
}
.join_in_wrapper .join_option.support {
  color: #fff;
  background-color: green;
}
.join_in_wrapper .join_option.unregistered {
  color: #fff;
  background-color: grey;
}