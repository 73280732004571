div.priority_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2em;
  height: 2.2em;
  font-size: 80%;
  /* value attribute in the element - the example uses title */
  /* [css content](https:css-tricks.com/css-content/) */
}
div.priority_badge:before {
  content: attr(value);
}
div.priority_badge.min5 {
  background-color: #ff9980;
}
div.priority_badge.min4 {
  background-color: #ffad99;
}
div.priority_badge.min3 {
  background-color: #ffc2b3;
}
div.priority_badge.min2 {
  background-color: #ffd6cc;
}
div.priority_badge.min1 {
  background-color: #ffebe6;
}
div.priority_badge.min0, div.priority_badge.plus0 {
  background-color: #fff0ff;
}
div.priority_badge.plus1 {
  background-color: #eaeafb;
}
div.priority_badge.plus2 {
  background-color: #e6e6fa;
}
div.priority_badge.plus3 {
  background-color: #d4d4f7;
}
div.priority_badge.plus4 {
  background-color: #bfbff2;
}
div.priority_badge.plus5 {
  background-color: #aaaaee;
}