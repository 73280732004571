.edit_resize_box {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.edit_preview {
  position: relative;
}
.edit_preview .reset_btn {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: red;
  font-weight: bold;
}