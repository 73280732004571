$mp: !important;

$wW: 12px;// wheel width

%color-wheel{
  border: $wW solid black;
  border-radius: 50%;
  border-top: $wW solid blue;
  border-right: $wW solid yellow;
  border-bottom: $wW solid red;
  width: $wW;
  height: $wW;
  cursor: pointer;
}// color-wheel

%bw-wheel{
  border: $wW solid black;
  border-top: $wW solid black;
  border-right: $wW solid white;
  border-bottom: $wW solid white;
}

%wheat-wheel {
  border: $wW solid wheat;
  border-top: $wW solid wheat;
  border-right: $wW solid wheat;
  border-bottom: $wW solid wheat;
}

.stage_cont {
  display: grid;
    grid-template-areas:
      "head"
      "content";
    // grid-template-rows: auto 90%;
    grid-template-rows: clamp(1rem, 10%, 2rem) auto;
    gap: .5rem;
    border: 1px solid #ccc;

  flex: 0 0 90%;
  padding: .5rem 1rem;
  width: 100%;
  border-radius: 5px;
  scroll-snap-align: center;

  &.admin{
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 6rem;
    transition: max-height .5s;
    z-index: 1;

    &.expanded{max-height: 13rem; min-height: 6rem;}
    &.inactive{display: none;}
  }

  color: #cdd4db; // isDark
  &.isLight {
    color: #212529;

    .clip_category {
      color: #6a6969 $mp;
    }
  }

  // scroll-snap-stop: always;// i like this but it doesn't work with Sortables
  .stage_header {
    display: grid;
    grid-template-columns: auto 20%;
    grid-template-areas:
      "title ctrls";
    // justify-content: space-between;
    gap: .5rem;
    grid-area: head;

    .stage_title {
      width: fit-content;
      grid-area: title;
      font-weight: bold;
    }

    .stage_recolor{
      display: flex;
      gap: .5rem;
      .stage_wheel{@extend %color-wheel; outline: 2px solid #ccc;}
      .stage_wheat {@extend %wheat-wheel;}
      .stage_bw{@extend %bw-wheel;}
    }// stage_recolor

    .stage_ctrls_cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-area: ctrls;
      .stage_color{
        @extend %color-wheel;
      }
      .stage_color_close, .sort_handle, .stage_btn{font-size: 1.5rem; cursor: pointer;}
    }
  }

  .stage_content {
    display: flex;
    flex-flow: column nowrap;
    color: wheat;
    grid-area: content;
    overflow-y: auto;

    .sort_wrapper{height: 100%;}
    .lookout_wrapper{
      background-color: white;
      color: black;
      cursor: pointer;
      grid-template-columns: auto 4fr 2rem;
    }// lookout_wrapper

    .clip_btn{font-size: 1.5rem;}
    .clip_handle{
      width: 2rem;
      aspect-ratio: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      // font-size: unset;
      color: #ccc;
    }// clip_handle
  }// stage_content
  
  .stage_dialog_cont{
    grid-area: head;
  }// stage_dialog_cont

  .stageDialog{
    // the actual stage dialog box
    display: grid;
    grid-template-rows: 90% 10%;
    grid-template-areas:
      "content" "ctrls";

    max-width: 30rem;
    width: 90%;
    height: 90vh;
    padding: unset;
    border: unset;
    overflow: unset;

    .panel_main{grid-area: content;}
    .ctrls_wrapper{grid-area: ctrls; align-items: center;}
  }// stageDialog
}// stage_cont

.bkmk_Box_modal_cont.stageCore{
  z-index: 1210;// paper_modal_main z-idex is 1200
}