$mp: !important;

.task_section{
  // padding-top: 1rem;
  height: 100%;
  .task_view_cont{padding-top: 1rem;}
  .task_section_view{
    display: block;
    &.inactive{display: none;}
    &.board{
      height: 100%;
      padding: 0 1rem;
    }
  }
}