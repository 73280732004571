.task_view_wrapper {
  display: grid;
  grid-template-rows: 3rem 90%;
  grid-template-areas: "btns" "content";
}
.task_view_wrapper .task_view_btn_cont {
  display: flex;
  grid-area: btns;
  position: relative;
  gap: 0.5rem;
  padding: 0 0.5rem;
}
.task_view_wrapper .task_view_btn_cont .task_view_btn {
  outline-style: unset;
  border: 1px solid #ccc;
  border-bottom: unset;
  border-radius: 7px 7px 0px 0px;
  bottom: -0.15rem;
  background: white;
  margin-bottom: -2px;
  align-self: flex-end;
}
.task_view_wrapper .task_view_btn_cont .task_view_btn:nth-child(1) {
  justify-self: flex-start;
  left: 0.5rem;
}
.task_view_wrapper .task_view_btn_cont .task_view_btn:nth-child(2) {
  justify-self: flex-start;
  left: 6rem;
}
.task_view_wrapper .task_view_btn_cont .task_view_btn.inactive {
  background-color: grey;
  margin-bottom: 0;
  color: white;
}
.task_view_wrapper .task_view_cont {
  overflow-y: auto;
  grid-area: content;
  border-top: 1px solid #ccc;
}

.task_stages .tags_UI_cont,
.task_stages .tasks_UI_cont {
  display: flex;
  gap: 0.5rem;
}
.task_stages .tags_UI_cont .tags_reorder_btn,
.task_stages .tags_UI_cont .tasks_reorder_btn,
.task_stages .tags_UI_cont .tags_save_btn,
.task_stages .tags_UI_cont .tasks_save_btn,
.task_stages .tasks_UI_cont .tags_reorder_btn,
.task_stages .tasks_UI_cont .tasks_reorder_btn,
.task_stages .tasks_UI_cont .tags_save_btn,
.task_stages .tasks_UI_cont .tasks_save_btn {
  font-size: 1.5rem;
  align-self: center;
  cursor: pointer;
}
.task_stages .tags_UI_cont .tags_ICont,
.task_stages .tags_UI_cont .tasks_ICont,
.task_stages .tasks_UI_cont .tags_ICont,
.task_stages .tasks_UI_cont .tasks_ICont {
  width: 100%;
}
.task_stages .tags_box,
.task_stages .tasks_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}