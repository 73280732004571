$mp: !important;

%grid_items{
  min-width: 0;
  min-height: 0;
}

.pjTiles_search {
  width: 100%;
  display: grid;
  grid-template-areas:
    "input"
    "content";
  grid-template-rows: 5.7rem 12fr;
  height: 100%;
  flex: 1;
  grid-area: main;
  gap: 0.25rem;
}// pjTiles_search