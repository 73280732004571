.dialog-icon {
  grid-area: note;
  align-self: center;
}
.dialog-icon.active {
  color: var(--active-blue);
}

.dialogModal {
  border: unset;
  border-radius: 7px;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.3);
}
.dialogModal::backdrop {
  backdrop-filter: blur(2px);
}
.dialogModal header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.dialogModal header .dialog-image-icon {
  font-size: 2.5rem;
  color: var(--site-main);
  text-shadow: 2px 2px 2px #ccc;
}
.dialogModal header .dialog-image-icon.remove {
  color: red;
}
.dialogModal header .dialog-title {
  text-shadow: 2px 2px 2px #ccc;
}
.dialogModal header p {
  font-weight: 100;
}
.dialogModal footer {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.dialogModal footer .dialog-btn {
  border-radius: 7px;
}
.dialogModal footer .dialog-btn:focus {
  outline-color: var(--active-blue);
}
.dialogModal footer .opt-in {
  background-color: var(--site-main);
}
.dialogModal footer .opt-out {
  background-color: red;
  color: white;
}
.dialogModal footer .test {
  flex: 0 100%;
  width: 100%;
  margin-top: 0.5rem;
  border: 2px solid;
}