.d3log_dialog {
  display: grid;
  grid-template-areas: "title" "ctrls" "content";
  justify-content: flex-start;
  grid-template-columns: 100%;
  grid-template-rows: 5% 5% auto;
  width: 90vw;
  height: 100%;
  max-width: 30rem !important;
  text-align: left;
  border: unset;
  gap: 0.5rem;
}
.d3log_dialog .d3log_close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.d3log_dialog .d3log_title {
  grid-area: title;
  align-self: center;
  text-shadow: 2px 2px 2px #ccc;
}
.d3log_dialog .d3log_ctrl_cont {
  grid-area: ctrls;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
}
.d3log_dialog .d3log_ctrl_cont .d3log_ctrl {
  cursor: pointer;
}
.d3log_dialog .d3log_content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  grid-area: content;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 0.5rem;
}
.d3log_dialog .d3log_content .d3log_entry {
  border: 1px solid #ccc;
  width: 100%;
  height: fit-content;
  flex: 0 0 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
}
.d3log_dialog .d3log_content .d3log_entry.error {
  border: 1px solid salmon;
}
.d3log_dialog .d3log_content .d3log_entry.warn {
  border: 1px solid wheat;
}