



.badge_btn{
  cursor: pointer;
}

.BadgeForm{
  &.badge_btn{
    font-size: 1.5rem;
  }
}