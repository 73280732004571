.notify-icon {
  grid-area: note;
  align-self: center;
}
.notify-icon.active {
  color: var(--active-blue);
}

.notifyDialog {
  border: unset;
  border-radius: 7px;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.3);
}
.notifyDialog::backdrop {
  backdrop-filter: blur(2px);
}
.notifyDialog header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.notifyDialog header .notify-image-icon {
  font-size: 2.5rem;
  color: var(--site-main);
  text-shadow: 2px 2px 2px #ccc;
}
.notifyDialog header .notify-image-icon.remove {
  color: red;
}
.notifyDialog header .notify-title {
  text-shadow: 2px 2px 2px #ccc;
}
.notifyDialog header p {
  max-width: 15rem;
  font-weight: 100;
}
.notifyDialog footer {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.notifyDialog footer .notify-btn {
  border-radius: 7px;
}
.notifyDialog footer .notify-btn:focus {
  outline-color: var(--active-blue);
}
.notifyDialog footer .opt-in {
  background-color: var(--site-main);
}
.notifyDialog footer .opt-out {
  background-color: red;
  color: white;
}
.notifyDialog footer .test {
  flex: 0 100%;
  width: 100%;
  margin-top: 0.5rem;
  border: 2px solid;
}