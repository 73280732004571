.note_title_wrap {
  padding: 1rem;
}
.note_title_wrap h3 {
  text-shadow: 2px 2px 2px #ccc;
}
.note_title_wrap p, .note_title_wrap h5, .note_title_wrap h3 {
  margin: unset;
}
.note_title_wrap .note_title_cat {
  color: #9e9e9e;
}
.note_title_wrap .note_title_date {
  color: #ccc;
}

.note_title_hr {
  border-color: #ccc;
  margin: unset;
}

.tasksForm.task_section {
  padding-top: 1rem;
}