.pClip_header {
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
}
.pClip_header .pClip_btn {
  width: 4rem;
  border-radius: 7px;
}

.pClip_cont {
  border: 1.5px dashed #ccc;
  display: flex;
  justify-content: center;
}