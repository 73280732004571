.arc_item_wrapper.skeleton .clip_profile_icon_img, .arc_item_wrapper.skeleton .clip_txt, .arc_item_wrapper.skeleton .clip_cat_txt, .arc_item_wrapper.skeleton .clip_image_area, .arc_item_wrapper.skeleton .clip_profile_icon_img {
  background-color: #ccc;
}
.arc_item_wrapper.skeleton .clip_txtbx {
  padding: 0.5rem;
  width: 90%;
}
.arc_item_wrapper.skeleton .clip_txtbx .clip_txt {
  height: 2rem;
}
.arc_item_wrapper.skeleton .clip_category {
  padding: 0.5rem;
  width: 70%;
}
.arc_item_wrapper.skeleton .clip_category .clip_cat_txt {
  height: 1rem;
}
.arc_item_wrapper.skeleton .clip_imgbx {
  padding: 0.25rem;
}
.arc_item_wrapper.skeleton .clip_imgbx .clip_image_area {
  height: 10rem;
}
.arc_item_wrapper.skeleton .clip_profile {
  padding: 0.25rem;
}
.arc_item_wrapper.skeleton .clip_profile .clip_profile_icon_img {
  width: 100%;
  height: unset !important;
  aspect-ratio: 1/1;
  border-radius: 4rem;
}