.inform_modal_cont.to-front ~ .modal_temp_cont .edit_canvas_modal_cont, .inform_modal_cont.to-front ~ .part_temp_cont > .part_modal_cont, .inform_modal_cont.to-front ~ .img_mkr_cont > .image_form_modal_cont, .inform_modal_cont.to-front {
  z-index: 1310 !important;
  /*310*/
}

/*************** 1300 ********************/
.warn_msg.dash {
  z-index: 1320 !important;
}

.inform_modal_cont.to-front ~ .modal_temp_cont {
  z-index: 1315 !important;
  /*310*/
}
.ui-header-fixed, .ui-footer-fixed {
  z-index: 105 !important;
}

.w3-modal.loading, .toaster_home {
  z-index: 1310 !important;
}

#pp_sidebar.active {
  z-index: 1300 !important;
}

/*************** 1000 ********************/
.warn_msg {
  z-index: 1235 !important;
}

.share_modal_cont, .qrc_view_modal_cont, .send_modal_cont {
  z-index: 1210 !important;
}

.paper_icon {
  z-index: 1205;
}

.paper_headers {
  z-index: 1200 !important;
}

.paper_modal_main {
  z-index: 1200;
}

/*************** 200 ********************/
.flavor_ctrls_iWall_modal_cont, .flavorFav_modal_cont {
  z-index: 235 !important;
}

.share_modal_cont.details {
  z-index: 215 !important;
}

.resize_canvas_modal_cont {
  z-index: 230 !important;
}

.edit_canvas_modal_cont {
  z-index: 220 !important;
}

.modal_temp_cont {
  z-index: 202 !important;
}
.modal_temp_cont .edit_canvas_modal_cont {
  z-index: 201 !important;
}

.pTree_view_modal_cont {
  z-index: 205 !important;
}

.inform_modal_cont, .part_modal_cont, .image_form_modal_cont {
  z-index: 201 !important;
}

/*************** 100 ********************/
.chk_bkmk_Box_lightbox.chk_bkmk_list {
  z-index: 118 !important;
}

div.li_liteBox_lightbox.arc_list_preview, div.arc_bkmk_Box_lightbox.arc_bkmk_list {
  z-index: 120 !important;
}

.filter_select_lightbox {
  z-index: 120 !important;
}

.arc_popup_modal_cont, .form_ctrls_span {
  z-index: 101 !important;
}

.navbar.details {
  z-index: unset !important;
}
.MainCore.arc_popup_modal_cont {
  z-index: 50 !important;
}

/**
* see core_class use cases below below
* core tag names: MainCore, PortableCore, CheckCore
*/
.MainCore {
  /*.paper_cont,.clip_modal_cont,*/
}
.MainCore.modal_cont {
  z-index: 50 !important;
}
.MainCore.details_modal_cont {
  z-index: 70 !important;
}
.MainCore.arc_panel_modal {
  z-index: 102 !important;
}
.MainCore.select_sections_modal_cont, .MainCore.stack_modal_cont, .MainCore.filter_select_modal_cont {
  z-index: 101 !important;
}
.MainCore.arc_panel.ui-panel, .MainCore.SelectOptionsIWL_modal_cont {
  z-index: 112 !important;
}
.MainCore.bkmk_Box_modal_cont {
  z-index: 119 !important;
}
.MainCore.topic_viewer_modal_cont {
  z-index: 120 !important;
}
.MainCore.resize_canvas_modal_cont {
  z-index: 230 !important;
}
.MainCore.edit_canvas_modal_cont {
  z-index: 220 !important;
}
.MainCore.modal_temp_cont {
  z-index: 202 !important;
}
.MainCore.modal_temp_cont .edit_canvas_modal_cont {
  z-index: 201 !important;
}
.MainCore.pTree_view_modal_cont {
  z-index: 205 !important;
}
.MainCore.inform_modal_cont, .MainCore.part_modal_cont, .MainCore.image_form_modal_cont {
  z-index: 201 !important;
}
.MainCore.flavor_ctrls_iWall_modal_cont, .MainCore.flavorFav_modal_cont {
  z-index: 235 !important;
}
.MainCore.share_modal_cont.hld_Share, .MainCore.flavor_modal_cont {
  z-index: 235 !important;
}
.MainCore.iWall_modal_cont.modal_cont.core, .MainCore.flav_iconSelect_iWall_modal_cont, .MainCore.hold_move_modal_cont {
  z-index: 300 !important;
}

.PortableCore {
  /*.paper_cont,.clip_modal_cont,*/
}
.PortableCore.modal_cont {
  z-index: 450 !important;
}
.PortableCore.details_modal_cont {
  z-index: 470 !important;
}
.PortableCore.arc_panel_modal {
  z-index: 502 !important;
}
.PortableCore.select_sections_modal_cont, .PortableCore.stack_modal_cont, .PortableCore.filter_select_modal_cont {
  z-index: 501 !important;
}
.PortableCore.arc_panel.ui-panel, .PortableCore.SelectOptionsIWL_modal_cont {
  z-index: 512 !important;
}
.PortableCore.bkmk_Box_modal_cont {
  z-index: 519 !important;
}
.PortableCore.topic_viewer_modal_cont {
  z-index: 520 !important;
}
.PortableCore.resize_canvas_modal_cont {
  z-index: 230 !important;
}
.PortableCore.edit_canvas_modal_cont {
  z-index: 220 !important;
}
.PortableCore.modal_temp_cont {
  z-index: 202 !important;
}
.PortableCore.modal_temp_cont .edit_canvas_modal_cont {
  z-index: 201 !important;
}
.PortableCore.pTree_view_modal_cont {
  z-index: 205 !important;
}
.PortableCore.inform_modal_cont, .PortableCore.part_modal_cont, .PortableCore.image_form_modal_cont {
  z-index: 201 !important;
}
.PortableCore.flavor_ctrls_iWall_modal_cont, .PortableCore.flavorFav_modal_cont {
  z-index: 635 !important;
}
.PortableCore.share_modal_cont.hld_Share, .PortableCore.flavor_modal_cont {
  z-index: 635 !important;
}
.PortableCore.iWall_modal_cont.modal_cont.core, .PortableCore.flav_iconSelect_iWall_modal_cont, .PortableCore.hold_move_modal_cont {
  z-index: 700 !important;
}

.LibraryCore {
  /*.paper_cont,.clip_modal_cont,*/
}
.LibraryCore.modal_cont {
  z-index: 450 !important;
}
.LibraryCore.details_modal_cont {
  z-index: 470 !important;
}
.LibraryCore.arc_panel_modal {
  z-index: 502 !important;
}
.LibraryCore.select_sections_modal_cont, .LibraryCore.stack_modal_cont, .LibraryCore.filter_select_modal_cont {
  z-index: 501 !important;
}
.LibraryCore.arc_panel.ui-panel, .LibraryCore.SelectOptionsIWL_modal_cont {
  z-index: 512 !important;
}
.LibraryCore.bkmk_Box_modal_cont {
  z-index: 519 !important;
}
.LibraryCore.topic_viewer_modal_cont {
  z-index: 520 !important;
}
.LibraryCore.resize_canvas_modal_cont {
  z-index: 230 !important;
}
.LibraryCore.edit_canvas_modal_cont {
  z-index: 220 !important;
}
.LibraryCore.modal_temp_cont {
  z-index: 202 !important;
}
.LibraryCore.modal_temp_cont .edit_canvas_modal_cont {
  z-index: 201 !important;
}
.LibraryCore.pTree_view_modal_cont {
  z-index: 205 !important;
}
.LibraryCore.inform_modal_cont, .LibraryCore.part_modal_cont, .LibraryCore.image_form_modal_cont {
  z-index: 201 !important;
}
.LibraryCore.flavor_ctrls_iWall_modal_cont, .LibraryCore.flavorFav_modal_cont {
  z-index: 635 !important;
}
.LibraryCore.share_modal_cont.hld_Share, .LibraryCore.flavor_modal_cont {
  z-index: 635 !important;
}
.LibraryCore.iWall_modal_cont.modal_cont.core, .LibraryCore.flav_iconSelect_iWall_modal_cont, .LibraryCore.hold_move_modal_cont {
  z-index: 700 !important;
}

.CheckCore {
  /*.paper_cont,.clip_modal_cont,*/
}
.CheckCore.modal_cont {
  z-index: 850 !important;
}
.CheckCore.details_modal_cont {
  z-index: 870 !important;
}
.CheckCore.arc_panel_modal {
  z-index: 902 !important;
}
.CheckCore.select_sections_modal_cont, .CheckCore.stack_modal_cont, .CheckCore.filter_select_modal_cont {
  z-index: 901 !important;
}
.CheckCore.arc_panel.ui-panel, .CheckCore.SelectOptionsIWL_modal_cont {
  z-index: 912 !important;
}
.CheckCore.bkmk_Box_modal_cont {
  z-index: 919 !important;
}
.CheckCore.topic_viewer_modal_cont {
  z-index: 920 !important;
}
.CheckCore.resize_canvas_modal_cont {
  z-index: 230 !important;
}
.CheckCore.edit_canvas_modal_cont {
  z-index: 220 !important;
}
.CheckCore.modal_temp_cont {
  z-index: 202 !important;
}
.CheckCore.modal_temp_cont .edit_canvas_modal_cont {
  z-index: 201 !important;
}
.CheckCore.pTree_view_modal_cont {
  z-index: 205 !important;
}
.CheckCore.inform_modal_cont, .CheckCore.part_modal_cont, .CheckCore.image_form_modal_cont {
  z-index: 201 !important;
}
.CheckCore.flavor_ctrls_iWall_modal_cont, .CheckCore.flavorFav_modal_cont {
  z-index: 1035 !important;
}
.CheckCore.share_modal_cont.hld_Share, .CheckCore.flavor_modal_cont {
  z-index: 1035 !important;
}
.CheckCore.iWall_modal_cont.modal_cont.core, .CheckCore.flav_iconSelect_iWall_modal_cont, .CheckCore.hold_move_modal_cont {
  z-index: 1100 !important;
}

.TopCore {
  /*.paper_cont,.clip_modal_cont,*/
}
.TopCore.modal_cont {
  z-index: 1250 !important;
}
.TopCore.details_modal_cont {
  z-index: 1270 !important;
}
.TopCore.arc_panel_modal {
  z-index: 1302 !important;
}
.TopCore.select_sections_modal_cont, .TopCore.stack_modal_cont, .TopCore.filter_select_modal_cont {
  z-index: 1301 !important;
}
.TopCore.arc_panel.ui-panel, .TopCore.SelectOptionsIWL_modal_cont {
  z-index: 1312 !important;
}
.TopCore.bkmk_Box_modal_cont {
  z-index: 1319 !important;
}
.TopCore.topic_viewer_modal_cont {
  z-index: 1320 !important;
}
.TopCore.resize_canvas_modal_cont {
  z-index: 230 !important;
}
.TopCore.edit_canvas_modal_cont {
  z-index: 220 !important;
}
.TopCore.modal_temp_cont {
  z-index: 202 !important;
}
.TopCore.modal_temp_cont .edit_canvas_modal_cont {
  z-index: 201 !important;
}
.TopCore.pTree_view_modal_cont {
  z-index: 205 !important;
}
.TopCore.inform_modal_cont, .TopCore.part_modal_cont, .TopCore.image_form_modal_cont {
  z-index: 201 !important;
}
.TopCore.flavor_ctrls_iWall_modal_cont, .TopCore.flavorFav_modal_cont {
  z-index: 1435 !important;
}
.TopCore.share_modal_cont.hld_Share, .TopCore.flavor_modal_cont {
  z-index: 1435 !important;
}
.TopCore.iWall_modal_cont.modal_cont.core, .TopCore.flav_iconSelect_iWall_modal_cont, .TopCore.hold_move_modal_cont {
  z-index: 1500 !important;
}

.arc_footer {
  z-index: 60 !important;
}

/**
  possible unresolved z-Index issues: 
  - ImageMaker
*/