$mp: !important;

.ckmt_wrap{
  cursor: pointer;
  .ckmt_in_wrap.d3-checkbox {
    position: relative;
    margin: 0px $mp;
    width: 2rem;
    // GOTCHA: if no width elements may appear to overlap each other or go behind the input element
    display: flex;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    input {
      position: absolute;
      // left: .466em;
      // top: 50%;
      top: 1rem;
      width: 1rem;
      height: 1rem;
      margin: -11px 0 0 0;
      outline: 0$mp;
      z-index: 1;
      cursor: pointer;
    }
  }// ckmt_in_wrap
}// ckmt_wrap

.bookmark_ckmt_wrap{
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  height: 2rem;
  padding: 0 5px;
  bottom: 0.25rem;
  left: 3.5rem;
  display: flex;
  gap: 5px;
  align-items: center;
  .ckmt_in_wrap{
    .ckmt_text{
      font-size: .9rem;
      color: #6c757d
    }
  }
}
