.progress_cont {
  display: flex;
  justify-content: center;
  width: 100%;
}
.progress_cont .btnGrpCont {
  width: 90%;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.progress_cont .btnGrpCont.Details {
  display: grid;
  grid-template-areas: "label" "controls";
  gap: 0.5rem 0;
  padding: 1rem;
}
.progress_cont .btnGrpCont.Details .labelText {
  grid-area: label;
}
.progress_cont .btnGrpCont.Details .btnGrpInner {
  grid-area: controls;
  display: inline-flex;
  flex-flow: row wrap;
  gap: 0.25rem;
}
.progress_cont .btnGrpCont.Details .btnGrpInner .btnGrp {
  flex: 1 auto;
  border-radius: 0;
}
.progress_cont .btnGrpCont .btnGrp {
  font-family: Verdana, sans-serif !important;
}
.progress_cont .btnGrpCont .btnGrp.selected {
  background: var(--active-blue);
  color: white;
}
.progress_cont .btnGrpCont .btnGrp.selected.pending {
  background: grey;
}
.progress_cont .btnGrpCont .btnGrp.selected.active {
  background: var(--site-main, yellow);
  color: black;
}
.progress_cont .btnGrpCont .btnGrp.selected.stop {
  background: red;
}
.progress_cont .btnGrpCont .btnGrp.selected.done {
  background: green;
}