


.task_view_wrapper {
  display: grid;
  // flex-flow: column nowrap;
  grid-template-rows: 3rem 90%;
  grid-template-areas: "btns""content";

  .task_view_btn_cont {
    display: flex;
    grid-area: btns;
    position: relative;
    gap: .5rem;
    padding: 0 .5rem;

    .task_view_btn {
      outline-style: unset;
      border: 1px solid #ccc;
      border-bottom: unset;
      border-radius: 7px 7px 0px 0px;
      bottom: -.15rem;
      background: white;
      // position: absolute;
      margin-bottom: -2px;
      align-self: flex-end;

      &:nth-child(1) {
        justify-self: flex-start;
        left: .5rem;
      }
      &:nth-child(2) {
        justify-self: flex-start;
        left: 6rem;
      }

      &.inactive {
        background-color: grey;
        margin-bottom: 0;
        color: white;
        // height: 90%;
      }
    }
  }

  .task_view_cont {
    overflow-y: auto;
    grid-area: content;
    border-top: 1px solid #ccc;
  }
}

.task_stages {

  .tags_UI_cont,
  .tasks_UI_cont {
    display: flex;
    gap: .5rem;

    .tags_reorder_btn,
    .tasks_reorder_btn,
    .tags_save_btn,
    .tasks_save_btn {
      font-size: 1.5rem;
      align-self: center;
      cursor: pointer;
    }

    .tags_ICont,
    .tasks_ICont {
      width: 100%;
    }
  }

  .tags_box,
  .tasks_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}