.stage_cont .stage_header .stage_ctrls_cont .stage_color, .stage_cont .stage_header .stage_recolor .stage_wheel {
  border: 12px solid black;
  border-radius: 50%;
  border-top: 12px solid blue;
  border-right: 12px solid yellow;
  border-bottom: 12px solid red;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.stage_cont .stage_header .stage_recolor .stage_bw {
  border: 12px solid black;
  border-top: 12px solid black;
  border-right: 12px solid white;
  border-bottom: 12px solid white;
}

.stage_cont .stage_header .stage_recolor .stage_wheat {
  border: 12px solid wheat;
  border-top: 12px solid wheat;
  border-right: 12px solid wheat;
  border-bottom: 12px solid wheat;
}

.stage_cont {
  display: grid;
  grid-template-areas: "head" "content";
  grid-template-rows: clamp(1rem, 10%, 2rem) auto;
  gap: 0.5rem;
  border: 1px solid #ccc;
  flex: 0 0 90%;
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 5px;
  scroll-snap-align: center;
  color: #cdd4db;
}
.stage_cont.admin {
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 6rem;
  transition: max-height 0.5s;
  z-index: 1;
}
.stage_cont.admin.expanded {
  max-height: 13rem;
  min-height: 6rem;
}
.stage_cont.admin.inactive {
  display: none;
}
.stage_cont.isLight {
  color: #212529;
}
.stage_cont.isLight .clip_category {
  color: #6a6969 !important;
}
.stage_cont .stage_header {
  display: grid;
  grid-template-columns: auto 20%;
  grid-template-areas: "title ctrls";
  gap: 0.5rem;
  grid-area: head;
}
.stage_cont .stage_header .stage_title {
  width: fit-content;
  grid-area: title;
  font-weight: bold;
}
.stage_cont .stage_header .stage_recolor {
  display: flex;
  gap: 0.5rem;
}
.stage_cont .stage_header .stage_recolor .stage_wheel {
  outline: 2px solid #ccc;
}
.stage_cont .stage_header .stage_ctrls_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-area: ctrls;
}
.stage_cont .stage_header .stage_ctrls_cont .stage_color_close, .stage_cont .stage_header .stage_ctrls_cont .sort_handle, .stage_cont .stage_header .stage_ctrls_cont .stage_btn {
  font-size: 1.5rem;
  cursor: pointer;
}
.stage_cont .stage_content {
  display: flex;
  flex-flow: column nowrap;
  color: wheat;
  grid-area: content;
  overflow-y: auto;
}
.stage_cont .stage_content .sort_wrapper {
  height: 100%;
}
.stage_cont .stage_content .lookout_wrapper {
  background-color: white;
  color: black;
  cursor: pointer;
  grid-template-columns: auto 4fr 2rem;
}
.stage_cont .stage_content .clip_btn {
  font-size: 1.5rem;
}
.stage_cont .stage_content .clip_handle {
  width: 2rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
.stage_cont .stage_dialog_cont {
  grid-area: head;
}
.stage_cont .stageDialog {
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-areas: "content" "ctrls";
  max-width: 30rem;
  width: 90%;
  height: 90vh;
  padding: unset;
  border: unset;
  overflow: unset;
}
.stage_cont .stageDialog .panel_main {
  grid-area: content;
}
.stage_cont .stageDialog .ctrls_wrapper {
  grid-area: ctrls;
  align-items: center;
}

.bkmk_Box_modal_cont.stageCore {
  z-index: 1210;
}