@layer alight {
  html {
    overscroll-behavior: contain;
  }

  .snapBox {
    /*snap_ctrls*/
    /*snap_cont*/
  }
  .snapBox.orientation_ctrls {
    display: grid !important;
    grid-template-areas: "left center right" "bottom bottom bottom";
    grid-template-columns: auto 9fr auto;
    grid-template-rows: 9fr auto;
  }
  .snapBox.orientation_ctrls .snap_cont {
    grid-area: center;
  }
  .snapBox.orientation_ctrls .snap_ctrls.snap_core {
    border-radius: 10px;
    padding: 5px;
    width: 40px;
    background: #e9ecef;
    grid-template-areas: "left" "main" "right" !important;
    grid-template-rows: auto 9fr auto;
  }
  .snapBox.orientation_ctrls .snap_ctrls.snap_core .snap_btn {
    border-radius: 0px;
  }
  .snapBox.orientation_ctrls .snap_ctrls .snap_ctrls_left {
    align-items: flex-start !important;
  }
  .snapBox.orientation_ctrls .snap_ctrls .snap_ctrls_left .user_menu {
    /*margin-top: .2rem;*/
  }
  .snapBox.orientation_ctrls .snap_ctrls .snap_ctrls_main {
    flex-flow: column;
    gap: 1rem;
    width: unset !important;
    margin: unset !important;
  }
  .snapBox.orientation_ctrls .snap_ctrls .snap_ctrls_main .snap_profile {
    margin: 0 0.5rem !important;
  }
  .snapBox.orientation_ctrls .snap_ctrls .snap_ctrls_right {
    flex-flow: column nowrap;
  }
  .snapBox.orientation_ctrls .snap_ctrls div.snap_btn {
    margin: unset !important;
  }
  .snapBox.orientation_ctrls.left .snap_ctrls {
    grid-area: left;
  }
  .snapBox.orientation_ctrls.right .snap_ctrls {
    grid-area: right;
  }
  .snapBox.orientation_ctrls.left .snap_ctrls, .snapBox.orientation_ctrls.right .snap_ctrls {
    height: 100%;
  }
  .snapBox.orientation_ctrls.left .snap_ctrls_main, .snapBox.orientation_ctrls.right .snap_ctrls_main {
    margin: 1rem 0 !important;
    overflow-x: hidden !important;
  }
  .snapBox .snap_ctrls {
    display: flex;
    justify-content: space-around;
    min-height: 0;
    /**/
  }
  .snapBox .snap_ctrls .snap_btn {
    height: 1.75em;
    line-height: 1.5;
  }
  .snapBox .snap_ctrls .snap_btn.dots {
    border-radius: 50% !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    flex: unset !important;
  }
  .snapBox .snap_ctrls .snap_btn:hover {
    transform: scale(1.2);
  }
  .snapBox .snap_ctrls .snap_btn.active {
    background-color: var(--active-blue);
    color: white;
    transform: unset;
  }
  .snapBox .snap_ctrls .snap_profile {
    height: 35px;
    width: 35px;
    cursor: pointer;
  }
  .snapBox .snap_ctrls .snap_profile .image_area {
    height: 100%;
    width: 100%;
  }
  .snapBox .snap_ctrls .snap_profile .snap_profile_icon {
    font-size: 1.6rem !important;
  }
  .snapBox .snap_ctrls .snap_profile .d3-profile-rounded {
    border-width: 2px !important;
  }
  .snapBox .snap_ctrls .snap_profile img, .snapBox .snap_ctrls .snap_profile canvas {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .snapBox .snap_cont {
    width: 100%;
    min-height: 20rem;
    border: 1px solid #ddd;
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */
    /*snap_section*/
  }
  .snapBox .snap_cont::-webkit-scrollbar {
    display: none;
  }
  .snapBox .snap_cont.scroll {
    display: flex;
    flex-flow: row nowrap;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .snapBox .snap_cont .snap_section {
    /*width: 100%;*/
    height: 100%;
    scroll-snap-align: center;
    overflow-y: auto;
    scroll-snap-stop: always;
    /*.tabs*/
  }
  .snapBox .snap_cont .snap_section.scroll {
    flex: 1 0 100%;
  }
  .snapBox .snap_cont .snap_section.tabs {
    display: none;
    width: auto;
  }
  .snapBox .snap_cont .snap_section.tabs.show {
    display: block;
  }
  .snapBox .snap_cont .snap_section .section_wrap {
    height: 100%;
  }

  /*snapBox*/
  .snap_profile {
    height: 35px !important;
    width: 35px;
    flex: 0 0 2.2rem;
    cursor: pointer;
    margin: 5px !important;
  }
  .snap_profile .image_area {
    height: 100%;
    width: 100%;
  }
  .snap_profile .snap_profile_icon {
    font-size: 1.6rem !important;
  }
  .snap_profile .d3-profile-rounded {
    border-width: 2px !important;
  }
  .snap_profile img, .snap_profile canvas {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .snap_dyn_ctrls {
    font-size: 1.5rem;
  }

  .snapBox.snap_core .snap_ctrls, .arc_footer .snap_ctrls {
    display: grid;
    grid-template-areas: "left main right";
    grid-template-columns: 1fr 5fr 1fr;
    margin: 0 0.5rem;
    min-height: 0;
  }
  .snapBox.snap_core .snap_ctrls .snap_ctrls_main, .arc_footer .snap_ctrls .snap_ctrls_main {
    display: flex;
    grid-area: main;
    align-items: center;
    overflow: auto;
    width: 15.3rem;
    margin: 0 auto;
  }
  .snapBox.snap_core .snap_ctrls .snap_ctrls_main.left, .arc_footer .snap_ctrls .snap_ctrls_main.left {
    justify-content: flex-start;
  }
  .snapBox.snap_core .snap_ctrls .snap_ctrls_main.center, .arc_footer .snap_ctrls .snap_ctrls_main.center {
    justify-content: center;
  }
  .snapBox.snap_core .snap_ctrls .snap_ctrls_left, .arc_footer .snap_ctrls .snap_ctrls_left {
    grid-area: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .snapBox.snap_core .snap_ctrls .snap_ctrls_right, .arc_footer .snap_ctrls .snap_ctrls_right {
    grid-area: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .snapBox.snap_core .snap_ctrls .snap_btn, .arc_footer .snap_ctrls .snap_btn {
    font-size: 1.5rem;
    margin: 0 0.5rem !important;
    height: unset !important;
    line-height: normal !important;
    color: #545454;
  }
  .snapBox.snap_core .snap_ctrls .snap_btn.snap_core, .arc_footer .snap_ctrls .snap_btn.snap_core {
    border: unset;
    background-color: unset;
  }
  .snapBox.snap_core .snap_ctrls .snap_btn.active, .arc_footer .snap_ctrls .snap_btn.active {
    color: #2d2dad;
  }

  .charcoal.snap_core_snap_ctrls {
    justify-content: center;
    background-color: #676363;
    border-radius: 7px 7px 25px 25px;
  }
  .charcoal.snap_core_snap_ctrls .snap_btn {
    color: #fff;
  }
}