.filter_wrapper {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0.25rem 0 0.5rem;
  background: #fff;
  border-bottom: thin solid #ccc;
  position: relative;
  /*filter_cont*/
}
.filter_wrapper .filter_cont {
  display: flex;
  padding: 3px 0.25rem 3px 7%;
  margin: 0 0.5rem !important;
  flex: 1 auto;
  width: unset;
  /*filter_reset*/
}
.filter_wrapper .filter_cont .filter_reset, .filter_wrapper .filter_cont .filter_input_btns {
  display: flex;
  align-items: center;
  margin: 0 0.25rem;
  color: #ccc;
}
.filter_wrapper .filter_cont .filter_reset.heartbeat, .filter_wrapper .filter_cont .filter_input_btns.heartbeat {
  color: #d4d414;
}
.filter_wrapper .filter_option_wrapper {
  display: grid;
  grid-template-areas: "scope all options none back";
  grid-template-columns: 1fr 1fr 8fr 1fr 1fr;
  gap: 0.25rem;
}
.filter_wrapper .filter_option_wrapper .filter_option_cont {
  grid-area: options;
  display: flex !important;
  flex-flow: row nowrap;
  flex: 1;
  overflow-x: auto;
}
.filter_wrapper .filter_option_wrapper .filter_option_all {
  grid-area: all;
}
.filter_wrapper .filter_option_wrapper .filter_option_scope {
  grid-area: scope;
}
.filter_wrapper .filter_option_wrapper .filter_option_none {
  grid-area: none;
}
.filter_wrapper .filter_option_wrapper .filter_option_back {
  grid-area: back;
}
.filter_wrapper .filter_option_wrapper .filter_option_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 3px !important;
  flex: 0 1 3rem !important;
  padding: 0px !important;
  border-radius: 0px !important;
  height: 1.75em;
}
.filter_wrapper .filter_option_wrapper .filter_option_btn.static {
  width: 100% !important;
}
.filter_wrapper .filter_option_wrapper .filter_option_btn.inactive {
  color: white;
  background-color: #bbb !important;
}
.filter_wrapper .filter_option_wrapper .filter_option_btn.active {
  color: white;
  background-color: #2d2dad !important;
}
.filter_wrapper .filter_option_wrapr {
  position: absolute;
  width: 100%;
  margin-top: 0.25rem !important;
}
.filter_wrapper .filter_option_wrapr .filter_iWall_modal {
  display: flex;
}
.filter_wrapper .filter_option_wrapr .filter_iWall_modal .filter_iWall_content {
  border: unset;
  padding: 0.5rem 0.25rem;
  flex: 1 auto;
}
.filter_wrapper .filter_option_wrapr .filter_iWall_modal .filter_iWall_content .filter_iWall_iconWall_cont {
  display: flex;
}
.filter_wrapper .filter_option_wrapr .filter_iWall_modal .filter_iWall_content .filter_iWall_iconWall_cont.charcoal {
  background-color: #676363;
  border-radius: 7px 7px 25px 25px;
}
.filter_wrapper .filter_option_wrapr .filter_iWall_modal .filter_iWall_content .filter_iWall_iconWall_cont.charcoal * {
  color: #fff;
  border: unset;
}
.filter_wrapper .filter_option_wrapr .filter_iWall_modal .filter_iWall_content .filter_iWall_iconWall_cont div {
  flex: 1 auto;
  min-width: 40px;
}
.filter_wrapper .filter_option_wrapr .iWall_modal {
  padding: 0.25rem 0 0.5rem;
  border-radius: unset;
}

/*filter_wrapper*/