$mp: !important;
@import '../../../../css/media';

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

%shadow{
  text-shadow: 2px 2px 2px #ccc;
}

.stack_modal_cont{
  z-index: 101 !important;
  height: 100vh;
  padding: 1rem 0.5rem 0.5rem;
  display: flex !important;
  .stack_modal{
    margin: 0 auto;
    display: grid;
    grid-template-areas:
        "content"
        "ctrls";
    grid-template-rows: 1fr auto;
    .stack_content{grid-area: content;}
    .stack_ctrls_wrapper{grid-area: ctrls;}
  }
}
.stack_wrapper{
  --min5: #ff9980;
  --min4: #ffad99;
  --min3: #ffc2b3;
  --min2: #ffd6cc;
  --min1: #ffebe6;
  --min0: #fff0ff;//same
  --plus0: #fff0ff;//same
  --plus1: #eaeafb;
  --plus2: #e6e6fa;
  --plus3: #d4d4f7;
  --plus4: #bfbff2;
  --plus5: #aaaaee;
  padding: 1rem;
  hr{margin: .75rem 0;}
  .stack_head{
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    .stack_upper{
      display: grid;
      grid-template-areas: "title icon" "cat icon";
      grid-template-columns: 6fr 1fr;
      gap: 0 .5rem;
      .news_btn{
        justify-self: center;
        align-self: center;
        font-size: 1.5rem; 
        grid-area: icon;
      }
    }
    .stack_ctrls{
      .stack_ctrls_header{
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        font-size: 1.5rem;
        &:has(.stack_feature_label){
          width: 100%;
          .stack_feature_label{
            width: 100%; font-size: 1rem;
            // hide on tiny screens
            @include tiny {display: none;}
          }
        }

        .stack_feature{
          &:before{color: #c7c74b;}
        }

        .stack_solution.on{ color: green; }
      }// stack_ctrls_header
      .stack_ctrls_menu{
        .rawDrop_header{
          justify-content: flex-end;
          gap: 1rem;
        }// rawDrop_header
        .access_select_icon_cont{padding: 1rem 0.5rem 0;}
        .stack_date_picker{
          display: grid;
          grid-template-areas: "label ." "date clr";
          grid-template-columns: 5fr auto;
          padding: 0 0.5rem;
          &:has(button){
            gap: 0 1rem;
            grid-template-columns: 5fr 1fr;
          }
          input{grid-area: date; padding: .5rem;}
          button{grid-area: clr; border: 1px solid #ccc; border-radius: 5px;}
        }
      }// stack_ctrls_menu
    }// stack_ctrls
    label{padding-right: .5rem;}
    .title_cont, .category_cont{
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white);
      width: 97%;
    }
    .title_cont{grid-area: title; label{}}
    .category_cont{grid-area: cat; color: #7a7a7a; margin-top: .25rem;}
    .caption_cont{
      display: grid;
      margin: 10px 0 5px;
      grid-template-areas:
      "ctrls text"
      ". counter";
      // grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
      grid-template-columns: auto 5fr;// .5fr
      gap: 0.25rem;
      .caption_ctrls{
        display: flex;
        grid-area: ctrls;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: center;
        .cap_ctrl_btn{
          cursor: pointer;
          &.active{color: var(--active-blue);}
        }
        .caption_profile{
          cursor: pointer;
          .canvas_area{width: 2rem; height: 2rem;}
          &.active{canvas{border: 2px solid var(--active-blue);}}
        }
      }
      .caption_text{grid-area: text;}
      // DOCS: originally the label was in column 1 the textarea spanned 2-4 and the counter was in column 
      // label{ grid-column: auto / span 1;}
      textarea{
        // height:70px;
        // resize: none;
        padding: .25rem .5rem;
        // grid-area: text;
        // grid-column: auto / span 4;

        // border-width: 1px;
        // border-style: solid;
        // background-color: #fff;
        // border-color: #ddd;
        // color: #333;
        // text-shadow: 0 1px 0 #f3f3f3;
        // box-shadow: inset 0 1px 3px rgba(0,0,0,.2);
        // border-radius: .3125em;
        // &:focus{outline-color: cornsilk;}
      }
      .caption_count{
        display:flex;
        justify-content: flex-end;
        grid-area: counter;

        font-size:15px;
        .caption_limit{color: #ccc;}
        .caption_counter{
          color: #0F8A09;
          &.warn{color: #d8d881;}
          &.full{color: red;}
        }
      }//caption_count
    }

  }/*stack_head*/
  .review_cont{
    display:flex;
    justify-content: space-between;
    label{
      display: flex;
      margin: unset;
      align-items: center;
      @extend %shadow;
    }
    .rate_btn{
      display: flex;
      align-items: center;
      justify-content: center;
      margin:unset;
      font-size: 1.6rem;
      cursor: pointer;
    }
    div.rate_btn::before, div.no_rate_btn::before{
      // color:red;
      color: #c7c74b;
    }
  }/*review_cont*/
  // hr{margin: unset}
  .priority_cont{
    display: grid;
    grid-template-areas:
        "title badge"
        "label label"
        "main main"
        "base base";
    .priority_title{@extend %shadow; grid-area: title;}
    .priority_badge{
      display: flex;
      justify-content: center;
      align-items: center;
      grid-area: badge;
      width: 2rem;
      height: 2rem;
      border-radius: 7px;
      justify-self: flex-end;
    }/*priority_badge*/
    .priority_labels{
      display: flex;
      justify-content: space-between;
      grid-area: label;
    }/*priority_labels*/
  }
  .priority_box{
    display: flex;
    &.main{grid-area: main;}
    &.base{grid-area: base; padding: .5rem;}
    .priority_btn{
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid #ccc;
      height: 30px;
      flex: 1;
      text-align: center;
      cursor: pointer;
      &:hover{
        background-color: blue;
        color: #fff;
      }
    }
  }
}/*stack_wrapper*/

.StackReviewIWL_modal{
  max-height: 550px $mp;
  margin-top: .5rem $mp;
  height: 100%;
  .StackReviewIWL_content{
    height: 94%;
  }
}

  .min5 { background-color: var(--min5 ); }
  .min4 { background-color: var(--min4 ); }
  .min3 { background-color: var(--min3 ); }
  .min2 { background-color: var(--min2 ); }
  .min1 { background-color: var(--min1 ); }
  .min0, .plus0{ background-color: var(--plus0 );}
  .plus1{ background-color: var(--plus1); }
  .plus2{ background-color: var(--plus2); }
  .plus3{ background-color: var(--plus3); }
  .plus4{ background-color: var(--plus4); }
  .plus5{ background-color: var(--plus5); }



.priority_btn{
  &.active{
    color: #fff;
    background-color: var(--active-blue) $mp;
  }
  &.main{
    &:nth-child(1){
      // background-color: #ff9980;
      background-color: var(--min5);
    }
    &:nth-child(2){
      // background-color: #ffad99;
      background-color: var(--min4);
    }
    &:nth-child(3){
      // background-color: #ffc2b3;
      background-color: var(--min3);
    }
    &:nth-child(4){
      // background-color: #ffd6cc;
      background-color: var(--min2);
    }
    &:nth-child(5){
      // background-color: #ffebe6;
      background-color: var(--min1);
    }
    &:nth-child(6){
      // background-color: #ffffff;
      background-color: var(--plus0);// lavenderblush
    }
    &:nth-child(7){
      // background-color: #eaeafb;
      background-color: var(--plus1);
    }
    &:nth-child(8){
      // background-color: #e6e6fa;
      background-color: var(--plus2);
    }
    &:nth-child(9){
      // background-color: #d4d4f7;
      background-color: var(--plus3);
    }
    &:nth-child(10){
      // background-color: #bfbff2;
      background-color: var(--plus4);
    }
    &:nth-child(11){
      // background-color: #aaaaee;
      background-color: var(--plus5);
    }

  }//.main
}

// #ff9980
// #ffad99
// #ffd6cc
// #ffc2b3
// #ffebe6
// #ffffff
// #eaeafb
// #e6e6fa
// #d4d4f7
// #bfbff2
// #aaaaee
