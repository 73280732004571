$mp: !important;

.binder_details{
  width: 30px;
  height: 30px;
  margin-right: .25rem;
  &.default{
    width: 100% $mp;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    height: 100%;
  }// default
  &.disabled, &.disabled:hover{
    color: #ccc;
  }
}// binder_details
