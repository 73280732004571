$mp: !important;

.arc_item_wrapper.skeleton{
  .clip_profile_icon_img, .clip_txt, .clip_cat_txt, .clip_image_area, .clip_profile_icon_img{
    background-color: #ccc;
    // border: 1px solid red;
  }
  
  .clip_txtbx{
    padding: .5rem;
    width: 90%;
    .clip_txt{
      height: 2rem;
    }
  }
  .clip_category{
    padding: .5rem;
    width: 70%;
    .clip_cat_txt{
      height: 1rem;
    }
  }
  .clip_imgbx{
    padding: .25rem;
    .clip_image_area{
      height: 10rem;
    }
  }
  .clip_profile{
    padding: .25rem;
    .clip_profile_icon_img {
      width: 100%;
      height: unset $mp;
      aspect-ratio: 1 / 1;
      border-radius: 4rem;
    }
  }
}