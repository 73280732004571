.views_view_wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 0 0.15rem;
  gap: 0.5rem;
}
.views_view_wrapper .views_view_header {
  display: grid;
  grid-template-areas: "icon title options";
  grid-template-columns: 1fr 80% 1fr;
  width: 100%;
  text-shadow: 2px 2px 2px #ccc;
  font-size: 1.2rem;
  gap: 0.5rem;
}
.views_view_wrapper .views_view_header .views_view_title {
  margin-left: 0.75rem;
  grid-area: title;
  height: fit-content;
  align-self: center;
}
.views_view_wrapper .views_view_header .views_view_icon {
  grid-area: icon;
  justify-self: left;
  text-align: left;
  margin-left: 0.75rem;
  padding: 0.25rem 0 0;
  height: fit-content;
  font-size: 2.2rem;
}
.views_view_wrapper .views_view_header .views_view_options {
  grid-area: options;
  justify-self: right;
  align-self: center;
  text-align: right;
  margin-right: 0.75rem;
  padding: 0.25rem 0 0;
  width: 2rem !important;
  height: fit-content !important;
}
.views_view_wrapper .views_view_cont {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
  height: 90%;
  flex: 1 auto;
}
.views_view_wrapper .views_view_cont .views_view_content {
  overflow-y: auto;
  height: 100%;
  overscroll-behavior-y: contain;
}
.views_view_wrapper .views_view_cont .views_view_content .views_item_wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  height: 255px;
  margin: 5px;
}
.views_view_wrapper .views_view_cont .views_view_content .views_item_wrapper .views_item {
  width: 100%;
}
.views_view_wrapper .views_view_cont .views_view_content .views_item_observer {
  width: 100%;
}

.views_loader_wrapper, .views_scroll_loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.views_loader_wrapper .views_item_loader, .views_scroll_loader_wrapper .views_item_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.views_loader_wrapper .views_item_loader .loader_refresh, .views_scroll_loader_wrapper .views_item_loader .loader_refresh {
  font-size: 1.5rem;
}
.views_loader_wrapper div[class~=item_loader], .views_scroll_loader_wrapper div[class~=item_loader] {
  border: 1px solid red !important;
}