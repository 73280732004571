$mp: !important;

  .paper_headers{
    // border: 1px solid red;
    // min-height: 2.7rem;
    height: 100%;
    display: grid;
    // position: relative;
    // position: fixed;
    top:.75rem;
    // height: 1rem;// no longer needed - it should be fine nested in a grid-area element
    width: 100%;
    grid-template-areas:"left pages right";
    // grid-template-columns: minmax(20%, auto) minmax(auto, 30rem) minmax(20%, auto);
    grid-template-columns: auto minmax(auto, 80%) auto;
    // NOTE to move down details see details.scss > .details_modal li:28
    &.core{
      // bottom: 5px;
    }
    // grid-template-columns: 1fr 7fr 1fr;
    // z-index: 2100;
    .paper_pages{
      grid-area: pages;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0 .5rem;
      height: 100%;

      .page_btn{
        height: 2.5rem;
        width: 2rem;
        // background: white;
        margin: 0 .25rem;
        cursor: pointer;
        &.blocks{
          background: #ffffffa3;
          height: 100%;
          flex: 1;
          &.active{
            background: #2d2dada3;
          }// active
        }// blocks

        &.icons{
          font-size: 2.5rem;
          color: white;
          display: flex;
          justify-content: center;
          &.active{
            color: #2d2dad;
          }
        }
        &.default{
          background: white;
          &.active{
            background-color: #2d2dad;
          }
        }
      }
    }
    .page_turners{
      // width: 25%;
      height: auto;
      background: var(--site-main);
      // border: 1px solid red;
      cursor: pointer;
      &.left{
        grid-area: left;
      }
      &.right{
        grid-area: right;
        // justify-self: right;
      }
    }
  }//paper_headers


