$mp: !important;

%icon_cont{
  display: flex;
  flex-flow: column nowrap;
  align-self: center; 
  grid-area: icon; 
  gap: 1rem;
  border-left: 1px solid #ccc;
  padding-left: 0.5rem;
  margin-left: 0.5rem;
}
%desc{color: #9e9e9e; grid-area: desc;}
%title{/*text-shadow: 2px 2px 2px #ccc;*/ grid-area: txt;line-height: 1.6; margin: unset;}
%edit_only{
  display: none $mp;
  color: #9e9e9e;
  &.edit{display: block $mp;}
}
%inactive{color: #9e9e9e $mp; text-decoration: line-through;}

.pTree_wrapper{
  .pTree_profile{
    display: grid;
    grid-template-areas: ". . ." ". icon ." ". user ." "hr hr hr";
    grid-template-columns: 1fr 5fr 1fr;
    grid-template-rows: 1fr auto auto 1fr;
    height: 14em;
    position: relative;
    // position: sticky;
    // top: 0;
    // background: white;
    hr{grid-area: hr; margin: unset; align-self: center; justify-self: center; width: 95%;}
    .ProfileIcon_icon_wrapper {
      display: flex;
      flex-flow: column;
      height: 100%;
      gap: .5rem;
      grid-area: icon;
      .ProfileIcon_icon{justify-self: center; align-self: center; font-size: 6em;}
      .image_area, .canvas_area{
        display:flex; justify-content: center;
        // hack until i fix master_image
        img, canvas{width: 8rem; height: 8rem;}
        &.d3-profile-rounded{
          border-radius: unset;
          border: unset;
        }
      }//image_area
    }// ProfileIcon_icon_wrapper
    .pTree_username{
      grid-area: user; 
      justify-self: center; 
      align-self: center;
      text-shadow: 2px 2px 2px #ccc;
      // font-size: 1.2em;
      // font-weight: 600;
    }// pTree_username
  }// pTree_profile
  .pTree_loader_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }// pTree_loader_wrapper 

  .lookout_wrapper{ 
    margin: unset;
  }
}// pTree_wrapper

.pTree_link_wrapper{
  padding: 0 1em;
  display: flex;
  flex-flow: column;
  // gap: 1em;
  gap: 0.5em;
  .sort_wrapper{
    display: flex;
    flex-flow: column;
    gap: 0.5em;
  }// sort_wrapper
}// pTree_link_wrapper

.pTree_link_content {
  width: 100%;
  border-radius: .5em;
  margin: 0.5rem 0;
  a{color: unset;
    &:hover{color: unset; text-decoration: unset;}
  }
  &.default{
    .pTree_link{
      &.left_display{
        .pTree_link_title, .pTree_link_desc{justify-self: flex-start;}
      }// left_display
      &:focus{
        outline: none !important;
        text-decoration: none;
      }
      display: grid;
      grid-template-areas:
          "img txt share icon" "img desc share icon";
      grid-template-columns: auto 3fr auto auto;
      border-radius: .5em;
      &.edit{gap: .5rem;}
      .pTree_link_title, .pTree_link_desc{text-align: left; width: 100%;}
      .pTree_link_title{@extend %title;}
      .pTree_link_desc{@extend %desc; }
      .pTree_QRBtn{ grid-area: share; justify-self: center; align-self: center; font-size: 1.4rem;}
      .pTree_link_icon_cont{ @extend %icon_cont;}
      // .pTree_link_edit{}
      // .pTree_link_delete{}
      .pTLink.ProfileIcon_icon_wrapper{
        grid-area: img; height: 4em; width: 5em;
         margin-right: 1rem;
        .image_area{
          height: 100%;
          img, canvas{height: 100%; width: 100%; object-fit: cover;}
        }
      }
    }// pTree_link
  }
  
}// pTree_link_content

.pTree_add{
  display: flex $mp;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 0.5em 1em $mp;
  border-radius: .75em;
  font-size: 1.2em;
  line-height: 1;
  // width: 6em;
  // position: absolute;
  right: 1em;
  bottom: 6em;
  position: sticky;
  bottom: .5rem;
  background: #9a9783 $mp;
  color: #fff $mp;
  margin: 1rem .25rem .5rem;
  gap: 1rem;
  overflow: unset $mp;// hack to correct pTree add btn height w3-btn has an overflow value(??)
  p{font-family: "Segoe UI",Arial,sans-serif; margin: unset;}
}// pTree_add

.pTree_empty{text-align: center;}

.pTree_form_cont{
  .cancelBtn{background-image: linear-gradient(to bottom, #f9fff8, #AAADAA) !important;}
  .glass_confirm{height: unset; margin: unset $mp;}
  .qrc_view_modal{padding: 1.4em;}
}// pTree_form_cont

.qrc_view_content{max-height: unset $mp;}

.pTree_form_wrapper{
  fieldset{border: unset;}
  .form_quick_menu{width: unset; margin: unset;}
  hr{margin: 0.5rem 0;}
  .pTree_form_form{
  }// pTree_form_form
  .pTree_form{
    border: 1px solid #ccc;
    padding: 1rem;
    max-height: 72vh;
    overflow-y: auto;
  }// pTree_form
  .title_error{
    margin: unset;
    background: salmon;
    padding: .25rem .5rem;
    color: white;
  }// title_error

  .pTree_clip_type_btns{
    &.active{color: white; background-color: var(--active-blue);}
  }
}// pTree_form_wrapper

.pTree_view_modal_cont {
    padding-top: 1em !important;
    font-size: 1rem;
    .pTree_view_modal {
        max-height: 90vh;
        padding-bottom: 0.5em;
        margin: 1.5em auto 30px;
        .pTree_view_content{border: unset;}
    }
}// pTree_view_modal_cont

.pTree_image_preview_cont{
  padding: 1rem 0;
  .pTree_image.ProfileIcon_icon_wrapper{display: flex; justify-content:center; align-items: center;}
  .image_area{
    height: 9rem;
    width: 9rem;
    img, canvas{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }// image_area
}

// .bio_links{
//   padding-top: 0 $mp;//2rem;
// }

.pTree_input, .pTree_textarea{
  display: grid;
  grid-template-areas: "label case" "input input";
  grid-template-columns: 9fr 1fr;
  gap: .25rem;
  label{grid-area: label;}
  .custom_input_ctrls{
    display:flex;
    flex-flow: row nowrap;
    grid-area: case;
  }// custom_input_ctrls
  .change_case_icon, .copy_me_icon, .show_hide_icon{
    font-size: 1.2rem;
    padding: 0.5rem;
    justify-self: center;
    color: #bbb;
    align-self: center;
    margin-left: 0.25em;
    &:only-child{margin: unset;}
  }// change_case_icon
  input{grid-area: input;}
  textarea{grid-area: input;}
}

.pTree_select{
  padding: 0.5rem;
  .MuiSelect-select{border: 1px solid #ced4da; padding: .5rem;}
}

.pTree_textarea{padding-top: 0.5rem;}

.pTree_content {
  width: 100%;
  // padding: unset $mp;
  display: inline-block;
  padding: 0 1rem;
  &.edit{border: 1.5px dashed #ccc $mp; padding: 0 1rem $mp;}
  .pTree_inner{

    .pTree_header{
      padding: .5rem 0;//1rem; 
      display: grid;
      grid-template-areas:"title icons" "desc icons" "note icons";
      grid-template-columns: 7fr auto;
      width: 100%;
      &.pTree_section_header{
        // padding: 0 1em;// see Description.scss for active selector
       .pTree_icon_cont {
         width: 4rem;
        flex-flow: row wrap;
       }
      }
    }
    .pTree_item_list{
      display: flex;
      flex-flow: column;
      gap: 0.5rem;
      .lookout_wrapper{
        min-height: 5rem;
        position: relative;
        .lookout_type {
          top: 2rem;
          bottom: unset;
          left: 50px;
        }
      }
    }
    display: flex;
    flex-flow: column nowrap;
    .pTree_title{@extend %title; grid-area: title; text-align: left; 
      &.inactive{@extend %inactive;}
    }
    .pTree_desc{@extend %desc; 
      &.inactive{@extend %inactive;}
    }
    .pTree_text{grid-area: note; padding: 0.25rem 0;}// 1rem
    .pTree_section_title{color: #9e9e9e;}
    .pTree_text_title, .pTree_text_desc, .pTree_text_header{
      // @extend %edit_only;
    }
    .pTree_text_header.edit{ display: grid $mp; color: black;}
    .pTree_icon_cont{  
      @extend %icon_cont; grid-area: icons;
      .pTree_icon{cursor: pointer;}
    }
    .pTree_add{grid-area: add; margin: 1rem .5rem;}
  }
}

.pTree_section_content {
  .pTree_section_ptree{

    &.hide{
      .pTree_wrapper.page_section{
        border: 1px solid red;
        height: 0rem;
        overflow: hidden;
      }
    }
    .pTree_link_wrapper{
      flex: unset !important;
      padding: unset;
    }// pTree_link_wrapper
  }// pTree_section_ptree
  &.auto_spacer, &.auto-spacer{padding-top: 3rem $mp;}
}// pTree_section_content

.pTree_clip_cont{
  padding-top: 1rem;
  .pTree_desc{color: #9e9e9e;}
  .mdx-embed {
    display: flex;
    justify-content: center;
  }
}

.MetaTree{
  .pTree_link_header{padding: 0.5rem 0 $mp;}
  .meta_tree.rich_cont{
    position: relative;
    cursor: pointer;
    padding: 1rem 0;
    .metaTree_QRBtn{
      position: absolute;
      top: 1.5em;
      right: 2em;
      font-size: 1.3em;
    }
  }// meta_tree
}// MetaTree