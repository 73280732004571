.task_section {
  height: 100%;
}
.task_section .task_view_cont {
  padding-top: 1rem;
}
.task_section .task_section_view {
  display: block;
}
.task_section .task_section_view.inactive {
  display: none;
}
.task_section .task_section_view.board {
  height: 100%;
  padding: 0 1rem;
}