/*
// IMPORTANT: the cascade for tiny and mobile work in the opposite the smaller must be last
// to override the larger

  @include mobile{ gap: 6%; }
  @include tiny{ gap: 4%;}
*/
/*DOCS: mobile first setup "min" 
* IMPORTANT - mobile first items have to cascade in a specific order from small to large otherwise
* ISSUE the smaller queries cancel the larger ones out
* keep the same order as below
* GOTCHA: NOTE MOBILE && TINY ARE NOT IN THE SAME FLOW AS EVERYTHING ELSE - WEARABLE IS THE BOTTOM OF THE MULTI DEVICE FLOW
*/
/**
  * NOTE: this is a mobile first setup.
  */
/**
    usage:

    @use "../../scss/media";
    @include media.mobile{
      padding: 0 .25rem;
      font-size: 1.5rem;
    }

  */
.arc_view_wrapper .arc_view_header > *, .arc_view_wrapper > * {
  min-width: 0;
  min-height: 0;
}

.arc_view_cont .arc_view_content .virtuoso-scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.arc_view_cont .arc_view_content .virtuoso-scroller {
  -ms-overflow-style: none;
}

.arc_view_wrapper {
  display: grid;
  grid-template-areas: "label" "main";
  grid-template-rows: auto 9fr;
  flex-flow: column;
  height: 100%;
  padding: 0 0.15rem;
  gap: 0.5rem;
}
.arc_view_wrapper .arc_view_header {
  grid-area: label;
  display: grid;
  grid-template-areas: "icon title options";
  grid-template-columns: auto 8fr 1fr;
  width: 100%;
  text-shadow: 2px 2px 2px #ccc;
  font-size: 1.2rem;
  gap: 0.5rem;
}
.arc_view_wrapper .arc_view_header .arc_view_title {
  grid-area: title;
  height: fit-content;
  align-self: center;
}
.arc_view_wrapper .arc_view_header .arc_view_icon {
  grid-area: icon;
  justify-self: left;
  text-align: left;
  margin-left: 0.75rem;
  padding: 0.25rem 0 0;
  height: fit-content;
  font-size: 2.2rem;
}
.arc_view_wrapper .arc_view_header .arc_view_options, .arc_view_wrapper .arc_view_header .TopOp_wrapper {
  grid-area: options;
  justify-self: right;
  align-self: center;
  text-align: right;
  margin-right: 0.75rem;
  padding: 0.25rem 0 0;
  height: fit-content !important;
}

.arc_view_cont {
  grid-area: main;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
  height: 100%;
  flex: 0 1 auto;
}
.arc_view_cont .arc_view_content {
  overflow: hidden;
  height: 100%;
}
.arc_view_cont .arc_view_content .virtuoso-scroller {
  flex: 1 auto;
}
.arc_view_cont .arc_view_content .arc_item_wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  height: 255px;
  margin: 5px auto;
  cursor: pointer;
}
@media only screen and (min-width: 600px) {
  .arc_view_cont .arc_view_content .arc_item_wrapper {
    aspect-ratio: 4/3;
  }
}
.arc_view_cont .arc_view_content .arc_item_wrapper .arc_item {
  width: 100%;
}
.arc_view_cont .arc_view_content .arc_item_observer {
  width: 100%;
}

*[class*=loader_wrapper], .arc_scroll_loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
*[class*=loader_wrapper] .arc_item_loader, .arc_scroll_loader_wrapper .arc_item_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
*[class*=loader_wrapper] .arc_item_loader .loader_refresh, .arc_scroll_loader_wrapper .arc_item_loader .loader_refresh {
  font-size: 1.5rem;
}
*[class*=loader_wrapper] div[class~=item_loader], .arc_scroll_loader_wrapper div[class~=item_loader] {
  border: 1px solid red !important;
}