
$mp: !important;

div[class*="modal_content"]{
  border: unset $mp;
  margin-bottom: unset $mp;
  div[class*="panel_main"]{
    padding:unset $mp;
    [class*="panel_main_content"]{
      margin-bottom:1.5rem;
    }
  }
}

.arc_panel_modal.active, .arc_panel.active, .arc_popup_modal.active, .arc_popup.active{display:block;}
.arc_panel{
  display:none;/*temporary*/
  width: 95%;
  margin: 0 auto;
  #arc_innerPanel{background:white; padding: 1em;}
  .arc_panel_close{position: absolute; top: .75rem; right: .75rem;}
  .arc_panel_go{position: absolute; top: .75rem; right: .75rem; right: 3.5rem;}
}

#arc_panel_main, #chk_panel_main{
  // this is actually part of arc_popup not arc_panel
  overflow: visible;
  border-width: 0;
  padding: 1em;
  display: grid;
  grid-template-areas:
      "header"
      "content";
  grid-template-columns: 100%;
  grid-template-rows: 7% 92%;
  gap: .25rem;
}

.chk_panel_main_content{margin-bottom: unset $mp;}

.arc_panel_infoBox{height:20px;margin-bottom:0px;}
.arc_panel_infoBtn{float:right; right:-8px; width: 1.75em $mp; margin: .5em 0;}
.arc_panel_infoCont{margin-bottom: 10px;}
.arc_info_close_btn{float:right; position:relative !important; top:-30px !important; right:-7px !important;margin:0px !important;}
.arc_infoBox{display:none;}
.main_arc_info{border: 1px solid #ddd; padding: 0px 10px;}
.contact_info_creator{border:1px solid #ccc;width:100%;border-radius:7px;}/*height:300px;*/
.contact_type_selector{float:left;height:300px;width:15%;
border:1px solid purple;float:left;}
.arc_header.flexbox, .chk_header.flexbox{/*height:100%;*/
  width:100%;
  justify-content:flex-start;
  // margin: 0 auto 7px auto;
  align-items: center;
  grid-area: header;
}

.modal_content.core{
  max-height: unset $mp;//snap
  .snap_cont{
    border: unset;
    //snap
  }
  .snap_ctrls{
    /*padding-top:.25rem;*///snap
  }
}
.arc_panel_main_content{
  margin-bottom: unset $mp;
}
.modal_dialog.core{
  padding: 1rem .5rem $mp;
  width: 95% $mp;
  max-height: none $mp;
}
div.modal_dialog.core{
  padding-bottom: .25rem $mp;//snap
}

// div[class$="popup_content"]{ literally means the end, no css class attribute can come after the word for this to apply
