.binder_details {
  width: 30px;
  height: 30px;
  margin-right: 0.25rem;
}
.binder_details.default {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  height: 100%;
}
.binder_details.disabled, .binder_details.disabled:hover {
  color: #ccc;
}