.rawDrop_wrapper {
  display: flex;
  flex-flow: column nowrap;
}
.rawDrop_wrapper .rawDrop_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.rawDrop_wrapper .rawDrop_header label {
  cursor: pointer;
}
.rawDrop_wrapper .rawDrop_display_cont {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  /*needs a max-height value for the animation to work*/
  /*I tried to use height instead of max-height - its container's height wouldn't return to squished height*/
}
.rawDrop_wrapper .rawDrop_display_cont.stretch {
  max-height: 80rem;
}
.rawDrop_wrapper .rawDrop_display_cont.squish {
  max-height: 0rem;
}
.rawDrop_wrapper hr {
  margin: 0.5rem 2rem;
}