@layer base {  // @layer reset, base, alight;

  :root{
    --site-main: #ffc107;
    --status-active-lt: #ffc107c9; 
    --status-active: #ffc107;// same as site main LATER: make this DRY
    --status-stop-lt: #ff00008a;
    --status-stop: red;
    --status-done-lt: #0080007d;
    --status-done: green;
    --off-black: #333;
  }

  $mp: !important;
    *{box-sizing:border-box;}

    body{
      overscroll-behavior-y: contain;
    }

    /* tab design
    border: 1px solid rebeccapurple;
    text-align: center;
    border-bottom: none;
    border-radius: 7px 7px 0px 0px;
    */
    .topper {
      display:none;
      &.extend{display: block $mp;}
    }

    /** SiteSide **/
    .arc_hidden_cont{height:0px;width:0px;}
    /*liteBox animation*/
    .arc_box_space{position: absolute; top: 0; left: 0; width: 100%;}
    .liteBox{width: 100%; height: 690px; position: absolute; /*z-index: 1150;*/ top: 0;
        left: 0;opacity:0;}
    .liteBox.help{top:-30px;}
    // .arc_popup_modal{z-index: 1001 $mp;}
    .arc_popup{
      position:relative $mp;
          border-width: 1px;
      border-style: solid;
      background-color: #fff;
      border-color: #ddd;
      color: #333;
      text-shadow: 0 1px 0 #f3f3f3;
      /*card shadow*/
          -webkit-box-shadow: 0 0 12px rgba(0,0,0,.6);
      -moz-box-shadow: 0 0 12px rgba(0,0,0,.6);
      box-shadow: 0 0 12px rgba(0,0,0,.6);

      /*does nothing*/
          -webkit-background-clip: padding;
      background-clip: padding-box;

      /*rounded*/
      -webkit-border-radius: .3125em;
      border-radius: .3125em;
    }/*formerly unset - i don't know why its unset.*/
    .w3-modal.loading{display:block; /*z-index:2000;*/}



    .liteBox.popup{opacity:1; background-color: none; /* background: url("../images/trans_block.png") 0 0;*/
    background-image: linear-gradient(rgba(74, 74, 74, 0.31) 400px, rgba(255, 255, 255, 0.5) 100%);}
    .fishBowl{ width: 95%; background-color: #fff;padding:20px; border-radius: 5px; position:relative;
      margin: 0 auto; top:55px;}/*width: 100%;*/
    .fish_head{display:flex;}
    .fish_eye{ top: 0px $mp;  right: 5px $mp; z-index: 100;}
    .fish_content{/*max-height:525px;*/ max-height: 62vh; overflow-y:hidden;border:1px solid #ddd;margin-bottom:10px; min-height: 200px;}
    .fish_content.edit_img{overflow-y: scroll $mp;}
    .fish_eye.fishy{ position:absolute; top: -15px $mp;  right: -7px $mp;}/* top: 0px;right: 10px;*/
    @keyframes lB_fade_in {
      0% {
        opacity: 0;
      }
      95%{
        opacity: .5;
      }
      100% {
        opacity: 1;
        background-color: none;
        background: url("../images/trans_block.png") 0 0;
      }
    }
    @-o-keyframes lB_fade_in {
      0% {
        opacity: 0;
      }
      95%{
        opacity: .5;
      }
      100% {
        opacity: 1;
        background-color: none;
        background-image: url("../images/trans_block.png");
      }
    }
    @-moz-keyframes lB_fade_in {
      0% {
        opacity: 0;
      }
      95%{
        opacity: .5;
      }
      100% {
        opacity: 1;
        background-color: none;
        background-image: url("../images/trans_block.png");
      }
    }
    @-webkit-keyframes lB_fade_in {
      0% {
        opacity: 0;
      }
      95%{
        opacity: .5;
      }
      100% {
        opacity: 1;
        background-color: none;
        background-image: url("../images/trans_block.png");
      }
    }
    .ui-nosvg .ui-icon-google_plus:after {
    /* Make your icon fit */
    background-size: 18px 18px;
    }

    .bright {
      -o-animation: lB_fade_in 2s 500ms 1 linear normal forwards;
      -mos-animation: lB_fade_in 2s 500ms 1 linear normal forwards;
      -webkit-animation: lB_fade_in 2s 500ms 1 linear normal forwards;
      animation: lB_fade_in 2s 500ms infinite 1 normal forwards;
    }

    /* end liteBox animation */
    .arc_stage{
      /*width: 100%;*/
      display: flex;
      flex-flow: column;
      justify-content: center; // pre MainCore flex-start;
      // width: 95%;// pre MainCore
      border-radius: 7px 7px 0 0;
      // height: 87vh;
      /*margin: 2% 0 0 0;*/
      // margin: 2% auto 0;// old margin pre MainCore
      // background-color: #9f9f9f;
      // padding: 5px;
      // padding-bottom: .75rem;

      .arc_intro_btn {
        background-color: #f6f6f6;
        border-color: #ddd;
        color: #333;
        text-shadow: 0 1px 0 #f3f3f3;

        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
      }
    }

    .glass_ctrls{
      height:35px;
    }

    .w3-modal{
      padding-top: 25px $mp;
    }

    .ui-btn{
      font-size: 16px;
      margin: .5em 0;
      padding: .7em 1em;
      display: block;
      position: relative;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      text-decoration: none$mp;

      font-weight: 700;
      border-width: 1px;
      border-style: solid;

      font-size: 1em;
      line-height: 1.3;
      font-family: sans-serif;
    }/*.ui-btn*/

    .arc_ctr_stage{background-color:#fff;border-radius: 7px; padding:10px;text-align:left;
    margin-top:5px;}
    #pUS_closeBtn{z-index:50 $mp; right: -7px $mp; position: absolute; top: -11px;
      margin: 0;}/*right foremerly 1%*/
    .arc_ctr_stage h4{margin:0 0 10px 10px;}
    .arc_cnxs{ }
    .arc_intro_btn{height:190px;width:100%;box-shadow: 5px 5px 5px #5d5a5a;border-radius:0px $mp; margin: .5em 0 0;}
    .arc_popup{width:95%;margin: 0 auto;}/*width:90%;*/
    #arc_popup-popup{width:100% $mp;top:30px $mp;left:0 $mp;max-width: none $mp;}/*top:60p;left:7% ;width:98%*/
    #arc_popup-screen{background-image: linear-gradient(rgb(74, 74, 74) 400px, rgb(255, 255, 255) 100%);}

    //LATER: move arc_panel to Panel.scss component

    // .arc_panel_modal{
    //   z-index: 1002 $mp;
    // }



    .feedback {
      // background-color: #4285f4;
      // -webkit-tap-highlight-color: transparent;/*removes chrome tap hightlight color*/
      background-image: none;/*removes btn gradients*/
      /*touch-action: none;*/// disables double click action which removes click delays
      touch-action:auto $mp;
      // touch-action:manipulation $mp;
    }

    .feedback:hover {
      background-color: #296CDB $mp;
      color:#fff;
      transition-delay: 0s $mp;
      transition-duration: 0s $mp;
    }


    .feedback:focus {
      background-color: #0F52C1 $mp;
      color:#fff;
      /* The outline parameter suppresses the border
      color / outline when focused */
      // outline: 0;
      transition-delay: 0s $mp;
      transition-duration: 0s $mp;
    }

    .feedback:active {
      background-color: #0039A8 $mp;
      color:#fff;
      transition-delay: 0s $mp;
      transition-duration: 0s $mp;
    }

    .feedback::-moz-focus-inner {
      border: 0;/*-moz-focus-inner - firefox tap hightlight*/
    }

    button.console_btns,button.in_ctrls,.in_cont{margin:1px 2px $mp;display:inline-block; flex:1 $mp; padding:0px $mp;}
    button.console_btns{ max-width:22%;}
    button.in_ctrls{border-radius:5px $mp; height:100%;}
    .in_cont{}
    button.view_bookmark.in_ctrls{width:100% $mp; margin:0px $mp; font-size:22px;}

    button.console_btns:after,button.in_ctrls:after{background-size: 70% $mp;height:25px $mp; width:24px $mp; top:45% $mp;left:45% $mp; border-radius:5px $mp;}
    button.console_btns:after{}

    .data_type_select_groupCont{display:flex $mp;}
    .media_btn{flex-grow:1 $mp; flex-basis: auto $mp;
      background-color: #f6f6f6 $mp;
      border-color: #ddd $mp;
      color: #333;
      text-shadow: 0 1px 0 #f3f3f3;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .arc_option_space, .option_space, .travel_space, .sort_space{flex:1; padding: 2px 10px; background: #dedede; margin-bottom:5px;}
    .ctrl_case.c_more,.arc_view_options.c_more,.arc_option_space.c_more,.arc_travel_space.c_more, .arc_sort_space.c_more{display:flex $mp;}
    .ctrl_case.c_none,.arc_view_options.c_none,.arc_option_space.c_none,.arc_travel_space.c_none, .arc_sort_space.c_none{display:none $mp;}

    // .view_options{height: unset $mp;}
    .view_options:after{background-color: unset $mp;}

    .flexbox{display:flex;}

    .arc_title, .chk_title, .glass_title{text-align: left;margin:0px; font-size:1.2rem; padding:3px; max-height: 30px; overflow: hidden;}
    .arc_title,.title, .glass_title{text-shadow: 2px 2px 2px #ccc;}
    .glass_title  h5{margin:0px $mp;}

    div.arc_nav_cont.flexbox, div.chk_nav_cont.flexbox, .navBtn_groupCase{height:auto $mp; padding:0px; justify-content:flex-start;}
  .arc_navBtn_groupCont, .chk_navBtn_groupCont{display:flex $mp;/*display:flex;height:auto $mp; width:100%;*/}
    .arc_nav_cont .TCasing, .chk_nav_cont .TCasing{ padding-left:0px $mp;}
    .arc_navHome{/*width: 1.75em $mp;*/}
    .flexbox.row{}
    .flex1{flex:1;}
    .flex2{flex:2;}
    .flex3{flex:3;}
    .flex4{flex:4;}
    .flex5{flex:5;}
    .flex6{flex:6;}
    .nav_disp{ margin:0 1%;}
    .navBtn{
      height: 25px $mp;
      width: 30px $mp;
      padding: 0px $mp;
      margin: 0 2% $mp;
      flex:1 $mp;
      text-indent: -9999px;
      white-space: nowrap$mp;
    }
    .navBtn:after{
          background-color: #666;
      background-color: rgba(0,0,0,.3);
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-border-radius: 1em;
      border-radius: 1em;
      content: "";
      position: absolute;
      display: block;
      width: 22px;
      height: 22px;
      left: 50%;
      margin-left: -11px;
      top: 50%;
      margin-top: -11px;
    }
    .navBtn.d3-ico:after {
      background: none;
    }
      .navHome{/*padding:0 $mp;*/margin:0 $mp;}
      .movHome{height: 30px; width: 30px;}
      .arc_display_icon_cont,.chk_display_icon_cont{
        display: flex; justify-content: flex-end; gap: .25rem;
        .custom_chk_TCont{width: 22px;}
      }
      .move_chk_TInput{height: 20px; width: 20px; margin: 2px auto; display: block;}

      .tabH2.ui-collapsible-heading{position: -webkit-sticky; position: sticky; top: -5px; background:#fff; z-index: 50;}

    .list_btns{float:left;}
    .arc_add_info{float:right;}
    .clear,.clr{clear:both;}
    .arc_display,.chk_display{
          width: 100%;
          min-height: 200px;
          /*overflow-y: auto;
          max-height: 490px;*/
          // max-height: 70vh;
          padding: 5px;
          overscroll-behavior-y: contain;
      }/*max-height: 450px;*/
    /*the folder and unpublished color classes are attached to the li that gives me room here to add additional colors
    with specificity*/
    .move_data{background-color:#c9d8ff $mp;/*box-shadow: 5px 5px 5px #5d5a5a;*/}


    .ul_display_list{margin:0px $mp;}

    .contact_form_title{margin:0;}
    .contact_form_cont,.contact_form_mid,.contact_form_rear{
      width:100%;min-height:250px;margin-top:15px;border: 1px solid #ccc;
      max-height:500px;overflow-y: auto;padding-bottom:10px; overflow-x:hidden;
    }
    .contact_form_cont{
      .display_area{width: 150px $mp; height: 150px $mp;}
    }//contact_form_cont
    .hr_90{width:90%;margin:8px auto $mp;}
    .info_booth{padding:5px 0;margin-top:10px;}
    .arc_info_type,.arc_info_core,.arc_info_other{margin:10px auto;width:90%;}

    .arc_info_other.info_booth{margin-top:0px;}
    .arc_info_other .TLabel{margin:0px auto $mp; width:90% $mp;}
    .arc_info_other .TCont, .arc_info_type .TCont{margin:0px auto $mp; width:90% $mp;}
    .arc_info_other .TInput, .arc_info_type .TInput{width:100% $mp;}

    .contact_form_select.form_input_cont{margin-bottom:5px;}
    .contact_form_select{ margin: 10px auto 5px; width: 90% $mp;}
    .form_input_cont{width:100%;min-height:30px;padding-bottom:10px;}
    div.data_type_select_cont{width:100%;margin-bottom:5px;padding:0px $mp;}
    div.data_type_select_TCasing{padding:0px;margin:0px auto;width:90%;padding-left: 0px $mp;}
    .data_type_select_cont .groupCase{width:100%;text-align: left; position:relative;  display:block;}
    .data_type_select_cont .groupCont{}
    .data_type_select_labelText{}
    .arc_input{width: 90%; border-radius: 7px;font-size: 1rem;line-height: 20px $mp;
    margin:0px auto$mp;min-height: 20px;display:block;}
    .arc_input.TInput{ padding: 2px 5px $mp;}
    .dataInp_TInput.arc_input{width:100% $mp;}
    .dataInp_groupCont{display:flex $mp;}
    .dataInp_labelText.arc_btn{}
    .act_btn {flex-grow:1 $mp; flex-basis: auto $mp;}
    .act_stop{background-color: rgba(207, 47, 47, 0.11)  $mp;}
    .act_work{background-color: rgba(242, 229, 12, 0.28) $mp;}
    .act_fin{background-color: rgba(76, 200, 40, 0.341)  $mp;}
    .arc_select{ width: 90% ;margin: auto $mp; display: block;  border-radius: 7px;background-color:#fff;}/*padding: 2px 5px $mp;*/
    .catSel_CInput{padding: 2px 5px $mp;}
    .catSel_CInput.invalid,.catSel_ICont.invalid{background: rgba(232, 16, 28, 0.12);}
    .catSel{margin-top: 5px $mp;}
    .nb{ height: 30px;}
    .TInput.arc_select,.TCont.arc_select{ width: 100% ;}
    .web_preview.TLabel,.web_preview.TCont{margin:0 auto;width:90%;}
    .arc_info_preview{ }
    .previewBox{position:relative; background-color: #ddd; width: 90%; margin: 0 auto; /*min-height: 75px;*/ padding:5px; text-align: center;}
    .prev_cont{ display: flex; align-items: center;  margin: 0 auto; width: 100px; height:100px; overflow:hidden; /*position:relative;*/}
    .prev_img{margin:0px auto;
      img{ object-fit: contain;}
      // LATER: move prev_img to a more appropriate scss location - maybe one of the components creating the preview
    }
    .slide_ph {
      /*TODO were going to rotate the sliders 90deg*/
        -ms-transform: rotate(20deg); /* IE 9 */
        -webkit-transform: rotate(20deg); /* Safari */
        transform: rotate(20deg); /* Standard syntax */
    }
    a.prev_edit_btn{ position: absolute $mp; right: .25rem;  top: .25rem; font-size: 1rem; font-size: 1.3rem; padding: .5rem; }
    .TCasing{padding-left:10px  $mp;}
    div.noteInp_TCasing{margin: 0 $mp;padding: 0px $mp;width:100% $mp;}
    div.noteInp_TCont{}

    /*formatting for nicedit display*/
    .nice_size{width: 100% $mp;}
    .nicEdit-main{width: 95% $mp; margin:4px auto $mp; height:300px;}
    .nicEdit-button{height:20px $mp; width: 20px $mp;}

    .note_spacer{margin:15px auto 0px $mp;}
    input.noteInp_TInput{width:100% $mp;}
    textarea.noteInp_TArea{height:100px $mp;margin-top:5px $mp;}
    .arc_select:active{border-color:none;}
    .SInput.arc_select{margin-bottom:5px;}
    /*div.STly{display:none $mp;}*/
    .SLabel{display: block $mp;}
    .SLabel,.STly{width: 90%;margin: 3px auto $mp;}
    .accSlide_SLabel.SLabel{width:auto;float:left;margin:5% 0 $mp;}
    .accSlide_SLabel{width:50% $mp;}
    .accSlide_SCont{float:left;width:40%;margin-left:3%;}
    .accSlide_SCont .ui-slider{width:100%;}
    .contact_form{}
    .contact_form_select_icon_cont,.contact_form_rear_head_cont,.contact_form_mid_top_cont{margin:5px auto;width:90%;}
    .contact_form_select_icon_cont{margin:5px auto;}
    .contact_form_slider_cont,.contact_form_rear_title_cont,.contact_form_mid_title_cont{ float: left; width: 80%; min-height: 40px;}
    .contact_form_slider_cont{display:flex; justify-content: space-between; align-items: center;
      label{margin:unset;}
    }
    .contact_form_rear_title,.contact_form_mid_title{text-shadow: 2px 2px 2px #ccc; padding-top: 12px;}
    .contact_form_rear_top_cont{margin-bottom: 25px;}
    .contact_form_rear_low_cont,.contact_form_mid_low_cont{width: 90%; margin: 0px auto;}
    .contact_form_rear_low_cont div.TCasing,.contact_form_mid_low_cont div.TCasing{margin-top: 15px $mp;}

    .contact_form_rear_low_cont div.TAreaCont,
    .contact_form_rear_low_cont textarea.TArea,
    .contact_form_rear_low_cont label.TAreaLabel{width:100% $mp;}
    .contact_form_mid_low_cont div.TAreaCont,
    .contact_form_mid_low_cont textarea.TArea,
    .contact_form_mid_low_cont label.TAreaLabel{width:100% $mp;}
    .contact_form_rear_low_cont label.TAreaLabel,.contact_form_mid_low_cont label.TAreaLabel{margin-bottom:10px $mp;}

    .SIcon{float: right; margin-right:5px $mp;cursor:pointer $mp;}

    /*.arc_panel_go{right: 2.7em $mp;}*/
    .arc_go_btn,.arc_can_btn,.fish_confirm,.fish_cancel{border-radius:6px;border: 1px solid #ccc;padding: 3px;width:100% $mp;
    height:25px;margin: 3px auto 6px $mp;padding:0 $mp;}

    .arc_go_btn,.fish_confirm,.arc_panel_go{background-image: linear-gradient(to bottom,rgb(88, 197, 71),#0F8A09)$mp;color:#fff $mp;}
    .arc_can_btn, .fish_cancel{
      background-image: linear-gradient(to bottom,rgb(249, 255, 248),#AAADAA)$mp;
      h4{margin:unset;}
    }
    .arc_can_btn h4{margin:0px;font-weight:600;}
    .tNoteBtn_btn_group {font-size: 16px $mp; font-weight: 300 $mp;
    margin: 0px $mp; width: 50% $mp; height: 30px; padding: 3px $mp;
    text-align: right $mp;}
    .tNoteBtn_btn_group0{ padding-right: 10% $mp;}
    .tNoteBtn_btn_group1{ padding-right: 5% $mp;}
    .tNoteBtn_groupCont {width:90%;margin: 0 auto;}

    /************ Panel Stuff ******************/
    .arc_panel.ui-panel { width: 100% $mp;/*z-index:1102;*/}

    .arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-overlay,
    .arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-push
    {
      right: 0;
      -webkit-transform: translate3d(22em,0,0);
      -moz-transform: translate3d(22em,0,0);
      transform: translate3d(22em,0,0);
    }

    li.arc_unpublished,li.arc_unpublished.arc_collection{background-color: #ffe3db $mp;}
    .arc_collection{background-color: rgba(239, 224, 47, 0.42);}
    .arc_collection.arc_folder{background-color: #f6f6f6;}
    .colap_cont_ListArea {margin:0 $mp;border-radius:0px $mp;}
    .arc_mstr_li.hide_mstr, .chk_mstr_li.hide_mstr{display:none $mp;}


    .typeSel_TCasing, .dataInp_TCasing{width:70%;display:inline-block;}
    .groupCase.form_fldr_type{display:flex; justify-content: center;}
    .typeSel_btn_group.form_fldr_type{min-width: 100px;}
    div.dataInp_TCasing{width:70%;display:inline-block $mp;min-height: 65px;}
    .side_car{width:20%;float:right;margin:0 3% 5px;text-align:center;padding:5px;border:1px solid #ddd;}
    .siteIcon_LBTag{margin: 0 auto 5px auto $mp;}
    .siteIcon_TDTag, .assoc_icon_TDTag {width:30px;height:30px; margin:0 auto 5px $mp;}

    .siteIcon_chk_TCont{height:25px $mp;}
    .siteIcon_chk_TInput{left:0 $mp;top:0 $mp;margin:0 auto $mp;position:relative $mp;}
    .siteIcon_chk_TCont .ui-checkbox{margin:0;text-align:center;}
    .chng_img_TDTag{width:90% $mp;margin:0 auto $mp;border-radius:7px;}
    .chng_img{width:50% $mp;display:inline-block $mp;margin-bottom: 0px $mp;}

    .tNoteBtn_TCasing{margin-top:25px;}

    .data_list{cursor: pointer;padding:0px $mp;border:none;}
    .data_list.ghost, .my_a_wrap.ghost{  opacity:.4; width:90%; margin:5px auto $mp;
      background-color:#c9d8ff $mp; color:white $mp;}

    div.li_liteBox_lightbox.arc_list_preview, div.arc_rF_Box_lightbox.arc_bkmk_list
    {
      /*z-index:1200;*/height: 100% $mp; position:absolute;
    }
    .chk_rF_Box_lightbox.chk_bkmk_list{/*z-index:1210;*/height: 100% $mp; position:absolute;}
    div.arc_rF_Box_lightbox.arc_bkmk_list,
    div.chk_rF_Box_lightbox.chk_bkmk_list{position:fixed $mp;}
    div.li_liteBox_glassHouse{margin:30px auto 0; width: 95% $mp;}
    .bkmk_targ, li.arc_unpublished.ListArea.bkmk_targ{border: 2px solid rgba(18, 214, 18, 0.4) $mp; border-radius: 7px;
      background-color:rgba(18, 214, 18, 0.4) $mp;
    }
    .bkmk_list {
      overscroll-behavior-y: contain;
    }

    .arc_rF_Box_glassHouse.glassHouse, .chk_rF_Box_glassHouse.glassHouse{width:100% $mp; height:90vh;}
    .arc_rF_Box_glass_content, .chk_rF_Box_glass_content {height:85%;}
    .arc_rF_Box_glass_page, .chk_rF_Box_glass_page{height:100%;  flex-flow: column;}
    .arc_rF_Box_glass_page.lbx_page_show, .chk_rF_Box_glass_page.lbx_page_show{display: flex $mp;}
    .arc_rF_Box_glass_page.lbx_page_hide, .chk_rF_Box_glass_page.lbx_page_hide{display: none $mp;}


    .my_info_icon{/*float:left;*/}


    .info_dot{
      width: 20px;
      /*height: 20px $mp;*/
      margin:5px $mp;
      border-radius:50% $mp;
      background:#ccc;/*border:1px solid red;*/
      overflow:visible $mp;
    }
    .info_dot:after{width:20px $mp;height:20px $mp; border-radius:50% $mp;
    margin-left: -10px $mp; margin-top: -10px $mp; left:45% $mp;}
    .info_dot.expanded:after{left:55% $mp;}
    .my_info_icon.info_dot{
      /*width: 25px $mp;*/
      /*: 25px $mp;*/
      margin:5px $mp;
      border-radius:50% $mp;
      background:#ccc $mp;/*border:1px solid red;*/
    }
    .my_info_icon.info_dot:after{width:23px $mp;height:23px $mp; border-radius:50% $mp;
      margin-left: -11px $mp;margin-top: -9px $mp;top:40%; overflow:hidden;}


    // .bookmark_ul{margin:0px $mp; padding:unset $mp;}
    // .bookmark_li{display:flex; padding:0px $mp; border-width: 0 0 1px $mp;
    //     border-width: 0 0 1px $mp;
    //     border-color: #ddd;
    //     border-style: solid;
    //     p{margin: .6em;}
    //   }
    // .bm_a_wrap{flex:1; display:flex; justify-content:flex-start; width:100%;
    //   background-color:transparent $mp; border:none $mp; }
    // .bm_info_delete{justify-self:flex-end;margin-left:auto $mp; color:#ddd $mp;}
    // .bm_info_delete.info_dot{background-color:transparent $mp; border-color:transparent $mp;margin:auto 5px $mp;}
    // .bm_info_text{
    //   flex: 1;
    //   margin: auto 5px;
    //   text-align: left;
    //   padding: 0px 5px;
    //   text-overflow:ellipsis;
    //   font-size: 1em $mp;

    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   white-space: nowrap;
    // }

    // .bm_info_icon{margin:auto 5px $mp;}

    button.rich_refresh_btn{
      /*width:27px $mp;
      height:27px $mp;*/
      position:absolute;top:0px $mp;
      right:0px $mp;
    }


    img.fit-cover{object-fit: cover;}
    img.fit-contain{object-fit: contain;}

    // .data_list.expanded{box-shadow: 1px 1px 5px #5f5858; margin:10px 5px $mp;/*#383535*/}

    // div.arc_icon_img_canvas_area,div.chk_icon_img_canvas_area,div.arc_icon_img_image_area,div.chk_icon_img_image_area{display:none;}
    div.arc_icon_img_canvas_area.expanded,div.chk_icon_img_canvas_area.expanded{width:100% $mp; display:inline-flex; margin:5px;height:3.5rem;}
    div.arc_icon_img_image_area.expanded,div.chk_icon_img_image_area.expanded{width:100% $mp; display:inline-flex; margin:5px;height:3.12rem;}
    .arc_icon_img_ImgCanvas,.chk_icon_img_ImgCanvas,.arc_icon_img_ImgImage,.chk_icon_img_ImgImage {width:100% $mp; height:100%; margin:0px auto $mp;}
    .arc_icon_img_ImgImage,.chk_icon_img_ImgImage{object-fit: cover;}


    .arc_list_preview{text-align: left;}

    /* The switch - the box around the slider */
  .arc_switch_label.switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 34px;
    flex: 0 0 auto;

    input{display:none;}

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 7px;
      text-align: center;
      line-height: 32px;
      text-indent: 1.5rem;
    }
    .slider.allow{
      text-indent: -1.5rem;
      color: white;
      text-shadow: 0 1px 0 #059;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 7px;
    }

    input:checked + .slider {
      background-color: #38c;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #059;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(65px);
      -ms-transform: translateX(65px);
      transform: translateX(65px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }//arc_switch_label



    .bookmark_li.search{ border-bottom:1px solid #ccc;}
    .search_cont{display:flex;flex-direction: column;padding:2%; border-bottom:1px solid #ccc;}
    .search_display{ min-height: 200px; border-top:1px solid #ccc;
      border-top: 1px solid #ccc; flex: 1; overflow-y: auto;}
    .search_display_ul{display:flex; flex-direction:column;}
    .search_log{width:95%; margin:5px auto; border:1px solid #ccc; display:none;}
    .search_log p{margin:1% 4%;}
    .ICont.inCombo:after{width:20px $mp; height:20px $mp; margin-top:-10px $mp;}
    .srchInp_ICont{display: inline-block;}
    .srchInp_TTly{font-size:.8rem;}

    .bm_info_text.search{ height:1.2rem; font-size:1rem; text-overflow: ellipsis; overflow: hidden;}
    button.tasks_inBtn,button.tags_inBtn,button.inBtn.inbox{background-color: rgb(135, 207, 150);}


      .ui-icon-activity.stopped, .d3-icon-activity.stopped{background: rgba(162, 11, 20, 0.6) $mp;}
      .ui-icon-activity.working, .d3-icon-activity.working{background: rgba(199, 199, 17, 0.73) $mp;}
      .ui-icon-activity.finished, .d3-icon-activity.finished{background: rgba(15, 162, 11, 0.6) $mp;}

      .d3-ui-icon:after {
      content: "";
      background-size: contain $mp;
      background-position: center center;
      position: absolute;


      background-position: center center;
      background-repeat: no-repeat;
      display:block;
      }

      .d3-med::after{
      margin-top: 0 /*-2vw*/;
      margin-left: 0 /*-2vw*/;
      left: 0 /*2vw*/;
      top: 0 /*2vw*/;
      width: 4rem /*10vw*/;
      height: 4rem /*10vw*/;
      }

      .d3-sm::after{
      left: 50%;
      top: 50%;
      width: 22px;
      height: 22px;
      }


  /*vw experiment
  div.li_liteBox_lightbox {width:100vw;}
  div.li_liteBox_glassHouse{width:90vw;}
  div.li_liteBox_glass_content{width:85vw}
  */


  @media only screen and (min-width:300px) and (max-width:324px)
  {
    #form_ctrls  button.form_btns{/*width:5%;*/}
    button.console_btns{width:25% $mp; border-radius:5px $mp;}
    button.console_btns:after{background-size:16px 16px;width:20px; height:20px; top: 54%; left: 52%;}
    .tabH2.ui-collapsible-heading{top: 0px;}

  }

  @media only screen and (min-width:320px) and (max-width:479px)
  {
    #form_ctrls  button.form_btns{/*width:8%;*/}
    button.console_btns{width:25% $mp; border-radius:7px $mp;}
    button.console_btns:after{background-size:16px 16px;width:20px; height:20px; top: 54%; left: 52%;}
    .tabH2.ui-collapsible-heading{top: 0px;}
  }

  @media only screen and (min-width:480px) and (max-width:767px){

  }

  @media only screen and (min-width:768px) and (max-width:991px)
  {

  }

  /* AT LEAST THIS TALL*/
  @media only screen and (min-width:300px){

  }

  @media only screen and (min-width:480px)
  {
    .arc_popup, .arc_panel, div.glassHouse{max-width: 30rem $mp;}
  }

  @media only screen and (min-width:768px)
  {

    .arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-overlay,
    .arc_panel.ui-panel-animate.ui-panel-position-right.ui-panel-display-push
    {
      /*controls panel slide animation*/
      right: 0;
      -webkit-transform: translate3d(17,0,0);
      -moz-transform: translate3d(17,0,0);
      transform: translate3d(17em,0,0);
    }
  }

  @media only screen and (min-width:992px)
  {

  }


  @media only screen and (min-width:992px) and (max-width:1999px)
  {

  }

  .TInput.borderline, .CInput.borderline, .detail_view.borderline{
    border-top:transparent $mp;border-right:transparent $mp;border-left:transparent $mp;border-radius:0 $mp;
  border-bottom:2px solid #ccc $mp;}
  .borderline input[disabled], .CInput.borderline:disabled{
    background-color:transparent $mp;
    border-bottom:2px solid rgba(178, 13, 13, 0.31) $mp;
    cursor:not-allowed;
  }

  .arc_btn.d3-btn{
    width: 1.75em;
      height: 1.75em;
      font-size: 16px;
      margin: .5em 0;
      padding: .7em 1em;
      display: block;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0;
      text-indent: -9999px;
      white-space: nowrap$mp;
  }

  .grid-item, .grid_item{
    min-height: 0;
    min-width: 0;
  }

  .d3-modal{
      display: none;
      padding-top: 100px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0,0.4);
  }

  /***************** test rules **********************/
  .test_yellow{border:1px solid yellow $mp;}
  .test_red{border:1px solid red $mp;}
  .test_blue{border:1px solid blue $mp;}
  .test_green{border:1px solid green $mp;}
  .test_purple{border:1px solid purple $mp;}
  .test_orange{border:1px solid orange $mp;}
  .test_brown{border:1px solid brown $mp;}
  .test_pink{border:1px solid pink $mp;}
  /***************** test rules **********************/

    %clamp{
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: block $mp;
      display: -webkit-box $mp;
      line-height: 1.5;
      white-space: initial;
      height: -moz-fit-content;
      height: fit-content;
    }
    .clamp-0{
        white-space: nowrap;
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .clamp-1{
      @extend %clamp;
      -webkit-line-clamp: 1;
    }
    .clamp-2{
      @extend %clamp;
      -webkit-line-clamp: 2;
    }
    .clamp-3{
      @extend %clamp;
      -webkit-line-clamp: 3;
    }


  .word_wrap{
  /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    }

    /* styles for '...' */
    // http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
    $bwt:1.2em;
    .block-with-text {
      /* hide text if it more than N lines  */
      overflow: hidden;
      /* for set '...' in absolute position */
      position: relative;
      /* use this value to count block height */
      line-height: $bwt;
      /* max-height = line-height (1.2) * lines max number (3) */
      max-height: $bwt * 3;
      /* fix problem when last visible word doesn't adjoin right side  */
      text-align: justify;
      /* place for '...' */
      margin-right: -1em;
      padding-right: 1.2em;
    }
    /* create the ... */
    .block-with-text:before {
      /* points in the end */
      content: '...';
      /* absolute position */
      position: absolute;
      /* set position to right bottom corner of block */
      right: 0;
      bottom: 0;
    }
    /* hide ... if we have text, which is less than or equal to max lines */
    .block-with-text:after {
      /* points in the end */
      content: '';
      /* absolute position */
      position: absolute;
      /* set position to right bottom corner of text */
      right: 0;
      /* set width and height */
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      /* bg color = bg color under block */
      background: white;
    }
    .bwt1{max-height: $bwt;}
    .bwt2{max-height: $bwt * 2;}
    .bwt3{max-height: $bwt * 3;}

    .chk_hidden_cont{
      .fishBowl{max-width: 30rem $mp;}
    }

    %hide-scroll::-webkit-scrollbar,
    %hide_scroll::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE and Edge */
    %hide-scroll,
    %hide_scroll {
      -ms-overflow-style: none;
      /* usage @extend %hide-scroll;*/
    }


    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scroll::-webkit-scrollbar, .hide_scroll::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE and Edge */
    .hide-scroll, .hide_scroll {
      -ms-overflow-style: none;
    }


  @import "fade";
  @import "flicker";
  @import "colors";
  @import "slide";
  @import "queries";
  @import "skeleton";
}// layer base