$mp: !important;

.progress_cont{
  display: flex;
  justify-content: center;
  width: 100%;
  .btnGrpCont {
    width: 90%;
    display: -ms-inline-flexbox;
    display: inline-flex;
    &.Details{
      display: grid;
      grid-template-areas: "label" "controls";
      gap: 0.5rem 0;
      padding: 1rem;
      .labelText{grid-area: label;}
      .btnGrpInner{
        grid-area: controls;
        display: inline-flex;
        flex-flow: row wrap;
        gap: .25rem;
        .btnGrp{flex: 1 auto; border-radius: 0;}
      }
    }
    .btnGrp{
      font-family: Verdana, sans-serif $mp;
      &.selected {
        &.pending{background: grey;}
        &.active{background: var(--site-main, yellow); color: black;}
        &.stop{background: red;}
        &.done{background: green;}
        background: var(--active-blue);
        color: white;
        // &:focus{box-shadow: unset;}
      }
    }

  }
}