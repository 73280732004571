.segue_view_wrapper .segue_view_header > *, .segue_view_wrapper > * {
  min-width: 0;
  min-height: 0;
}

.segue_view_wrapper .segue_view_cont .segue_view_content .virtuoso-scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.segue_view_wrapper .segue_view_cont .segue_view_content .virtuoso-scroller {
  -ms-overflow-style: none;
}

.segue_view_wrapper {
  display: grid;
  grid-template-areas: "label" "main";
  grid-template-rows: auto 9fr;
  flex-flow: column;
  height: 100%;
  padding: 0 0.15rem;
  gap: 0.5rem;
}
.segue_view_wrapper .segue_view_header {
  grid-area: label;
  display: grid;
  grid-template-areas: "icon title options";
  grid-template-columns: auto 8fr auto;
  width: 100%;
  text-shadow: 2px 2px 2px #ccc;
  font-size: 1.2rem;
  gap: 0.5rem;
}
.segue_view_wrapper .segue_view_header .segue_view_title {
  grid-area: title;
  height: fit-content;
  align-self: center;
}
.segue_view_wrapper .segue_view_header .segue_view_title.golden {
  cursor: pointer;
}
.segue_view_wrapper .segue_view_header .segue_view_icon {
  grid-area: icon;
  justify-self: left;
  text-align: left;
  margin-left: 0.75rem;
  padding: 0.25rem 0 0;
  height: fit-content;
  font-size: 2.2rem;
}
.segue_view_wrapper .segue_view_header .segue_view_icon.golden {
  color: var(--site-main, gold);
  text-shadow: 2px 2px 2px #ccc;
}
.segue_view_wrapper .segue_view_header .segue_view_btn {
  align-self: center;
  text-align: center;
  padding: 0.25rem 0 0;
  width: 2rem !important;
  height: fit-content !important;
}
.segue_view_wrapper .segue_view_header .segue_view_btn.active {
  color: var(--active-blue) !important;
}
.segue_view_wrapper .segue_view_header .segue_view_options, .segue_view_wrapper .segue_view_header .TopOp_wrapper {
  grid-area: options;
}
.segue_view_wrapper .segue_view_header .segue_view_bkmks {
  grid-area: bkmk;
}
.segue_view_wrapper .segue_view_header .segue_view_bkmks.grey {
  color: #ccc;
}
.segue_view_wrapper .segue_view_header .segue_view_feeds {
  grid-area: feed;
}
.segue_view_wrapper .segue_view_header .segue_view_options {
  display: flex;
  justify-content: center;
  align-items: center;
}
.segue_view_wrapper .segue_view_header .segue_view_options.filtered:has(.main_options) .feed_options {
  display: none;
}
.segue_view_wrapper .segue_view_header .segue_view_options.filtered.feed .main_options {
  display: none;
}
.segue_view_wrapper .segue_view_cont {
  grid-area: main;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
  height: 100%;
  flex: 0 1 auto;
  margin-top: unset !important;
}
.segue_view_wrapper .segue_view_cont.feed:has(.segue_view_content):has(.mplx_featured) {
  border: unset !important;
}
.segue_view_wrapper .segue_view_cont .segue_view_content {
  overflow: hidden;
  height: 100%;
  padding-top: 0.5rem;
}
.segue_view_wrapper .segue_view_cont .segue_view_content:has(.mplx_featured) {
  padding-top: unset !important;
}
.segue_view_wrapper .segue_view_cont .segue_view_content .mplx_featured .snap_mplx_snap_ctrls {
  padding-top: unset;
}
.segue_view_wrapper .segue_view_cont .segue_view_content .mplx_featured .snap_mplx_snap_cont {
  border: unset !important;
}
.segue_view_wrapper .segue_view_cont .segue_view_content .segue_item_wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  height: 255px;
  margin: 5px;
}
.segue_view_wrapper .segue_view_cont .segue_view_content .segue_item_wrapper .segue_item {
  width: 100%;
}
.segue_view_wrapper .segue_view_cont .segue_view_content .segue_item_observer {
  width: 100%;
}
.segue_view_wrapper .segue_view_cont .segue_view_content .virtuoso-scroller {
  flex: 1 auto;
}

*[class*=loader_wrapper], .segue_scroll_loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
*[class*=loader_wrapper] .segue_item_loader, .segue_scroll_loader_wrapper .segue_item_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
*[class*=loader_wrapper] .segue_item_loader .loader_refresh, .segue_scroll_loader_wrapper .segue_item_loader .loader_refresh {
  font-size: 1.5rem;
}
*[class*=loader_wrapper] div[class~=item_loader], .segue_scroll_loader_wrapper div[class~=item_loader] {
  border: 1px solid red !important;
}