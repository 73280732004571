$mp: !important;

.regBtn_btnGrpCont{
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 0.5rem 0.5rem;
  .regBtn{
    flex: unset $mp; 
    padding: .25rem 2rem;
    border: 2px solid var(--active-blue) $mp;
  }
}