.send_modal {
  max-height: unset;
  height: 90%;
}
.send_modal .send_content {
  max-height: unset;
  height: 100%;
}

.send_wrap {
  max-width: 90vw;
  display: grid;
  grid-template-areas: "details" "text" "contact";
  grid-template-rows: auto auto 7fr;
  gap: 0.25rem;
  height: 100%;
}
.send_wrap .send_details {
  grid-area: details;
}
.send_wrap .rawDrop_wrapper {
  grid-area: text;
  align-self: center;
}
.send_wrap .rawDrop_wrapper .rawDrop_header {
  padding: 0 0.5rem;
}
.send_wrap .rawDrop_wrapper .rawDrop_dropdown_icon {
  font-size: 1.3rem;
}
.send_wrap .send_text_cont {
  display: grid;
  grid-template-columns: auto 10%;
  grid-template-areas: "comment reset";
  width: 100%;
  position: relative;
}
.send_wrap .send_text_cont .send_text_comment {
  grid-area: comment;
}
.send_wrap .send_text_cont .send_text_reset {
  grid-area: reset;
  justify-self: center;
  font-size: 1.3rem;
  color: var(--bold-grey);
}
.send_wrap .send_contact_cont {
  grid-area: contact;
}
.send_wrap .send_contact_cont .search_display .lookout_wrapper {
  grid-template-columns: auto 4fr auto;
}
.send_wrap .send_contact_cont .search_display .lookout_wrapper .send_btn {
  grid-area: icon;
  background: var(--active-blue);
  max-width: 3.5rem;
  aspect-ratio: 3/1.7;
  color: white;
  padding: 0.25rem 0.5rem;
  justify-self: center;
  align-self: center;
}
.send_wrap .send_contact_cont .search_display .lookout_wrapper .send_btn.undo {
  background: lightgrey;
  color: black;
}
.send_wrap .send_contact_cont .search_display .lookout_wrapper .send_btn:has(.loader_wrapper) {
  background: var(--site-main, wheat);
}
.send_wrap .send_contact_cont .search_display .lookout_wrapper .send_btn .loader_wrapper .loader {
  border-width: 0.25rem;
  width: 1.5rem;
  height: unset;
  aspect-ratio: 1;
  margin: 0.25rem;
}