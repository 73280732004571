.mplx_container {
  height: 100%;
  display: grid;
  grid-template-areas: "label" "main";
  grid-template-rows: auto 9fr;
  height: 100%;
  padding: 0 0.15rem;
}
.mplx_container.hidden {
  display: none !important;
}

.mplx_header {
  display: grid;
  grid-template-areas: "icon title options";
  grid-template-columns: 1fr 8fr 1fr;
  overflow: unset;
  align-items: center;
  width: 100%;
  padding-right: 1rem;
  margin-top: 0.5rem;
  text-shadow: 2px 2px 2px #ccc;
  font-size: 1.2rem;
  gap: 0.5rem;
  grid-area: label;
}
.mplx_header .TopOp_wrapper.hide {
  display: none;
}
.mplx_header.details_mplx {
  height: 2.5rem;
}
.mplx_header .mplx_title {
  grid-area: title;
  padding-top: 0.5rem;
}
.mplx_header .mplx_icon {
  grid-area: icon;
  justify-self: left;
  text-align: left;
  margin-left: 0.75rem;
  padding: 0.25rem 0 0;
  font-size: 2.2rem;
}

.mplx_error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 0.9em;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
  height: 90%;
  flex: 0 1 auto;
}

.mplx_featured {
  width: 100%;
  display: grid;
  grid-template-areas: "nav" "content";
  grid-template-rows: auto 12fr;
  /*.8fr*/
  height: 100%;
  overflow: hidden;
  flex: 1;
  grid-area: main;
}
.mplx_featured.search {
  grid-template-areas: "input" "nav" "content";
  grid-template-rows: auto auto 12fr;
  /*.8fr*/
}
.mplx_featured.details_mplx {
  grid-template-rows: auto 8fr;
}
.mplx_featured.details_mplx.search {
  grid-template-rows: auto 0.8fr 8fr;
}
.mplx_featured .mplx_mock_input {
  background-color: #e9ecef;
  width: 100%;
  margin: 0 auto;
  border-radius: 7px;
  height: 80%;
  align-self: center;
  grid-area: input;
  color: #b4bbc1;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: text;
}
.mplx_featured .mplx_nav_section_wrap {
  display: flex;
  width: 100%;
  overflow: auto;
}
.mplx_featured .mplx_nav_section_wrap .mplx_nav_section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25rem;
  gap: 0.5rem;
  grid-area: nav;
}
.mplx_featured .mplx_nav_section_wrap .mplx_nav_section .mplx_nav_ctrls {
  padding: 0 0.5rem;
}
.mplx_featured .mplx_nav_section_wrap .mplx_nav_section .mplx_nav_ctrls.active {
  background-color: var(--active-blue) !important;
  color: white;
}
.mplx_featured .mplx_nav_section_wrap .mplx_nav_section .mplx_nav_ctrls:focus {
  outline: none;
}
.mplx_featured .mplx_featured_content {
  grid-area: content;
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}
.mplx_featured .mplx_featured_content .snap_mplx_snapBox {
  display: grid;
  /* flex-flow: column nowrap; */
  grid-template-areas: "ctrls" "cont";
  grid-template-rows: auto 13fr;
  height: 100%;
}
.mplx_featured .mplx_featured_content .snap_mplx_snapBox .snap_mplx_snap_ctrls {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
  overflow: auto;
  padding: 0.5rem;
  grid-area: ctrls;
  overscroll-behavior-x: contain;
}
.mplx_featured .mplx_featured_content .snap_mplx_snapBox .snap_mplx_snap_ctrls .snap_mplx_snap_btn {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: unset;
  font-size: 1.2rem;
  height: unset;
}
.mplx_featured .mplx_featured_content .snap_mplx_snapBox .snap_mplx_snap_ctrls .snap_mplx_snap_btn.active {
  background-color: var(--active-blue);
  color: white;
}
.mplx_featured .mplx_featured_content .snap_mplx_snap_cont {
  border: 1px solid #ddd;
  grid-area: cont;
}
.mplx_featured .mplx_featured_content .segue_item_wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  height: 255px;
  margin: 5px;
}
.mplx_featured .mplx_featured_content .segue_view_content {
  height: 100%;
  padding: 0.25rem 0;
  overflow-y: auto;
}
.mplx_featured .mplx_featured_content .arc_view_content.plain {
  padding-top: unset;
}
.mplx_featured .mplx_featured_content .arc_view_content.plain.admin_requests .arc_item_wrapper {
  height: -moz-fit-content;
  height: fit-content;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests {
  height: 70px;
  aspect-ratio: unset;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests .lookout_wrapper {
  display: grid;
  grid-template-areas: "image title icon" "image text icon" "ctrls ctrls ctrls";
  width: 100%;
  padding: 0.5rem 0.25rem 1rem;
  position: relative;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests .lookout_wrapper.open {
  padding: 0.5rem 0.25rem;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests .lookout_wrapper .lookout_title {
  font-size: 1.2rem;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests .lookout_wrapper .lookout_type {
  bottom: -0.5rem;
  left: 28px;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests .lookout_wrapper .lookout_icon_cont {
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests .lookout_wrapper .lookout_icon_cont .acs-tree__option-btn {
  font-size: 1.3rem;
}
.mplx_featured .mplx_featured_content .arc_item_wrapper.arc_mplx.admin_requests .lookout_wrapper .acs-tree__user-input-btns_btnGrpCont {
  grid-area: ctrls;
  padding: 1rem 0.25rem 0;
  gap: 0.5rem;
}

.mplx_search {
  width: 100%;
  display: grid;
  grid-template-areas: "input" "content";
  grid-template-rows: 5.7rem 12fr;
  height: 100%;
  flex: 1;
  grid-area: main;
  gap: 0.25rem;
}
.mplx_search .mplx_search_header {
  display: flex;
  justify-content: space-between;
  grid-area: input;
}
.mplx_search .mplx_search_header .mplx_search_back {
  height: fit-content;
  margin-top: 0.75rem;
}
.mplx_search .mplx_search_header input {
  width: 85%;
}
.mplx_search .mplx_search_header .filter_wrapper {
  width: 100%;
  border: unset;
}
.mplx_search .mplx_search_content {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
  height: 95%;
  /* margin-top: 0.25rem; */
  overflow: hidden;
}
.mplx_search .mplx_search_content .search_display {
  height: 100%;
}
.mplx_search .mplx_search_content .lookout_profile_icon {
  font-size: 2.3rem;
}