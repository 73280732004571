.panel_profile {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}
.panel_profile .image_area {
  height: 100%;
  width: 100%;
}
.panel_profile .panel_profile_icon {
  font-size: 1.4rem !important;
}
.panel_profile .d3-profile-rounded {
  border-width: 2px !important;
}
.panel_profile img, .panel_profile canvas {
  height: 100%;
  width: 100%;
  object-fit: cover;
}