$mp: !important;

.warn_msg.glassHouse.confirm{
  padding-bottom: .75rem;
}

.warn_msg.glass_content{
  min-height: unset $mp;
}

.warn_msg_inner{
  grid-template-areas: "title title" "confirm deny";
}

.warn_msg_inner.inform{
  grid-template-areas: "title title" "confirm confirm";
}

.warn_msg_inner{
  display: grid;
  padding: 1rem;
  grid-template-rows: auto /*3fr*/ 1fr;
  gap: .5rem;
  height: fit-content;
  .warn_msg_text{
    grid-area: title;
    margin: 1rem 2rem;
    height: fit-content;
  }
  .warn_msg_instructions{
    font-size: .9rem;
    color: #757575;
  }
  .warn_msg_btn{
    cursor: pointer;
    &.confirm{grid-area: confirm; background-color: #bdeabd;}
    &.deny{grid-area: deny; background-color: #eabdbd;}
  }
}
