$mp: !important;

// see core/core_height.scss
// .MainCore.popup_modal_cont {
//   height: 100%;
//   .popup_modal.core{
//     height: 100%;
//   }
// }
.arc_footer {
  position: sticky;
  // padding: 10px 5px;
  // div{
  //   margin: 5px $mp;
  // }
  bottom: 0;
  background: white;
  width: 100%;
}
