.search_wrapr .search_cont .search_log p, .search_wrapr .search_cont .search_log, .search_wrapr .search_cont .filter_wrapper .filter_option_wrapr, .search_wrapr .search_cont .filter_wrapper .filter_cont, .search_wrapr .search_cont .search_label {
  margin: unset !important;
}

.search_wrapr .search_cont .filter_wrapper .filter_iWall_content, .search_wrapr .search_cont .filter_wrapper {
  padding: unset !important;
}

.search_wrapr {
  display: grid !important;
  grid-template-areas: "header" "content";
  grid-template-rows: auto 1fr;
  height: 100%;
}
.search_wrapr .search_cont {
  display: grid !important;
  grid-template-areas: "label" "filter" "log";
  grid-template-rows: 0.8fr 2fr auto;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  gap: 0.5rem;
}
.search_wrapr .search_cont * {
  min-height: 0;
  min-width: 0;
}
.search_wrapr .search_cont.overlay {
  padding: unset !important;
}
.search_wrapr .search_cont.overlay .TLabel {
  margin: 2% 2% 0;
}
.search_wrapr .search_cont.overlay .TCont {
  margin: 0 2%;
}
.search_wrapr .search_cont.overlay .searchParty_iconWall_wrapper {
  position: absolute;
  padding: 0.5rem;
  background: #fff;
  margin: unset !important;
}
.search_wrapr .search_cont .filter_wrapper {
  grid-area: filter;
  border-bottom: unset !important;
}
.search_wrapr .search_cont .filter_wrapper .filter_iWall_iconWall_cont {
  padding: 0.25rem 0 !important;
}
.search_wrapr .search_cont .filter_iWall_modal {
  background: unset;
}
.search_wrapr .search_cont .filter_option_wrapr {
  background: white;
}
.search_wrapr .search_cont .search_log {
  grid-area: log;
  width: 100%;
  padding: 0.25rem;
}
.search_wrapr .search_cont .search_log .loader {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 0.25rem;
}
.search_wrapr .search_display {
  height: 100%;
  overflow: hidden;
}

div.srchInp_ICont.ICont {
  display: grid;
  grid-template-areas: "input go options";
  grid-template-columns: 8fr 1fr 1fr;
  padding: unset !important;
  gap: 0.5rem;
  padding: 0.25rem !important;
}
div.srchInp_ICont.ICont .srchInp_TInput {
  grid-area: input;
}
div.srchInp_ICont.ICont .srchInp_TInput:focus {
  outline: white;
}
div.srchInp_ICont.ICont .srchInp_inBtn {
  grid-area: go;
  background-color: #87cf96 !important;
  color: white;
}
div.srchInp_ICont.ICont .srchInp_options {
  grid-area: options;
  border-color: #ddd;
}
div.srchInp_ICont.ICont .srchInp_options.heartbeat {
  color: #d4d414;
}
div.srchInp_ICont.ICont .srchInpBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  height: 26px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  padding: 4px 7px;
  border-radius: 5px;
  overflow: hidden;
  margin: unset;
  width: 100% !important;
  justify-self: center;
  align-self: center;
  text-align: center;
  z-index: 1;
}

.search_options {
  position: relative;
}
.search_options .searchParty_iconWall_wrapper {
  width: 100%;
  margin-top: 0.25rem !important;
}
.search_options .searchParty_iconWall_wrapper .searchParty_iconWall_cont {
  display: flex;
  padding: unset;
}
.search_options .searchParty_iconWall_wrapper .searchParty_iconWall_cont.charcoal {
  background-color: #676363;
  border-radius: 7px 7px 25px 25px;
}
.search_options .searchParty_iconWall_wrapper .searchParty_iconWall_cont.charcoal * {
  color: #fff;
  border: unset;
}
.search_options .searchParty_iconWall_wrapper .searchParty_iconWall_cont div {
  flex: 1 auto;
  min-width: 40px;
}

.sp_modal_cont .iWall_modal {
  margin: 0 auto;
}