/*HTML SECTION*/

/*END HTML SECTION */
*{box-sizing: border-box;}

$rem:1rem;
$huge:26.25rem;
$tall:21.25rem;
$medium:10rem;
$short:2.8rem;
$single:10%;
$full:100%;
$qtr:$rem * .25;//5px
$hlf:$rem * .5;
$fxmp:flex !important;

$mp:!important;

.glassHouse{ max-height: 650px; width: 90%; background-color: #fff;padding:20px; border-radius: 5px;margin:20px auto;
position:relative;}
.glass_header{display:flex; /*justify-content:space-around;*/ padding:0px 5px;}
.glass_header.lbox_row{min-height:40px;}
.glass_content{max-height:550px;overflow-y:auto;border:1px solid #ddd;margin-bottom:10px; min-height:200px;}
.glass_confirm,.glass_cancel{border-radius:6px;border: 1px solid #ccc;padding: 3px;width:95%;
height:25px;margin: 3px auto 6px !important;padding:0 !important;}

.glass_confirm{background-image: linear-gradient(to bottom,rgb(88, 197, 71),#0F8A09)!important;color:#fff !important;}
.glass_cancel{background-image: linear-gradient(to bottom,rgb(249, 255, 248),#AAADAA)!important}


.edit_box{padding:5px;position:relative; display: flex; flex-direction: column;}
button.edit_ctrl_btn,.color_btn{width:15%;height:30px !important;cursor:pointer;display:inline-block;margin-right:3px;}
input.color_btn{ display:none !important; background: linear-gradient(rgba(235, 228, 228, 0.03), #e0e0e0) !important; border-color:#adadad !important;}
.edit_sectionB{width:85%;float:right;position:relative;margin:5px 5% 0 0;}
.edit_resize_box{height:30px;margin:5px 0 10px; text-align: center;}
.edit_resize_btn{height:20px; width:18%;margin-right:3px; display:inline-block;border:1px solid #ccc;text-align:center;padding:0px;cursor:pointer;}
.edit_resize_btn h6{margin:0px;text-align:center;font-size:14px;}
.edit_preview{display: inline-block; width: 100%;  min-height: 100px; background-color:#ddd; border:1px solid #008000;}
.edit_preview_box{position:relative; overflow:hidden;margin:5px auto;}

.zoom_ctrl_cont{margin:5px 5% 15px 0;width:60%; text-align: right;}
.zoom_ctrls{width:10%;height:30px;display:inline-block !important;margin-left:2px;border-radius:3px;
text-align:center; padding:5px; cursor:pointer;min-width:30px; border:1px solid #bbb;
background: linear-gradient(rgb(255, 255, 255) 0px, rgb(229, 233, 234) 100%)}/*#e9e9e9*/

.zoom_ctrls:first-child{border-radius:7px 0 0 7px;width:30%;}
.zoom_ctrls:last-child{border-radius:0 7px 7px 0;width:30%;}
.zoom_ctrls:after{background-color: rgba(146, 136, 136, 0.25);}
.zoom_ctrls:first-child:after{border-radius:7px 0 0 7px;}
.zoom_ctrls:last-child:after{border-radius:0 7px 7px 0;}
.zoom_ctrls:focus{
outline:none;
box-shadow: 0 0 12px #38c;
}

.edit_img_ImgCanvas,.edit_img_mini_canvas{/*padding:20px 0;*/ background-color:#fff;}
div.canvas_wrapper{
  display:flex;
  width:100% $mp;
  flex: 0 0 auto;
  /*height:100% $mp;*/
  /*margin:.5rem auto;*/
  .canvas_cont{
    display:table-cell $mp;
    /*width:unset $mp;*/
    width:100%;
    .canvas_area{
      margin:0 auto;
      width:unset $mp;
      height:unset $mp;
      display:unset $mp;
      .ImgCanvas{width:100% $mp; height:auto $mp;}
    }
  }
}
/*
.zoom_in{
background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Plus_font_awesome.svg/2000px-Plus_font_awesome.svg.png");
}

.reset_image{
background-image: url("https://image.freepik.com/freie-ikonen/pfeil-zeigt-die-mitte-eines-kreisformigen-knopf-aus-zwei-konzentrischen-kreisen_318-44897.jpg");
}

.zoom_out{
background-image: url("https://image.freepik.com/free-icon/black-minus_318-8480.jpg");
}
*/

.edit_xtra_ctrls{padding-top:5px;display:none;}
.edit_xtra_ctrls.show_adv{display:block;}
.ctrl_box{height:30px;width:100%;margin:0 auto; display: flex; justify-content: space-around;}
.image_display{width:90%;margin:0 auto 5px; border:1px solid #ddd;cursor:move; overflow:hidden;
  position:relative;border-radiu:25px;height:250px; display: flex; background-color:#eee;}
.canvas_cont, .canvas_area{width:90%; margin:0 auto;}
.canvas_cont { display: flex !important; justify-content: center; align-items: center;
  height:100%;}
.responsive_canvas{width:100%;}
.ImgCanvas.portrait,.mini_canvas.portrait,.edit_img.portrait{width:unset !important; height:100%;}
.canvas_area.portrait{width:unset !important; height:100%;}
.d3-profile-rounded{border-radius: 50%;  border:4px solid #e9e9e9;overflow:hidden; position:relative;}
.d3-profile-rounded.ready{box-shadow: 0px 0px 7px 2px #cccc12;}
.edit_cmd_label{height:30px;margin:5px; border:1px solid #e9e9e9; text-align:center;}
.edit_cmd_label h5{margin: 6px auto !important;}
.edit_slider_box{height:50px;margin-bottom:5px;position:relative;clear:both;}
.edit_slider_cont{margin-top: 10px;}
.edit_lock_box{   height: 30px; width: 15%;  margin: -10px 0 0 -30px; display:none;}

.canvas_wrapper{ display:flex; width:100% !important; flex: 0 0 auto;}
.canvas_wrapper .canvas_cont{ display:table-cell !important; /*width:unset $mp;*/ width:100%;}
.canvas_wrapper .canvas_cont .canvas_area{ margin:0 auto; width:unset !important; height:unset  !important;
  display:unset  !important;}
.canvas_wrapper .canvas_cont .canvas_area .ImgCanvas{width:100%  !important; height:auto  !important;}



/*JQM slide styles*/
.sli_ctrl_contA{ position:relative; width:83%; right:0px; margin:5px auto; padding:2px 5px; text-align: center;}
.sli_ctrl_contA input.ui-slider-input{display:none;}
.sli_ctrl_contA .ui-slider{margin:0px;}
.sli_ctrl_contA .ui-slider-track{
  margin-left:15px;
}



.sli_ctrl_boxA{width:30%;}

.sli_ctrl_contB{margin: -160px 0 0 -110px; width: 200px;  text-align: center;  top: -125px;  height: 35px;

  padding: 2px 5px;}
/*.sli_ctrl_contB input.ui-slider-input{display:none;}
.sli_ctrl_contB .ui-slider-track{
  margin-left:15px;
}*/
.sli_ctrl_contB{
  /*DONE:40 were going to rotate the sliders 90deg*/
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg); /* IE 9 */
    -webkit-transform: rotate(-90deg); /* Safari */
    transform: rotate(-90deg); /* Standard syntax */
}
.sli_ctrl_inputB{
  -webkit-appearance: slider-vertical;
  writing-mode: bt-lr;
}
.sli_ctrl_inputB{ width: 15%; height: 30px; background:none;}

.sli_ctrl_boxB{width:50px;margin:5px 0 0 -8px;}


/**********************   Styling Help   *****************************/
/*
//a good tutorial
//https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

//a decent css generator
//http://danielstern.ca/range.css/#/

//the best generator i found - i used this one
//http://www.cssportal.com/style-input-range/


*/


/**************   RANGE SLIDER SECTION   *****************/
/*here is the width*/
input[type=range] {
  -webkit-appearance: none;
  margin:0;
  width: 90%;
}
input[type=range]:focus {
  outline: none;
}

/*this section styles the track*/
/*this width doesn't effect the track*/
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 2px 2px #ccc;
  background: #e9e9e9;
  border-radius: 5px;

}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #828282;
  border: 1px solid #ddd;
  height: 28px;
  width: 28px;
  border-radius: 5px;
  background: #f6f6f6;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;

-webkit-box-shadow: 0 1px 3px rgba(0,0,0,.15);
-moz-box-shadow: 0 1px 3px rgba(0,0,0,.15);
box-shadow: 0 1px 3px rgba(0,0,0,.15);
}

/*on click bg color*/
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #e9e9e9;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 2px 2px #ccc;
  background: #e9e9e9;
  border-radius: 25px;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #828282;
  border: 1px solid #ddd;
  height: 24px;
  width: 35px;
  border-radius: 6px;
  background: #DADADA;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #e9e9e9;
  border: 1px solid #8A8A8A;
  border-radius: 50px;
  box-shadow: 1px 2px 2px #ccc;
}
input[type=range]::-ms-fill-upper {
  background: #e9e9e9;
  border: 1px solid #8A8A8A;
  border-radius: 50px;
  box-shadow: 1px 2px 2px #ccc;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #828282;
  border: 1px solid #ddd;
  height: 24px;
  width: 35px;
  border-radius: 6px;
  background: #DADADA;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #e9e9e9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #e9e9e9;
}

/********************************************************************/





/***************** test rules **********************/
.test_yellow{border:1px solid yellow !important;}
.test_red{border:1px solid red !important;}
.test_blue{border:1px solid blue !important;}
.test_green{border:1px solid green !important;}
.test_purple{border:1px solid purple !important;}
.test_orange{border:1px solid orange !important;}
.test_brown{border:1px solid brown !important;}
.test_pink{border:1px solid pink !important;}
/***************** test rules **********************/

.clear,.clr{clear:both;}

.word_wrap{
/* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  }

  .d3-ui:after{
  content: "";
  height: 20px;
  width: 100%;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  }


  .d3-btn:focus{
    outline:none;
    box-shadow: 0 0 12px #38c;
  }

  .ui-icon-twitter::after {
  background-image: url("../images/twitter.svg");/* Make your icon fit */
  background-size: 18px 18px;
  }
