.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section > *, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section > *, .narr_hidden_area .narr_hidden_message_cont .message .narr_message_content > *, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content > *, .narr_hidden_area .narr_hidden_message_cont .contact.message > *, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .contact.message > *, .narr_hidden_area .narr_hidden_message_cont .viewer.message > *, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .viewer.message > *, .narr_wrapper .narr_cont .narr_footer > *, .narr_wrapper .narr_cont .narr_header > * {
  min-height: 0;
  min-width: 0;
}

.narr_wrapper {
  height: 100%;
  padding-bottom: 0.5rem;
}
.narr_wrapper .narr_cont {
  display: flex;
  flex-flow: column;
  height: 100%;
  --surround: #ddd;
  border: thin solid var(--surround);
}
.narr_wrapper .narr_cont .narr_btn {
  justify-self: center;
  height: fit-content;
  font-size: 1.3rem;
}
.narr_wrapper .narr_cont .narr_btn.footer {
  align-self: flex-end;
  margin-bottom: 0.25rem;
}
.narr_wrapper .narr_cont .narr_header {
  display: grid;
  grid-template-areas: "users invite . video options";
  grid-template-columns: 6fr 1fr 3fr 1fr 1fr;
  align-items: center;
  width: 100%;
  background: rgba(239, 224, 47, 0.53);
}
.narr_wrapper .narr_cont .narr_header .narr_users {
  display: flex;
  width: 100%;
  grid-area: users;
}
.narr_wrapper .narr_cont .narr_header .narr_users .narr_profile {
  height: 3rem;
  width: 3rem;
  padding: 0.25rem;
}
.narr_wrapper .narr_cont .narr_header .narr_users .narr_profile:nth-child(1n+2) {
  margin-left: -1.5rem;
}
.narr_wrapper .narr_cont .narr_header .narr_users .narr_profile .image_area {
  width: 100%;
  height: 100%;
  border: unset;
}
.narr_wrapper .narr_cont .narr_header .narr_users .narr_profile img, .narr_wrapper .narr_cont .narr_header .narr_users .narr_profile canvas {
  height: 100%;
  width: 100%;
  min-height: unset;
  border-width: 0.15rem;
  object-fit: cover;
  background: #fff;
}
.narr_wrapper .narr_cont .narr_header .narr_invite {
  grid-area: invite;
  /*@extend %grid_item;*/
}
.narr_wrapper .narr_cont .narr_header .narr_video {
  grid-area: video;
  font-size: 1.6rem !important;
  /*@extend %grid_item;*/
}
.narr_wrapper .narr_cont .narr_header .narr_options {
  grid-area: options;
  font-size: 1.6rem !important;
  /*@extend %grid_item;*/
}
.narr_wrapper .narr_cont .narr_content_cont {
  flex: 1 auto;
  height: 22rem;
  overflow-y: auto;
  height: 22rem;
  /* from flex - height this section fixes the Entry editor overflowing improperly*/
}
.narr_wrapper .narr_cont .narr_content_cont .narr_messages {
  height: 100%;
}
.narr_wrapper .narr_cont .narr_footer {
  display: grid;
  position: relative;
  height: fit-content;
  grid-template-areas: "ctrls ctrls ctrls ctrls" "input . send options";
  grid-template-columns: 8fr 0.5fr 1fr 1fr;
  grid-template-rows: auto 1fr;
  border-top: thin solid var(--surround);
  padding: 0.5rem;
}
.narr_wrapper .narr_cont .narr_footer .narr_comment_ctrls {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0 0.5rem;
  grid-area: ctrls;
}
.narr_wrapper .narr_cont .narr_footer .narr_comment_ctrls.visible {
  margin-bottom: 0.5rem;
}
.narr_wrapper .narr_cont .narr_footer .narr_comment_ctrls .comment_btn {
  height: 1.7rem;
  font-size: 1.3rem;
  color: #6c757d;
}
.narr_wrapper .narr_cont .narr_footer .narr_text {
  grid-area: input;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  padding: 0.25rem 0.5rem;
}
.narr_wrapper .narr_cont .narr_footer .narr_text:focus {
  outline-color: aquamarine;
}
.narr_wrapper .narr_cont .narr_footer .narr_send {
  grid-area: send;
  font-size: 1.6rem;
}
.narr_wrapper .narr_cont .narr_footer .narr_options {
  grid-area: options;
}
.narr_wrapper .narr_cont .narr_footer .narr_options.icon-cross {
  color: #ccc;
}
.narr_wrapper .narr_cont .narr_footer .entry_editor {
  grid-area: input;
}
.narr_wrapper .narr_cont .narr_footer .lexical_wrapper {
  grid-area: input;
}

.narr_wrapper .narr_cont .narr_footer .narr_text {
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  border-color: #ddd;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.3125em;
}

.narr_hidden_area .narr_hidden_message_cont .message, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message {
  padding: 0.75rem 0;
}
.narr_hidden_area .narr_hidden_message_cont .viewer.message, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .viewer.message {
  display: grid;
  grid-template-areas: "message profile";
  grid-template-columns: 8fr 1fr;
  margin-left: 2rem;
}
.narr_hidden_area .narr_hidden_message_cont .viewer.message .narr_message_profile, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .viewer.message .narr_message_profile, .narr_hidden_area .narr_hidden_message_cont .viewer.message .narr_reply_profile, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .viewer.message .narr_reply_profile {
  align-self: flex-end;
}
.narr_hidden_area .narr_hidden_message_cont .viewer.message .narr_message_content, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .viewer.message .narr_message_content {
  border-bottom-right-radius: unset !important;
}
.narr_hidden_area .narr_hidden_message_cont .contact.message, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .contact.message {
  display: grid;
  grid-template-areas: "profile message";
  grid-template-columns: 1fr 8fr;
  margin-right: 2rem;
}
.narr_hidden_area .narr_hidden_message_cont .contact.message .narr_message_profile, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .contact.message .narr_message_profile, .narr_hidden_area .narr_hidden_message_cont .contact.message .narr_reply_profile, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .contact.message .narr_reply_profile {
  align-self: flex-start;
}
.narr_hidden_area .narr_hidden_message_cont .contact.message .narr_message_content, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .contact.message .narr_message_content {
  border-top-left-radius: unset !important;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_profile, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_profile, .narr_hidden_area .narr_hidden_message_cont .message .narr_reply_profile, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_reply_profile {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.25rem;
  grid-area: profile;
  justify-self: center;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_profile .image_area, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_profile .image_area, .narr_hidden_area .narr_hidden_message_cont .message .narr_reply_profile .image_area, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_reply_profile .image_area {
  width: 100%;
  height: 100%;
  border: unset;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_profile img, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_profile img, .narr_hidden_area .narr_hidden_message_cont .message .narr_message_profile canvas, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_profile canvas, .narr_hidden_area .narr_hidden_message_cont .message .narr_reply_profile img, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_reply_profile img, .narr_hidden_area .narr_hidden_message_cont .message .narr_reply_profile canvas, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_reply_profile canvas {
  height: 100%;
  width: 100%;
  min-height: unset;
  border-width: 0.15rem;
  object-fit: cover;
  background: #fff;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content {
  display: grid;
  grid-template-areas: ". topic reply" ". upper reply" ". inner reply" ". lower reply";
  grid-template-columns: 1fr 9fr 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-area: message;
  border-radius: 1rem;
  position: relative;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .inner, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .inner {
  grid-area: inner;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .inner .draft_editor, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .inner .draft_editor {
  padding: 0.5rem;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .inner .lexical_wrapper, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .inner .lexical_wrapper {
  padding: unset;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .inner .LexicalDisplay, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .inner .LexicalDisplay {
  border: unset;
  padding: 0.5rem;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_topic_section, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_topic_section {
  grid-area: topic;
  font-size: 0.7em;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_topic_section .lookout_wrapper, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_topic_section .lookout_wrapper {
  cursor: pointer;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section {
  display: grid;
  grid-template-areas: "up_text up_btn";
  grid-template-columns: 9fr auto;
  grid-area: upper;
  padding: 0.5em 0;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section:empty, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section:empty {
  padding: unset !important;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section .narr_reply, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section .narr_reply {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-flow: row nowarp;
  gap: 0.5rem;
  grid-area: up_text;
  margin-bottom: -2%;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section .narr_reply .reply_ref, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section .narr_reply .reply_ref {
  margin: 0.5rem auto 0;
  padding: 0;
  font-size: 0.8rem;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section .narr_reply .reply_ref .narr_reply_profile_icon_img img, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section .narr_reply .reply_ref .narr_reply_profile_icon_img img {
  width: 80%;
  height: 80%;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section .narr_reply .reply_ref .draft_editor.reply, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section .narr_reply .reply_ref .draft_editor.reply {
  padding: 0.5rem 0.8rem;
  color: #000;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_section .narr_un_reply, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_section .narr_un_reply {
  width: 2rem !important;
  grid-area: up_btn;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 0;
  top: 1em;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_reply_icon, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_reply_icon {
  grid-area: reply;
  justify-self: center;
  align-self: flex-end;
  margin-bottom: 0.5rem;
  color: #fff;
  font-size: 1.2rem;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section {
  display: flex;
  justify-content: center;
  grid-area: lower;
  font-size: 20px;
  padding: 0.5em 0;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section:empty, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section:empty {
  padding: unset !important;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section .clip_wrapper, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section .clip_wrapper, .narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section .clip_item, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section .clip_item {
  font-size: 0.6em;
}
.narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section .clip_wrapper .clip_item img, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section .clip_wrapper .clip_item img, .narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section .clip_wrapper .clip_item canvas, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section .clip_wrapper .clip_item canvas, .narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section .clip_item .clip_item img, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section .clip_item .clip_item img, .narr_hidden_area .narr_hidden_message_cont .message .narr_message_content .narr_ref_section .clip_item .clip_item canvas, .narr_wrapper .narr_cont .narr_content_cont .narr_messages .message .narr_message_content .narr_ref_section .clip_item .clip_item canvas {
  min-height: unset !important;
}

.narr_hidden_area {
  height: 0px;
  overflow: hidden;
}
.narr_hidden_area .preview_label {
  font-size: 0.9rem;
  display: inline-block;
  /* margin-bottom: .5rem; */
  margin: 0 1.5rem 0;
  color: #ccc;
}
.narr_hidden_area.preview {
  height: unset;
  overflow: unset;
}