body {
  touch-action: unset !important;
}

#content {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.root {
  display: flex;
  flex-flow: column;
  height: 100vh !important;
  overflow-y: auto;
  scroll-snap-align: end;
  scroll-snap-stop: always;
}
.root.details {
  height: unset !important;
}
.root .arc_intro_btn {
  flex: 1;
}

.modal_dialog.core {
  margin: 0 auto !important;
}

.arc_popup_content {
  /*snap_ctrls*/
}
.arc_popup_content .snap_section.snap_core {
  padding: 0 0.5rem;
}
.arc_popup_content .snap_ctrls.snap_core {
  /*snap_ctrls_right*/
}
.arc_popup_content .snap_ctrls.snap_core .snap_ctrls_right {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*arc_popup_content*/