.loader_cont {
  width: 100%;
}
.loader_cont .loader_modal {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 8000;
}
.loader_cont .loader_modal.active {
  display: flex;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #1717a0;
  border-right: 16px solid #9090f7fa;
  border-bottom: 16px solid #d6d6fbfa;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.item.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #1717a0;
  border-right: 10px solid #9090f7fa;
  border-bottom: 10px solid #d6d6fbfa;
  width: 80px;
  height: 80px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bounce-loader {
  margin: unset;
  width: 70px;
  text-align: center;
}

.bounce-loader > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.bounce-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.bounce-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}