/*
// IMPORTANT: the cascade for tiny and mobile work in the opposite the smaller must be last
// to override the larger

  @include mobile{ gap: 6%; }
  @include tiny{ gap: 4%;}
*/
/*DOCS: mobile first setup "min" 
* IMPORTANT - mobile first items have to cascade in a specific order from small to large otherwise
* ISSUE the smaller queries cancel the larger ones out
* keep the same order as below
* GOTCHA: NOTE MOBILE && TINY ARE NOT IN THE SAME FLOW AS EVERYTHING ELSE - WEARABLE IS THE BOTTOM OF THE MULTI DEVICE FLOW
*/
/**
  * NOTE: this is a mobile first setup.
  */
/**
    usage:

    @use "../../scss/media";
    @include media.mobile{
      padding: 0 .25rem;
      font-size: 1.5rem;
    }

  */
/* mixin for multiline */
.stack_wrapper .priority_cont .priority_title, .stack_wrapper .review_cont label {
  text-shadow: 2px 2px 2px #ccc;
}

.stack_modal_cont {
  z-index: 101 !important;
  height: 100vh;
  padding: 1rem 0.5rem 0.5rem;
  display: flex !important;
}
.stack_modal_cont .stack_modal {
  margin: 0 auto;
  display: grid;
  grid-template-areas: "content" "ctrls";
  grid-template-rows: 1fr auto;
}
.stack_modal_cont .stack_modal .stack_content {
  grid-area: content;
}
.stack_modal_cont .stack_modal .stack_ctrls_wrapper {
  grid-area: ctrls;
}

.stack_wrapper {
  --min5: #ff9980;
  --min4: #ffad99;
  --min3: #ffc2b3;
  --min2: #ffd6cc;
  --min1: #ffebe6;
  --min0: #fff0ff;
  --plus0: #fff0ff;
  --plus1: #eaeafb;
  --plus2: #e6e6fa;
  --plus3: #d4d4f7;
  --plus4: #bfbff2;
  --plus5: #aaaaee;
  padding: 1rem;
  /*stack_head*/
  /*review_cont*/
}
.stack_wrapper hr {
  margin: 0.75rem 0;
}
.stack_wrapper .stack_head {
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
}
.stack_wrapper .stack_head .stack_upper {
  display: grid;
  grid-template-areas: "title icon" "cat icon";
  grid-template-columns: 6fr 1fr;
  gap: 0 0.5rem;
}
.stack_wrapper .stack_head .stack_upper .news_btn {
  justify-self: center;
  align-self: center;
  font-size: 1.5rem;
  grid-area: icon;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_header {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  font-size: 1.5rem;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_header:has(.stack_feature_label) {
  width: 100%;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_header:has(.stack_feature_label) .stack_feature_label {
  width: 100%;
  font-size: 1rem;
}
@media only screen and (max-width: 330px) {
  .stack_wrapper .stack_head .stack_ctrls .stack_ctrls_header:has(.stack_feature_label) .stack_feature_label {
    display: none;
  }
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_header .stack_feature:before {
  color: #c7c74b;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_header .stack_solution.on {
  color: green;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_menu .rawDrop_header {
  justify-content: flex-end;
  gap: 1rem;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_menu .access_select_icon_cont {
  padding: 1rem 0.5rem 0;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_menu .stack_date_picker {
  display: grid;
  grid-template-areas: "label ." "date clr";
  grid-template-columns: 5fr auto;
  padding: 0 0.5rem;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_menu .stack_date_picker:has(button) {
  gap: 0 1rem;
  grid-template-columns: 5fr 1fr;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_menu .stack_date_picker input {
  grid-area: date;
  padding: 0.5rem;
}
.stack_wrapper .stack_head .stack_ctrls .stack_ctrls_menu .stack_date_picker button {
  grid-area: clr;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.stack_wrapper .stack_head label {
  padding-right: 0.5rem;
}
.stack_wrapper .stack_head .title_cont, .stack_wrapper .stack_head .category_cont {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 1.2em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  width: 97%;
}
.stack_wrapper .stack_head .title_cont:before, .stack_wrapper .stack_head .category_cont:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}
.stack_wrapper .stack_head .title_cont:after, .stack_wrapper .stack_head .category_cont:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}
.stack_wrapper .stack_head .title_cont {
  grid-area: title;
}
.stack_wrapper .stack_head .category_cont {
  grid-area: cat;
  color: #7a7a7a;
  margin-top: 0.25rem;
}
.stack_wrapper .stack_head .caption_cont {
  display: grid;
  margin: 10px 0 5px;
  grid-template-areas: "ctrls text" ". counter";
  grid-template-columns: auto 5fr;
  gap: 0.25rem;
}
.stack_wrapper .stack_head .caption_cont .caption_ctrls {
  display: flex;
  grid-area: ctrls;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}
.stack_wrapper .stack_head .caption_cont .caption_ctrls .cap_ctrl_btn {
  cursor: pointer;
}
.stack_wrapper .stack_head .caption_cont .caption_ctrls .cap_ctrl_btn.active {
  color: var(--active-blue);
}
.stack_wrapper .stack_head .caption_cont .caption_ctrls .caption_profile {
  cursor: pointer;
}
.stack_wrapper .stack_head .caption_cont .caption_ctrls .caption_profile .canvas_area {
  width: 2rem;
  height: 2rem;
}
.stack_wrapper .stack_head .caption_cont .caption_ctrls .caption_profile.active canvas {
  border: 2px solid var(--active-blue);
}
.stack_wrapper .stack_head .caption_cont .caption_text {
  grid-area: text;
}
.stack_wrapper .stack_head .caption_cont textarea {
  padding: 0.25rem 0.5rem;
}
.stack_wrapper .stack_head .caption_cont .caption_count {
  display: flex;
  justify-content: flex-end;
  grid-area: counter;
  font-size: 15px;
}
.stack_wrapper .stack_head .caption_cont .caption_count .caption_limit {
  color: #ccc;
}
.stack_wrapper .stack_head .caption_cont .caption_count .caption_counter {
  color: #0F8A09;
}
.stack_wrapper .stack_head .caption_cont .caption_count .caption_counter.warn {
  color: #d8d881;
}
.stack_wrapper .stack_head .caption_cont .caption_count .caption_counter.full {
  color: red;
}
.stack_wrapper .review_cont {
  display: flex;
  justify-content: space-between;
}
.stack_wrapper .review_cont label {
  display: flex;
  margin: unset;
  align-items: center;
}
.stack_wrapper .review_cont .rate_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: unset;
  font-size: 1.6rem;
  cursor: pointer;
}
.stack_wrapper .review_cont div.rate_btn::before, .stack_wrapper .review_cont div.no_rate_btn::before {
  color: #c7c74b;
}
.stack_wrapper .priority_cont {
  display: grid;
  grid-template-areas: "title badge" "label label" "main main" "base base";
  /*priority_badge*/
  /*priority_labels*/
}
.stack_wrapper .priority_cont .priority_title {
  grid-area: title;
}
.stack_wrapper .priority_cont .priority_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: badge;
  width: 2rem;
  height: 2rem;
  border-radius: 7px;
  justify-self: flex-end;
}
.stack_wrapper .priority_cont .priority_labels {
  display: flex;
  justify-content: space-between;
  grid-area: label;
}
.stack_wrapper .priority_box {
  display: flex;
}
.stack_wrapper .priority_box.main {
  grid-area: main;
}
.stack_wrapper .priority_box.base {
  grid-area: base;
  padding: 0.5rem;
}
.stack_wrapper .priority_box .priority_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  flex: 1;
  text-align: center;
  cursor: pointer;
}
.stack_wrapper .priority_box .priority_btn:hover {
  background-color: blue;
  color: #fff;
}

/*stack_wrapper*/
.StackReviewIWL_modal {
  max-height: 550px !important;
  margin-top: 0.5rem !important;
  height: 100%;
}
.StackReviewIWL_modal .StackReviewIWL_content {
  height: 94%;
}

.min5 {
  background-color: var(--min5);
}

.min4 {
  background-color: var(--min4);
}

.min3 {
  background-color: var(--min3);
}

.min2 {
  background-color: var(--min2);
}

.min1 {
  background-color: var(--min1);
}

.min0, .plus0 {
  background-color: var(--plus0);
}

.plus1 {
  background-color: var(--plus1);
}

.plus2 {
  background-color: var(--plus2);
}

.plus3 {
  background-color: var(--plus3);
}

.plus4 {
  background-color: var(--plus4);
}

.plus5 {
  background-color: var(--plus5);
}

.priority_btn.active {
  color: #fff;
  background-color: var(--active-blue) !important;
}
.priority_btn.main:nth-child(1) {
  background-color: var(--min5);
}
.priority_btn.main:nth-child(2) {
  background-color: var(--min4);
}
.priority_btn.main:nth-child(3) {
  background-color: var(--min3);
}
.priority_btn.main:nth-child(4) {
  background-color: var(--min2);
}
.priority_btn.main:nth-child(5) {
  background-color: var(--min1);
}
.priority_btn.main:nth-child(6) {
  background-color: var(--plus0);
}
.priority_btn.main:nth-child(7) {
  background-color: var(--plus1);
}
.priority_btn.main:nth-child(8) {
  background-color: var(--plus2);
}
.priority_btn.main:nth-child(9) {
  background-color: var(--plus3);
}
.priority_btn.main:nth-child(10) {
  background-color: var(--plus4);
}
.priority_btn.main:nth-child(11) {
  background-color: var(--plus5);
}