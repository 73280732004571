$mp: !important;

.custom_input_wrapper {
  display: grid;
  grid-template-areas: "label case""input input" "other other";
  grid-template-columns: 9fr 1fr;
  gap: .25rem;
  margin-bottom: unset $mp;
  &.inline{
    grid-template-areas: "label case""input case""other case";
    padding: 0 .5rem .5rem;
    .custom_input_cont{
      padding: unset $mp;
    }
    .custom_input_ctrls{
      flex-flow: column nowrap; 
      justify-content: center;
      gap: .25rem;
    }
  }

  label {
    grid-area: label;
  }

  .MuiTextField-root{
    .MuiInputBase-root{
      padding: .5rem 1rem;
    }
  }

  .custom_input_ctrls {
    display: flex;
    flex-flow: row nowrap;
    grid-area: case;
  }
  .custom_input_other{grid-area: other;}

  // custom_input_ctrls
  .change_case_icon,
  .copy_me_icon,
  .show_hide_icon {
    font-size: 1.2rem;
    padding: 0.5rem;
    justify-self: center;
    color: #bbb;
    align-self: center;
    margin-left: 0.25em;

    &:only-child {
      margin: unset;
    }
  }

  // change_case_icon
  input, textarea, .MuiTextField-root {
    grid-area: input;
    // resize: none;
  }

  .no-resize, .no_resize{
    resize: none;
  }

  .d3-textarea{
    // width: 90%;
    margin: 0.25rem auto 0.5rem;
    // height: 7rem;
    // min-height: 6rem;
    // resize: none;
    padding: 0.5rem $mp;
    // color: #8e8c8c;
    border-radius: 7px;
    background-color: #fff;
  }
}