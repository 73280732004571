$mp: !important;

.clip_list{
  display: flex;
  flex-flow: row wrap;
  padding-top: .5em;

}/*clip_list*/

  .clip_item.text_only{
    // this section overrides the section below because of its specificity priority
    display: grid;
    grid-template-areas:
        "icon . . ."
        "title title title title"
        "cat cat cat cat";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr auto 1fr;
    grid-gap: .25em;
     &.isLight{
       .clip_txtbx{color: #212529;}
       .clip_category, .clip_date{color: #6a6969 $mp;}
     }
    // justify-content: center;
    // align-items: center;
    .clip_remove{
      position: absolute;
      right: 1em;// .5em
      top: 1em;// .5em
      @extend %clip_icon;
    }
    .clip_icon{
      grid-area: icon;
    }
    .clip_icon {
      // font-size: 2.3em;
      color: #545454;
      // transform: scale(2);
      // position: absolute;
      &.clip_icon_type{
        // top: 1.2em;
        // left: 1.2em;
        &.pending{/*color: red;*/}// no color change
        &.active{background-color: var(--status-active);}
        &.stop{background-color: var(--status-stop); color: white; text-shadow: unset;}
        &.done{background-color: var(--status-done); color: white; text-shadow: unset;}
      }//clip_icon_type
      &.clip_icon_caption{
        // top: 1.2em;
        // left: 6em;
      }//clip_icon_type
      padding: .3em;
      text-shadow: 2px 2px 2px #ccc;
      border-width: thin !important;
    }
    .clip_profile{
      grid-area: icon;
      // position: absolute;
      // left: 3em;
      // top: .5em;
      justify-self: flex-start;
      align-self: center;
      height: 3em;
      width: 3em;
      margin: .5em;
      z-index: 1;
      .clip_profile_icon{font-size: 25px;}
      .image_area{
        width: 100%;
        height: 100%;
        border: 4px solid #e9e9e9;
      }
      canvas, img{
        height: 100%;
        width: 100%;
        min-height: unset;
        border-width: .15em;
        object-fit: cover;
        background: #fff;
      }
    }
    .clip_txtbx{
      grid-area: title;
      font-size: 1.5em;
      padding: 0 2em;
      text-align: center;
      color: #cdd4db;// isDark
    }
    .clip_txtbx, .clip_category, .clip_date{
      padding: .25em .5em;//.75
    }
    .clip_info_wrapper{
      // display: flex;
      // flex-flow: column;
      display: grid;
      grid-gap: 1em;
      margin: 0 .7em;
      grid-area: cat;
      grid-template-areas:
          ". category category badges"
          ". date date .";// ". . . badges"
      gap: unset;
      grid-template-columns: repeat(4,1fr);
      grid-template-rows: 2em 2em;
      height: -moz-fit-content;
      height: fit-content;
      .clip_category, .clip_date {
        height: -moz-fit-content;
        height: fit-content;
        // padding: 0 2em;
        text-align: center;
        color: #b8b5b5;// isDark
      }/*clip_category*/
      .clip_category{font-size: 1.2em;}
      .clip_date{font-size: .8em; padding: 0 .75em;}
      .review_rate_cont{
        grid-area: rating;
        align-items: flex-end;
        justify-content: flex-end;
      }// review_rate_cont
      .clip_badges{
        display:flex;
        flex-flow: row nowrap;
        grid-area: badges;
        justify-content: flex-end;
        gap: .2em;
        // padding: 0 .75em;
      }// clip_badges
    }// clip_info_wrapper
  }/*clip_item*/

  .clip_item{
    display:grid $mp;
    grid-template-areas: "image image image" "icon title title" "icon category category" "icon date badges";
    grid-template-columns: .8fr 5fr 3fr;
    // grid-template-rows: 8fr auto 1.5fr;
    grid-template-rows: 8fr auto 1fr 1fr;
    // margin: 0em 0.3em 0.6em 0.3em;
    margin: 0 0 0.6em;
    padding-bottom: 1em;
    border-radius: unset;
    width: 100%;
    cursor: pointer;
    &.card{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    }

    .clip_badges{
      display:flex;
      flex-flow: row nowrap;
      grid-area: badges;
      justify-content: flex-end;
      gap: .2em;
      padding: 0 .75em;
      height: -moz-fit-content;
      height: fit-content;
      align-self: flex-end;
    }// clip_badges
    .clip_remove{
      position: absolute;
      right: .5em;
      top: .5em;
      @extend %clip_icon;
    }
    .clip_icon{
      // font-size: 2.3em;
      color: #545454;
      height: -moz-fit-content;
      height: fit-content $mp;
      // transform: scale(2);
      // position: absolute;
      // grid-area: badges;
      &.clip_icon_type{
        // top: 1.2em;
        // left: 1.2em;
      }//clip_icon_type
      &.clip_icon_caption{
        // top: 1.2em;
        // left: 6em;
      }//clip_icon_type
      &.clip_icon_rating{
        font-size: 1.4em;
        padding: .1em;
        display: flex;
        justify-content: center;
        align-items: center;
      }// clip_icon_rating
      padding: .3em;
      text-shadow: 2px 2px 2px #ccc;
      border-width: thin !important;
    }
    div.priority_badge.clip_icon{
      width: 2.2em !important;
      height: 2.2em !important;
      padding: unset;
    }
    .clip_profile{
      grid-area: icon;
      justify-self: center;
      margin-top: .5em;
      // position: absolute;
      left: 3em;
      top: .5em;
      height: 2.5em;
      width: 2.5em;
      z-index: 1;
      .clip_profile_icon{font-size: 25px;}
      .image_area{
        width: 100%;
        height: 100%;
        border: unset;
      }
      canvas, img{
        height: 100%;
        width: 100%;
        min-height: unset;
        border-width: .15em;
        object-fit: cover;
        background: #fff;
      }
    }

    flex: 0 1 100%;
    max-height: 17em;
    img, canvas{
      width:400px; height: 200px;
      min-height: 100px;
      max-height: 250px $mp;
    }

    .clip_txtbx{grid-area: title;}
    .clip_txtbx, .clip_category, .clip_date{
      padding: .25em .75em 0 .5em;//.75
    }
    // .clip_info_wrapper{
    //   grid-area: category;
    //   display: grid;
    //   grid-template-areas: "category rating";
    //   grid-template-columns: 4fr 1fr;
    //   grid-gap: 1em;
    //   margin: 0 .7em;
      .clip_category, .clip_date{
        font-size: .8em;
        line-height: 1.7;
        height: -moz-fit-content;
        height: fit-content;
        align-self: center;
        // line-height: 12px;/*clamp sets a line-height and if it did't use measurements like 1.4 no rem or px*/
        padding: 0 .5em;
        color: #9a9a9a;
      }
      .clip_category{grid-area: category;}
      .clip_date{grid-area: date; text-align: left;}
    // }//clip_item_info_wrapper
    .clip_imgbx{
      // .clip_item_img_cont{
        display: flex;
        grid-area: image;
        justify-content: center;
        overflow: hidden;
        .clip_image_area{
          width:100%;
          max-height:200px;
          .clip_ImgImage{
            width:100%;
            height:100%;
          }/*item_img_ImgImage*/
        }/*item_img_image_area*/
        .clip_image_area, .clip_canvas_area{
          display: flex;
          justify-content: center;
          flex: 1;
          img, canvas{
            object-fit: cover;
            /* i need a flex height and width the accomodate css order: property*/
            /*min-height: 100px;
            max-height: 100px;*/
          }
        }/*item_img_image_area, item_canvas_area*/
      // }/*item_image_cont*/
    }/*item_imgbx*/
  }/*.clip_item*/

// what is a .clip_item.flip?
.clip_item.flip{
  margin: 0em 0.3em 0.6em 0.3em;
  padding-bottom: 1em;
  border-radius: unset;
  cursor: pointer;
  &.card{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  }

  &:nth-child(3n+1){
    // LATER: i think i want to make this every nth child that doesnt have portrait class for horizontal views or x-tool
    //the 1st and every 4th item
    flex: 0 1 100%;
    max-height: 17em;
    img, canvas{
      width:400px; height: 200px;
      min-height: 100px;
      max-height: 250px $mp;
    }
  }
  //otherwise make every 2nd and 3rd
  flex: 1 1 45%;
  .clip_txtbx{
    padding: .25em .75em;
  }
  .clip_imgbx{
    // .clip_img_cont{
    display: flex;
    justify-content: center;
    .clip_image_area{
      width:100%;
      max-height:200px;
      .clip_ImgImage{
        width:100%;
        height:100%;
      }/*clip_img_ImgImage*/
    }/*clip_img_image_area*/
    .clip_image_area, .clip_canvas_area{
      display: flex;
      justify-content: center;
      img, canvas{
        object-fit: cover;
        /* i need a flex height and width the accomodate css order: property*/
        /*min-height: 100px;
        max-height: 100px;*/
      }
    }/*clip_img_image_area, clip_canvas_area*/
    // }/*clip_image_cont*/
  }/*clip_imgbx*/
}/*clip_item*/

.clip_wrapper{
  cursor: pointer;
  height: 100%;
  padding: 1em;
  gap: .75em;
  margin: 0em 0em 0.6em 0em;
  width: 100%;
  &.card{box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);}
  .clip_item{
    transition: width 500ms ease-out;
  }// clip_item
  .clip_zoom{
    position: absolute;
    font-size: 1.3em;
    padding: .5em;
    right: .5em;
    transition: bottom 500ms ease-out;
    &.left{right: unset; left: .5em}
  }// clip_zoom
  &.out{
    display: grid;
    grid-template-areas: "caption" "hr" "clip";
    .clip_caption_wrap{
      display: grid;
      grid-template-areas: "profile cap close";
      grid-template-columns: 0.6fr 4fr .4fr;
      gap: 0.25em;
      .clip_profile{
          grid-area: profile;
        .image_area, .canvas_area{
          height: 2.5em;
          width: 2.5em;
          img, canvas{
            width: 100%;
            height: 100%;
            border: unset;
          }// image, canvas
        }// image_area
      }//clip_profile
      .clip_caption{
        font-size: 1em; // 0.9em;
        grid-area: cap;
        line-height: 1.5;
        height: 3em;
        padding-left: .25em;
      }// clip_caption
    }// clip_caption_wrap
    .clip_hr{margin: unset; grid-area: hr;}
    .clip_item{
      grid-area: clip;
      // width: 70%;
      // width: 53%;
      width: unset;
      height: 100%;
      aspect-ratio: 4 / 3;
      // max-height: unset;
      font-size: .6em;// 9px
      justify-self: center;
      align-self: center;
      margin: unset;
      padding: unset;
      padding-bottom: .5em;
    }// clip_item
    .clip_zoom{
      bottom: .5em;
    }// clip_zoom
  }// out
  &.in{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    transition: font-size 500ms ease-out;
    .clip_caption_wrap, .clip_hr{display: none;}
    .clip_item{
      margin: unset;
    }// clip_item
    .clip_zoom{/*display: none;*/ bottom: 50%;}
  }
  .clip_remove{
  display: flex;
  justify-content: center;
  align-items: center;
  // grid-area: close; 
  height: 1.8em $mp; 
  width: 1.8em $mp;
  text-shadow: 2px 2px 2px #ccc;
  position: absolute;
  top: 1em;
  right: 1em;
}// clip_remove
}// clip_wrapper

.clip_modal_cont
{
  display: none;
  height: 100%;
  // &.block{
  //   display: block;
  // }
}

%clip_icon{
  color: #545454;
  padding: .3em;
  text-shadow: 2px 2px 2px #ccc;
  border-width: thin !important;
}
