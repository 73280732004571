.panel_main .panel_main_content .contact_info_creator ._display .fldr_display, .panel_main .panel_main_content .contact_info_creator, .panel_main, .modal_dialog.core .modal_content .snapBox.snap_core {
  display: flex;
  flex-flow: column;
  height: 100%;
}

:root {
  --vh: 1vh;
}

.arc_stage {
  height: calc(var(--vh, 1vh) * 100);
}
.arc_stage .root {
  height: 100%;
}

.MainCore.modal_wrapper {
  display: flex !important;
  justify-content: center;
  height: 100%;
}
.MainCore.modal_wrapper .modal_dialog.core {
  height: 100% !important;
  margin: unset !important;
  width: 100% !important;
  padding: 0.5rem !important;
}

.modal_dialog.core {
  height: 95% !important;
  /*arc_popup_content*/
}
.modal_dialog.core .modal_content {
  height: 100%;
  /*.snapBox.snap_core*/
}
.modal_dialog.core .modal_content.chk_popup_content {
  height: 95%;
  max-height: unset;
}
.modal_dialog.core .modal_content .snapBox.snap_core {
  /*snap_cont*/
  /*.snap_ctrls.snap_core*/
}
.modal_dialog.core .modal_content .snapBox.snap_core .snap_cont {
  flex: 1 auto;
  /*snap_section*/
}
/*arc_popup_modal*/
.chk_popup_modal.core {
  height: 95vh !important;
}

.panel_main {
  /*arc_panel_main_content*/
}
.panel_main .panel_main_content {
  grid-area: content;
  flex: 1 auto;
  /*arc_contact_info_creator*/
}
.panel_main .panel_main_content .contact_info_creator {
  /*arc_display*/
}
.panel_main .panel_main_content .contact_info_creator ._display {
  flex: 1 auto;
  /*fldr_display*/
}
.panel_main .panel_main_content .contact_info_creator ._display .fldr_display {
  /*ul_display_list*/
}
.panel_main .panel_main_content .contact_info_creator ._display .fldr_display .filter_wrapper {
  width: 100%;
}
.panel_main .panel_main_content .contact_info_creator ._display .fldr_display .ul_display_list {
  flex: 1 auto;
}

/*arc_panel_main*/