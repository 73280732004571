.custom_input_wrapper {
  display: grid;
  grid-template-areas: "label case" "input input" "other other";
  grid-template-columns: 9fr 1fr;
  gap: 0.25rem;
  margin-bottom: unset !important;
}
.custom_input_wrapper.inline {
  grid-template-areas: "label case" "input case" "other case";
  padding: 0 0.5rem 0.5rem;
}
.custom_input_wrapper.inline .custom_input_cont {
  padding: unset !important;
}
.custom_input_wrapper.inline .custom_input_ctrls {
  flex-flow: column nowrap;
  justify-content: center;
  gap: 0.25rem;
}
.custom_input_wrapper label {
  grid-area: label;
}
.custom_input_wrapper .MuiTextField-root .MuiInputBase-root {
  padding: 0.5rem 1rem;
}
.custom_input_wrapper .custom_input_ctrls {
  display: flex;
  flex-flow: row nowrap;
  grid-area: case;
}
.custom_input_wrapper .custom_input_other {
  grid-area: other;
}
.custom_input_wrapper .change_case_icon,
.custom_input_wrapper .copy_me_icon,
.custom_input_wrapper .show_hide_icon {
  font-size: 1.2rem;
  padding: 0.5rem;
  justify-self: center;
  color: #bbb;
  align-self: center;
  margin-left: 0.25em;
}
.custom_input_wrapper .change_case_icon:only-child,
.custom_input_wrapper .copy_me_icon:only-child,
.custom_input_wrapper .show_hide_icon:only-child {
  margin: unset;
}
.custom_input_wrapper input, .custom_input_wrapper textarea, .custom_input_wrapper .MuiTextField-root {
  grid-area: input;
}
.custom_input_wrapper .no-resize, .custom_input_wrapper .no_resize {
  resize: none;
}
.custom_input_wrapper .d3-textarea {
  margin: 0.25rem auto 0.5rem;
  padding: 0.5rem !important;
  border-radius: 7px;
  background-color: #fff;
}