#pp_sidebar {
  display: grid;
  grid-template-areas: "content" "footer";
  padding: 0.5rem !important;
  grid-template-rows: 90% 4rem;
}
#pp_sidebar .pp_close_cont {
  position: absolute;
  right: 0;
  z-index: 1;
}
#pp_sidebar .pp_close_cont .pp_panel_cls_btn {
  background: #00000040;
}
#pp_sidebar .pp_mobx_cont, #pp_sidebar .pp_mxr_cont {
  grid-area: content;
}
#pp_sidebar #pp_content_cont {
  grid-area: content;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  gap: 0.5rem;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header {
  height: 20rem;
  grid-area: profile;
  display: grid;
  grid-template-rows: 3.5rem 8.5rem 2.5rem;
  grid-template-areas: "top" "profile" "name";
  position: relative;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile {
  grid-area: profile;
  display: flex;
  justify-content: center;
  align-items: center;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .dash_profile_icon {
  font-size: 6rem;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .image_area, #pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .canvas_area {
  display: flex;
  justify-content: center;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .image_area img, #pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .image_area canvas, #pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .canvas_area img, #pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .canvas_area canvas {
  width: 8rem;
  height: 8rem;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .image_area.d3-profile-rounded, #pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_profile .canvas_area.d3-profile-rounded {
  border-radius: unset;
  border: unset;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_title_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  grid-area: name;
  text-transform: capitalize;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_title_cont .dash_title {
  max-width: 145px;
  word-break: break-all;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_header .dash_title_cont .dash_form {
  font-size: 0.9rem;
  padding: unset;
  cursor: pointer;
  background: #00000040;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_settings {
  grid-area: settings;
  height: 100%;
}
#pp_sidebar #pp_content_cont .dashboard_wrapper .dashboard_settings .WonderBtn {
  color: white;
}
#pp_sidebar .pp_sign_out {
  grid-area: footer;
}

.dashboard_settings {
  padding-top: 2rem;
  overflow-y: auto;
}
.dashboard_settings .access_select_icon_cont, .dashboard_settings .miniForm_cont {
  width: 90%;
}
.dashboard_settings .access_select_icon_cont .switch_label, .dashboard_settings .miniForm_cont .switch_label {
  font-size: 0.8rem;
}
.dashboard_settings input:checked + .slider {
  background-color: var(--active-blue, #38c) !important;
}
.dashboard_settings .raw_dash {
  margin: 0.3em auto;
  width: 90%;
}
.dashboard_settings .raw_dash hr {
  margin: 0.75rem 1rem;
  border-width: 0.15rem;
  border-top: 1px solid #eeeeee45;
}
.dashboard_settings .raw_dash .rawDrop_display_cont {
  background: #00000036;
}
.dashboard_settings .raw_dash .rawDrop_display_cont hr:last-child {
  display: none;
  /*works*/
}