.tasksForm .tags_ICont .tasks_inBtn_wrapper .tags_inBtn, .tasksForm .tags_ICont .tasks_inBtn_wrapper .tasks_inBtn, .tasksForm .tags_ICont .tags_inBtn_wrapper .tags_inBtn, .tasksForm .tags_ICont .tags_inBtn_wrapper .tasks_inBtn, .tasksForm .tasks_ICont .tasks_inBtn_wrapper .tags_inBtn, .tasksForm .tasks_ICont .tasks_inBtn_wrapper .tasks_inBtn, .tasksForm .tasks_ICont .tags_inBtn_wrapper .tags_inBtn, .tasksForm .tasks_ICont .tags_inBtn_wrapper .tasks_inBtn, .tagsForm .tags_ICont .tasks_inBtn_wrapper .tags_inBtn, .tagsForm .tags_ICont .tasks_inBtn_wrapper .tasks_inBtn, .tagsForm .tags_ICont .tags_inBtn_wrapper .tags_inBtn, .tagsForm .tags_ICont .tags_inBtn_wrapper .tasks_inBtn, .tagsForm .tasks_ICont .tasks_inBtn_wrapper .tags_inBtn, .tagsForm .tasks_ICont .tasks_inBtn_wrapper .tasks_inBtn, .tagsForm .tasks_ICont .tags_inBtn_wrapper .tags_inBtn, .tagsForm .tasks_ICont .tags_inBtn_wrapper .tasks_inBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  height: 26px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  padding: 4px 7px;
  border-radius: 5px;
  overflow: hidden;
  margin: unset;
  width: 100% !important;
  justify-self: center;
  align-self: center;
  text-align: center;
  z-index: 1;
}

.tasksForm .tags_ICont, .tasksForm .tasks_ICont, .tagsForm .tags_ICont, .tagsForm .tasks_ICont {
  position: relative;
  color: #333;
  font-size: 1em;
  line-height: 1.3;
  font-family: sans-serif;
}

.tasksForm .tags_ICont, .tagsForm .tags_ICont {
  margin: 0.5em 0;
  background-color: #fff;
  border-color: #ddd;
  border-width: 1px;
  border-style: solid;
  text-shadow: 0 1px 0 #f3f3f3;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.3125em;
}

.tasksForm .tasksLabel, .tagsForm .tasksLabel {
  display: flex;
  text-shadow: 2px 2px 2px #ccc;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  gap: 0.5rem;
}
.tasksForm .tasksLabel .custom_input_wrapper, .tagsForm .tasksLabel .custom_input_wrapper {
  width: 100%;
}
.tasksForm .tasksLabel .task_tags_edit_cont, .tagsForm .tasksLabel .task_tags_edit_cont {
  display: flex;
  flex-flow: row-reverse;
  gap: 1rem;
}
.tasksForm .tasksLabel .task_tags_edit_cont .task_tags_btn, .tagsForm .tasksLabel .task_tags_edit_cont .task_tags_btn,
.tasksForm .tasksLabel .task_tags_edit_cont .tasks_sort_icon,
.tagsForm .tasksLabel .task_tags_edit_cont .tasks_sort_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.tasksForm .tasksLabel .task_tags_edit_cont .task_tags_btn.loader, .tagsForm .tasksLabel .task_tags_edit_cont .task_tags_btn.loader,
.tasksForm .tasksLabel .task_tags_edit_cont .tasks_sort_icon.loader,
.tagsForm .tasksLabel .task_tags_edit_cont .tasks_sort_icon.loader {
  height: 2rem;
  width: 2rem;
  border-width: 0.4rem;
}
.tasksForm .tasksLabel .task_tags_edit_cont .task_tags_btn.save_tasks, .tagsForm .tasksLabel .task_tags_edit_cont .task_tags_btn.save_tasks,
.tasksForm .tasksLabel .task_tags_edit_cont .tasks_sort_icon.save_tasks,
.tagsForm .tasksLabel .task_tags_edit_cont .tasks_sort_icon.save_tasks {
  color: green;
}

.tasksForm .tasks_sort_icon, .tagsForm .tasks_sort_icon {
  font-size: 1.4rem;
  cursor: pointer;
}
.tasksForm .tags_ICont, .tasksForm .tasks_ICont, .tagsForm .tags_ICont, .tagsForm .tasks_ICont {
  display: grid;
  grid-template-areas: "input go";
  grid-template-columns: 8fr 1fr;
  padding: unset !important;
  gap: 0.5rem;
  padding: 0.25rem !important;
}
.tasksForm .tags_ICont .tagsInput, .tasksForm .tags_ICont .tasksInput, .tasksForm .tasks_ICont .tagsInput, .tasksForm .tasks_ICont .tasksInput, .tagsForm .tags_ICont .tagsInput, .tagsForm .tags_ICont .tasksInput, .tagsForm .tasks_ICont .tagsInput, .tagsForm .tasks_ICont .tasksInput {
  grid-area: input;
}
.tasksForm .tags_ICont .tasksInput, .tasksForm .tasks_ICont .tasksInput, .tagsForm .tags_ICont .tasksInput, .tagsForm .tasks_ICont .tasksInput {
  padding: 0.5rem 0.75rem;
}
.tasksForm .tags_ICont .tasks_inBtn_wrapper, .tasksForm .tags_ICont .tags_inBtn_wrapper, .tasksForm .tasks_ICont .tasks_inBtn_wrapper, .tasksForm .tasks_ICont .tags_inBtn_wrapper, .tagsForm .tags_ICont .tasks_inBtn_wrapper, .tagsForm .tags_ICont .tags_inBtn_wrapper, .tagsForm .tasks_ICont .tasks_inBtn_wrapper, .tagsForm .tasks_ICont .tags_inBtn_wrapper {
  display: flex;
  flex-flow: column nowrap;
  grid-area: go;
  gap: 0.5rem;
}
.tasksForm .tags_ICont .tasks_inBtn_wrapper .tags_inBtn.go-btn, .tasksForm .tags_ICont .tasks_inBtn_wrapper .tasks_inBtn.go-btn, .tasksForm .tags_ICont .tags_inBtn_wrapper .tags_inBtn.go-btn, .tasksForm .tags_ICont .tags_inBtn_wrapper .tasks_inBtn.go-btn, .tasksForm .tasks_ICont .tasks_inBtn_wrapper .tags_inBtn.go-btn, .tasksForm .tasks_ICont .tasks_inBtn_wrapper .tasks_inBtn.go-btn, .tasksForm .tasks_ICont .tags_inBtn_wrapper .tags_inBtn.go-btn, .tasksForm .tasks_ICont .tags_inBtn_wrapper .tasks_inBtn.go-btn, .tagsForm .tags_ICont .tasks_inBtn_wrapper .tags_inBtn.go-btn, .tagsForm .tags_ICont .tasks_inBtn_wrapper .tasks_inBtn.go-btn, .tagsForm .tags_ICont .tags_inBtn_wrapper .tags_inBtn.go-btn, .tagsForm .tags_ICont .tags_inBtn_wrapper .tasks_inBtn.go-btn, .tagsForm .tasks_ICont .tasks_inBtn_wrapper .tags_inBtn.go-btn, .tagsForm .tasks_ICont .tasks_inBtn_wrapper .tasks_inBtn.go-btn, .tagsForm .tasks_ICont .tags_inBtn_wrapper .tags_inBtn.go-btn, .tagsForm .tasks_ICont .tags_inBtn_wrapper .tasks_inBtn.go-btn {
  background-color: #87cf96 !important;
  color: white;
}
.tasksForm .tags_ICont .tasks_inBtn_wrapper .tags_inBtn.close-btn, .tasksForm .tags_ICont .tasks_inBtn_wrapper .tasks_inBtn.close-btn, .tasksForm .tags_ICont .tags_inBtn_wrapper .tags_inBtn.close-btn, .tasksForm .tags_ICont .tags_inBtn_wrapper .tasks_inBtn.close-btn, .tasksForm .tasks_ICont .tasks_inBtn_wrapper .tags_inBtn.close-btn, .tasksForm .tasks_ICont .tasks_inBtn_wrapper .tasks_inBtn.close-btn, .tasksForm .tasks_ICont .tags_inBtn_wrapper .tags_inBtn.close-btn, .tasksForm .tasks_ICont .tags_inBtn_wrapper .tasks_inBtn.close-btn, .tagsForm .tags_ICont .tasks_inBtn_wrapper .tags_inBtn.close-btn, .tagsForm .tags_ICont .tasks_inBtn_wrapper .tasks_inBtn.close-btn, .tagsForm .tags_ICont .tags_inBtn_wrapper .tags_inBtn.close-btn, .tagsForm .tags_ICont .tags_inBtn_wrapper .tasks_inBtn.close-btn, .tagsForm .tasks_ICont .tasks_inBtn_wrapper .tags_inBtn.close-btn, .tagsForm .tasks_ICont .tasks_inBtn_wrapper .tasks_inBtn.close-btn, .tagsForm .tasks_ICont .tags_inBtn_wrapper .tags_inBtn.close-btn, .tagsForm .tasks_ICont .tags_inBtn_wrapper .tasks_inBtn.close-btn {
  background-color: #ccc !important;
  grid-area: close;
  border: unset;
}
.tasksForm .tags_ICont .tasks_inBtn_wrapper, .tasksForm .tasks_ICont .tasks_inBtn_wrapper, .tagsForm .tags_ICont .tasks_inBtn_wrapper, .tagsForm .tasks_ICont .tasks_inBtn_wrapper {
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.tasksForm .tags_ICont .tags_inBtn_wrapper, .tasksForm .tasks_ICont .tags_inBtn_wrapper, .tagsForm .tags_ICont .tags_inBtn_wrapper, .tagsForm .tasks_ICont .tags_inBtn_wrapper {
  justify-content: center;
}
.tasksForm .tags_ICont .tagsInput, .tagsForm .tags_ICont .tagsInput {
  border: transparent !important;
}
.tasksForm .tags_side_ctrls_cont, .tagsForm .tags_side_ctrls_cont {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding-bottom: 1rem;
  gap: 1rem;
}
.tasksForm .tagsHolder, .tagsForm .tagsHolder {
  flex-flow: row wrap;
  max-height: 115px;
}
.tasksForm .tagsHolder, .tasksForm .tasksHolder, .tagsForm .tagsHolder, .tagsForm .tasksHolder {
  width: 90%;
  margin: 5px auto;
  padding: 3px;
  background-color: #ddd;
}
.tasksForm .sort_wrapper, .tagsForm .sort_wrapper {
  width: 100%;
  flex-flow: row wrap;
}
.tasksForm .tagsHolder, .tasksForm .tasksHolder, .tasksForm .sort_wrapper, .tagsForm .tagsHolder, .tagsForm .tasksHolder, .tagsForm .sort_wrapper {
  display: flex;
  min-height: 40px;
  text-align: left;
  overflow-y: auto;
}
.tasksForm .tagsHolder .tags_box, .tasksForm .tagsHolder .tasks_box, .tasksForm .tasksHolder .tags_box, .tasksForm .tasksHolder .tasks_box, .tasksForm .sort_wrapper .tags_box, .tasksForm .sort_wrapper .tasks_box, .tagsForm .tagsHolder .tags_box, .tagsForm .tagsHolder .tasks_box, .tagsForm .tasksHolder .tags_box, .tagsForm .tasksHolder .tasks_box, .tagsForm .sort_wrapper .tags_box, .tagsForm .sort_wrapper .tasks_box {
  margin: 2px 3px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}
.tasksForm .tagsHolder .tags_box .tags_text, .tasksForm .tagsHolder .tags_box .tasks_text, .tasksForm .tagsHolder .tasks_box .tags_text, .tasksForm .tagsHolder .tasks_box .tasks_text, .tasksForm .tasksHolder .tags_box .tags_text, .tasksForm .tasksHolder .tags_box .tasks_text, .tasksForm .tasksHolder .tasks_box .tags_text, .tasksForm .tasksHolder .tasks_box .tasks_text, .tasksForm .sort_wrapper .tags_box .tags_text, .tasksForm .sort_wrapper .tags_box .tasks_text, .tasksForm .sort_wrapper .tasks_box .tags_text, .tasksForm .sort_wrapper .tasks_box .tasks_text, .tagsForm .tagsHolder .tags_box .tags_text, .tagsForm .tagsHolder .tags_box .tasks_text, .tagsForm .tagsHolder .tasks_box .tags_text, .tagsForm .tagsHolder .tasks_box .tasks_text, .tagsForm .tasksHolder .tags_box .tags_text, .tagsForm .tasksHolder .tags_box .tasks_text, .tagsForm .tasksHolder .tasks_box .tags_text, .tagsForm .tasksHolder .tasks_box .tasks_text, .tagsForm .sort_wrapper .tags_box .tags_text, .tagsForm .sort_wrapper .tags_box .tasks_text, .tagsForm .sort_wrapper .tasks_box .tags_text, .tagsForm .sort_wrapper .tasks_box .tasks_text {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tasksForm .tagsHolder .tags_box .tags_text, .tasksForm .tagsHolder .tasks_box .tags_text, .tasksForm .tasksHolder .tags_box .tags_text, .tasksForm .tasksHolder .tasks_box .tags_text, .tasksForm .sort_wrapper .tags_box .tags_text, .tasksForm .sort_wrapper .tasks_box .tags_text, .tagsForm .tagsHolder .tags_box .tags_text, .tagsForm .tagsHolder .tasks_box .tags_text, .tagsForm .tasksHolder .tags_box .tags_text, .tagsForm .tasksHolder .tasks_box .tags_text, .tagsForm .sort_wrapper .tags_box .tags_text, .tagsForm .sort_wrapper .tasks_box .tags_text {
  max-height: 30px;
}
.tasksForm .tagsHolder .tags_box .tasks_text, .tasksForm .tagsHolder .tasks_box .tasks_text, .tasksForm .tasksHolder .tags_box .tasks_text, .tasksForm .tasksHolder .tasks_box .tasks_text, .tasksForm .sort_wrapper .tags_box .tasks_text, .tasksForm .sort_wrapper .tasks_box .tasks_text, .tagsForm .tagsHolder .tags_box .tasks_text, .tagsForm .tagsHolder .tasks_box .tasks_text, .tagsForm .tasksHolder .tags_box .tasks_text, .tagsForm .tasksHolder .tasks_box .tasks_text, .tagsForm .sort_wrapper .tags_box .tasks_text, .tagsForm .sort_wrapper .tasks_box .tasks_text {
  max-height: unset !important;
}
.tasksForm .tagsHolder .tags_box .tags_inner, .tasksForm .tagsHolder .tags_box .tasks_inner, .tasksForm .tagsHolder .tasks_box .tags_inner, .tasksForm .tagsHolder .tasks_box .tasks_inner, .tasksForm .tasksHolder .tags_box .tags_inner, .tasksForm .tasksHolder .tags_box .tasks_inner, .tasksForm .tasksHolder .tasks_box .tags_inner, .tasksForm .tasksHolder .tasks_box .tasks_inner, .tasksForm .sort_wrapper .tags_box .tags_inner, .tasksForm .sort_wrapper .tags_box .tasks_inner, .tasksForm .sort_wrapper .tasks_box .tags_inner, .tasksForm .sort_wrapper .tasks_box .tasks_inner, .tagsForm .tagsHolder .tags_box .tags_inner, .tagsForm .tagsHolder .tags_box .tasks_inner, .tagsForm .tagsHolder .tasks_box .tags_inner, .tagsForm .tagsHolder .tasks_box .tasks_inner, .tagsForm .tasksHolder .tags_box .tags_inner, .tagsForm .tasksHolder .tags_box .tasks_inner, .tagsForm .tasksHolder .tasks_box .tags_inner, .tagsForm .tasksHolder .tasks_box .tasks_inner, .tagsForm .sort_wrapper .tags_box .tags_inner, .tagsForm .sort_wrapper .tags_box .tasks_inner, .tagsForm .sort_wrapper .tasks_box .tags_inner, .tagsForm .sort_wrapper .tasks_box .tasks_inner {
  margin: 3px;
  display: inline-block;
  vertical-align: top;
}
.tasksForm .tagsHolder .tags_box .tasks_check_cont, .tasksForm .tagsHolder .tags_box .tasks_close_cont, .tasksForm .tagsHolder .tasks_box .tasks_check_cont, .tasksForm .tagsHolder .tasks_box .tasks_close_cont, .tasksForm .tasksHolder .tags_box .tasks_check_cont, .tasksForm .tasksHolder .tags_box .tasks_close_cont, .tasksForm .tasksHolder .tasks_box .tasks_check_cont, .tasksForm .tasksHolder .tasks_box .tasks_close_cont, .tasksForm .sort_wrapper .tags_box .tasks_check_cont, .tasksForm .sort_wrapper .tags_box .tasks_close_cont, .tasksForm .sort_wrapper .tasks_box .tasks_check_cont, .tasksForm .sort_wrapper .tasks_box .tasks_close_cont, .tagsForm .tagsHolder .tags_box .tasks_check_cont, .tagsForm .tagsHolder .tags_box .tasks_close_cont, .tagsForm .tagsHolder .tasks_box .tasks_check_cont, .tagsForm .tagsHolder .tasks_box .tasks_close_cont, .tagsForm .tasksHolder .tags_box .tasks_check_cont, .tagsForm .tasksHolder .tags_box .tasks_close_cont, .tagsForm .tasksHolder .tasks_box .tasks_check_cont, .tagsForm .tasksHolder .tasks_box .tasks_close_cont, .tagsForm .sort_wrapper .tags_box .tasks_check_cont, .tagsForm .sort_wrapper .tags_box .tasks_close_cont, .tagsForm .sort_wrapper .tasks_box .tasks_check_cont, .tagsForm .sort_wrapper .tasks_box .tasks_close_cont {
  height: 100%;
}
.tasksForm .tagsHolder .tags_box .tags_inner, .tasksForm .tagsHolder .tags_box .tasks_check, .tasksForm .tagsHolder .tasks_box .tags_inner, .tasksForm .tagsHolder .tasks_box .tasks_check, .tasksForm .tasksHolder .tags_box .tags_inner, .tasksForm .tasksHolder .tags_box .tasks_check, .tasksForm .tasksHolder .tasks_box .tags_inner, .tasksForm .tasksHolder .tasks_box .tasks_check, .tasksForm .sort_wrapper .tags_box .tags_inner, .tasksForm .sort_wrapper .tags_box .tasks_check, .tasksForm .sort_wrapper .tasks_box .tags_inner, .tasksForm .sort_wrapper .tasks_box .tasks_check, .tagsForm .tagsHolder .tags_box .tags_inner, .tagsForm .tagsHolder .tags_box .tasks_check, .tagsForm .tagsHolder .tasks_box .tags_inner, .tagsForm .tagsHolder .tasks_box .tasks_check, .tagsForm .tasksHolder .tags_box .tags_inner, .tagsForm .tasksHolder .tags_box .tasks_check, .tagsForm .tasksHolder .tasks_box .tags_inner, .tagsForm .tasksHolder .tasks_box .tasks_check, .tagsForm .sort_wrapper .tags_box .tags_inner, .tagsForm .sort_wrapper .tags_box .tasks_check, .tagsForm .sort_wrapper .tasks_box .tags_inner, .tagsForm .sort_wrapper .tasks_box .tasks_check {
  height: 20px;
}
.tasksForm .tagsHolder .tags_box .tasks_text, .tasksForm .tagsHolder .tasks_box .tasks_text, .tasksForm .tasksHolder .tags_box .tasks_text, .tasksForm .tasksHolder .tasks_box .tasks_text, .tasksForm .sort_wrapper .tags_box .tasks_text, .tasksForm .sort_wrapper .tasks_box .tasks_text, .tagsForm .tagsHolder .tags_box .tasks_text, .tagsForm .tagsHolder .tasks_box .tasks_text, .tagsForm .tasksHolder .tags_box .tasks_text, .tagsForm .tasksHolder .tasks_box .tasks_text, .tagsForm .sort_wrapper .tags_box .tasks_text, .tagsForm .sort_wrapper .tasks_box .tasks_text {
  height: unset !important;
}
.tasksForm .tagsHolder .tags_box .tasks_check, .tasksForm .tagsHolder .tags_box .tasks_close, .tasksForm .tagsHolder .tasks_box .tasks_check, .tasksForm .tagsHolder .tasks_box .tasks_close, .tasksForm .tasksHolder .tags_box .tasks_check, .tasksForm .tasksHolder .tags_box .tasks_close, .tasksForm .tasksHolder .tasks_box .tasks_check, .tasksForm .tasksHolder .tasks_box .tasks_close, .tasksForm .sort_wrapper .tags_box .tasks_check, .tasksForm .sort_wrapper .tags_box .tasks_close, .tasksForm .sort_wrapper .tasks_box .tasks_check, .tasksForm .sort_wrapper .tasks_box .tasks_close, .tagsForm .tagsHolder .tags_box .tasks_check, .tagsForm .tagsHolder .tags_box .tasks_close, .tagsForm .tagsHolder .tasks_box .tasks_check, .tagsForm .tagsHolder .tasks_box .tasks_close, .tagsForm .tasksHolder .tags_box .tasks_check, .tagsForm .tasksHolder .tags_box .tasks_close, .tagsForm .tasksHolder .tasks_box .tasks_check, .tagsForm .tasksHolder .tasks_box .tasks_close, .tagsForm .sort_wrapper .tags_box .tasks_check, .tagsForm .sort_wrapper .tags_box .tasks_close, .tagsForm .sort_wrapper .tasks_box .tasks_check, .tagsForm .sort_wrapper .tasks_box .tasks_close {
  margin-top: 0.4em !important;
}
.tasksForm .tagsHolder .tags_box, .tasksForm .tasksHolder .tags_box, .tasksForm .sort_wrapper .tags_box, .tagsForm .tagsHolder .tags_box, .tagsForm .tasksHolder .tags_box, .tagsForm .sort_wrapper .tags_box {
  height: 30px;
  max-width: 95%;
}
.tasksForm .tagsHolder .tags_box .tags_text, .tasksForm .tasksHolder .tags_box .tags_text, .tasksForm .sort_wrapper .tags_box .tags_text, .tagsForm .tagsHolder .tags_box .tags_text, .tagsForm .tasksHolder .tags_box .tags_text, .tagsForm .sort_wrapper .tags_box .tags_text {
  display: flex;
  align-items: center;
}
.tasksForm .tagsHolder .tags_tasks_no_data, .tasksForm .tasksHolder .tags_tasks_no_data, .tasksForm .sort_wrapper .tags_tasks_no_data, .tagsForm .tagsHolder .tags_tasks_no_data, .tagsForm .tasksHolder .tags_tasks_no_data, .tagsForm .sort_wrapper .tags_tasks_no_data {
  padding: 0.5rem 1rem;
}

.faux_error {
  color: red;
  margin: unset;
  color: red;
  font-size: 0.8em !important;
  width: 90%;
  margin: 0 auto !important;
  text-align: left;
}

.part_temp_cont .tasksHolder {
  max-height: 24rem !important;
}

.snap_section .tasksHolder {
  border: 1px solid #ccc;
  max-height: 24rem !important;
  background-color: unset;
}
.snap_section .tasksHolder .sort_wrapper {
  background-color: unset;
}