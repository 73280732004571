.clip_list {
  display: flex;
  flex-flow: row wrap;
  padding-top: 0.5em;
}

/*clip_list*/
.clip_item.text_only {
  display: grid;
  grid-template-areas: "icon . . ." "title title title title" "cat cat cat cat";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr auto 1fr;
  grid-gap: 0.25em;
}
.clip_item.text_only.isLight .clip_txtbx {
  color: #212529;
}
.clip_item.text_only.isLight .clip_category, .clip_item.text_only.isLight .clip_date {
  color: #6a6969 !important;
}
.clip_item.text_only .clip_remove {
  position: absolute;
  right: 1em;
  top: 1em;
}
.clip_item.text_only .clip_icon {
  grid-area: icon;
}
.clip_item.text_only .clip_icon {
  color: #545454;
  padding: 0.3em;
  text-shadow: 2px 2px 2px #ccc;
  border-width: thin !important;
}
.clip_item.text_only .clip_icon.clip_icon_type.pending {
  /*color: red;*/
}
.clip_item.text_only .clip_icon.clip_icon_type.active {
  background-color: var(--status-active);
}
.clip_item.text_only .clip_icon.clip_icon_type.stop {
  background-color: var(--status-stop);
  color: white;
  text-shadow: unset;
}
.clip_item.text_only .clip_icon.clip_icon_type.done {
  background-color: var(--status-done);
  color: white;
  text-shadow: unset;
}
.clip_item.text_only .clip_profile {
  grid-area: icon;
  justify-self: flex-start;
  align-self: center;
  height: 3em;
  width: 3em;
  margin: 0.5em;
  z-index: 1;
}
.clip_item.text_only .clip_profile .clip_profile_icon {
  font-size: 25px;
}
.clip_item.text_only .clip_profile .image_area {
  width: 100%;
  height: 100%;
  border: 4px solid #e9e9e9;
}
.clip_item.text_only .clip_profile canvas, .clip_item.text_only .clip_profile img {
  height: 100%;
  width: 100%;
  min-height: unset;
  border-width: 0.15em;
  object-fit: cover;
  background: #fff;
}
.clip_item.text_only .clip_txtbx {
  grid-area: title;
  font-size: 1.5em;
  padding: 0 2em;
  text-align: center;
  color: #cdd4db;
}
.clip_item.text_only .clip_txtbx, .clip_item.text_only .clip_category, .clip_item.text_only .clip_date {
  padding: 0.25em 0.5em;
}
.clip_item.text_only .clip_info_wrapper {
  display: grid;
  grid-gap: 1em;
  margin: 0 0.7em;
  grid-area: cat;
  grid-template-areas: ". category category badges" ". date date .";
  gap: unset;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 2em 2em;
  height: -moz-fit-content;
  height: fit-content;
  /*clip_category*/
}
.clip_item.text_only .clip_info_wrapper .clip_category, .clip_item.text_only .clip_info_wrapper .clip_date {
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  color: #b8b5b5;
}
.clip_item.text_only .clip_info_wrapper .clip_category {
  font-size: 1.2em;
}
.clip_item.text_only .clip_info_wrapper .clip_date {
  font-size: 0.8em;
  padding: 0 0.75em;
}
.clip_item.text_only .clip_info_wrapper .review_rate_cont {
  grid-area: rating;
  align-items: flex-end;
  justify-content: flex-end;
}
.clip_item.text_only .clip_info_wrapper .clip_badges {
  display: flex;
  flex-flow: row nowrap;
  grid-area: badges;
  justify-content: flex-end;
  gap: 0.2em;
}

/*clip_item*/
.clip_item {
  display: grid !important;
  grid-template-areas: "image image image" "icon title title" "icon category category" "icon date badges";
  grid-template-columns: 0.8fr 5fr 3fr;
  grid-template-rows: 8fr auto 1fr 1fr;
  margin: 0 0 0.6em;
  padding-bottom: 1em;
  border-radius: unset;
  width: 100%;
  cursor: pointer;
  flex: 0 1 100%;
  max-height: 17em;
  /*item_imgbx*/
}
.clip_item.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.clip_item .clip_badges {
  display: flex;
  flex-flow: row nowrap;
  grid-area: badges;
  justify-content: flex-end;
  gap: 0.2em;
  padding: 0 0.75em;
  height: -moz-fit-content;
  height: fit-content;
  align-self: flex-end;
}
.clip_item .clip_remove {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
.clip_item .clip_icon {
  color: #545454;
  height: -moz-fit-content;
  height: fit-content !important;
  padding: 0.3em;
  text-shadow: 2px 2px 2px #ccc;
  border-width: thin !important;
}
.clip_item .clip_icon.clip_icon_rating {
  font-size: 1.4em;
  padding: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clip_item div.priority_badge.clip_icon {
  width: 2.2em !important;
  height: 2.2em !important;
  padding: unset;
}
.clip_item .clip_profile {
  grid-area: icon;
  justify-self: center;
  margin-top: 0.5em;
  left: 3em;
  top: 0.5em;
  height: 2.5em;
  width: 2.5em;
  z-index: 1;
}
.clip_item .clip_profile .clip_profile_icon {
  font-size: 25px;
}
.clip_item .clip_profile .image_area {
  width: 100%;
  height: 100%;
  border: unset;
}
.clip_item .clip_profile canvas, .clip_item .clip_profile img {
  height: 100%;
  width: 100%;
  min-height: unset;
  border-width: 0.15em;
  object-fit: cover;
  background: #fff;
}
.clip_item img, .clip_item canvas {
  width: 400px;
  height: 200px;
  min-height: 100px;
  max-height: 250px !important;
}
.clip_item .clip_txtbx {
  grid-area: title;
}
.clip_item .clip_txtbx, .clip_item .clip_category, .clip_item .clip_date {
  padding: 0.25em 0.75em 0 0.5em;
}
.clip_item .clip_category, .clip_item .clip_date {
  font-size: 0.8em;
  line-height: 1.7;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  padding: 0 0.5em;
  color: #9a9a9a;
}
.clip_item .clip_category {
  grid-area: category;
}
.clip_item .clip_date {
  grid-area: date;
  text-align: left;
}
.clip_item .clip_imgbx {
  display: flex;
  grid-area: image;
  justify-content: center;
  overflow: hidden;
  /*item_img_image_area*/
  /*item_img_image_area, item_canvas_area*/
}
.clip_item .clip_imgbx .clip_image_area {
  width: 100%;
  max-height: 200px;
  /*item_img_ImgImage*/
}
.clip_item .clip_imgbx .clip_image_area .clip_ImgImage {
  width: 100%;
  height: 100%;
}
.clip_item .clip_imgbx .clip_image_area, .clip_item .clip_imgbx .clip_canvas_area {
  display: flex;
  justify-content: center;
  flex: 1;
}
.clip_item .clip_imgbx .clip_image_area img, .clip_item .clip_imgbx .clip_image_area canvas, .clip_item .clip_imgbx .clip_canvas_area img, .clip_item .clip_imgbx .clip_canvas_area canvas {
  object-fit: cover;
  /* i need a flex height and width the accomodate css order: property*/
  /*min-height: 100px;
  max-height: 100px;*/
}

/*.clip_item*/
.clip_item.flip {
  margin: 0em 0.3em 0.6em 0.3em;
  padding-bottom: 1em;
  border-radius: unset;
  cursor: pointer;
  flex: 1 1 45%;
  /*clip_imgbx*/
}
.clip_item.flip.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.clip_item.flip:nth-child(3n+1) {
  flex: 0 1 100%;
  max-height: 17em;
}
.clip_item.flip:nth-child(3n+1) img, .clip_item.flip:nth-child(3n+1) canvas {
  width: 400px;
  height: 200px;
  min-height: 100px;
  max-height: 250px !important;
}
.clip_item.flip .clip_txtbx {
  padding: 0.25em 0.75em;
}
.clip_item.flip .clip_imgbx {
  display: flex;
  justify-content: center;
  /*clip_img_image_area*/
  /*clip_img_image_area, clip_canvas_area*/
}
.clip_item.flip .clip_imgbx .clip_image_area {
  width: 100%;
  max-height: 200px;
  /*clip_img_ImgImage*/
}
.clip_item.flip .clip_imgbx .clip_image_area .clip_ImgImage {
  width: 100%;
  height: 100%;
}
.clip_item.flip .clip_imgbx .clip_image_area, .clip_item.flip .clip_imgbx .clip_canvas_area {
  display: flex;
  justify-content: center;
}
.clip_item.flip .clip_imgbx .clip_image_area img, .clip_item.flip .clip_imgbx .clip_image_area canvas, .clip_item.flip .clip_imgbx .clip_canvas_area img, .clip_item.flip .clip_imgbx .clip_canvas_area canvas {
  object-fit: cover;
  /* i need a flex height and width the accomodate css order: property*/
  /*min-height: 100px;
  max-height: 100px;*/
}

/*clip_item*/
.clip_wrapper {
  cursor: pointer;
  height: 100%;
  padding: 1em;
  gap: 0.75em;
  margin: 0em 0em 0.6em 0em;
  width: 100%;
}
.clip_wrapper.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.clip_wrapper .clip_item {
  transition: width 500ms ease-out;
}
.clip_wrapper .clip_zoom {
  position: absolute;
  font-size: 1.3em;
  padding: 0.5em;
  right: 0.5em;
  transition: bottom 500ms ease-out;
}
.clip_wrapper .clip_zoom.left {
  right: unset;
  left: 0.5em;
}
.clip_wrapper.out {
  display: grid;
  grid-template-areas: "caption" "hr" "clip";
}
.clip_wrapper.out .clip_caption_wrap {
  display: grid;
  grid-template-areas: "profile cap close";
  grid-template-columns: 0.6fr 4fr 0.4fr;
  gap: 0.25em;
}
.clip_wrapper.out .clip_caption_wrap .clip_profile {
  grid-area: profile;
}
.clip_wrapper.out .clip_caption_wrap .clip_profile .image_area, .clip_wrapper.out .clip_caption_wrap .clip_profile .canvas_area {
  height: 2.5em;
  width: 2.5em;
}
.clip_wrapper.out .clip_caption_wrap .clip_profile .image_area img, .clip_wrapper.out .clip_caption_wrap .clip_profile .image_area canvas, .clip_wrapper.out .clip_caption_wrap .clip_profile .canvas_area img, .clip_wrapper.out .clip_caption_wrap .clip_profile .canvas_area canvas {
  width: 100%;
  height: 100%;
  border: unset;
}
.clip_wrapper.out .clip_caption_wrap .clip_caption {
  font-size: 1em;
  grid-area: cap;
  line-height: 1.5;
  height: 3em;
  padding-left: 0.25em;
}
.clip_wrapper.out .clip_hr {
  margin: unset;
  grid-area: hr;
}
.clip_wrapper.out .clip_item {
  grid-area: clip;
  width: unset;
  height: 100%;
  aspect-ratio: 4/3;
  font-size: 0.6em;
  justify-self: center;
  align-self: center;
  margin: unset;
  padding: unset;
  padding-bottom: 0.5em;
}
.clip_wrapper.out .clip_zoom {
  bottom: 0.5em;
}
.clip_wrapper.in {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;
  transition: font-size 500ms ease-out;
}
.clip_wrapper.in .clip_caption_wrap, .clip_wrapper.in .clip_hr {
  display: none;
}
.clip_wrapper.in .clip_item {
  margin: unset;
}
.clip_wrapper.in .clip_zoom {
  /*display: none;*/
  bottom: 50%;
}
.clip_wrapper .clip_remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8em !important;
  width: 1.8em !important;
  text-shadow: 2px 2px 2px #ccc;
  position: absolute;
  top: 1em;
  right: 1em;
}

.clip_modal_cont {
  display: none;
  height: 100%;
}

.clip_item.text_only .clip_remove, .clip_item .clip_remove {
  color: #545454;
  padding: 0.3em;
  text-shadow: 2px 2px 2px #ccc;
  border-width: thin !important;
}