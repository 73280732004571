.my_a_wrap .my_info_icon_cont .pending.my_info_icon:before, .my_a_wrap .my_info_icon_cont .pending.my_info_icon {
  /*color: red;*/
}
.my_a_wrap .my_info_icon_cont .active.my_info_icon:before, .my_a_wrap .my_info_icon_cont .active.my_info_icon {
  background-color: var(--status-active);
}
.my_a_wrap .my_info_icon_cont .stop.my_info_icon:before, .my_a_wrap .my_info_icon_cont .stop.my_info_icon {
  background-color: var(--status-stop);
}
.my_a_wrap .my_info_icon_cont .done.my_info_icon:before, .my_a_wrap .my_info_icon_cont .done.my_info_icon {
  background-color: var(--status-done);
}

.my_a_wrap {
  display: grid;
  grid-gap: 0.25rem;
}
.my_a_wrap.expanded {
  grid-template-areas: "icon text alt btn" "icon caption review review";
  grid-template-columns: 1fr 3fr 0.5fr minmax(2rem, auto);
}
.my_a_wrap .my_info_icon_cont {
  grid-area: icon;
}
.my_a_wrap .my_info_text {
  grid-area: text;
  align-self: center;
}
.my_a_wrap .my_info_options {
  grid-area: btn;
}
.my_a_wrap .chk_show_topics {
  grid-area: alt;
  justify-self: center;
  align-self: center;
}
.my_a_wrap .review_rate_cont {
  grid-area: review;
  justify-content: center;
}
.my_a_wrap .review_rate_cont .rate_btn {
  margin: unset !important;
  font-size: 0.8rem;
}
.my_a_wrap .review_rate_cont .rate_btn:before {
  color: #ccc;
}
.my_a_wrap.expanded .my_info_options {
  justify-self: center;
  align-self: self-start;
  margin-top: 1rem !important;
}
.my_a_wrap.minified .review_rate_cont {
  display: none !important;
}

.my_a_wrap {
  padding: 0px !important;
  background-color: transparent !important;
  width: 100%;
  border: none;
  text-align: left !important;
}

.my_a_wrap:after {
  background-color: transparent !important;
  background-image: none !important;
}

.my_a_wrap {
  color: #333 !important;
  /*text-shadow:none $mp;*/
  text-shadow: 0 1px 0 #f3f3f3 !important;
  border-top: thin solid rgba(204, 204, 204, 0.53) !important;
  /*modified for mobile*/
  touch-action: auto !important;
  /*touch-action: pan-y;*/
  /*pan-y restricts x movement in snapper*/
}

.my_info_icon_cont {
  float: left;
  max-width: 95%;
}

.my_info_icon_cont.expanded {
  display: inline-flex;
  position: relative;
  /*width:20%;*/
  /*margin-bottom: 10px;*/
  height: fit-content;
  min-height: 4rem;
  min-width: 4rem;
}

div.my_info_icon.expanded {
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 12px !important;
  /*width:15px $mp; */
  /*height:15px $mp; */
  width: unset !important;
  height: unset !important;
}
div.my_info_icon.expanded.big-badge {
  position: relative;
  font-size: 25px !important;
  bottom: unset;
  right: unset;
}
div.my_info_icon.expanded.big-badge:before {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px !important;
}

div.my_info_icon.expanded:before {
  width: 19px !important;
  height: 19px !important;
  line-height: 19px !important;
}

div.my_info_icon.expanded:after {
  width: 20px !important;
  height: 20px !important;
  background-size: 20px;
}

.my_info_text {
  margin: 2% !important;
  max-width: 90%;
  line-height: 1.2;
  font-size: 1em !important;
  /*prevent selecting : works on mobile*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
  display: block;
  margin: 0.6em 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.my_info_text.minified {
  max-width: 75%;
}

.my_info.my_info_text {
  display: inline-block;
  min-height: 20px;
}

.my_info_text.expanded {
  white-space: normal !important;
  /*width: 62% $mp;*/
  margin-left: 10px;
}

.my_info_caption_wrap {
  grid-area: caption;
}
.my_info_caption_wrap hr {
  margin: unset;
  border-color: #e3e3e3;
}
.my_info_caption_wrap.minified {
  display: none !important;
}
.my_info_caption_wrap .my_info_caption {
  margin: unset;
  font-size: 0.9rem;
  line-height: 1rem !important;
  height: 1.4rem;
  color: #aaa9a9;
  padding: 0.25rem;
  white-space: normal !important;
}

.my_info_btn {
  background: unset;
}

.li_btn {
  border: none !important;
  height: 25px !important;
  border-radius: 0 !important;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3;
  font-size: 16px;
  padding: 0;
  text-indent: -9999px;
  white-space: nowrap !important;
  display: block;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 700;
  line-height: 1.3;
  font-family: sans-serif;
}

div.li_btn {
  float: right;
  font-size: 1.3rem;
  margin: 2% !important;
  background-color: transparent !important;
}

/*.my_info_edit,.my_info_delete,*/
.li_btn:after {
  background-color: transparent !important;
  top: 50%;
  content: "";
  position: absolute;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
}

.li_btn:before {
  color: #7e7e7e;
}

.li_opt_btn {
  border-color: #ddd;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3;
  font-size: 16px;
  padding: 0;
  box-sizing: content-box;
  -webkit-appearance: none;
  text-indent: -9999px;
  white-space: nowrap !important;
  display: block;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
  text-decoration: none !important;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  line-height: 1.3;
  font-family: sans-serif;
}

.li_opt_btn:after {
  top: 50%;
  content: "";
  position: absolute;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  background-position: center center;
  background-repeat: no-repeat;
}

.d3-checkbox {
  position: relative;
  margin: 0px !important;
}
.d3-checkbox input {
  position: absolute;
  top: 1rem;
  width: 22px;
  height: 22px;
  margin: -11px 0 0 0;
  outline: 0 !important;
  z-index: 1;
}

input.arc_info_chk {
  margin: 0px;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  cursor: pointer;
}

div.arc_info_chk_TCont {
  height: 22px;
  width: 22px;
  float: right;
  margin: 5px 10px 5px 0;
}

.arc_info_chk_TCont.unpublished {
  border: 1px solid #e8dddd;
  background-color: #fdebeb;
  border-radius: 5px;
}

input.arc_info_chk:disabled {
  height: 22px !important;
  width: 22px !important;
  position: relative !important;
}