$mp: !important;

$wW: 12px; // wheel width
%color-wheel {
  border-radius: 50%;
  border: $wW solid black;
  border-top: $wW solid blue;
  border-right: $wW solid yellow;
  border-bottom: $wW solid red;
  width: $wW;
  height: $wW;
  cursor: pointer;
}// color-wheel

%active-wheel{
  border: $wW solid black;
  border-top: $wW solid blue;
  border-right: $wW solid yellow;
  border-bottom: $wW solid red;
}

.board_cont {
  display: grid;
  grid-template-rows: auto 1fr;// 80%
  grid-template-areas:
    "head"
    "content";
  border: 1px solid grey;
  height: 100%;
  padding: .5rem;
  gap: .5rem;
  &.boxed{position: relative;}

  .board_header {
    display: grid;
    grid-template-rows: 2rem auto; // 80%
    grid-template-columns: 2rem auto;
    grid-template-areas:
      "title ctrls"
      "options options";
    justify-content: space-between;
    align-items: center;
    grid-area: head;
    padding: 0 .5rem;
    // gap: .5rem;
    color: #545454;

    .board_title {
      width: fit-content;
      grid-area: title;
    }

    .board_ctrl{
      cursor: pointer;
      font-size: 1.2rem;
      &.board_cancel{margin-left: 2rem;}
      &.board_color{
        @extend %color-wheel;
        // &.active
      }// board_color
    }
    .board_ctrls_cont {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      font-size: 1.2rem;
      grid-area: ctrls;
    }
    .board_option_ctrls_cont{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 2rem;
      padding-top: .5rem;
      grid-area: options;

      background-color: #e9ecef;
      padding: .25rem .5rem;
      border-radius: 5px;
    }
  }

  %scroller{
    overflow-x: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    gap: .5rem;
  }

  .board_content {
    display: flex;
    // flex-flow: column nowrap;
    grid-area: content;
    width: 100%;
    // padding: .5rem;
    overflow: hidden;
    &.clean{
      @extend %scroller;
    }
    .taskboard.sort_wrapper{
      display: flex;
      // gap: 1rem;
      height: 100%;
      width: 100%;
      @extend %scroller;
    }
  }
}// board_cont
