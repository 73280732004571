div[class*=modal_content] {
  border: unset !important;
  margin-bottom: unset !important;
}
div[class*=modal_content] div[class*=panel_main] {
  padding: unset !important;
}
div[class*=modal_content] div[class*=panel_main] [class*=panel_main_content] {
  margin-bottom: 1.5rem;
}

.arc_panel_modal.active, .arc_panel.active, .arc_popup_modal.active, .arc_popup.active {
  display: block;
}

.arc_panel {
  display: none;
  /*temporary*/
  width: 95%;
  margin: 0 auto;
}
.arc_panel #arc_innerPanel {
  background: white;
  padding: 1em;
}
.arc_panel .arc_panel_close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
.arc_panel .arc_panel_go {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  right: 3.5rem;
}

#arc_panel_main, #chk_panel_main {
  overflow: visible;
  border-width: 0;
  padding: 1em;
  display: grid;
  grid-template-areas: "header" "content";
  grid-template-columns: 100%;
  grid-template-rows: 7% 92%;
  gap: 0.25rem;
}

.chk_panel_main_content {
  margin-bottom: unset !important;
}

.arc_panel_infoBox {
  height: 20px;
  margin-bottom: 0px;
}

.arc_panel_infoBtn {
  float: right;
  right: -8px;
  width: 1.75em !important;
  margin: 0.5em 0;
}

.arc_panel_infoCont {
  margin-bottom: 10px;
}

.arc_info_close_btn {
  float: right;
  position: relative !important;
  top: -30px !important;
  right: -7px !important;
  margin: 0px !important;
}

.arc_infoBox {
  display: none;
}

.main_arc_info {
  border: 1px solid #ddd;
  padding: 0px 10px;
}

.contact_info_creator {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 7px;
}

/*height:300px;*/
.contact_type_selector {
  float: left;
  height: 300px;
  width: 15%;
  border: 1px solid purple;
  float: left;
}

.arc_header.flexbox, .chk_header.flexbox {
  /*height:100%;*/
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-area: header;
}

.modal_content.core {
  max-height: unset !important;
}
.modal_content.core .snap_cont {
  border: unset;
}
.modal_content.core .snap_ctrls {
  /*padding-top:.25rem;*/
}

.arc_panel_main_content {
  margin-bottom: unset !important;
}

.modal_dialog.core {
  padding: 1rem 0.5rem !important;
  width: 95% !important;
  max-height: none !important;
}

div.modal_dialog.core {
  padding-bottom: 0.25rem !important;
}