  $mp: !important;

  %hide-scroll::-webkit-scrollbar,
  %hide_scroll::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  %hide-scroll,
  %hide_scroll {
    -ms-overflow-style: none;
  }

  .fldr_display{
    // display:none $mp;// needed to offset bootstrap .media
    display: grid $mp;
    grid-template-areas:
        "filter"
        "list";
    grid-template-rows: auto 8fr;
    // display:flex $mp;
    // max-height: 64vh;
    width:100%;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    .filter_wrapper{
      grid-area: filter;
    }
    .ul_display_list{
      grid-area: list;
      height: 100%;
      overflow: hidden;
    }
  }

  .core_vscroller.viewport{
    // overflow-y: auto;
    overscroll-behavior-y: contain;
    .core_vscroller_loader_wrapper {
      display: flex;
      justify-content: center;
      .core_vscroller_loader{
        border-width: .7rem;
        width: 5rem;
        height: 5rem;
      }/*core_vscroller_loader*/
    }/*core_vscroller_loader_wrapper*/
  }

  .list_item_wrapper{
    border: 1px solid transparent;
  }

  .hide-scroll::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  .hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;/*hides in firefox*/
  }
  .fldr_display.show_binder{
    // display:block $mp;// needed to offset bootstrap .media
  }


  ul.ListArea{

  }
  .ul_list{
    // same as ul.ListArea just more specific
    .virtuoso-scroller {
      @extend %hide-scroll;
    }// .virtuoso-scroller
  }// .ul_list
  .ul_display_list {
    width: 100%;
    margin: 0px $mp;
    padding: 0;
    list-style: none;
  }
  .list_item_wrapper{
    display: flex;
    justify-content: center;
    // align-items: center;
    padding: .25rem 0;
    width: 100%;
    height:100px; //85px;
    .info_dot, .d3-dot{margin: 0 5px $mp;}
    &.drop_show, &.sort_show{
      height: 125px;
    }
    &.minified{
      height: 40px;
      padding: unset;
      &.drop_show, &.sort_show{
        height: 80px;
      }
    }
    &.colorize{border: 5px solid green $mp;}
  }
  li.ListArea{
    display: block;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    background-color: #fff;
    // border-color: #ddd;
    border: 1px solid #9e9e9e $mp;
    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    list-style: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:has(.move_data){
      background-color: #c9d8ff;
    }
  }
  .data_list {
    display: flex $mp;
    flex-flow: column;
    justify-content: space-between;
    cursor: pointer;
    padding: 0px $mp;
    border: none;
    width:100%;
    // height:85px;
    &.expanded {
      box-shadow: 1px 1px 5px #ccc;// #5f5858;
      // margin: 5px $mp;
      min-height: 70px;
    }
    &.minified{
      .my_a_wrap{
        display: grid;
        grid-template-areas:"image text options";
        grid-template-columns: 1fr 4fr .5fr;
        .my_info_icon_cont{grid-area:image;}
        .my_info_text{grid-area:text;}
        .my_info_options {grid-area: options;}
      }
    }
    &.asleep{
      display:none $mp;
    }
  }

  .drop_menu, .sort_menu{margin: 0 auto 5px;  width: 97%; display: flex; justify-content: flex-end;
    border-radius:5px; background-color: rgba(140, 143, 144, 0.23);}
  .drop_menu.drop_hide, .sort_menu.sort_hide{display:none;}
  .drop_menu.drop_show, .sort_menu.sort_show{display:flex;}

  .my_info_options.c_less, .my_sort_options.c_less, .drop_menu.c_less, .sort_menu.c_less{display:none;}
  .my_info_options.c_more, .my_sort_options.c_more{display:block;}

  .drop_menu.c_more, .sort_menu.c_more{display:flex;}

  .arc_info_copy{}

  

  @media only screen and (min-width:300px) and (max-width:324px)
  {

  }

  @media only screen and (min-width:320px) and (max-width:479px)
  {
    button.my_a_wrap{border-top: none $mp;}
  }

  @media only screen and (min-width:480px) and (max-width:767px){

  }

  @media only screen and (min-width:768px) and (max-width:991px)
  {

  }

  /* AT LEAST THIS TALL*/
  @media only screen and (min-width:300px){

  }

  @media only screen and (min-width:480px)
  {

  }

  @media only screen and (min-width:768px)
  {

  }

  @media only screen and (min-width:992px)
  {

  }

  @media only screen and (min-width:992px) and (max-width:1999px)
  {

  }
