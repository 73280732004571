$mp: !important;

%loi{
  justify-self: center;
  align-self: flex-start;
  height: 3em;
  width: 3em;
  grid-area: image;
  img{object-fit: cover;}
  .image_area, .canvas_area {
    width: 100%;
    height: 100%;
    border: unset;
    img, canvas{ width: 100%; height: 100%;}
  }// image_area
}

.lookout_wrapper{
  display: grid $mp;
  position: relative;
  grid-template-areas: "image title icon" "image text icon";
  // width: 100%;
  border: 1px solid #ccc;
  // grid-template-rows: 1fr auto;
  grid-template-columns: auto 4fr 1.5rem;
  text-align: unset $mp;
  margin: .25rem;
  padding: .5rem .5rem 1rem;
  gap: 0 1rem;
  // font-size: 1.2em;
  .lookout_title{grid-area: title; margin: unset; align-self: center; /*text-shadow: 2px 2px 2px #ccc;*/ padding-left: .25rem;}
  .lookout_path, .lookout_desc{grid-area: text; margin: unset; color: #ccc; font-size: .9em; padding-left: .25rem;}
  .lookout_type{
    position: absolute; 
    bottom: -.5rem;//.75rem; 
    left: 25px; //37px; 
    grid-area: image;
    // text-shadow: 2px 2px 2px #ccc;
    color: #545454;
    &.big-badge{
      position: relative;
      font-size: 2rem $mp;
      display: flex;
      justify-content: center;
      align-items: center;
      height: -moz-fit-content;
      height: fit-content;
      padding: 0.5rem;
      top: unset $mp;
      bottom: unset $mp;
      right: unset $mp;
      left: unset $mp;
    }
  }
  .lookout_profile{
    @extend %loi;
    .lookout_profile_icon{font-size: 2.3rem;}
  }
  .lookout_icon_cont{
    display: flex;
    flex-flow: column nowrap;
    gap: .5rem;
    grid-area: icon;
  }
  .lookout_icon{
    @extend %loi;
    height: 2em;
    width: 2em;
    grid-area: icon;
  }
  .lookout_img{
    @extend %loi;
    width: 4em;
  }
  .lookout_badge{grid-area: icon;}
  div.priority_badge {
    width: 2em $mp;
    height: 2em $mp;
  }
  &.borderless {
    border: none $mp;
  }
}//lookout_wrapper
