

.TopOp_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  &.hide{display: none;}
  .TopOp_ctrls{
    display:flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
  }// TopOp_ctrls
}// TopOp_wrapper
