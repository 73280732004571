.mdx-embed_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mdx-embed_wrapper .mdx-embed {
  padding: 1rem 0.5rem;
  width: 100%;
}
.mdx-embed_wrapper .mdx-embed iframe {
  width: 100%;
}