$mp: !important;

.note_title_wrap{
  padding: 1rem;
  h3{text-shadow: 2px 2px 2px #ccc;}
  p, h5, h3{ margin: unset;}
  .note_title_cat{color: #9e9e9e;}
  .note_title_date{color: #ccc;}
}
.note_title_hr{border-color: #ccc; margin: unset;}

.tasksForm.task_section{
  padding-top: 1rem;
}