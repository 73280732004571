.ul_list .virtuoso-scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.ul_list .virtuoso-scroller {
  -ms-overflow-style: none;
}

.fldr_display {
  display: grid !important;
  grid-template-areas: "filter" "list";
  grid-template-rows: auto 8fr;
  width: 100%;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}
.fldr_display .filter_wrapper {
  grid-area: filter;
}
.fldr_display .ul_display_list {
  grid-area: list;
  height: 100%;
  overflow: hidden;
}

.core_vscroller.viewport {
  overscroll-behavior-y: contain;
  /*core_vscroller_loader_wrapper*/
}
.core_vscroller.viewport .core_vscroller_loader_wrapper {
  display: flex;
  justify-content: center;
  /*core_vscroller_loader*/
}
.core_vscroller.viewport .core_vscroller_loader_wrapper .core_vscroller_loader {
  border-width: 0.7rem;
  width: 5rem;
  height: 5rem;
}

.list_item_wrapper {
  border: 1px solid transparent;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /*hides in firefox*/
}

.ul_display_list {
  width: 100%;
  margin: 0px !important;
  padding: 0;
  list-style: none;
}

.list_item_wrapper {
  display: flex;
  justify-content: center;
  padding: 0.25rem 0;
  width: 100%;
  height: 100px;
}
.list_item_wrapper .info_dot, .list_item_wrapper .d3-dot {
  margin: 0 5px !important;
}
.list_item_wrapper.drop_show, .list_item_wrapper.sort_show {
  height: 125px;
}
.list_item_wrapper.minified {
  height: 40px;
  padding: unset;
}
.list_item_wrapper.minified.drop_show, .list_item_wrapper.minified.sort_show {
  height: 80px;
}
.list_item_wrapper.colorize {
  border: 5px solid green !important;
}

li.ListArea {
  display: block;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: #fff;
  border: 1px solid #9e9e9e !important;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  list-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
li.ListArea:has(.move_data) {
  background-color: #c9d8ff;
}

.data_list {
  display: flex !important;
  flex-flow: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px !important;
  border: none;
  width: 100%;
}
.data_list.expanded {
  box-shadow: 1px 1px 5px #ccc;
  min-height: 70px;
}
.data_list.minified .my_a_wrap {
  display: grid;
  grid-template-areas: "image text options";
  grid-template-columns: 1fr 4fr 0.5fr;
}
.data_list.minified .my_a_wrap .my_info_icon_cont {
  grid-area: image;
}
.data_list.minified .my_a_wrap .my_info_text {
  grid-area: text;
}
.data_list.minified .my_a_wrap .my_info_options {
  grid-area: options;
}
.data_list.asleep {
  display: none !important;
}

.drop_menu, .sort_menu {
  margin: 0 auto 5px;
  width: 97%;
  display: flex;
  justify-content: flex-end;
  border-radius: 5px;
  background-color: rgba(140, 143, 144, 0.23);
}

.drop_menu.drop_hide, .sort_menu.sort_hide {
  display: none;
}

.drop_menu.drop_show, .sort_menu.sort_show {
  display: flex;
}

.my_info_options.c_less, .my_sort_options.c_less, .drop_menu.c_less, .sort_menu.c_less {
  display: none;
}

.my_info_options.c_more, .my_sort_options.c_more {
  display: block;
}

.drop_menu.c_more, .sort_menu.c_more {
  display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  button.my_a_wrap {
    border-top: none !important;
  }
}
/* AT LEAST THIS TALL*/