.paper_headers {
  height: 100%;
  display: grid;
  top: 0.75rem;
  width: 100%;
  grid-template-areas: "left pages right";
  grid-template-columns: auto minmax(auto, 80%) auto;
}
.paper_headers .paper_pages {
  grid-area: pages;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0.5rem;
  height: 100%;
}
.paper_headers .paper_pages .page_btn {
  height: 2.5rem;
  width: 2rem;
  margin: 0 0.25rem;
  cursor: pointer;
}
.paper_headers .paper_pages .page_btn.blocks {
  background: #ffffffa3;
  height: 100%;
  flex: 1;
}
.paper_headers .paper_pages .page_btn.blocks.active {
  background: #2d2dada3;
}
.paper_headers .paper_pages .page_btn.icons {
  font-size: 2.5rem;
  color: white;
  display: flex;
  justify-content: center;
}
.paper_headers .paper_pages .page_btn.icons.active {
  color: #2d2dad;
}
.paper_headers .paper_pages .page_btn.default {
  background: white;
}
.paper_headers .paper_pages .page_btn.default.active {
  background-color: #2d2dad;
}
.paper_headers .page_turners {
  height: auto;
  background: var(--site-main);
  cursor: pointer;
}
.paper_headers .page_turners.left {
  grid-area: left;
}
.paper_headers .page_turners.right {
  grid-area: right;
}