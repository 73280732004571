.lookout_wrapper .lookout_img, .lookout_wrapper .lookout_icon, .lookout_wrapper .lookout_profile {
  justify-self: center;
  align-self: flex-start;
  height: 3em;
  width: 3em;
  grid-area: image;
}
.lookout_wrapper .lookout_img img, .lookout_wrapper .lookout_icon img, .lookout_wrapper .lookout_profile img {
  object-fit: cover;
}
.lookout_wrapper .lookout_img .image_area, .lookout_wrapper .lookout_icon .image_area, .lookout_wrapper .lookout_profile .image_area, .lookout_wrapper .lookout_img .canvas_area, .lookout_wrapper .lookout_icon .canvas_area, .lookout_wrapper .lookout_profile .canvas_area {
  width: 100%;
  height: 100%;
  border: unset;
}
.lookout_wrapper .lookout_img .image_area img, .lookout_wrapper .lookout_icon .image_area img, .lookout_wrapper .lookout_profile .image_area img, .lookout_wrapper .lookout_img .image_area canvas, .lookout_wrapper .lookout_icon .image_area canvas, .lookout_wrapper .lookout_profile .image_area canvas, .lookout_wrapper .lookout_img .canvas_area img, .lookout_wrapper .lookout_icon .canvas_area img, .lookout_wrapper .lookout_profile .canvas_area img, .lookout_wrapper .lookout_img .canvas_area canvas, .lookout_wrapper .lookout_icon .canvas_area canvas, .lookout_wrapper .lookout_profile .canvas_area canvas {
  width: 100%;
  height: 100%;
}

.lookout_wrapper {
  display: grid !important;
  position: relative;
  grid-template-areas: "image title icon" "image text icon";
  border: 1px solid #ccc;
  grid-template-columns: auto 4fr 1.5rem;
  text-align: unset !important;
  margin: 0.25rem;
  padding: 0.5rem 0.5rem 1rem;
  gap: 0 1rem;
}
.lookout_wrapper .lookout_title {
  grid-area: title;
  margin: unset;
  align-self: center;
  /*text-shadow: 2px 2px 2px #ccc;*/
  padding-left: 0.25rem;
}
.lookout_wrapper .lookout_path, .lookout_wrapper .lookout_desc {
  grid-area: text;
  margin: unset;
  color: #ccc;
  font-size: 0.9em;
  padding-left: 0.25rem;
}
.lookout_wrapper .lookout_type {
  position: absolute;
  bottom: -0.5rem;
  left: 25px;
  grid-area: image;
  color: #545454;
}
.lookout_wrapper .lookout_type.big-badge {
  position: relative;
  font-size: 2rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5rem;
  top: unset !important;
  bottom: unset !important;
  right: unset !important;
  left: unset !important;
}
.lookout_wrapper .lookout_profile .lookout_profile_icon {
  font-size: 2.3rem;
}
.lookout_wrapper .lookout_icon_cont {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  grid-area: icon;
}
.lookout_wrapper .lookout_icon {
  height: 2em;
  width: 2em;
  grid-area: icon;
}
.lookout_wrapper .lookout_img {
  width: 4em;
}
.lookout_wrapper .lookout_badge {
  grid-area: icon;
}
.lookout_wrapper div.priority_badge {
  width: 2em !important;
  height: 2em !important;
}
.lookout_wrapper.borderless {
  border: none !important;
}