$mp: !important;


.list_item_wrapper.skeleton{
  .my_a_wrap{}
  
  &.expanded{
    .my_info_icon_cont{
      position: relative;
      padding: .5rem .25rem;
      height: 4.5rem;
    }
  }
  &.minified{
    .icon_img{display: none;}
    .my_info_options{padding-top: .5rem;}
  }
  .icon_img, .my_info_icon, .my_info_btn{background-color: #ccc;}
  .icon_img{}
}