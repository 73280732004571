$mp: !important;

.lexical_wrapper{
  position: relative;
}
.entry.contentEditable{
  border: 1px solid #ccc;
  padding: 0 0.25rem;
}

.lexical_placeholder{
  position: absolute;
  left: .25rem;
  top: 0px;
}
