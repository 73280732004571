/**
 *
*/
/*
// IMPORTANT: the cascade for tiny and mobile work in the opposite the smaller must be last
// to override the larger

  @include mobile{ gap: 6%; }
  @include tiny{ gap: 4%;}
*/
/*DOCS: mobile first setup "min" 
* IMPORTANT - mobile first items have to cascade in a specific order from small to large otherwise
* ISSUE the smaller queries cancel the larger ones out
* keep the same order as below
* GOTCHA: NOTE MOBILE && TINY ARE NOT IN THE SAME FLOW AS EVERYTHING ELSE - WEARABLE IS THE BOTTOM OF THE MULTI DEVICE FLOW
*/
/**
  * NOTE: this is a mobile first setup.
  */
/**
    usage:

    @use "../../scss/media";
    @include media.mobile{
      padding: 0 .25rem;
      font-size: 1.5rem;
    }

  */
.toaster_home {
  position: fixed;
  bottom: 0;
  width: 100vw;
  margin: 0 auto;
  padding: 0.5rem;
}
.toaster_home .d3-toast, .toaster_home .d3_toast {
  display: flex;
  justify-content: space-around;
  background-color: cornsilk;
  padding: 0.5rem;
  margin: 0 auto;
}
.toaster_home .d3-toast.danger, .toaster_home .d3-toast.fail, .toaster_home .d3-toast.stop, .toaster_home .d3_toast.danger, .toaster_home .d3_toast.fail, .toaster_home .d3_toast.stop {
  background-color: salmon;
  color: white;
}
.toaster_home .d3-toast.success, .toaster_home .d3-toast.go, .toaster_home .d3_toast.success, .toaster_home .d3_toast.go {
  background-color: lightgreen;
  color: #000;
}
.toaster_home .d3-toast.hide, .toaster_home .d3_toast.hide {
  display: none !important;
}
.toaster_home .d3-toast .toast_close, .toaster_home .d3_toast .toast_close {
  right: -10px;
  top: -10px !important;
}
.toaster_home .d3-toast p, .toaster_home .d3_toast p {
  margin: unset;
  word-break: break-word;
  /*helps in p tag word wrapping*/
  flex: 0 1 80% !important;
}
.toaster_home .d3-toast a, .toaster_home .d3_toast a {
  color: var(--active-blue) !important;
  cursor: pointer;
}
@media only screen and (max-width: 400px) {
  .toaster_home .d3-toast, .toaster_home .d3_toast {
    max-width: 30rem;
  }
}
@media only screen and (max-width: 480px) {
  .toaster_home .d3-toast, .toaster_home .d3_toast {
    max-width: 30rem;
  }
}

.contact_info_creator {
  position: relative;
}

.message_display_span {
  bottom: 0;
  position: absolute;
  width: 100%;
  /*toast_cont*/
}
.message_display_span .toast_cont {
  display: grid;
  grid-template-areas: "img txt .";
  background: #eee;
  grid-template-columns: 1fr 7fr 1fr;
  padding: 0px 10px;
  border-radius: 0 0 7px 7px;
  border-top: 1px solid #ccc !important;
  cursor: pointer;
  /*border: thin solid #ccc;
  border-top:unset !important;*/
}
.message_display_span .toast_cont img {
  grid-area: img;
  width: 2rem;
}
.message_display_span .toast_cont p {
  margin: 0;
  grid-area: txt;
  text-align: center;
  padding: 5px 10px;
}

/*message_display_span*/