.iWall_modal, .flavor_ctrls_iWall_modal {
  margin: 0 auto 30px !important;
}
.iWall_modal .iWall_content, .iWall_modal .flavor_iWall_content, .flavor_ctrls_iWall_modal .iWall_content, .flavor_ctrls_iWall_modal .flavor_iWall_content {
  border: unset !important;
}
.iWall_modal .iWall_content, .flavor_ctrls_iWall_modal .iWall_content {
  max-height: unset;
  height: 80vh !important;
}

/*iWall_modal*/
.iconWall_wrapper {
  display: grid;
  height: 100%;
  grid-template-areas: "header" "content";
  grid-template-rows: auto 8fr;
}
.iconWall_wrapper .iconWall_filter_wrap, .iconWall_wrapper .iconwall_title {
  grid-area: header;
}
.iconWall_wrapper .iconwall_title {
  text-shadow: 2px 2px 2px #ccc;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.iconWall_wrapper .iconWall_contents {
  grid-area: content;
  height: 100%;
  overflow-y: auto;
}
.iconWall_wrapper .iconWall_cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.25rem;
}
.iconWall_wrapper .iconWall_cont.double {
  display: flex;
  justify-content: space-around;
}
.iconWall_wrapper .iconWall_cont.double div {
  flex: 1 50%;
}
.iconWall_wrapper .iconWall_cont.single {
  display: flex;
  justify-content: center;
}
.iconWall_wrapper .iconWall_cont label {
  width: 100%;
  text-align: center;
}
.iconWall_wrapper .iconWall_cont.grid_4 {
  grid-template-columns: repeat(4, 1fr);
}
.iconWall_wrapper .iconWall_cont .iconWall_btn {
  border: 1px solid #ccc;
  text-align: center;
  color: #9a9783;
  height: 9rem;
  line-height: 130px;
  font-size: 5rem;
  cursor: pointer;
}
.iconWall_wrapper .iconWall_cont .iconWall_btn.active {
  background-color: #2d2dad;
  color: white;
}
.iconWall_wrapper .iconWall_cont .iconWall_btn_wrapper.labels {
  border: 1px solid #ccc;
}
.iconWall_wrapper .iconWall_cont .iconWall_btn_wrapper.labels.active {
  background-color: #2d2dad;
  color: white;
}
.iconWall_wrapper .iconWall_cont .iconWall_btn_wrapper.labels.active .iconWall_btn {
  color: white;
}
.iconWall_wrapper .iconWall_cont .iconWall_btn_wrapper.labels .iconWall_btn {
  border: unset !important;
  height: 6rem;
  line-height: 6.5rem;
}

/*iconWall_wrapper*/
.iconWall_wrapper.inline .iconWall_cont {
  display: flex;
  grid-gap: 0.25rem;
  padding: 0 0.5rem;
}
.iconWall_wrapper.inline .iconWall_cont .iconWall_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  text-align: center;
  color: #9a9783;
  height: 1.7rem;
  line-height: 1.7rem;
  font-size: 1.3rem;
  cursor: pointer;
}
.iconWall_wrapper.inline .iconWall_cont .iconWall_btn.active {
  background-color: #2d2dad;
  color: white;
}

.iconWall_filter_wrap {
  display: flex;
  width: 100%;
  overflow: auto;
}
.iconWall_filter_wrap .iconWall_filter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25rem;
  gap: 0.5rem;
  grid-area: nav;
}
.iconWall_filter_wrap .iconWall_filter .iconWall_filter_ctrls {
  padding: 0 0.5rem;
}
.iconWall_filter_wrap .iconWall_filter .iconWall_filter_ctrls.active {
  background-color: var(--active-blue) !important;
  color: white;
}
.iconWall_filter_wrap .iconWall_filter .iconWall_filter_ctrls:focus {
  outline: none;
}