  $mp:!important;

  // .arc_details_cont.core{
  //   z-index: 1010 $mp;
  //   display:none;
  // }
  // .block{
  //   display: block $mp;
  // }
  // .details_modal.detail{
  //   margin-top:2rem;
  //   max-height: 88vh;
  //   .details_content{
  //     max-height: 80vh;
  //     overflow-y: auto;
  //     border: 1px solid #ddd;
  //     margin-bottom: 10px;
  //     min-height: 200px;
  //   }
  // }
  .modal_cont.core{
    z-index: 1010;//  $mp
    // display: block;// why do i need this?
    // display:none;
  }
  .block{
    display: block $mp;
  }

  .resize_canvas_modal_cont, .edit_canvas_modal_cont{
    z-index: 1050 $mp;
  }
  .resize_canvas_goBtn, .edit_canvas_goBtn{
    background-image: linear-gradient(to bottom, #58c547, #0F8A09) !important;
    color: #fff !important;
  }
  .modal_ctrls{
    border-radius: 6px; border: 1px solid #ccc;
  }
  .modal_temp_cont{
    z-index: 3000;
  }
