.VirtualScroll.viewport {
  padding-bottom: 2rem;
  min-height: 300px;
  width: 100%;
  overscroll-behavior-y: contain;
  /*visible_area*/
}
.VirtualScroll.viewport .visible_area {
  width: 100%;
  padding: 0 0.25rem;
  /*inner_content*/
}
.VirtualScroll.viewport .visible_area .inner_content {
  width: 100%;
}

/*viewport*/