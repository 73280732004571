.resize_canvas_resize_cont {
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem 2.5rem 2.5rem;
  /*resize_canvas_resize_ctrls*/
  /*import_cont*/
  /*resize_canvas_resize_header*/
}
.resize_canvas_resize_cont .resize_canvas_resize_ctrls {
  widows: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 1rem;
  /*resize_modes*/
}
.resize_canvas_resize_cont .resize_canvas_resize_ctrls .resize_modes {
  border: 1px solid #ccc;
  height: 30px;
  flex: 1;
  margin: 2px 3px !important;
  border-radius: unset;
}
.resize_canvas_resize_cont .import_cont {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0 0;
}
.resize_canvas_resize_cont .import_cont label {
  margin-right: 10px;
  width: 20%;
}
.resize_canvas_resize_cont .import_cont input {
  width: 70%;
  padding: 0 10px;
}
.resize_canvas_resize_cont .column {
  border-color: none;
}
.resize_canvas_resize_cont .resize_canvas_resize_header h1, .resize_canvas_resize_cont .resize_canvas_resize_header h2, .resize_canvas_resize_cont .resize_canvas_resize_header h3, .resize_canvas_resize_cont .resize_canvas_resize_header h4, .resize_canvas_resize_cont .resize_canvas_resize_header h5, .resize_canvas_resize_cont .resize_canvas_resize_header h6, .resize_canvas_resize_cont .resize_canvas_resize_header hr {
  margin: 5px 0;
}
.resize_canvas_resize_cont .resize_canvas_resize_header label {
  margin-right: 1rem;
  margin-bottom: 0;
}
.resize_canvas_resize_cont .resize_canvas_resize_restrict_btn {
  position: absolute;
  right: 2rem;
  top: 13.5rem;
}

/*resize_canvas_resize_cont*/