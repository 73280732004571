$mp:  !important;

.filter_modal_cont {
  // z-index: 100;
  position: relative;
}

.filter_select_modal{
  height: fit-content;
}

.filter_select_lightbox,
.filter_select_content {

  /*z-index: 1200;*/
  .glassHouse {
    width: 90vw;
  }

  .filter_select_glassHouseWrapper {
    padding: 10px;
    border: thin solid #ccc;
  }

  .filter_select_glass_title {
    max-height: unset $mp;
    font-size: 18px;
    padding-left: 1rem;
    margin: .5rem 0 0;
  }

  .filter_select_glass_header {
    padding: .5rem 1rem;
    font-size: .9rem;
  }

  .filter_select_glass_content {
    margin: 10px 0;
  }

  .filter_select_checks{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0.5rem;
    .ckmt_wrap{ display: flex;}
  }

  .arc_filterBtn_groupCont,
  .chk_filterBtn_groupCont,
  .arc_filterBtn_btnGrpCont,
  .chk_filterBtn_btnGrpCont {
    display: grid;
    grid-row-gap: .25rem;

    .arc_filterBtn,
    .chk_filterBtn {
      background: #eee;
      border-radius: 0px;
      text-align: left;
    }
  }

  .filter_select_glass_cancel {
    width: 100% $mp;
    margin: 0 $mp;
    height: 40px;
    border-color: #ccc $mp;
    background-image: unset $mp;
    border: 2px solid #ccc !important;

    * {
      font-weight: 500;
      margin: 0;
    }
  }
}

.filterBtn.active_filter {
  background-color: #0039A8 $mp;
  color: #fff;
  touch-action: auto $mp;
}


button.filterBtn:focus {
  background-color: #0F52C1 $mp;
  color: #fff;
  touch-action: auto $mp;
}

button.filterBtn:active {
  /*this doesn't seem to work at all*/
  // background-color: red $mp;
  background-color: #0F52C1 $mp;
  color: #fff;
  touch-action: auto $mp;
}

button.filterBtn:visited {
  /*this doesn't seem to work at all*/
  background-color: #0039A8 $mp;
  color: #fff;
  touch-action: auto $mp;
}