/*
// IMPORTANT: the cascade for tiny and mobile work in the opposite the smaller must be last
// to override the larger

  @include mobile{ gap: 6%; }
  @include tiny{ gap: 4%;}
*/
/*DOCS: mobile first setup "min" 
* IMPORTANT - mobile first items have to cascade in a specific order from small to large otherwise
* ISSUE the smaller queries cancel the larger ones out
* keep the same order as below
* GOTCHA: NOTE MOBILE && TINY ARE NOT IN THE SAME FLOW AS EVERYTHING ELSE - WEARABLE IS THE BOTTOM OF THE MULTI DEVICE FLOW
*/
/**
  * NOTE: this is a mobile first setup.
  */
/**
    usage:

    @use "../../scss/media";
    @include media.mobile{
      padding: 0 .25rem;
      font-size: 1.5rem;
    }

  */
.calendar_cont .fc-view-harness .fc-listMonth-view .fc-list-event-title a {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: block !important;
  display: -webkit-box !important;
  line-height: 1.5;
}

.calendar_cont .fc-view-harness .fc-listMonth-view .fc-list-event-title a {
  -webkit-line-clamp: 3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.calendar_cont .fc-view-harness .fc-listMonth-view .fc-scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.calendar_cont .fc-view-harness .fc-listMonth-view .fc-scroller {
  -ms-overflow-style: none;
}

.calendar_cont {
  padding: 1rem;
  height: 100%;
  overflow: auto;
}
.calendar_cont.foot_view .fc {
  height: 100%;
}
.calendar_cont.foot_view .fc .fc-header-toolbar {
  margin-bottom: 1rem;
  gap: unset;
}
.calendar_cont.foot_view .fc .fc-toolbar-chunk:last-child {
  grid-area: nav;
}
.calendar_cont.foot_view .fc .fc-toolbar-chunk:last-child .fc-button-group {
  width: unset;
}
.calendar_cont.foot_view .fc .fc-footer-toolbar {
  flex-flow: column nowrap;
  margin-top: 1rem;
}
.calendar_cont.foot_view .fc .fc-footer-toolbar .fc-toolbar-chunk {
  width: 100%;
}
.calendar_cont.foot_view .fc .fc-footer-toolbar .fc-toolbar-chunk .fc-button-group {
  width: 100%;
}
.calendar_cont .fc-view-harness .fc-listMonth-view .fc-list-event-time {
  width: 20%;
  white-space: unset !important;
}
.calendar_cont .fc-view-harness .fc-listMonth-view .fc-list-event-graphic {
  padding: unset;
}
.calendar_cont .fc-col-header-cell {
  font-size: 0.8em;
}
@media only screen and (max-width: 480px) {
  .calendar_cont .fc-col-header-cell {
    font-size: 0.6em;
  }
}
.calendar_cont .fc-header-toolbar {
  display: grid;
  grid-template-areas: "title title" "nav nav";
  gap: 0.5rem 0;
}
.calendar_cont .fc-header-toolbar .fc-toolbar-title {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 400px) {
  .calendar_cont .fc-header-toolbar .fc-toolbar-title {
    font-size: 1rem;
  }
}
.calendar_cont .fc-header-toolbar .fc-toolbar-chunk:first-child {
  grid-area: title;
}
.calendar_cont .fc-header-toolbar .fc-toolbar-chunk {
  grid-area: nav;
}
.calendar_cont .fc-header-toolbar .fc-toolbar-chunk:last-child {
  grid-area: time;
  display: flex;
  justify-content: center;
}
.calendar_cont .fc-header-toolbar .fc-toolbar-chunk:last-child button {
  flex: 1;
}
.calendar_cont .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group {
  width: 100%;
  flex: 1;
}
@media only screen and (max-width: 400px) {
  .calendar_cont .fc-header-toolbar .fc-toolbar-chunk:last-child {
    flex-flow: column nowrap;
    justify-content: flex-end;
  }
  .calendar_cont .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group {
    margin-left: unset;
  }
}
.calendar_cont .fc-more-popover {
  top: 25% !important;
  left: 0 !important;
  width: 100%;
}