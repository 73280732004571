$mp: !important;
@import '../../../../css/media';

:root{
  // --active-blue: #2d2dad;
  --disabled-grey: #9e9e9e;
}

%active2{border: 2px solid var(--active-blue) $mp;}

.qrc_view_modal_cont, .paperPopupDialog {
    // z-index: 10;
    padding-top: 1em $mp;
    font-size: 1rem;
    .qrc_view_modal{ 
      max-height: unset; //90vh; 
      min-height: 38rem;
      padding-bottom:.5em;
      margin: 0 auto;
      @include mobile{
        width: 80%;
      }// mobile
    }// overflow-y: auto; overflow-x: hidden;
    
    .closeBtn{
      display: flex;
      justify-content: center;
      align-self: center;
      font-size: 1.3rem;
      height: 1.7em $mp;
      width: 1.7em $mp;
      top: -1em $mp;
      right: -.5em $mp;
      padding: unset;
      &:before{
        height: 1.4em $mp;
        width: 1.4em $mp;
        line-height: 1.4em;
      }// :after
    }// closeBtn
  }// qrc_view_modal_cont

  .qrc_view_content {
      // height: 32em;// i don't think this needs a height
      border: unset;
      margin-bottom: unset;
      .qr_sponsor, .qr_counselor, .qr_copy, .qr_share{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5em 1em;
        width: unset;
        border-radius: 7px;
        border: 1px solid #ccc;
      }//
      .qr_sponsor{
        grid-area: sponsor; 
        height: 3em;
        flex: 1 auto;
      }
      .qr_counselor{grid-area: counselor; height: 3em;}
      .qr_code_wrapper{
        max-width: 90vw;
        height: 100%;
        display: grid;
        grid-template-areas:"title""content";
        grid-template-rows: auto 1fr;//1fr 3fr .25fr auto 2fr;
        grid-template-columns: 100%;
        .qr_content{
          display: grid;
          grid-template-areas:"canvas""hr""ctrls""url";
          grid-template-rows: auto 0.25fr auto auto;
          grid-template-columns: 100%;
          padding: 1rem 0;
          canvas, .qr_load { 
            justify-self: center;
            align-self: center;
            grid-area: canvas;
          }// canvas
          hr{grid-area: hr; margin: 1rem 0 0;}
          .qr_code_ctrls{
            grid-area: ctrls; 
            display: flex;
            justify-content: space-around;
            margin: 1em 0 0;
            gap: 0.5rem;
            padding: 0 1rem;
            flex-flow: row wrap;
            .qr_btn{
              &.active{@extend %active2;}
            }
            
          }
        }// qr_content
        .qr_embed{margin: 1rem auto; min-height: unset;}
        .snapBox {
          grid-area: content;
          .snap_cont{border: unset $mp;}
        }
      }//qr_code_wrapper
      .qr_text_url_cont{
        grid-area: url; 
        padding: 1em;
        display: grid;
        grid-template-areas:"text text""copy share" "visit visit";
        grid-template-columns: 5fr auto;
        gap: .5em;
        .qr_text_url{
          grid-area: text; 
          height: 2.5em;
          border: 1px solid #ccc;
          padding: 5px .5em;
          background: var(--disabled-grey) $mp;
          color: white;
        }// qr_text_url
        .qr_copy{
          grid-area: copy; gap: 1rem;
          .pp_panelBtn {font-size: 2rem;}
        }// qr_copy
        .qr_share{ font-size: 1.4rem; cursor: pointer;}
        .qr_visit{grid-area: visit; padding: 0.5rem 0; color: blue;}
      }// qr_text_url_cont
      .qr_title_cont{
        height: fit-content;
        p{
          margin: unset;
          padding: 0 1em;
          color: #9e9e9e;
          line-height: 1.3;
          margin-bottom: 1em;
        }// p
        label{
          text-shadow: 2px 2px 2px #ccc;
          font-size: 1.3em;
              padding: .5em 1em 0;
        }// label
      }
      .snap_ctrls_main{ padding: .25em;}// snap_ctrls_main
      .snap_btn.snap_core{
        width: 1.75rem !important;
        margin: 5px;
        flex: 0 0 1.75rem;
        height: 1.75rem !important;
        background-color: unset $mp;
        border-color: #ddd;
      }// snap_btn
    }// qrc_view_content 


  // .qrc_modal_cont.qr_details{
  //   .qr_title_cont{
  //     label{text-shadow: 2px 2px 2px #ccc; font-size: 1rem;}
  //     p{color: black; font-size: 1.3rem;}
  //   }
  // }
  .qrc_modal_cont {
    .snap_section{max-height: unset/*48vh*/;}
  }