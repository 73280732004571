  $mp: !important;
  %status{
    &.pending{/*color: red;*/}// no color change
    &.active{background-color: var(--status-active);}
    &.stop{background-color: var(--status-stop);}
    &.done{background-color: var(--status-done);}
  }


  .my_a_wrap{ display: grid;
    &.expanded{
      grid-template-areas:"icon text alt btn" "icon caption review review";
      // grid-template-columns: 1fr 3fr .5fr .5fr;
      grid-template-columns: 1fr 3fr .5fr minmax(2rem, auto);
    }
    // grid-template-rows: 50% 30%;
    // also see list.scss - .list_item_wrapper
    grid-gap: .25rem;
    .my_info_icon_cont{
      grid-area: icon;
      .my_info_icon{
        @extend %status;
        &:before{
          @extend %status;
        }
      }
    }
    .my_info_text{grid-area: text; align-self: center;}
    .my_info_options {grid-area: btn;}
    .chk_show_topics {
      grid-area: alt;
      justify-self: center;
      align-self: center;
    }
    .review_rate_cont{
      grid-area: review;
      justify-content: center;
      .rate_btn{
        margin: unset $mp;
        font-size: .8rem;
        &:before{
          color: #ccc;
        }
      }
    }
    &.expanded{
      .my_info_options {justify-self: center; align-self: self-start; margin-top: 1rem !important;}
    }
    &.minified{
      .review_rate_cont{display:none $mp;}
    }
  }
  .my_a_wrap{padding:0px $mp;background-color: transparent $mp;width:100%;border:none;text-align:left $mp;}
  .my_a_wrap:after{background-color: transparent $mp;background-image: none $mp;}
  .my_a_wrap{
    color:#333 $mp;/*text-shadow:none $mp;*/text-shadow: 0 1px 0 #f3f3f3 $mp;
    border-top: thin solid rgba(204, 204, 204, 0.53) $mp;/*modified for mobile*/
    touch-action: auto $mp;/*touch-action: pan-y;*//*pan-y restricts x movement in snapper*/
  }

  .my_info_icon_cont{float:left;max-width: 95%;}
  .my_info_icon_cont.expanded{
    display:inline-flex; 
    position:relative; 
    /*width:20%;*/ /*margin-bottom: 10px;*/
    height: fit-content;
    min-height: 4rem;// WORKS - trying to stop opening details before items are ready
    min-width: 4rem;
    // background-color: grey;
  }
  div.my_info_icon.expanded{
    position:absolute;
    bottom:-10px;
    right:-10px;
    font-size: 12px $mp;
    /*width:15px $mp; */
    /*height:15px $mp; */
    width:unset $mp;
    height:unset $mp;
    &.big-badge{
      position: relative;
      font-size: 25px $mp;
      bottom: unset;
      right: unset;
      // padding: .15em;
      &:before{
        width:45px $mp;
        height:45px $mp;
        line-height: 45px $mp;
      }// ::before
    }
  }
  div.my_info_icon.expanded:before{
    width:19px $mp;
    height:19px $mp;
    line-height: 19px $mp;
  }


  div.my_info_icon.expanded:after{width:20px $mp; height:20px $mp; background-size:20px;}
  div.my_info_icon.expanded.stopped,div.my_info_icon.expanded.working,div.my_info_icon.expanded.finished{
    // width: 20px $mp; height: 20px $mp;// legacy styles
  }

  .my_info_text{
    margin:2% $mp;
    max-width: 90%;
    line-height: 1.2;
    &.minified{
      max-width: 75%;
    }
    font-size:1em $mp;
    /*prevent selecting : works on mobile*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    font-weight: 400;
    display: block;
    margin: .6em 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .my_info.my_info_text{display:inline-block;min-height:20px;}
  .my_info_text.expanded{white-space: normal $mp; /*width: 62% $mp;*/ margin-left:10px;}

  .my_info_caption_wrap{
    grid-area: caption;
    hr{margin: unset; border-color: #e3e3e3;}
    &.minified{ display: none $mp;}
    .my_info_caption{
      // display: flex $mp;
      // align-items: center;
      margin: unset;
      font-size: .9rem;
      line-height: 1rem $mp;
      height: 1.4rem;
      color: #aaa9a9;
      padding: .25rem;
      white-space: normal !important;
    }
  }//my_info_caption_wrap

  .my_info_btn{background:unset;}

  .li_btn{
    border:none $mp;
    height:25px $mp;
    border-radius:0 $mp;

    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    font-size: 16px;
    padding: 0;
    text-indent: -9999px;
    white-space: nowrap $mp;
    display: block;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: none$mp;
    font-weight: 700;
    line-height: 1.3;
    font-family: sans-serif;
  }

  div.li_btn{
    float:right;
    // width: 5% $mp;
    font-size: 1.3rem;
    margin: 2% $mp;
    background-color: transparent $mp;
  }/*.my_info_edit,.my_info_delete,*/
  .li_btn:after{
    background-color: transparent $mp;

    top: 50%;
    content: "";
    position: absolute;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .li_btn:before{
    color: #7e7e7e;
  }

  .li_opt_btn{
    border-color: #ddd;
    color: #333;
    text-shadow: 0 1px 0 #f3f3f3;
    font-size: 16px;
    padding: 0;
    box-sizing: content-box;
    -webkit-appearance: none;
    text-indent: -9999px;
    white-space: nowrap$mp;
    display: block;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    cursor: pointer;
    user-select: none;
    text-decoration: none$mp;
    font-weight: 700;
    border-width: 1px;
    border-style: solid;
    line-height: 1.3;
    font-family: sans-serif;
  }
  .li_opt_btn:after{
    top: 50%;
    content: "";
    position: absolute;
    display: block;
    background-color: rgba(0,0,0,.3);
    background-position: center center;
    background-repeat: no-repeat;
  }

  .d3-checkbox {
    position: relative;
    margin: 0px $mp;
    input {
      position: absolute;
      // left: .466em;
      // top: 50%;
      top: 1rem;
      width: 22px;
      height: 22px;
      margin: -11px 0 0 0;
      outline: 0$mp;
      z-index: 1;
    }
  }

  input.arc_info_chk {
      margin: 0px;
      position: relative $mp;
      left: 0 $mp;
      top: 0 $mp;
      cursor: pointer;
  }

  div.arc_info_chk_TCont{height:22px; width:22px;float:right;margin:5px 10px 5px 0;}
  .arc_info_chk_TCont.unpublished{ border:1px solid #e8dddd; background-color: #fdebeb;border-radius:5px;}
  input.arc_info_chk:disabled{height:22px $mp; width:22px $mp; position:relative $mp;}
