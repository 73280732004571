
  $mp: !important;
  $mpl: 1;

  %to-front{
    z-index: 1300 * $mpl + 10 $mp;/*310*/
  }

  
  /*************** 1300 ********************/
.warn_msg.dash{  z-index: 1300 * $mpl + 20 $mp;}
.inform_modal_cont.to-front {
  @extend %to-front;
  & ~ .part_temp_cont > .part_modal_cont, & ~ .img_mkr_cont > .image_form_modal_cont{
    @extend %to-front;
  }
  & ~ .modal_temp_cont{
    z-index: 1300 * $mpl + 15 $mp;/*310*/
    .edit_canvas_modal_cont{@extend %to-front;}
  }
  // [General sibling combinator](https://developer.mozilla.org/en-US/docs/Web/CSS/General_sibling_combinator)
}// .inform_modal_cont.to-front
.ui-header-fixed, .ui-footer-fixed {z-index: 100 * $mpl + 5 $mp;}//300 || 3005
.w3-modal.loading, .toaster_home{ z-index: 1310 * $mpl $mp;}
#pp_sidebar.active{z-index: 1300  * $mpl $mp;}
// .iWall_modal_cont.modal_cont.core, .flav_iconSelect_iWall_modal_cont { z-index: 300  * $mpl $mp;}

/*************** 1000 ********************/
  .warn_msg {z-index: 1235 * $mpl $mp;}
  .share_modal_cont, .qrc_view_modal_cont, .send_modal_cont { z-index: 1210 * $mpl $mp;}
  .paper_icon{z-index: 1200  * $mpl + 5;}// 2150
  .paper_headers{ z-index: 1200 * $mpl $mp;}
  .paper_modal_main{ z-index: 1200 * $mpl; }
  // /*.paper_cont,.clip_modal_cont,*/ .details_modal_cont{z-index: 1200 * $mpl $mp;}// details/page modal

/*************** 200 ********************/
.flavor_ctrls_iWall_modal_cont, .flavorFav_modal_cont{z-index: 235 * $mpl $mp;}
// .share_modal_cont.hld_Share, /*.warn_msg,*/ .flavor_modal_cont {z-index: 235 * $mpl $mp;}// flavor form modal (from bkmks menu and sections menu)
// /*.clip_modal_cont.chk,*/ .details_modal_cont.chk {z-index: 221 * $mpl $mp;}
// .liteBox, .chk_popup_modal_cont{ z-index: 220 * $mpl + 5 $mp;}/*chk_ mode*/
.share_modal_cont.details{ z-index: 215 * $mpl $mp;}

.resize_canvas_modal_cont{z-index: 200 * $mpl + 30 !important;}// z-index same as container (modal_temp_cont) - doesn't need a z-index
.edit_canvas_modal_cont { z-index: 200 * $mpl + 20 !important;}// z-index same as container (modal_temp_cont) - doesn't need a z-index
.modal_temp_cont{
  z-index: 202 * $mpl $mp;
  .edit_canvas_modal_cont {
    z-index: 201 * $mpl $mp;
  }// edit_canvas_modal_cont
}// contains the img editor
.pTree_view_modal_cont{z-index: 200 * $mpl + 5 $mp;}
.inform_modal_cont, .part_modal_cont, .image_form_modal_cont{z-index: 200 * $mpl + 1 $mp;}// 200  * $mpl



/*************** 100 ********************/
  // /*.paper_cont,.clip_modal_cont,*/ .details_modal_cont{z-index: 1200 * $mpl $mp;}// details/page modal

  // .arc_bkmk_Box_modal_cont, .chk_bkmk_Box_modal_cont {z-index:119 * $mpl $mp;}//225 bookmark modal - mixin'd
  .chk_bkmk_Box_lightbox.chk_bkmk_list{z-index:118 * $mpl $mp;}// 221 bookmark modal

  div.li_liteBox_lightbox.arc_list_preview, div.arc_bkmk_Box_lightbox.arc_bkmk_list{ z-index:120 * $mpl $mp;}
  .filter_select_lightbox  { z-index: 120 * $mpl $mp;}

  // .liteBox{ z-index: 1150 $mp;}

  // .arc_panel.ui-panel, .SelectOptionsIWL_modal_cont { z-index:110 * $mpl + 2 $mp;}

  // section modal SelectOptions
  // .select_sections_modal_cont/*.core*/, .stack_modal_cont { z-index: 101 * $mpl $mp;}// .modal_home/*.core*/, - setting this limited its containing modals z-index

  // .arc_panel_modal{z-index: 100 * $mpl + 2 $mp;}
  .arc_popup_modal_cont, .form_ctrls_span{z-index: 100 * $mpl + 1 $mp;}// guest core?

  .navbar.details{
      z-index: unset $mp;
    .pp_panelBtn, .pp_panelHomeBtn {
      // z-index: 1001 $mp;
    }
  }

  // base/core container - you can't really see the modal but its in something (hovers over the page)
  // also the inform form modal container
  .MainCore.arc_popup_modal_cont{
    z-index: 50 * $mpl $mp;
  }

  /**
  * see core_class use cases below below
  * core tag names: MainCore, PortableCore, CheckCore
  */
  @mixin core_class($tag, $lvl) {

    .#{$tag} {

      &.modal_cont { z-index: $lvl + 50 $mp; }
      // NOTE GOTCHA: .paper_modal_main actually controls the deaitls_modal_cont z-index
      /*.paper_cont,.clip_modal_cont,*/ &.details_modal_cont{z-index: $lvl + 70 $mp;}// details/page modal
      
      
      // lvl 1
      &.arc_panel_modal { z-index: $lvl + 100 + 2 $mp; }// Where is this?
      // section modal SelectOptions
      &.select_sections_modal_cont/*.core*/, &.stack_modal_cont, &.filter_select_modal_cont { z-index: $lvl + 101 * $mpl $mp;}// .modal_home/*.core*/, - setting this limited its containing modals z-index
      &.arc_panel.ui-panel, &.SelectOptionsIWL_modal_cont { z-index: $lvl + 110 * $mpl + 2 $mp;}
      &.bkmk_Box_modal_cont {z-index: $lvl + 119 $mp;}//225 bookmark modal display_bookmarks // * $mpl
      // &.arc_bkmk_Box_modal_cont, &.chk_bkmk_Box_modal_cont {z-index: $lvl + 119 $mp;}//225 bookmark modal display_bookmarks // * $mpl
      &.topic_viewer_modal_cont{z-index: $lvl + 120 $mp;}
      
      
      // lvl 2
      &.resize_canvas_modal_cont{z-index: 200 * $mpl + 30 !important;}// z-index same as container (modal_temp_cont) - doesn't need a z-index
      &.edit_canvas_modal_cont { z-index: 200 * $mpl + 20 !important;}// z-index same as container (modal_temp_cont) - doesn't need a z-index
      &.modal_temp_cont{
        z-index: 202 * $mpl $mp;
        .edit_canvas_modal_cont {
          z-index: 201 * $mpl $mp;
        }// edit_canvas_modal_cont
      }// contains the img editor
      &.pTree_view_modal_cont{z-index: 200 * $mpl + 5 $mp;}
      &.inform_modal_cont, &.part_modal_cont, &.image_form_modal_cont{z-index: 200 * $mpl + 1 $mp;}// 200  * $mpl
      
      // &.liteBox, &.chk_popup_modal_cont{ z-index: $lvl + 220 * $mpl + 5 $mp;}
      &.flavor_ctrls_iWall_modal_cont, &.flavorFav_modal_cont{z-index: $lvl + 235 * $mpl $mp;}
      &.share_modal_cont.hld_Share, /*.warn_msg,*/ &.flavor_modal_cont {z-index: $lvl + 235 * $mpl $mp;}// flavor form modal (from bkmks menu and sections menu)
      
      // lvl 3
      &.iWall_modal_cont.modal_cont.core, &.flav_iconSelect_iWall_modal_cont, &.hold_move_modal_cont { z-index: $lvl + 300  * $mpl $mp;}
      
    }// #{$tag}

  }// @mixin core_class
  
  @include core_class("MainCore", 0);// 0 - 400

  @include core_class("PortableCore", 400);// 400 - 800

  @include core_class("LibraryCore", 400); // 400 - 800 NOTE: may conflict with portable core
  
  // ISSUE - idn how to pass state.store_tag to the rest of the modal elements without changing MainStore (shared)
  // IMPORTANT - remember CheckCore is limited to selecting items (not creating new items or editing data) 
  // so many of the modal popups layering isn't needed
  @include core_class("CheckCore", 800);// 800 - 1200 - i want this at the top of everything

  @include core_class("TopCore", 1200);

  .arc_footer{
    z-index: 60 * $mpl $mp;
  }

  /**
    possible unresolved z-Index issues: 
    - ImageMaker
  */