.review_rate_cont {
  display: flex;
}
.review_rate_cont .rate_btn {
  margin: unset;
  padding: unset;
}
.review_rate_cont .rate_btn:before {
  color: gold;
  padding: unset;
}