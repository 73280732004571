.TopOp_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.TopOp_wrapper.hide {
  display: none;
}
.TopOp_wrapper .TopOp_ctrls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}