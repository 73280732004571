$mp:!important;

  .form_ctrls{
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-areas: "views case form" "options options options";
    gap: .25rem;
    .list_btns{
      grid-area: views;
      &:first-child{justify-self: flex-start;}
      // &:last-child{justify-self: flex-end;}
      &:nth-child(2){justify-self: flex-end;}
    }
    .console_case{grid-area: case; width: 100% $mp;}
    .add_info{grid-area: form; justify-self: flex-end;}
    .refresh_btn{grid-area: form; justify-self: flex-start;}

    width:100%;min-height: 50px; border-bottom:1px solid #ccc; padding: 0 1% !important;
    &.shape_up{border:1px solid #ccc;border-radius:5px 5px 0 0;}

    .form_btns{
      height:38px !important;
      // width:15%;
      border-radius:5px;
      margin:5px 1% !important;
      padding:0  10px !important;
      background-color: #f6f6f6;
      border-color: #ddd;
      color: #333;
      text-shadow: 0 1px 0 #f3f3f3;
      line-height: 2.5rem;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      // width: 100%;
      font-size: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45% $mp;

      /*icon-font ctrls*/
      font-size: 25px;

      &.disabled::before{color: #ccc;}
    }
    .form_btns:before{ color: #545454; width: 33px $mp; height: 33px $mp; line-height: 30px $mp; /*icon-font ctrls;*/ }
    .form_btns:after{width:30px !important; height:30px !important; top:40% !important; left:40% !important;}

    // @media only screen and (min-width:300px) and (max-width:375px)
    // {
    //   display:flex; justify-content: space-evenly; flex-flow: row wrap;
    // }
    .form_ctrls_span{
      grid-area: options;
      position: relative;
      @media only screen and (min-width:300px) and (max-width:375px)
      {
        width:100%;
      }
    }// form_ctrls_span
  }// form_ctrls

  .view_srch{height:100%;}


  .arc_console_case,.console_case{display:flex; justify-content:space-around; border-radius: 7px; height: 38px !important; width: 38% !important;
  margin: 5px 0 !important;  padding: 2px !important; float:left; border: 2px solid #ddd;}
  .arc_console_ctrl,.console_ctrl, .arc_moveBtn_groupCont,  .arc_sortBtn_groupCont{
    display:flex !important;
    flex-flow:row nowrap;
    flex:2;
    justify-content: flex-start;
  }
  .arc_console_ctrl,.console_ctrl{ 
    max-width: 70%; 
    // margin-bottom: .1rem;
    height: 100%;
  }
  .arc_view_options {
    border-radius: 5px !important;
    width: 25% $mp;
    height: 100% $mp;
  }

  .arc_moveBtn_groupCase, .arc_sortBtn_groupCase {display:flex !important; flex:2; background:#c9d8ff; margin: 0 2px; border-radius: 0 7px 7px 0;}

  .chk_form_ctrls.moving{display:flex; flex-flow:column;}
  .chk.form_btns.moving{display:none !important;}
  .chk_console_case.moving{align-self:center;}

  .arc_moveBtn_groupCont, .arc_sortBtn_groupCont{justify-content: center;}/*groupCont different fron groupCase  move_move sits in Case which is in Cont*/
  .ctrl_space{display:flex; flex-flow:row nowrap;justify-content:center;}
  .ctrl_space_btn,.moveBtn, .sortBtn{
    margin:2px 3px !important;
    flex:1 !important;
    padding:0px !important;
    border-radius:0px!important;
    height:1.75em
  }
  .ctrl_space_btn:active, .bm_a_wrap:active{
    background-color: var(--active-blue)/*#38c*/ !important;
    transition-delay: 0s !important;
    color:#fff;
  }
