.board_cont .board_header .board_ctrl.board_color {
  border-radius: 50%;
  border: 12px solid black;
  border-top: 12px solid blue;
  border-right: 12px solid yellow;
  border-bottom: 12px solid red;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.board_cont {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: "head" "content";
  border: 1px solid grey;
  height: 100%;
  padding: 0.5rem;
  gap: 0.5rem;
}
.board_cont.boxed {
  position: relative;
}
.board_cont .board_header {
  display: grid;
  grid-template-rows: 2rem auto;
  grid-template-columns: 2rem auto;
  grid-template-areas: "title ctrls" "options options";
  justify-content: space-between;
  align-items: center;
  grid-area: head;
  padding: 0 0.5rem;
  color: #545454;
}
.board_cont .board_header .board_title {
  width: fit-content;
  grid-area: title;
}
.board_cont .board_header .board_ctrl {
  cursor: pointer;
  font-size: 1.2rem;
}
.board_cont .board_header .board_ctrl.board_cancel {
  margin-left: 2rem;
}
.board_cont .board_header .board_ctrls_cont {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  font-size: 1.2rem;
  grid-area: ctrls;
}
.board_cont .board_header .board_option_ctrls_cont {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  padding-top: 0.5rem;
  grid-area: options;
  background-color: #e9ecef;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
}
.board_cont .board_content .taskboard.sort_wrapper, .board_cont .board_content.clean {
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 0.5rem;
}
.board_cont .board_content {
  display: flex;
  grid-area: content;
  width: 100%;
  overflow: hidden;
}
.board_cont .board_content .taskboard.sort_wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}