$mp:  !important;
$mw: 4;

  @mixin core_class($tag, $lvl) {
    .#{$tag} {
      @content;
    }
  }

  %right-menu{
    justify-content: flex-start;
    right: 0;
    left: unset;
    padding: 0 4rem 0 1rem;
  }

  %left-menu{
    justify-content: flex-end;
    left: 0;
    right: unset;
    padding: 0 1rem 0 4rem;
  }

  %right-close{
    right: -1.5rem;
    left: unset;
  }
  %left-close{
    left: -1.5rem;
    right: unset;
  }

  .paper_cont{
    div.paper_icon {
      display: none;
      position: absolute;
      font-size: 3rem;
      bottom: 3rem;
      left: 50%;
      width: 4rem !important;
      height: 4rem;
      // text-shadow: 2px 2px 2px #ccc;
      // box-shadow: 2px 2px 2px #ccc;
      touch-action: none;
      user-select: none;
      // &.dragging{
      //   touch-action: none;
      //   user-select: none;
      // }// dragging
      // outline: white solid 2px;
      // border: 3px solid white;
      &.block{
        .paper_icon_handle {
          display: flex $mp;// block
          justify-content: center;
          align-items: center;
          font-size: 2.3rem;
          box-shadow: 4px 4px 4px #ccc;
          transition: 1s border-color;
        }
      }
      &.active{.paper_icon_handle{border-color: rgb(255, 235, 59);}}// gold
      &.inactive{.paper_icon_handle{border-color: #4caf50;}}// green
      &.menu{.paper_icon_handle{border-color: #2196f3;}}// blue
      &.right{
        // default
        @include core_class("paper_icon_menu_wrapper", 1200){@extend %right-menu};
        @include core_class("paper_icon_btn", 1200){@extend %right-close};
      }
      &.left{
        @include core_class("paper_icon_menu_wrapper", 1200){@extend %left-menu;};
        @include core_class("paper_icon_btn", 1200){@extend %left-close;};
        @include core_class("paper_icon_menu_cont", 1200){flex-flow: row-reverse;};
      }
      .paper_icon_dialog_cont{
        .paperPopupDialog{
          outline: unset;
          border: unset;
          border-radius: .5rem;
          overflow: hidden;
          display: flex;
          font-size: 1.2rem;
          width: 90vw;
          max-width: 450px;
          height: 80vh;

          .paperIcon_dialog_close_btn{
            position: absolute;
            right: .5rem;
            top: .5rem;
            font-size: 1rem;
            color: var(--off-black, #333);
            cursor: pointer;
          }// paperIcon_dialog_close_btn
        }// paperPopupDialog
      }// paper_icon_dialog_cont
      .paper_icon_handle{
        width: 100%;
        height: 100%;
        text-shadow: 2px 2px 2px #ccc;
        box-shadow: 2px 2px 2px #ccc;
        touch-action: none;
        user-select: none;
        outline: white solid 2px;
        border: 3px solid white;
      }// paper_icon_handle
      .paper_icon_menu_wrapper{
        display: flex;
        // right by default
        right: 0;
        height: 80%;
        position: absolute;
        width: #{$mw * 4}rem;// fit-content; WORKS (12rem)
        background: #f6f6f6;
        top: .5rem;
        border-radius: 1em;
        box-shadow: 2px 2px 2px #ccc;
        z-index: -1;
        transition: .5s width;
        &.active{
          // .paper_icon_menu_cont{opacity: 1;}
        }
        &.inactive{
          padding: unset $mp; width: 4rem;
        }

        .paper_icon_menu_cont{
          display: flex;
          justify-content: space-around;
          align-items: center;
          overflow: hidden;
          width: 3rem;
          height: 100%;
          flex: 0 0 100%;
          // border: 1px solid red;
          transition: 2s opacity;
          
          .paper_option_btn{
            cursor: pointer;
            font-size: 2rem;
            color: var(--off-black, #333);
            &.disabled{color: #ccc;}
          }// paper_option_btn
          
        }// paper_icon_menu_cont
      }// paper_icon_menu

      .paper_icon_btn{
        position: absolute;
        text-shadow: 2px 2px 2px #ccc;
        top: -1rem;
        right: -1.5rem;
        font-size: 1rem;
        color: #f6f6f6;
      }// paper_icon_btn


    }// paper_icon
  }// paper_cont