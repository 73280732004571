/*
// IMPORTANT: the cascade for tiny and mobile work in the opposite the smaller must be last
// to override the larger

  @include mobile{ gap: 6%; }
  @include tiny{ gap: 4%;}
*/
/*DOCS: mobile first setup "min" 
* IMPORTANT - mobile first items have to cascade in a specific order from small to large otherwise
* ISSUE the smaller queries cancel the larger ones out
* keep the same order as below
* GOTCHA: NOTE MOBILE && TINY ARE NOT IN THE SAME FLOW AS EVERYTHING ELSE - WEARABLE IS THE BOTTOM OF THE MULTI DEVICE FLOW
*/
/**
  * NOTE: this is a mobile first setup.
  */
/**
    usage:

    @use "../../scss/media";
    @include media.mobile{
      padding: 0 .25rem;
      font-size: 1.5rem;
    }

  */
.paper_cont {
  display: flex;
  flex-flow: column;
}
.paper_cont .paper_modal_main {
  height: 100%;
  padding-top: unset !important;
  overflow: unset;
}
.paper_cont .paper_modal_main .paper_wrapper {
  display: grid;
  grid-template-areas: "ctrls" "content";
  grid-template-rows: auto 8fr;
  flex-flow: column;
  height: 99vh;
  padding-top: 0.5rem;
}
.paper_cont .paper_modal_main .paper_wrapper.details {
  padding-top: 4rem;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents {
  flex: 1 auto;
  height: 100%;
  grid-area: content;
  width: 100%;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .details_modal_cont {
  height: 95% !important;
  padding: 3VH 0 0 !important;
  width: 100%;
  max-width: 100vw;
}
@media only screen and (max-width: 400px) {
  .paper_cont .paper_modal_main .paper_wrapper .paper_contents .details_modal_cont {
    height: 95vh !important;
  }
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .details_modal_cont .details_modal {
  max-height: unset !important;
  margin: 0 auto !important;
  height: 100% !important;
  width: 90% !important;
}
@media only screen and (max-width: 400px) {
  .paper_cont .paper_modal_main .paper_wrapper .paper_contents .details_modal_cont .details_modal {
    height: 95% !important;
  }
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider {
  z-index: 2000;
  transform: scale(0.7);
  height: 100%;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .sli_swiper {
  height: 100%;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .swiper-slide {
  display: flex;
  justify-content: center;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .swiper-slide-shadow {
  background: unset;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .details_content, .paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .details_snap_snap_cont, .paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .details_snap_snap_section {
  overflow: hidden;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .paper_overlay .details_modal_cont {
  z-index: -1 !important;
  position: relative;
  height: unset !important;
  padding: unset !important;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_contents .Slider .paper_overlay .details_modal_cont .details_modal {
  aspect-ratio: 10/19;
}
.paper_cont .paper_modal_main .paper_wrapper .paper_header_cont {
  flex: 1 auto;
  height: 1rem;
  grid-area: ctrls;
}

.paper_cont.detail {
  /*paper_modal_main*/
}
.paper_cont.detail .paper_modal_main {
  justify-content: center;
  align-items: flex-end;
}

#content {
  /*temp fix for layer*/
  z-index: 3100;
  width: 100%;
}

.item_loader_cont {
  display: flex;
  justify-content: center;
}

div.share_modal_cont.details {
  padding-top: 3rem !important;
}