
$mp: !important;

.tip_pages_snap_ctrls{
  padding: .5rem 0.25rem 0;
  .tip_pages_snap_btn{
    border-radius: 1em $mp;
    padding: .25rem .5rem .4rem $mp;
    display: flex $mp;
    justify-content: center $mp;
    align-items: center $mp;
    font-size: 1.2rem;
  }// tip_pages_snap_btn
}// tip_pages_snap_ctrls
.tip_pages_snap_cont {border: unset $mp; padding: .5rem .75rem $mp;}