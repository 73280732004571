$mp: !important;
%grid_item{
  min-width: 0;
  min-height: 0;
}

%hide-scroll::-webkit-scrollbar,
%hide_scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
%hide-scroll,
%hide_scroll {
  -ms-overflow-style: none;
}

.segue_view_wrapper{
  display: grid;
  & > *{@extend %grid_item;}
  grid-template-areas:
      "label"
      "main";
  grid-template-rows: auto 9fr;
  // padding: 0 1rem;
  // display: flex;
  flex-flow: column;
  height:100%;
  padding: 0 .15rem;//2px
  gap: .5rem;
  
  .segue_view_header{
    grid-area: label;
    display: grid;
    & > *{@extend %grid_item;}
    grid-template-areas: "icon title options";// bkmk feed
    grid-template-columns: auto 8fr auto;// auto 1fr
    // height: 5%;
    // height: 2.5%;
    // height: 1.7rem;
    // flex: 1 auto;
    width: 100%;
    text-shadow: 2px 2px 2px #ccc;
    font-size: 1.2rem;
    gap: .5rem;
    // padding: 0 .5rem;
    .segue_view_title{
      // margin-left: .75rem;
      grid-area: title;
      height: fit-content;
      align-self: center;
      &.golden{cursor: pointer;}
    }//segue_view_title
    .segue_view_icon{
      grid-area: icon;
      justify-self: left;
      text-align: left;
      margin-left: .75rem;
      padding: .25rem 0 0;
      height: fit-content;
      // width: 2rem !important;
      font-size: 2.2rem;
      &.golden{color: var(--site-main, gold); text-shadow: 2px 2px 2px #ccc;}
    }// projects_icon
    .segue_view_btn{
      // justify-self: right;
      align-self: center;
      text-align: center;// right;
      // margin-right: .75rem;
      padding: .25rem 0 0;
      width: 2rem $mp;
      height: fit-content $mp;
      &.active{
        color: var(--active-blue)/*#2d2dad*/ /*blue*/ $mp;
      }
    }//segue_view_options
    .segue_view_options, .TopOp_wrapper{grid-area: options; }
    .segue_view_bkmks{
      grid-area: bkmk;
      &.grey{color: #ccc;}
    }
    .segue_view_feeds{grid-area: feed;}

    .segue_view_options{
      display: flex;
      justify-content: center;
      align-items: center;
      &.filtered{
        &:has(.main_options){
          .feed_options{display: none;}
        }
        &.feed{
          // hide main_options (not necessary but just in case)
          .main_options{display: none;}
        }
      }// filtered
    }
  }//segue_view_header
  .segue_view_cont{
    grid-area: main;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 7px;
    height: 100%; //90%;
    flex: 0 1 auto;// no-grow fixes viewport height creep on init
    margin-top: unset $mp;
    // height: 32.5rem;

    &.feed:has(.segue_view_content){
      &:has(.mplx_featured) {
        border: unset $mp;
      }
    }

    .segue_view_content{
      // max-height: 77vh;
      // overflow-y: auto;
      overflow: hidden;
      height: 100%;
      // overscroll-behavior-y: contain;
      padding-top: .5rem;
      &:has(.mplx_featured){
        padding-top: unset $mp;
      }

      .mplx_featured{
        .snap_mplx_snap_ctrls{
          padding-top: unset;
        }
        .snap_mplx_snap_cont{
          border: unset $mp;
        }
      }

      .segue_item_wrapper{
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;
        height: 255px;
        margin: 5px;
        .segue_item{
          width:100%;
        }
      }
      .segue_item_observer{
        width: 100%;
      }
      .virtuoso-scroller{ @extend %hide-scroll; flex: 1 auto;}
    }// segue_view_content
  }//segue_view_cont
}

*[class*="loader_wrapper"], .segue_scroll_loader_wrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .segue_item_loader{
    display:flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    .loader_refresh{
      font-size: 1.5rem;
    }// .loader_refresh
  }
  div[class~="item_loader"]{
    border:1px solid red $mp;
  }
}
