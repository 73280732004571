.modal_cont.core {
  z-index: 1010;
}

.block {
  display: block !important;
}

.modal_temp_cont {
  z-index: 3020 !important;
}

.core_modal .modal_ctrls {
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 100% !important;
  display: flex;
}
.core_modal .modal_ctrls_wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  padding: 0 0.5rem;
}
.core_modal .modal_ctrls_wrapper.stack {
  flex-flow: column nowrap;
  gap: 0;
  height: unset;
}
.core_modal .topGoBtn {
  top: -16px !important;
  right: 32px !important;
  z-index: 100;
  padding: 0.17rem !important;
}
.core_modal .glass_confirm {
  background-image: linear-gradient(to bottom, #58c547, #0F8A09) !important;
  color: #fff !important;
}

.modal_cont.inline .glassHouse {
  display: grid;
  grid-template-areas: "inner go can";
  grid-template-columns: 5fr 0.5fr 0.5fr;
  padding: unset;
  margin: unset !important;
  width: 100%;
}
.modal_cont.inline .glassHouse .modal_ctrls_wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
}
.modal_cont.inline .glassHouse .modal_ctrls_wrapper.stack {
  flex-flow: column nowrap;
}
.modal_cont.inline .glassHouse .modal_ctrls {
  width: 2rem !important;
}
.modal_cont.inline .glassHouse .goBtn {
  grid-area: go;
}
.modal_cont.inline .glassHouse .cancelBtn {
  grid-area: can;
}
.modal_cont.inline .glass_content {
  min-height: unset;
  min-height: unset;
  margin: unset;
  padding: 0.5rem 0.25rem 0;
  grid-area: inner;
}