.pully_pull_to_refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
}
.pully_pull_to_refresh.shrink {
  max-height: 50px;
}

.pull-arrow {
  font-size: 2rem;
}
.pull-arrow.envy {
  color: #0F8A09;
}

.pull-loader {
  margin: unset;
  width: 70px;
  text-align: center;
}

.pull-loader > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.pull-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.pull-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}