



.news_btn{
  cursor: pointer;
}

.NewsForm{
  &.news_btn{
    font-size: 1.5rem;
  }
}