@use "./media";

:root{
  --shimmer-color: #fff;//#666666 or rgba(102,102,102,1)
}

%skeleton-video{
  position: relative; 
  box-sizing: content-box; 
  // min-height: 290px; 
  background-color: #ffffff; 
  border-radius: 0px 0px 0px 0px; 
  background-image: linear-gradient( #E0E0E0 20px, transparent 0 ),radial-gradient( circle 17.5px at 17.5px 17.5px, #E0E0E0 16.5px, transparent 17.5px ),linear-gradient( #E0E0E0 200px, transparent 0 );
  background-repeat: no-repeat;
  background-size: 300px 290px,35px 290px,360px 290px;
  background-position: 70px 244px,20px 236px,20px 20px;

  // border: 1px solid red;
}

%skeleton-video-before{
  content: ' '; 
  position: absolute; 
  z-index: 1; 
  width: 100%; 
  height: 290px;
  -webkit-mask-image: linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ); 
  -webkit-mask-repeat : repeat-y; 
  -webkit-mask-size : 50px 290px; 
  -webkit-mask-position: -20% 0;
  background-image: linear-gradient( var(--shimmer-color) 20px, transparent 0 ),radial-gradient( circle 17.5px at 17.5px 17.5px, var(--shimmer-color) 16.5px, transparent 17.5px ),linear-gradient( var(--shimmer-color) 200px, transparent 0 );
  background-repeat: repeat-y;
  background-size: 300px 290px,35px 290px,360px 290px;
  background-position: 70px 244px,20px 236px,20px 20px;
  animation: shineForD3-skeleton-video 2s infinite;
}

@mixin sv-mobile { // works
  //  %sv-mobile { // GOTCHA: failed - can not @extend selector across media queries
  // [sass mixin](https://sass-lang.com/documentation/at-rules/mixin)
  background-size: 72% 10%, 30% 30%, 88% 60%;
  background-position: 70px 210px, 20px 200px, 20px 20px;
}



.d3-skeleton-video {
  width: 96%;
  // min-height: 290px;
  aspect-ratio: 4/3;
  @include media.mobile{
    // min-height: 250px; 
    @include sv-mobile;// works
    // @extend %sv-mobile;// fails
  }
  margin: 1rem auto 0;
  @extend %skeleton-video;
  &::before{
    @extend %skeleton-video-before;
    @include media.mobile{
      // min-height: 250px; 
      // height: unset;
      @include sv-mobile;// works
      // @extend %sv-mobile;// fails
    }
  }// before
  div{z-index: 2;}
}



// .d3-skeleton-video:empty {
//   @extend %skeleton-video;
//   &::before{
//     @extend skeleton-video-before;
//   }
// }

// .d3-skeleton-video:empty:before {

// }

@keyframes shineForD3-skeleton-video {to {-webkit-mask-position: 120% 0}}


%skeleton-ig {
  position: relative;
  height: 452px;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  background-image: linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), linear-gradient(#cccccc 20px, transparent 0), radial-gradient(circle 14px at 14px 14px, #cccccc 13px, transparent 14px), linear-gradient(#E0E0E0 20px, transparent 0), linear-gradient(#E0E0E0 300px, transparent 0);
  background-repeat: repeat-y;
  background-size: 350px 451px, 56px 451px, 180px 451px, 30px 451px, 300px 451px, 28px 451px, 30px 451px, 360px 451px;
  background-position: 23px 415px, 317px 381px, 121px 381px, 25px 381px, 70px 17px, 29px 13px, 70px 381px, 22px 53px;
}

%skeleton-ig-before {
  content: ' ';
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 452px;
  -webkit-mask-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
  -webkit-mask-repeat: repeat-y;
  -webkit-mask-size: 50px 452px;
  -webkit-mask-position: -20% 0;
  background-image: linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), radial-gradient(circle 14px at 14px 14px, rgba(102, 102, 102, 1) 13px, transparent 14px), linear-gradient(rgba(102, 102, 102, 1) 20px, transparent 0), linear-gradient(rgba(102, 102, 102, 1) 300px, transparent 0);
  background-repeat: repeat-y;
  background-size: 350px 451px, 56px 451px, 180px 451px, 30px 451px, 300px 451px, 28px 451px, 30px 451px, 360px 451px;
  background-position: 23px 415px, 317px 381px, 121px 381px, 25px 381px, 70px 17px, 29px 13px, 70px 381px, 22px 53px;
  animation: shineForSkeleton-ig 2s infinite;
}

@keyframes shineForSkeleton-ig {
  to {
    -webkit-mask-position: 120% 0
  }
}

.d3-skeleton-ig{
  @extend %skeleton-ig;
  aspect-ratio: 2/4;
  &::before {
    @extend %skeleton-ig-before;

    @include media.mobile {
      // min-height: 250px;
      // height: unset;
      // @extend %sv-mobile;// fails
    }
  }// before

  div {
    z-index: 2;
  }
}


// skeleton generator