$mp:  !important;

.admin_container {
  height: 100%;
  // padding: 0 1rem;
  display: grid;
  grid-template-areas: "label""main";
  grid-template-rows: auto 9fr;
  height: 100%;
  padding: 0 .15rem; //2px
  gap: .5rem;

  .admin_header {
    display: grid;
    grid-template-areas: "icon title options";
    grid-template-columns: 1fr 8fr 1fr;
    overflow: unset;

    .TopOp_wrapper{
      &.hide{display:none;}
    }

    &.details_admin {
      height: 2.5rem;
    }

    // height: 5%;
    // height: 2rem;
    // flex: 1 auto;
    align-items: center;
    width: 100%;
    text-shadow: 2px 2px 2px #ccc;
    font-size: 1.2rem;
    gap: .5rem;
    // height: 8%;
    grid-area: label;

    // padding: 0 .5rem;
    .admin_title {
      // margin-left: .75rem;
      grid-area: title;
    }

    //admin_title
    .admin_icon {
      grid-area: icon;
      justify-self: left;
      text-align: left;
      margin-left: .75rem;
      padding: .25rem 0 0;
      // width: 2rem !important;
      font-size: 2.2rem;
    }

    // admin_icon
  }

  //admin_header
  .admin_error {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-size: .9em;

    border: 1px solid #ccc;
    width: 100%;
    border-radius: 7px;
    height: 90%;
    flex: 0 1 auto;
  }

  .admin_featured {
    width: 100%;
    display: grid;

    // NOTE: adjust here if i decide to do something with the extra input section - remove non-search declarations
    &.search {
      grid-template-areas: "input""nav""content";
      grid-template-rows: auto auto 12fr;
      /*.8fr*/
    }

    // search
    grid-template-areas: "nav""content";
    grid-template-rows: auto 12fr;
    /*.8fr*/
    height: 100%;

    &.details_admin {
      grid-template-rows: auto 8fr; // .8fr

      &.search {
        grid-template-rows: auto .8fr 8fr;
      }
    }

    flex: 1;
    grid-area: main;

    .admin_mock_input {
      background-color: #e9ecef;
      width: 100%;
      margin: 0 auto;
      border-radius: 7px;
      height: 80%;
      align-self: center;
      grid-area: input;

      color: #b4bbc1;
      padding: .5rem;
      display: flex;
      align-items: center;
      cursor: text;
    }

    // admin_mock_input
    .admin_nav_section_wrap {
      display: flex;
      width: 100%;
      overflow: auto;

      .admin_nav_section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin: .25rem;
        gap: .5rem;
        grid-area: nav;

        .admin_nav_ctrls {
          padding: 0 .5rem;

          &.active {
            background-color: var(--active-blue) $mp;
            color: white;
          }

          &:focus {
            outline: none;
          }
        }

        // admin_nav_ctrls
      }

      // admin_nav_section
    }

    // admin_nav_section_wrap
    .admin_featured_content {
      grid-area: content;
      // border: 1px solid #ccc;
      width: 100%;
      border-radius: 7px;
      // height: 95%;
      margin-top: .25rem;
      overflow: hidden;

      .snap_admin_snapBox{
        display: grid;
        /* flex-flow: column nowrap; */
        grid-template-areas: "ctrls" "cont";
        grid-template-rows: 2rem 90%;
        height: 100%;
        .snap_admin_snap_ctrls{
          display: flex;
          justify-content: flex-start;
          gap: .5rem;
          width: 100%; 
          padding-bottom: .3rem;
          grid-area: ctrls;
          .snap_admin_snap_btn{
            // border-radius: 7px;
            // background: #e9ecef;
            padding: 0 .5rem;
            font-size: 1.3rem;
            &.active{
              background-color: var(--active-blue);
              color: white;
            }
          }// snap_admin_snap_btn
        }// snap_admin_snap_ctrls
      }// snap_admin_snapBox
      .snap_admin_snap_cont{
        border: 1px solid #ddd;
        grid-area: cont;
      }// snap_admin_snap_cont
      
      .segue_item_wrapper {
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;
        height: 255px;
        margin: 5px;
      }// segue_item_wrapper

      .segue_view_content {
        height: 100%;
        padding: .25rem 0;
        overflow-y: auto;
      }// segue_view_content

      .arc_view_content.plain {
        padding-top: unset;
        &.admin_requests{
          .arc_item_wrapper{
            height: -moz-fit-content;
            height: fit-content;
          }
        }
      }

      .arc_item_wrapper.arc_admin{
        &.admin_requests{
          height: 70px;
          .lookout_wrapper{
            display: grid;
            grid-template-areas: "image title icon""image text icon" "ctrls ctrls ctrls";
            width: 100%;
            padding: 0.5rem 0.25rem 1rem;
            &.open{padding: 0.5rem 0.25rem;}
            position: relative;
            .lookout_title{font-size: 1.2rem;}
            .lookout_type{
              bottom: -0.5rem;
              left: 28px;
            }//lookout_type
            .lookout_icon_cont{
              // grid-area: icon;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .acs-tree__option-btn{font-size: 1.3rem;}
            }
            .acs-tree__user-input-btns_btnGrpCont{
              grid-area: ctrls;
              padding: 1rem 0.25rem 0;
              gap: 0.5rem;
            }
          }
        }// admin_requests
      }

    }// admin_featured_content
  }

  // admin_featured
  .admin_search {
    width: 100%;
    display: grid;
    grid-template-areas: "input""content";
    grid-template-rows: 5.7rem 12fr;
    height: 100%;
    flex: 1;
    grid-area: main;
    gap: .25rem;

    .admin_search_header {
      display: flex;
      justify-content: space-between;
      grid-area: input;

      .admin_search_back {
        height: fit-content;
        margin-top: .75rem;
      }

      input {
        width: 85%;
      }

      .filter_wrapper {
        width: 100%;
        border: unset;
        // .filter_option_wrapr{position: relative;}
      }

      // filter_wrapper
    }

    // admin_search_header
    .admin_search_content {
      border: 1px solid #ccc;
      width: 100%;
      border-radius: 7px;
      height: 95%;
      /* margin-top: 0.25rem; */
      overflow: hidden;

      .search_display {
        // display: none;
        height: 100%;
      }

      // search_display
    }

    // admin_search_content
  }

  // admin_search
}