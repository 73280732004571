$mp: !important;
%full-flex{
  display: flex;
  flex-flow: column;
  height: 100%;
}

:root {
  --vh: 1vh;
}

.arc_stage{
  // height: 99vh $mp; //92vh
  height: calc(var(--vh, 1vh) * 100);
  // height: 100%;// test to go with the app.js resize_body fn
  .root{height: 100%;}
}

.MainCore.modal_wrapper {
  display: flex $mp;
  justify-content: center; // a view for mobile and tablets, desktop should be side to side
  height: 100%;
  .modal_dialog.core{
    // on desktop too?
    height:100% $mp;
    margin: unset !important;
    width: 100% !important;
    padding: .5rem $mp;
  }
}

.modal_dialog.core{
  height:95% $mp;
  // max-height: 655px;
  .modal_content{
    height: 100%;
    &.chk_popup_content{
      height: 95%;// was at 100% - idk what this change will affect
      max-height: unset;// on mobile this setting disallowed the content window to reach its full height
    }// chk_popup_content
    .snapBox.snap_core{
      @extend %full-flex;
      .snap_cont{
        // height:93%;
        flex: 1 auto;
        .snap_section{
        }/*snap_section*/
      }/*snap_cont*/
      .snap_ctrls.snap_core{
        // margin: 5px;// was the margin for both the L & R ctrls - deprecated
      }/*.snap_ctrls.snap_core*/
    }/*.snapBox.snap_core*/
  }/*arc_popup_content*/
}/*arc_popup_modal*/
.chk_popup_modal.core {
    // height: unset !important;
  height: 95vh !important;
}

.panel_main{
  @extend %full-flex;
  .panel_main_content{
    grid-area: content;
    flex: 1 auto;
    .contact_info_creator{
      @extend %full-flex;
      ._display{
        flex: 1 auto;
        .fldr_display{
          @extend %full-flex;
          .filter_wrapper{width: 100%;}
          .ul_display_list{
            flex: 1 auto;
            // .core_vscroller{}
          }/*ul_display_list*/
        }/*fldr_display*/
      }/*arc_display*/
    }/*arc_contact_info_creator*/
  }/*arc_panel_main_content*/
}/*arc_panel_main*/
