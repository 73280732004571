.desc_details_cont {
  height: 100%;
  overflow-y: auto;
}

.desc_details_cont {
  position: relative;
  transition: background-color 0.5s;
}
.desc_details_cont.open {
  overflow: hidden;
}
.desc_details_cont .desc_menu_btn {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  background-color: #0000008f;
  z-index: 10;
}
.desc_details_cont .desc_menu_cont {
  position: absolute;
  right: 1rem;
  min-height: 200px;
  width: 60%;
  z-index: -1;
  font-size: 1.2rem;
}
.desc_details_cont .desc_menu_cont ul {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  list-style: none;
  padding: 3rem 2rem 2rem;
  color: white;
}
.desc_details_cont .desc_menu_cont ul li {
  text-transform: capitalize;
  cursor: pointer;
}
.desc_details_cont .desc_display {
  transform-origin: top;
  transition: transform 0.5s;
}
.desc_details_cont .desc_display .pTree_section_header {
  padding: 0 1em;
}
.desc_details_cont.open {
  background: #000;
  height: 100%;
  overflow: hidden;
}
.desc_details_cont.open .desc_menu_cont {
  z-index: 0;
}
.desc_details_cont.open .desc_display {
  background: white;
  transform: scale(0.85) translate(-21rem, 3rem);
  min-height: 100%;
}