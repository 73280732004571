
$mp: !important;
$sec: .5s;

%full-display-hack{
  // FIX for section full display issue
  // NOTE: if there is an issue with this down the line i could use :has() in its segue parent
  height: 100%;
  overflow-y: auto;
}

.desc_details_cont{
  position: relative;
  transition: background-color $sec;
  @extend %full-display-hack;
  &.open{overflow: hidden;}
  
  .desc_menu_btn{
    position: absolute;
    right:1.5rem;
    top: 1rem;
    background-color: #0000008f;
    z-index: 10;
  }
  .desc_menu_cont{
    // display: none;
    position: absolute;
    right: 1rem;
    min-height: 200px;
    // border: 1px solid red;
    width: 60%;
    z-index: -1;
    font-size: 1.2rem;
    ul{
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      list-style: none;
      padding: 3rem 2rem 2rem;
      color: white;
      li{text-transform: capitalize; cursor: pointer;}
    }
  }
  .desc_display{
    transform-origin: top;
    transition: transform $sec;
    .pTree_section_header{padding: 0 1em;}
  }
  &.open{
    background: #000;// lemonchiffon
    height: 100%;
    overflow: hidden;
    .desc_menu_cont{
      // display: block;
      // top: 1rem;
      z-index: 0;
    }
    .desc_display{
      background: white;
      transform: scale(.85) translate(-21rem, 3rem);
      min-height: 100%;// does nothing outside of .open
    }
  }
}