.ckmt_wrap {
  cursor: pointer;
}
.ckmt_wrap .ckmt_in_wrap.d3-checkbox {
  position: relative;
  margin: 0px !important;
  width: 2rem;
  display: flex;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}
.ckmt_wrap .ckmt_in_wrap.d3-checkbox input {
  position: absolute;
  top: 1rem;
  width: 1rem;
  height: 1rem;
  margin: -11px 0 0 0;
  outline: 0 !important;
  z-index: 1;
  cursor: pointer;
}

.bookmark_ckmt_wrap {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  height: 2rem;
  padding: 0 5px;
  bottom: 0.25rem;
  left: 3.5rem;
  display: flex;
  gap: 5px;
  align-items: center;
}
.bookmark_ckmt_wrap .ckmt_in_wrap .ckmt_text {
  font-size: 0.9rem;
  color: #6c757d;
}