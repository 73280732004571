
.access_select_icon_cont{
  display: flex;
  justify-content: center;
  margin: .3em auto;//5px
  width: 100%;
  .access_slider_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & label {
      margin: unset;
    }
  }
  .access_slider_cont, .access_rear_title_cont, .access_mid_title_cont {
    float: left;
    width: 100%;
    min-height: 40px;
  }
}
.access_hr{
  margin: .75rem 1rem;
  border-width: .15rem;
  border-top: 1px solid #eeeeee45;
}

  /* The switch - the box around the slider */
.switch_label.switch {
  position: relative;
  display: inline-block;
  width: 6.7em;//100px;
  height: 2.3em;//34px;
  flex: 0 0 auto;

  input{display:none;}

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: .4em;// 7px
    text-align: center;
    line-height: 2.3em;//32px;
    text-indent: 1.5em;//1.5rem;
  }
  .slider.allow, .slider.on{
    text-indent: -1.5em;//-1.5rem;
    color: white;
    text-shadow: 0 1px 0 #059;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.8em;//26px;
    width: 1.8em;//26px;
    left: .3em;//4px;
    bottom: .25em;//4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: .4em;//7px;
  }

  input:checked + .slider {
    background-color: #38c;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #059;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(4.3em);// 65px
    -ms-transform: translateX(4.3em);// 65px
    transform: translateX(4.3em);// 65px
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 2.4em;//34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}//switch_label
