.filter_modal_cont {
  position: relative;
}

.filter_select_modal {
  height: fit-content;
}

.filter_select_lightbox,
.filter_select_content {
  /*z-index: 1200;*/
}
.filter_select_lightbox .glassHouse,
.filter_select_content .glassHouse {
  width: 90vw;
}
.filter_select_lightbox .filter_select_glassHouseWrapper,
.filter_select_content .filter_select_glassHouseWrapper {
  padding: 10px;
  border: thin solid #ccc;
}
.filter_select_lightbox .filter_select_glass_title,
.filter_select_content .filter_select_glass_title {
  max-height: unset !important;
  font-size: 18px;
  padding-left: 1rem;
  margin: 0.5rem 0 0;
}
.filter_select_lightbox .filter_select_glass_header,
.filter_select_content .filter_select_glass_header {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}
.filter_select_lightbox .filter_select_glass_content,
.filter_select_content .filter_select_glass_content {
  margin: 10px 0;
}
.filter_select_lightbox .filter_select_checks,
.filter_select_content .filter_select_checks {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 0.5rem;
}
.filter_select_lightbox .filter_select_checks .ckmt_wrap,
.filter_select_content .filter_select_checks .ckmt_wrap {
  display: flex;
}
.filter_select_lightbox .arc_filterBtn_groupCont,
.filter_select_lightbox .chk_filterBtn_groupCont,
.filter_select_lightbox .arc_filterBtn_btnGrpCont,
.filter_select_lightbox .chk_filterBtn_btnGrpCont,
.filter_select_content .arc_filterBtn_groupCont,
.filter_select_content .chk_filterBtn_groupCont,
.filter_select_content .arc_filterBtn_btnGrpCont,
.filter_select_content .chk_filterBtn_btnGrpCont {
  display: grid;
  grid-row-gap: 0.25rem;
}
.filter_select_lightbox .arc_filterBtn_groupCont .arc_filterBtn,
.filter_select_lightbox .arc_filterBtn_groupCont .chk_filterBtn,
.filter_select_lightbox .chk_filterBtn_groupCont .arc_filterBtn,
.filter_select_lightbox .chk_filterBtn_groupCont .chk_filterBtn,
.filter_select_lightbox .arc_filterBtn_btnGrpCont .arc_filterBtn,
.filter_select_lightbox .arc_filterBtn_btnGrpCont .chk_filterBtn,
.filter_select_lightbox .chk_filterBtn_btnGrpCont .arc_filterBtn,
.filter_select_lightbox .chk_filterBtn_btnGrpCont .chk_filterBtn,
.filter_select_content .arc_filterBtn_groupCont .arc_filterBtn,
.filter_select_content .arc_filterBtn_groupCont .chk_filterBtn,
.filter_select_content .chk_filterBtn_groupCont .arc_filterBtn,
.filter_select_content .chk_filterBtn_groupCont .chk_filterBtn,
.filter_select_content .arc_filterBtn_btnGrpCont .arc_filterBtn,
.filter_select_content .arc_filterBtn_btnGrpCont .chk_filterBtn,
.filter_select_content .chk_filterBtn_btnGrpCont .arc_filterBtn,
.filter_select_content .chk_filterBtn_btnGrpCont .chk_filterBtn {
  background: #eee;
  border-radius: 0px;
  text-align: left;
}
.filter_select_lightbox .filter_select_glass_cancel,
.filter_select_content .filter_select_glass_cancel {
  width: 100% !important;
  margin: 0 !important;
  height: 40px;
  border-color: #ccc !important;
  background-image: unset !important;
  border: 2px solid #ccc !important;
}
.filter_select_lightbox .filter_select_glass_cancel *,
.filter_select_content .filter_select_glass_cancel * {
  font-weight: 500;
  margin: 0;
}

.filterBtn.active_filter {
  background-color: #0039A8 !important;
  color: #fff;
  touch-action: auto !important;
}

button.filterBtn:focus {
  background-color: #0F52C1 !important;
  color: #fff;
  touch-action: auto !important;
}

button.filterBtn:active {
  /*this doesn't seem to work at all*/
  background-color: #0F52C1 !important;
  color: #fff;
  touch-action: auto !important;
}

button.filterBtn:visited {
  /*this doesn't seem to work at all*/
  background-color: #0039A8 !important;
  color: #fff;
  touch-action: auto !important;
}