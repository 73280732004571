
$mp: !important;

.mdx-embed_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  .mdx-embed{
    padding: 1rem 0.5rem;
    // div{height: 200px;}
    width: 100%;

    iframe{ width: 100%; }
  }// mdx-embed
}