$mp: !important;
$send-icon-size: 1.3rem;

.send_modal{
  max-height: unset;
  height: 90%;
  .send_content{
    max-height: unset;
    height: 100%;
  }// send_content
}// send_modal

.send_wrap{ 
  max-width: 90vw;
  display: grid;
  grid-template-areas: "details" "text" "contact";
  grid-template-rows: auto auto 7fr;// 70%;
  gap: 0.25rem;
  height: 100%;
  // border:1px solid red;
  .send_details{grid-area: details;}
  .rawDrop_wrapper{
    grid-area: text;
    align-self: center;
    .rawDrop_header{
      padding: 0 0.5rem;
    }// rawDrop_header
    .rawDrop_dropdown_icon{font-size: $send-icon-size;}
  }// rawDrop_wrapper
  .send_text_cont{
    display: grid;
    grid-template-columns: auto 10%;
    grid-template-areas: "comment reset";
    // grid-area: text;
    width: 100%;
    position: relative;
    .send_text_comment{grid-area: comment;}
    .send_text_reset{
      grid-area: reset;
      justify-self: center;
      font-size: $send-icon-size;;
      color: var(--bold-grey);
    }
  }// send_text_cont
  .send_contact_cont{
    grid-area: contact;
    .search_display{
      .lookout_wrapper{
        grid-template-columns: auto 4fr auto;
        .send_btn{
          grid-area: icon;
          background: var(--active-blue);
          max-width: 3.5rem;
          aspect-ratio: 3/1.7;
          &.undo{
            background: lightgrey;
            color: black;
          }
          color: white;
          padding: 0.25rem 0.5rem;
          // height: fit-content;
          justify-self: center;
          align-self: center;
          &:has(.loader_wrapper){
            background: var(--site-main, wheat);
          }
          .loader_wrapper{
            .loader{
              border-width: 0.25rem;
              width: 1.5rem;
              height: unset;
              aspect-ratio: 1;
              margin: 0.25rem;
            }// loader
          }// loader_wrapper
        }// send_btn
      }// lookout_wrapper
    }// search_display
  }//send_contact_cont
}// .send_wrap