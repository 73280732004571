.pTree_content .pTree_inner .pTree_icon_cont, .pTree_link_content.default .pTree_link .pTree_link_icon_cont {
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
  grid-area: icon;
  gap: 1rem;
  border-left: 1px solid #ccc;
  padding-left: 0.5rem;
  margin-left: 0.5rem;
}

.pTree_content .pTree_inner .pTree_desc, .pTree_link_content.default .pTree_link .pTree_link_desc {
  color: #9e9e9e;
  grid-area: desc;
}

.pTree_content .pTree_inner .pTree_title, .pTree_link_content.default .pTree_link .pTree_link_title {
  /*text-shadow: 2px 2px 2px #ccc;*/
  grid-area: txt;
  line-height: 1.6;
  margin: unset;
}

.pTree_content .pTree_inner .pTree_desc.inactive, .pTree_content .pTree_inner .pTree_title.inactive {
  color: #9e9e9e !important;
  text-decoration: line-through;
}

.pTree_wrapper .pTree_profile {
  display: grid;
  grid-template-areas: ". . ." ". icon ." ". user ." "hr hr hr";
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 1fr auto auto 1fr;
  height: 14em;
  position: relative;
}
.pTree_wrapper .pTree_profile hr {
  grid-area: hr;
  margin: unset;
  align-self: center;
  justify-self: center;
  width: 95%;
}
.pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  gap: 0.5rem;
  grid-area: icon;
}
.pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .ProfileIcon_icon {
  justify-self: center;
  align-self: center;
  font-size: 6em;
}
.pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .image_area, .pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .canvas_area {
  display: flex;
  justify-content: center;
}
.pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .image_area img, .pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .image_area canvas, .pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .canvas_area img, .pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .canvas_area canvas {
  width: 8rem;
  height: 8rem;
}
.pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .image_area.d3-profile-rounded, .pTree_wrapper .pTree_profile .ProfileIcon_icon_wrapper .canvas_area.d3-profile-rounded {
  border-radius: unset;
  border: unset;
}
.pTree_wrapper .pTree_profile .pTree_username {
  grid-area: user;
  justify-self: center;
  align-self: center;
  text-shadow: 2px 2px 2px #ccc;
}
.pTree_wrapper .pTree_loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pTree_wrapper .lookout_wrapper {
  margin: unset;
}

.pTree_link_wrapper {
  padding: 0 1em;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
}
.pTree_link_wrapper .sort_wrapper {
  display: flex;
  flex-flow: column;
  gap: 0.5em;
}

.pTree_link_content {
  width: 100%;
  border-radius: 0.5em;
  margin: 0.5rem 0;
}
.pTree_link_content a {
  color: unset;
}
.pTree_link_content a:hover {
  color: unset;
  text-decoration: unset;
}
.pTree_link_content.default .pTree_link {
  display: grid;
  grid-template-areas: "img txt share icon" "img desc share icon";
  grid-template-columns: auto 3fr auto auto;
  border-radius: 0.5em;
}
.pTree_link_content.default .pTree_link.left_display .pTree_link_title, .pTree_link_content.default .pTree_link.left_display .pTree_link_desc {
  justify-self: flex-start;
}
.pTree_link_content.default .pTree_link:focus {
  outline: none !important;
  text-decoration: none;
}
.pTree_link_content.default .pTree_link.edit {
  gap: 0.5rem;
}
.pTree_link_content.default .pTree_link .pTree_link_title, .pTree_link_content.default .pTree_link .pTree_link_desc {
  text-align: left;
  width: 100%;
}
.pTree_link_content.default .pTree_link .pTree_QRBtn {
  grid-area: share;
  justify-self: center;
  align-self: center;
  font-size: 1.4rem;
}
.pTree_link_content.default .pTree_link .pTLink.ProfileIcon_icon_wrapper {
  grid-area: img;
  height: 4em;
  width: 5em;
  margin-right: 1rem;
}
.pTree_link_content.default .pTree_link .pTLink.ProfileIcon_icon_wrapper .image_area {
  height: 100%;
}
.pTree_link_content.default .pTree_link .pTLink.ProfileIcon_icon_wrapper .image_area img, .pTree_link_content.default .pTree_link .pTLink.ProfileIcon_icon_wrapper .image_area canvas {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.pTree_add {
  display: flex !important;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 0.5em 1em !important;
  border-radius: 0.75em;
  font-size: 1.2em;
  line-height: 1;
  right: 1em;
  bottom: 6em;
  position: sticky;
  bottom: 0.5rem;
  background: #9a9783 !important;
  color: #fff !important;
  margin: 1rem 0.25rem 0.5rem;
  gap: 1rem;
  overflow: unset !important;
}
.pTree_add p {
  font-family: "Segoe UI", Arial, sans-serif;
  margin: unset;
}

.pTree_empty {
  text-align: center;
}

.pTree_form_cont .cancelBtn {
  background-image: linear-gradient(to bottom, #f9fff8, #AAADAA) !important;
}
.pTree_form_cont .glass_confirm {
  height: unset;
  margin: unset !important;
}
.pTree_form_cont .qrc_view_modal {
  padding: 1.4em;
}

.qrc_view_content {
  max-height: unset !important;
}

.pTree_form_wrapper fieldset {
  border: unset;
}
.pTree_form_wrapper .form_quick_menu {
  width: unset;
  margin: unset;
}
.pTree_form_wrapper hr {
  margin: 0.5rem 0;
}
.pTree_form_wrapper .pTree_form {
  border: 1px solid #ccc;
  padding: 1rem;
  max-height: 72vh;
  overflow-y: auto;
}
.pTree_form_wrapper .title_error {
  margin: unset;
  background: salmon;
  padding: 0.25rem 0.5rem;
  color: white;
}
.pTree_form_wrapper .pTree_clip_type_btns.active {
  color: white;
  background-color: var(--active-blue);
}

.pTree_view_modal_cont {
  padding-top: 1em !important;
  font-size: 1rem;
}
.pTree_view_modal_cont .pTree_view_modal {
  max-height: 90vh;
  padding-bottom: 0.5em;
  margin: 1.5em auto 30px;
}
.pTree_view_modal_cont .pTree_view_modal .pTree_view_content {
  border: unset;
}

.pTree_image_preview_cont {
  padding: 1rem 0;
}
.pTree_image_preview_cont .pTree_image.ProfileIcon_icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pTree_image_preview_cont .image_area {
  height: 9rem;
  width: 9rem;
}
.pTree_image_preview_cont .image_area img, .pTree_image_preview_cont .image_area canvas {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.pTree_input, .pTree_textarea {
  display: grid;
  grid-template-areas: "label case" "input input";
  grid-template-columns: 9fr 1fr;
  gap: 0.25rem;
}
.pTree_input label, .pTree_textarea label {
  grid-area: label;
}
.pTree_input .custom_input_ctrls, .pTree_textarea .custom_input_ctrls {
  display: flex;
  flex-flow: row nowrap;
  grid-area: case;
}
.pTree_input .change_case_icon, .pTree_input .copy_me_icon, .pTree_input .show_hide_icon, .pTree_textarea .change_case_icon, .pTree_textarea .copy_me_icon, .pTree_textarea .show_hide_icon {
  font-size: 1.2rem;
  padding: 0.5rem;
  justify-self: center;
  color: #bbb;
  align-self: center;
  margin-left: 0.25em;
}
.pTree_input .change_case_icon:only-child, .pTree_input .copy_me_icon:only-child, .pTree_input .show_hide_icon:only-child, .pTree_textarea .change_case_icon:only-child, .pTree_textarea .copy_me_icon:only-child, .pTree_textarea .show_hide_icon:only-child {
  margin: unset;
}
.pTree_input input, .pTree_textarea input {
  grid-area: input;
}
.pTree_input textarea, .pTree_textarea textarea {
  grid-area: input;
}

.pTree_select {
  padding: 0.5rem;
}
.pTree_select .MuiSelect-select {
  border: 1px solid #ced4da;
  padding: 0.5rem;
}

.pTree_textarea {
  padding-top: 0.5rem;
}

.pTree_content {
  width: 100%;
  display: inline-block;
  padding: 0 1rem;
}
.pTree_content.edit {
  border: 1.5px dashed #ccc !important;
  padding: 0 1rem !important;
}
.pTree_content .pTree_inner {
  display: flex;
  flex-flow: column nowrap;
}
.pTree_content .pTree_inner .pTree_header {
  padding: 0.5rem 0;
  display: grid;
  grid-template-areas: "title icons" "desc icons" "note icons";
  grid-template-columns: 7fr auto;
  width: 100%;
}
.pTree_content .pTree_inner .pTree_header.pTree_section_header .pTree_icon_cont {
  width: 4rem;
  flex-flow: row wrap;
}
.pTree_content .pTree_inner .pTree_item_list {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}
.pTree_content .pTree_inner .pTree_item_list .lookout_wrapper {
  min-height: 5rem;
  position: relative;
}
.pTree_content .pTree_inner .pTree_item_list .lookout_wrapper .lookout_type {
  top: 2rem;
  bottom: unset;
  left: 50px;
}
.pTree_content .pTree_inner .pTree_title {
  grid-area: title;
  text-align: left;
}
.pTree_content .pTree_inner .pTree_text {
  grid-area: note;
  padding: 0.25rem 0;
}
.pTree_content .pTree_inner .pTree_section_title {
  color: #9e9e9e;
}
.pTree_content .pTree_inner .pTree_text_header.edit {
  display: grid !important;
  color: black;
}
.pTree_content .pTree_inner .pTree_icon_cont {
  grid-area: icons;
}
.pTree_content .pTree_inner .pTree_icon_cont .pTree_icon {
  cursor: pointer;
}
.pTree_content .pTree_inner .pTree_add {
  grid-area: add;
  margin: 1rem 0.5rem;
}

.pTree_section_content .pTree_section_ptree.hide .pTree_wrapper.page_section {
  border: 1px solid red;
  height: 0rem;
  overflow: hidden;
}
.pTree_section_content .pTree_section_ptree .pTree_link_wrapper {
  flex: unset !important;
  padding: unset;
}
.pTree_section_content.auto_spacer, .pTree_section_content.auto-spacer {
  padding-top: 3rem !important;
}

.pTree_clip_cont {
  padding-top: 1rem;
}
.pTree_clip_cont .pTree_desc {
  color: #9e9e9e;
}
.pTree_clip_cont .mdx-embed {
  display: flex;
  justify-content: center;
}

.MetaTree .pTree_link_header {
  padding: 0.5rem 0 !important;
}
.MetaTree .meta_tree.rich_cont {
  position: relative;
  cursor: pointer;
  padding: 1rem 0;
}
.MetaTree .meta_tree.rich_cont .metaTree_QRBtn {
  position: absolute;
  top: 1.5em;
  right: 2em;
  font-size: 1.3em;
}